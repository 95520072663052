import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import { vw } from "../../util";
// import { options } from "./pricingData";
import { useEffect, useState } from "react";
export const FeaturesColumn = (props) => {
  const { cardIndex, shownProducts, setShownProducts, options } = props;
  console.log(shownProducts, "checking shown products");
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    // Create a copy of shownProducts
    const updatedS = { ...shownProducts };

    // Remove the current cardIndex from updatedS and convert it to array of values
    delete updatedS[cardIndex];
    const sArray = Object.values(updatedS);
    console.log(sArray, "sArray of card", cardIndex);
    // filter elements with index other than updatedS from options array using index in data

    const result = options.map((option) => sArray.includes(option.index));

    const filtered = options.filter((_, index) => !result[index]);
    const targetIndex = filtered.findIndex(
      (option) => option.index === shownProducts[cardIndex]
    );
    setProduct(targetIndex);
    setFilteredOptions(filtered);
    console.log(filtered, "filtered from useEffect for card", cardIndex);
  }, [shownProducts]);
  // useEffect(() => {
  //   const targetIndex = filteredOptions.findIndex(
  //     (option) => option.index === shownProducts[cardIndex]
  //   );
  //   console.log(
  //     filteredOptions,
  //     targetIndex,
  //     "targetIndex for card",
  //     cardIndex
  //   );
  //   setProduct(targetIndex);
  // }, [shownProducts, cardIndex, filteredOptions]);

  var [product, setProduct] = useState(0);
  const handleLeftArrow = () => {
    if (product !== 0) {
      setProduct(product - 1);
      setShownProducts({
        ...shownProducts,
        [cardIndex]: filteredOptions[product - 1].index,
      });
      console.log(
        cardIndex,
        "card",
        product,
        "product",
        filteredOptions[product - 1].index,
        "setting index of current data"
      );
    }
  };
  const handleRightArrow = () => {
    // alert("clicked on right arrow")
    if (product < filteredOptions.length - 1) {
      setProduct(product + 1);
      setShownProducts({
        ...shownProducts,
        [cardIndex]: filteredOptions[product + 1].index,
      });
      console.log(
        cardIndex,
        "card",
        product,
        "product",
        filteredOptions[product + 1].index,
        "setting index of current data"
      );
    }
  };
  return (
    <VStack
      style={{
        width: "30.44%",
        borderRadius: "14px",
        border: "0.5px solid #7D2BE5",
        background: "#FFF",
        padding: "5px",
        gap: "5px",
      }}
    >
      <Center
        onLayout={(e) => {
          props.setSelectionHeight(e.nativeEvent.layout.height);
        }}
        style={{
          borderRadius: "12px",
          padding: "17px",
          background: "linear-gradient(302deg, #7D2BE5 21.24%, #BB85FF 84.48%)",
        }}
      >
        <HStack style={{ width: "100%", justifyContent: "space-between" }}>
          {/* left icon */}
          {!props.defaultOnly && (
            <Center>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  flexShrink: 0,
                  transform: "rotate(180deg)",
                }}
                onClick={handleLeftArrow}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.5671 10.921L10.3612 4.45135C10.2251 4.30833 10.0632 4.19482 9.88488 4.11735C9.70652 4.03988 9.51521 4 9.32199 4C9.12877 4 8.93747 4.03988 8.75911 4.11735C8.58075 4.19482 8.41886 4.30833 8.2828 4.45135C8.01019 4.73724 7.85718 5.12398 7.85718 5.52709C7.85718 5.9302 8.01019 6.31694 8.2828 6.60283L13.4641 12.0044L8.2828 17.406C8.01019 17.6919 7.85718 18.0786 7.85718 18.4817C7.85718 18.8848 8.01019 19.2716 8.2828 19.5575C8.41956 19.6989 8.58176 19.8108 8.76009 19.8867C8.93841 19.9626 9.12937 20.0011 9.32199 20C9.51462 20.0011 9.70557 19.9626 9.8839 19.8867C10.0622 19.8108 10.2244 19.6989 10.3612 19.5575L16.5671 13.0878C16.7043 12.9459 16.8132 12.7772 16.8875 12.5912C16.9618 12.4053 17 12.2058 17 12.0044C17 11.803 16.9618 11.6035 16.8875 11.4176C16.8132 11.2317 16.7043 11.0629 16.5671 10.921Z"
                    fill={product === 0 ? "rgba(255,255,255,0.5)" : "white"}
                  />
                </svg>
              </div>
            </Center>
          )}
          {/* center content */}
          <Center style={{ margin: "auto" }}>
            <Text
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: 20,
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              {filteredOptions[product]?.product}
            </Text>
            <Box
              style={{
                border: "3px solid #FFF",
                borderRadius: "5px",
                width: "58px",
                marginTop: "7px",
              }}
            />
            <Text
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: 10,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "7px",
              }}
            >
              {`(${filteredOptions[product]?.trail})`}
            </Text>
          </Center>
          {/* right icon */}
          {!props.defaultOnly && (
            <Center>
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  flexShrink: 0,
                }}
                onClick={() => {
                  handleRightArrow();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.5671 10.921L10.3612 4.45135C10.2251 4.30833 10.0632 4.19482 9.88488 4.11735C9.70652 4.03988 9.51521 4 9.32199 4C9.12877 4 8.93747 4.03988 8.75911 4.11735C8.58075 4.19482 8.41886 4.30833 8.2828 4.45135C8.01019 4.73724 7.85718 5.12398 7.85718 5.52709C7.85718 5.9302 8.01019 6.31694 8.2828 6.60283L13.4641 12.0044L8.2828 17.406C8.01019 17.6919 7.85718 18.0786 7.85718 18.4817C7.85718 18.8848 8.01019 19.2716 8.2828 19.5575C8.41956 19.6989 8.58176 19.8108 8.76009 19.8867C8.93841 19.9626 9.12937 20.0011 9.32199 20C9.51462 20.0011 9.70557 19.9626 9.8839 19.8867C10.0622 19.8108 10.2244 19.6989 10.3612 19.5575L16.5671 13.0878C16.7043 12.9459 16.8132 12.7772 16.8875 12.5912C16.9618 12.4053 17 12.2058 17 12.0044C17 11.803 16.9618 11.6035 16.8875 11.4176C16.8132 11.2317 16.7043 11.0629 16.5671 10.921Z"
                    fill={
                      product === filteredOptions.length - 1
                        ? "rgba(255,255,255,0.5)"
                        : "white"
                    }
                  />
                </svg>
              </div>
            </Center>
          )}
        </HStack>
      </Center>
      {filteredOptions[product]?.features.map((item, index) => (
        <VStack key={index} style={{ gap: "5px" }}>
          {item.basicFeatures?.map((feature, idx) => (
            <Center
              style={{
                borderRadius: "10px",
                border: "1px solid #F7ECFD",
                background:
                  "linear-gradient(271deg, rgba(125, 43, 229, 0.10) 4.57%, rgba(187, 133, 255, 0.10) 93.23%)",
                minHeight: "46px",
                paddingHorizontal: "8px",
              }}
            >
              <Text
                style={{
                  color: "#382058",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "15px",
                }}
              >
                {feature.value ? feature.value : "-"}
              </Text>
            </Center>
          ))}
          {index === 0 && <Box style={{ height: "136px" }} />}
          {item.additionalFeatures?.map((feature, idx) => (
            <Center
              style={{
                borderRadius: "10px",
                border: "1px solid #F7ECFD",
                background:
                  "linear-gradient(271deg, rgba(125, 43, 229, 0.10) 4.57%, rgba(187, 133, 255, 0.10) 93.23%)",
                minHeight: "46px",
                paddingHorizontal: "8px",
              }}
            >
              <Text
                style={{
                  color: "#382058",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "15px",
                }}
              >
                {feature.value ? feature.value : "-"}
              </Text>
            </Center>
          ))}
        </VStack>
      ))}
    </VStack>
  );
};
