import React from "react";
import { Stack, useBreakpointValue, useMediaQuery } from "native-base";

import Small from "./Small";
import Medium from "./Medium";
import Large from "./Large";

const FoldFour = () => {
    const sz = useBreakpointValue({
      base: "base",
      lg: "lg",
      xl: "xl",
      md: "md",
      sm: "sm",
    });
  
    const [landscape] = useMediaQuery([
      {
        orientation: "landscape",
      },
      {
        orientation: "portrait",
      },
    ]);
    return (
      <Stack name="fold4" className="element">
        {/* for small devices */}
        {(sz === "base" || sz === "sm") && (
          <Small
            landscape={landscape}
            sz={sz}
          />
        )}
        {/* for medium devices */}
        {sz === "md" && (
          <Medium
            landscape={landscape}
            sz={sz}
          />
        )}
        {/* for large devices */}
        {(sz === "lg" || sz === "xl") && (
          <Large
            landscape={landscape}
            sz={sz}
          />
        )}
      </Stack>
    );
  };
  
  export default React.memo(FoldFour);