const TickIcon = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Checkmark / undefined / Glyph: undefined">
      <path
        id="Vector"
        d="M9.91667 19.3501L5.49163 14.925C5.03601 14.4695 4.29732 14.4695 3.8417 14.925C3.3861 15.3806 3.3861 16.1194 3.8417 16.5749L9.0917 21.8249C9.54732 22.2805 10.286 22.2805 10.7416 21.8249L23.5749 8.99163C24.0305 8.53601 24.0305 7.79732 23.5749 7.3417C23.1194 6.8861 22.3806 6.8861 21.925 7.3417L9.91667 19.3501Z"
        fill="#7D2BE5"
      />
    </g>
  </svg>
);

const CrossIcon = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Cross">
      <g id="icon-x-2">
        <path
          id="Path_100-2"
          d="M21 7L7 21"
          stroke="#FF5136"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_101-2"
          d="M7 7L21 21"
          stroke="#FF5136"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

// const DashIcon = (props) => (
//   <svg
//     width={16}
//     height={2}
//     viewBox="0 0 16 2"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     {...props}
//   >
//     <path
//       id="Line 62"
//       d="M1 1H15"
//       stroke="#494440"
//       strokeWidth={2}
//       strokeLinecap="round"
//     />
//   </svg>
// );

export const featuresData = [
  {
    feature: "Driving for Dollars",
    shadow: <TickIcon />,
    dealmachine: <TickIcon />,
    propstream: <TickIcon />,
    resimpli: <TickIcon />,
    batchLeads: <TickIcon />,
  },
  {
    feature: "Supervising Driving for Dollars",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Off-Market and On-Market(MLS) properties",
    shadow: <TickIcon />,
    dealmachine: <TickIcon />,
    propstream: <TickIcon />,
    resimpli: "No MLS Properties",
    batchLeads: <TickIcon />,
  },
  {
    feature: "In-app Calling and Texting",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <TickIcon />,
    batchLeads: "Requires Batch Dialer",
  },
  {
    feature: "Drip Campaign Property List Organization",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Bulk Drip Campaign Management",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Skiptracing",
    shadow: <TickIcon />,
    dealmachine: <TickIcon />,
    propstream: <TickIcon />,
    resimpli: <TickIcon />,
    batchLeads: <TickIcon />,
  },
  {
    feature: "Buyer List Automation",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Email and Mail Campaigns",
    shadow: <TickIcon />,
    dealmachine: <TickIcon />,
    propstream: <TickIcon />,
    resimpli: <TickIcon />,
    batchLeads: <TickIcon />,
  },
  {
    feature: "Campaign Scheduling Calendar",
    shadow: <TickIcon />,
    dealmachine: <TickIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "In-app Remainders",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Team User Performance Assessment",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Personalized Date-Based Analytics",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Team User's Property List Accessibility",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Roles in Team Management",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Extra Costs for Feature-Specific Services",
    shadow: <CrossIcon />,
    dealmachine: <TickIcon />,
    propstream: <TickIcon />,
    resimpli: <TickIcon />,
    batchLeads: <TickIcon />,
  },
  {
    feature: "Export Properties",
    shadow: <CrossIcon />,
    dealmachine: <TickIcon />,
    propstream: <TickIcon />,
    resimpli: <TickIcon />,
    batchLeads: <TickIcon />,
  },
  {
    feature: "Extra Drivers",
    shadow: <CrossIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <TickIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Additional Monthly Leads with a Price Tag",
    shadow: "No Additional Monthly Leads",
    dealmachine: "No Additional Monthly Leads",
    propstream: <TickIcon />,
    resimpli: "No Additional Monthly Leads",
    batchLeads: <TickIcon />,
  },
  {
    feature: "Feature Requests",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
  {
    feature: "Fully Featured on Web and Mobile",
    shadow: "On Web and Mobile",
    dealmachine: "On Web Only",
    propstream: "On Web Only",
    resimpli: "On Web Only",
    batchLeads: "On Web Only",
  },
  {
    feature: "Forever Free Plan",
    shadow: <TickIcon />,
    dealmachine: <CrossIcon />,
    propstream: <CrossIcon />,
    resimpli: <CrossIcon />,
    batchLeads: <CrossIcon />,
  },
];
