import React, { useState } from "react";
import {
  VStack,
  Center,
  Text,
  HStack,
  Pressable,
  Box,
  Divider,
  ScrollView,
} from "native-base";

import { vh, vw } from "../../util";
import DropDown from "../../assets/svg/DropDown";
import CustomCarousel from "../../SPcarousel/CustomCarousel";
import PinProperties from "../../assets/svg/whatweoffer/PinProperties";
import AddProperties from "../../assets/svg/whatweoffer/BulkAddProperties";
import MLSProperties from "../../assets/svg/whatweoffer/MLSProperties";
import ListManagement from "../../assets/svg/ListManagement";
import SmartSearch from "../../assets/svg/whatweoffer/SmartSearch";
import ParcelLookup from "../../assets/svg/whatweoffer/ParcelLookup";
import OwnerLookup from "../../assets/svg/whatweoffer/OwnerLookup";
import Campaigns from "../../assets/svg/Campaigns";
import AutomatedMails from "../../assets/svg/whatweoffer/AutomatedMails";
import Pipelines from "../../assets/svg/whatweoffer/Pipelines";
import LiveDrivingMonitor from "../../assets/svg/whatweoffer/LiveDrivingMonitor";
import DrivingRoutes from "../../assets/svg/whatweoffer/DrivingRoutes";
import Analytics from "../../assets/svg/whatweoffer/Analytics";
import FeaturesRequest from "../../assets/svg/whatweoffer/FeaturesRequest";
import UserRoles from "../../assets/svg/whatweoffer/UserRoles";
import BuyerContact from "../../assets/svg/BuyerContact";
import PaginationDots from "../../pagination";
import { featuresData } from "./featuresData";
import CallsTexts from "../../assets/svg/CallsTexts";
import LandingPage from "../../assets/svg/LandingPage";

const offers = [
  {
    // CARD-1
    route: "/pin-properties",
    heading: "Pin Properties",
    text: "Search for a property address or drop a pin on the map to add a property to your list.",
    svgindex: 0,
    description:
      "Finding quality properties and adding them to your list is now an effortless process. There are two distinct ways within the Shadow app to find and add parcels to your property list. Any property, whether it’s in your neighborhood or across the country, can be quickly shortlisted. When you find a property that meets your requirements, immediately add it to your property list with a single tap. A pin will appear on the screen with the owner’s information, tap again on “add,” and you’ve saved the property for future reference.",
  },
  // CARD-2
  {
    route: "/bulk-add-properties",
    heading: "Bulk Add Properties",
    text: "Draw a selection line around the properties on the map to add multiple properties at once to your list.",
    svgindex: 1,
    description:
      "Add multiple properties to your list in an instant using this feature. When you find numerous potential properties in a neighborhood, you can add them all together to your list and save time. The mere act of drawing an enclosed figure (a circle, square, rectangle, polygon, etc.) around all the required properties and tapping “Save or Add” will shortlist them for you. The more properties you can deal with, the better your chances of conversion.",
  },
  // CARD-3
  {
    route: "/mls-properties",
    heading: "MLS Properties",
    text: "Explore the best On-Market properties and add them to your list with a single click.",
    svgindex: 2,
    description:
      "Quickly discover the best On-Market properties directly from the maps using the Shadow App. MLS are ‘on-sale’ properties that you can participate in purchasing right away. You can differentiate between an MLS property as a “$” sign denotes it. This feature displays the characteristics of each property, such as its structure, address, previous sale value, and current on-sale price of the property. You can also add the property directly to your pipeline,list or both for further categorization.",
  },
  // CARD-4
  {
    route: "/list-management",
    heading: "List Management",
    text: "A lucid approach to reach multiple property owners automatically using list specific campaigns",
    svgindex: 3,
    description:
      "Create custom lists in your app to manipulate and organize your properties. List management is a feature that enables you to create unlimited custom lists of properties and also attach a campaign to the list directly. Instead of attaching a campaign to each property, add all the similar properties to one list and tie one suitable campaign for all properties within.",
  },
  {
    // CARD-5
    route: "/smart-search",
    heading: "Skip Trace",
    text: "Get instant access to phone numbers and e-mail addresses of potential property owners.",
    svgindex: 4,
    description:
      "An intelligent way to gain quick access to the personal contact information of property owners. You can get the property owner’s phone number and e-mail address directly from the app using Skip Trace.",
  }, // CARD-6
  {
    route: "/parcel-lookup",
    heading: "Parcel lookup",
    text: "Get detailed information about each property, including its structure, deeds, taxes, assessments, and valuations.",
    svgindex: 5,
    description:
      "Get complete detail of every property on your bucket list through the Shadow App. Knowing the ins and outs of the property you wish to invest in is necessary. Therefore, we’ve introduced this feature for you. Parcel Lookup gives you a comprehensive view of your shortlisted property, including a mailing address, property area (in acres), county, lot number, approximate structure, official deed, previously paid taxes, internal and external property assessment, and actual property value.",
  },
  // CARD-7
  {
    route: "/owner-lookup",
    heading: "Owner Lookup",
    text: "Find property owners and obtain their postal addresses to freewheel your deal to a closure.",
    svgindex: 6,
    description:
      "Obtaining the actual owner’s information is a hassle on its own, but not anymore! Owner lookup is a unique feature that displays the postal address of a property owner. Using this feature, you can interact directly with the property owner and discuss the flow of your deal. Attach and run postal campaigns to each property and entice the owners to respond positively.",
  },
  // CARD-8
  {
    route: "/campaigns",
    heading: "Campaigns",
    text: "Create your iconic signatures and templates to reach 50000 potential property owners at once",
    svgindex: 7,
    description:
      "Campaigns automate 90% of your conversations and take you directly to closure. You can run E-mail and Postal Mail campaigns using Shadow app. When setting up a campaign, you can justify your iconic signature that remains constant throughout your interactions and portrays you as an authority in the marketplace. Moreover, you can create reusable templates that streamline your process, so you don’t waste time relaying the same information to every property owner.",
  },
  {
    // CARD-9
    route: "/mails",
    heading: "Setup Automated Mails/Emails",
    text: "Effortlessly set up and send initial and follow-up postal mails & Emails to property owners.",
    svgindex: 8,
    description:
      "Send e-mails and postal mail to property owners. After setting up the campaigns, you have the opportunity to reach out to property owners using their e-mail and postal addresses. You might be in-discussion with multiple property owners at once, and the quality of your message might be affected somewhere along the way. Therefore, setting up automated e-mails and mails becomes vital to sustaining your conversation flow.",
  },
  // CARD-10
  {
    route: "/pipelines",
    heading: "Pipelines",
    text: "A versatile section to update you on the individual property deal status.",
    svgindex: 9,
    description:
      "A versatile and personalized section designed to help you categorize the vast property list according to your needs. For instance, you might want to organize the properties in your list with respect to the deal status. Meaning you can create multiple pipelines such as Postcard/e-mail sent, Response pending, Appointment set, deal Postponed, In negotiation, deal closed, Offer Rejected, Invested In, Portfolio, and add the properties to each pipeline respectively. You can move the property from one pipeline to another and monitor the flow.",
  },
  // CARD-11
  {
    route: "/live-driving-monitor",
    heading: "Live Driving Monitor",
    text: "Keep track of your team members in real-time as they drive around the city to find amazing properties for you.",
    svgindex: 10,
    description:
      "Whenever your team is dispatched to find excellent properties for you, use this feature and keep track of their route. As the name suggests, a live driving monitor gives you a view of all your drivers on the road. When your drivers are on the road, even if they are in different parts of the city, you can see them all on one screen.So that you can communicate with them during the drive and instruct.",
  },
  // CARD-12
  {
    route: "/driving-routes",
    heading: "Driving Routes",
    text: "Surveil every driving data including your route, miles driven, time taken, and properties added, from a single screen",
    svgindex: 11,
    description:
      "Whenever you or your drivers use the Shadow App, your entire route will be mapped out and stored in the driving routes section. As the drivers move, the distance they cover, the direction they take, the places they visit, the properties they add, and the total time they take to complete the ride are all registered within the app for your future reference. You can review each driving circuit at a later date and formulate a strategy to shortlist properties within that area.",
  },
  {
    // CARD-13
    route: "/analytics",
    heading: "Analytics",
    text: "Inspect your real estate activities, actions, and progress, within the app and make informed decisions.",
    svgindex: 12,
    description:
      "Using the analytics feature, keep track of your entire real estate business and management from a single app. When you have comprehensive data to evaluate, you can make informed decisions, re-route your strategies, and close more deals. In the analytics tab, you gain access to the following: Number of properties saved (in chronological order of their date), Total campaigns running, Current status of each campaign, Mails sent , E-mails sent, Miles driven, Time driven, Investments, etc. The analytics field is easily customizable based on the time period.",
  },
  // CARD-14
  {
    route: "/feature-requests",
    heading: "Feature Requests",
    text: "Inform us about your feature requirements and we will make it happen for you!",
    svgindex: 13,
    description:
      "A newly reformed module that helps us add more features to the Shadow App based on your needs and requirements. We already offer an extended series of features to you; however, when you need more features to run your real estate business, we can also make it happen for you. We have created a personalized space within the app where you can describe your feature need to us. It will be visible to each Shadow App user and operates on a voting mechanism. For instance, the feature request that gets the most votes during a fixed period will be implemented and availed before the others.",
  },
  // CARD-15
  {
    route: "/user-roles",
    heading: "User Roles",
    text: "Manage your team and business hierarchy effortlessly by entitling them as Owner, Admin, and User.",
    svgindex: 14,
    description:
      "The user roles maintain a justifiable hierarchy within your real estate business. When you and your team use the app, you can assign various roles to each member, and their access to your team account will be based on their title. We currently have three positions in the app: Owner, Admin, and User. You get the owner’s title as a subscribed account holder of the Shadow App. Furthermore, anyone who joins after you will be assigned a different role by you. The owner has the ultimate control of the app. The second is the Admin and the one with the most limited access in the user.",
  },
  //CARD-16
  {
    route: "/buyer-contacts",
    heading: "Buyer Contacts (Coming Soon)",
    text: "An intelligent way to share and manage hand-picked properties with buyers.",
    svgindex: 15,
    description:
      "Automatically share shortlisted properties with buyers and help them make a decision from the app. The whole point of using the app is managing and making a sale. This feature enables you to share hand-picked properties with interested buyers, meet their requirements, and close multiple deals simultaneously. All you have to do is add buyers to the app, update their contact details, and run automated E-mail and SMS campaigns that send them a periodic list of properties they might be interested in.",
  },
  //CARD-17
  {
    route: "/calls-texts",
    heading: "Calls and Texts",
    text: "Manage incoming and outgoing calls and SMS directly from the app to enhance efficiency and productivity.",
    svgindex: 16,
    description:
      "Simplify call and text management by consolidating them in one place, providing a convenient way to keep track of your communications. Forget the hassle of switching between various apps and platforms granting you easy access to all the necessary tools at your fingertips.",
  },
  //CARD-18
  {
    route: "/landing-pages",
    heading: "Custom Landing Page to Your Buyers",
    text: "Create customized landing pages for buyers with shortlisted properties and close deals faster.",
    svgindex: 17,
    description:
      "A sophisticated way to empower you to craft custom, visually stunning showcases for your diverse shortlisted properties, revolutionizing how you present listings to potential clients. Seamlessly align each landing page with the unique attributes and charm of individual properties, going beyond conventional presentations to create a truly immersive experience for prospective buyers, helping you bring more versatility to your catalog and increase your chances of closing deals.",
  },
];

const Icons = [
  <PinProperties />,
  <AddProperties />,
  <MLSProperties />,
  <ListManagement />,
  <SmartSearch />,
  <ParcelLookup />,
  <OwnerLookup />,
  <Campaigns />,
  <AutomatedMails />,
  <Pipelines />,
  <LiveDrivingMonitor />,
  <DrivingRoutes />,
  <Analytics />,
  <FeaturesRequest />,
  <UserRoles />,
  <BuyerContact />,
  <CallsTexts />,
  <LandingPage />,
];
const Small = ({ landscape, gifs }) => {
  //console.log("Fold 4 small Landscape", landscape);
  let i = 0;
  const [feature, setFeature] = useState("Pin Properties");
  const [isOpen, setOpen] = useState(false);
  const [item, setItem] = useState(offers[0]);
  const ListItem = ({ offer }) => {
    return (
      <Pressable
        onPress={() => {
          setFeature(offer.heading);
          setItem(offer);
          setOpen(!isOpen);
        }}
      >
        <Text
          // style={{
          //   textAlign: "left",
          //   fontFamily: "Poppins_medium",
          //   color: feature === offer.heading ? "#7D2BE9" : "#988AB4",
          // }}
          style={{
            textAlign: "left",
            fontFamily: "Poppins",
            fontWeight: "600",
            color: feature === offer.heading ? "#7D2BE9" : "#988AB4",
          }}
          fontSize={window.innerWidth <= 360 ? 14 : 16}
        >
          {offer.heading}
        </Text>
        {offer.heading !== "Buyer Contacts (Coming Soon)" ? (
          <Divider mt={1} />
        ) : (
          <></>
        )}
      </Pressable>
    );
  };
  const [totalSlides, setTotalSlides] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleSlideChange = (currentIndex, totalSlides) => {
    console.log("Current slide index:", currentIndex);
    setCurrentSlide(currentIndex);
    console.log("Total slides:", totalSlides);
    setTotalSlides(totalSlides);
  };
  return (
    <VStack
      style={{
        // background: `linear-gradient(90deg, rgba(234,215,249,1) 0%, rgba(250,246,254,1) 25%, rgba(255,255,255,1) 50%, rgba(255,247,227,1) 75%, rgba(255,244,217,1) 100%)`,
        // justifyContent: "center",
        minHeight: landscape ? vw(1700) : vw(3500),
      }}
    >
      <Center>
        <Text
          fontSize={vw(landscape ? 65 : 130)}
          marginTop={vh(40)}
          marginBottom={vh(38)}
          fontFamily={"Poppins"}
          fontWeight={"600"}
          color={"#7D2BE9"}
          textAlign="center"
        >
          What We Offer
        </Text>
        <CustomCarousel
          animation="sliding"
          onSlideChange={handleSlideChange}
          // autoplay={true}
          // interval={8000}
          loop={true}
          style={{
            width: "100%",
            height: "min-content",
          }}
          showArrows={false}
        >
          {offers.map((offer) => {
            return (
              <Center>
                <VStack
                  style={{
                    width: "80vw",
                    height: vw(landscape ? 1300 : 2700),
                    backgroundColor: "#F9F1FD",
                    marginTop: vw(landscape ? 65 : 130),
                    borderRadius: vw(landscape ? 30 : 60),
                  }}
                >
                  <Center
                    style={{
                      position: "relative",
                      width: vw(landscape ? 130 : 260),
                      height: vw(landscape ? 130 : 260),
                      overflow: "hidden",
                      backgroundColor: "#8339FF80",
                      borderRadius: vw(landscape ? 40 : 80),
                      alignSelf: "center",
                      marginTop: vw(landscape ? -65 : -130),
                    }}
                  >
                    <Center
                      style={{
                        position: "absolute",
                        top: vw(landscape ? 5 : 10),
                        left: vw(landscape ? 5 : 10),
                        width: `calc(100% - ${vw(landscape ? 10 : 20)})`,
                        height: `calc(100% - ${vw(landscape ? 10 : 20)})`,
                        borderRadius: vw(landscape ? 38 : 75),
                        backgroundColor: "#7D2BE9",
                      }}
                    >
                      {Icons[offer.svgindex]}
                    </Center>
                  </Center>
                  <Center
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginTop: vw(landscape ? 65 : 130),
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: vw(landscape ? 50 : 100),
                        color: "#6D549F",
                        textAlign: "center",
                      }}
                    >
                      {offer.heading}
                    </Text>
                  </Center>
                  <Center
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginTop: vw(65),
                    }}
                  >
                    <Text
                      style={{
                        color: "#303030",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        fontSize: vw(landscape ? 40 : 65),
                        marginTop: vh(10),
                        lineHeight: "auto",
                      }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      textAlign={"center"}
                    >
                      {offer.description}
                    </Text>
                  </Center>
                </VStack>
              </Center>
            );
          })}
        </CustomCarousel>
        <Box mt={vw(landscape ? -50 : -100)}>
          <PaginationDots total={16} currentIndex={currentSlide} />
        </Box>
      </Center>
      <ScrollView
        horizontal
        style={{ width: "100%", marginBottom: "5.56%", marginTop: "5.56%" }}
      >
        <Center width={"1366px"}>
          <VStack width={"94.44%"}>
            <table className="roundedCorners">
              <tr>
                <th
                  className="table-cell1"
                  style={{ fontSize: vw(landscape ? 40 : 65) }}
                >
                  Features
                </th>
                <th
                  className="table-cell2"
                  style={{ fontSize: vw(landscape ? 40 : 65) }}
                >
                  Shadow
                </th>
                <th
                  className="table-cell3"
                  style={{ fontSize: vw(landscape ? 40 : 65) }}
                >
                  DealMachine
                </th>
                <th
                  className="table-cell4"
                  style={{ fontSize: vw(landscape ? 40 : 65) }}
                >
                  PropStream
                </th>
                <th
                  className="table-cell5"
                  style={{ fontSize: vw(landscape ? 40 : 65) }}
                >
                  ReSimpli
                </th>
                <th
                  className="table-cell6"
                  style={{ fontSize: vw(landscape ? 40 : 65) }}
                >
                  BatchLeads
                </th>
              </tr>
              {featuresData.map((item, index) => (
                <tr
                  key={index}
                  style={{ background: index % 2 !== 0 ? "white" : "#F4ECFD" }}
                >
                  <td
                    className="table-cell1"
                    style={{ fontSize: vw(landscape ? 40 : 65) }}
                  >
                    {item.feature}
                  </td>
                  <td
                    className="table-cell2"
                    style={{ fontSize: vw(landscape ? 40 : 65) }}
                  >
                    {item.shadow}
                  </td>
                  <td
                    className="table-cell3"
                    style={{ fontSize: vw(landscape ? 40 : 65) }}
                  >
                    {item.dealmachine}
                  </td>
                  <td
                    className="table-cell4"
                    style={{ fontSize: vw(landscape ? 40 : 65) }}
                  >
                    {item.propstream}
                  </td>
                  <td
                    className="table-cell5"
                    style={{ fontSize: vw(landscape ? 40 : 65) }}
                  >
                    {item.resimpli}
                  </td>
                  <td
                    className="table-cell6"
                    style={{ fontSize: vw(landscape ? 40 : 65) }}
                  >
                    {item.batchLeads}
                  </td>
                </tr>
              ))}
            </table>
          </VStack>
        </Center>
      </ScrollView>
      {/* <Center
        w={"100%"}
        zIndex={99}
        position={"absolute"}
        top={vh(landscape ? 150 : 100)}
      >
        <Pressable
          width={"100%"}
          alignItems={"center"}
          onPress={() => {
            setOpen(!isOpen);
          }}
        >
          <Center
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "2px solid #7D2BE9",
              borderRadius: "25px",
              opacity: 1,
              width: "90%",
              padding: 5,
              paddingHorizontal: 0,
              margin: 20,
            }}
          >
            <HStack w={"90%"} justifyContent={"space-between"} padding={1.5}>
              <Box>
                <Text
                  style={{
                    color: "#7D2BE9",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  fontSize={window.innerWidth <= 360 ? 14 : 16}
                >
                  {feature}
                </Text>
              </Box>
              <Pressable
                onPress={() => {
                  setOpen(!isOpen);
                }}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <Box
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    transform: isOpen ? "rotate(180deg)" : "",
                    transition: "transform 150ms ease",
                  }}
                >
                  <DropDown fill={"#7D2BE9"} />
                </Box>
              </Pressable>
            </HStack>

            {isOpen ? (
              <VStack w={"90%"} style={{ transition: "height 4s" }}>
                <Divider mt={2} />
                {[...Array(16).keys()].map(() => {
                  let offer = offers[i++];
                  return (
                    <VStack m={1}>
                      <ListItem offer={offer} />
                    </VStack>
                  );
                })}
              </VStack>
            ) : (
              <VStack style={{ transition: "height 4s" }}></VStack>
            )}
          </Center>
        </Pressable>
      </Center>
      <Center>
        <VStack zIndex={9} top={vw(200)} m={5} width={"90%"}>
          <Text
            style={{
              fontWeight: 500,
              fontFamily: "Poppins",
              color: "#8339FF",
              fontSize: vw(landscape ? 50 : 100),
            }}
          >
            {item.heading}
          </Text>
          <Text
            style={{
              color: "#303030",
              fontFamily: "Poppins",
              fontWeight: "300",
              fontSize: vw(landscape ? 40 : 65),
              // marginTop: vh(30),
              lineHeight: 28,
              marginTop: 5,
            }}
          >
            {item.description}
          </Text>
        </VStack>
      </Center> */}
    </VStack>
  );
};

export default Small;
