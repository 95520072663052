import React from "react";
import { useBreakpointValue, useMediaQuery, HStack, Link, Stack } from "native-base";

import Medium from "./Medium";
import Small from "./Small";
import Large from "./Large";

const FoldSeven = () => {
  const sz = useBreakpointValue({
    base: "base",
    lg: "lg",
    xl: "xl",
    md: "md",
    sm: "sm",
  });

  const [landscape] = useMediaQuery([
    {
      orientation: "landscape",
    },
    {
      orientation: "portrait",
    },
  ]);

  return (
    <Stack name="fold7" className="element">
      {/* for small devices */}
      {(sz === "base" || sz === "sm") && (
        <Small landscape={landscape} sz={sz} />
      )}
      {/* for medium devices */}
      {sz === "md" && <Medium landscape={landscape} sz={sz} />}
      {/* for large devices */}
      {(sz === "lg" || sz === "xl") && <Large landscape={landscape} sz={sz} />}
    </Stack>
  );
};

export default React.memo(FoldSeven);
