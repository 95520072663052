// import * as React from "react";
// import Svg, {
//   Defs,
//   LinearGradient,
//   Stop,
//   G,
//   Path,
//   Rect,
// } from "react-native-svg";
// import { vw, vh } from "../../util";

// const IconSp = (props) => (
//   <Svg
//     xmlns="http://www.w3.org/2000/svg"
//     xmlnsXlink="http://www.w3.org/1999/xlink"
//     width={vw(60)}
//     height={vh(60)}
//     viewBox="0 0 16.02 21.83"
//     {...props}
//   >
//     <Defs>
//       <LinearGradient
//         id="linear-gradient"
//         x1={0.904}
//         y1={1.417}
//         x2={0.209}
//         y2={-0.253}
//         gradientUnits="objectBoundingBox"
//       >
//         <Stop offset={0} stopColor="#c047f3" />
//         <Stop offset={1} stopColor="#7d2be9" />
//       </LinearGradient>
//     </Defs>
//     <G id="Group_725" data-name="Group 725" transform="translate(-206 -224)">
//       <G
//         id="Group_903"
//         data-name="Group 903"
//         transform="translate(206 224)"
//         style={{
//           isolation: "isolate",
//         }}
//       >
//         <Path
//           id="Path_6018"
//           data-name="Path 6018"
//           d="M3.73,7.07c0-1.7,1.96-3.12,9.05-5.5A8.015,8.015,0,0,0,0,8.01,11.947,11.947,0,0,0,1.48,13.2c.91-.21,1.78-.44,2.58-.67V11.11h0V11.1l1.13-.93,1.13.93v.66c1.33-.54,2.14-1.11,2.12-1.69-.04-1.36-4.71-1.41-4.71-3Zm3.03,3.9-.12.14a.147.147,0,0,1-.04.02h0s-.03,0-.04-.01L5.2,9.99,3.85,11.12a.2.2,0,0,1-.05.01c-.02,0-.03,0-.04-.02l-.12-.14s-.02-.03-.01-.05,0-.03.02-.04L5.06,9.71a.411.411,0,0,1,.15-.05.269.269,0,0,1,.15.05l.48.4V9.73a.078.078,0,0,1,.02-.05.076.076,0,0,1,.04-.02h.38s.06.02.06.06h0v.8l.43.36a.147.147,0,0,1,.02.04.055.055,0,0,1-.02.05Z"
//           fill="url(#linear-gradient)"
//         />
//         <Path
//           id="Path_6019"
//           data-name="Path 6019"
//           d="M5.44,11.43v.67c-.16.06-.33.11-.5.17v-.83h.5Z"
//           fill="url(#linear-gradient)"
//         />
//         <Path
//           id="Path_6020"
//           data-name="Path 6020"
//           d="M16.02,8.01c0,6.26-8.01,13.82-8.01,13.82A27.454,27.454,0,0,1,4.82,18.3l-2.51.92c.82-.42,1.58-.82,2.29-1.21,7.38-4.03,8.99-6.38,8.7-7.84a3.721,3.721,0,0,0-2.64-2.54V5.76h-.41V7h.12v.52c-.07-.02-.13-.05-.2-.07V5.6H9.35V7.17l-.2-.06V7h.12V5.76H8.86V7.02C7.84,6.68,7.01,6.35,6.9,5.75,6.65,4.37,9.53,2.82,12.8,1.6a8.009,8.009,0,0,1,3.21,6.41Z"
//           fill="url(#linear-gradient)"
//         />
//         <Path
//           id="Path_6021"
//           data-name="Path 6021"
//           d="M9.86,6.96v.37c-.06-.02-.13-.04-.19-.06V6.96Z"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1518"
//           data-name="Rectangle 1518"
//           width={0.19}
//           height={0.23}
//           transform="translate(9.82 5.81)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1519"
//           data-name="Rectangle 1519"
//           width={0.19}
//           height={0.23}
//           transform="translate(9.52 5.81)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1520"
//           data-name="Rectangle 1520"
//           width={0.19}
//           height={0.23}
//           transform="translate(9.52 6.18)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1521"
//           data-name="Rectangle 1521"
//           width={0.19}
//           height={0.23}
//           transform="translate(9.82 6.18)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1522"
//           data-name="Rectangle 1522"
//           width={0.19}
//           height={0.23}
//           transform="translate(9.82 6.55)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1523"
//           data-name="Rectangle 1523"
//           width={0.19}
//           height={0.23}
//           transform="translate(9.52 6.55)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1524"
//           data-name="Rectangle 1524"
//           width={0.17}
//           height={0.21}
//           transform="translate(10.34 6.62)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1525"
//           data-name="Rectangle 1525"
//           width={0.17}
//           height={0.21}
//           transform="translate(10.34 6.28)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1526"
//           data-name="Rectangle 1526"
//           width={0.17}
//           height={0.21}
//           transform="translate(10.34 5.94)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1527"
//           data-name="Rectangle 1527"
//           width={0.17}
//           height={0.21}
//           transform="translate(9.02 6.62)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1528"
//           data-name="Rectangle 1528"
//           width={0.17}
//           height={0.21}
//           transform="translate(9.02 6.28)"
//           fill="url(#linear-gradient)"
//         />
//         <Rect
//           id="Rectangle_1529"
//           data-name="Rectangle 1529"
//           width={0.17}
//           height={0.21}
//           transform="translate(9.02 5.94)"
//           fill="url(#linear-gradient)"
//         />
//       </G>
//       <G id="Group_904" data-name="Group 904" transform="translate(206 224)">
//         <Path
//           id="Path_6022"
//           data-name="Path 6022"
//           d="M8.65,11.21a.792.792,0,0,1-.3.62C6.84,13.1,2.14,14.17.28,14.12a37.115,37.115,0,0,0,3.88-1.07h0c.26-.08.52-.17.77-.26.17-.06.34-.12.5-.18.3-.11.59-.22.88-.33a22.2,22.2,0,0,0,2.33-1.06Z"
//           fill="#ccc"
//         />
//         <Path
//           id="Path_6023"
//           data-name="Path 6023"
//           d="M7.05,4.41A5.823,5.823,0,0,0,4.8,6a4.174,4.174,0,0,0-.58,1.13,1.2,1.2,0,0,1,.13-.98A5.29,5.29,0,0,1,7.04,4.41Z"
//           fill="#ccc"
//         />
//         <Path
//           id="Path_6024"
//           data-name="Path 6024"
//           d="M12.93,10.76c-.38-1.49-1.8-1.91-2.73-2.28A12.046,12.046,0,0,1,6.71,6.77a.935.935,0,0,1-.3-.79A4.849,4.849,0,0,0,8.87,7.5c.1.04.2.07.29.1.07.02.13.05.2.07s.15.06.21.08c.04.02.07.03.11.05a1.539,1.539,0,0,0,.19.08c.09.04.18.08.26.11s.17.07.25.1l.29.12c1.51.64,2.14.96,2.26,2.28a2.253,2.253,0,0,1,0,.26Z"
//           fill="#ccc"
//         />
//       </G>
//     </G>
//   </Svg>
// );
// export default IconSp;



import * as React from "react";
import Svg, {
  Defs,
  LinearGradient,
  Stop,
  G,
  Path,
  Rect,
} from "react-native-svg";
import { vw, vh } from "../../util";

const IconSp = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={ props.width || vw(60)}
    height={props.height || vh(60)}
    viewBox="0 0 16.02 21.83"
    {...props}
  >
    <Defs>
      <LinearGradient
        id="linear-gradient"
        x1={0.904}
        y1={1.417}
        x2={0.209}
        y2={-0.253}
        gradientUnits="objectBoundingBox"
      >
        <Stop offset={0} stopColor="#c047f3" />
        <Stop offset={1} stopColor="#7d2be9" />
      </LinearGradient>
    </Defs>
    <G id="Group_725" data-name="Group 725" transform="translate(-206 -224)">
      <G
        id="Group_903"
        data-name="Group 903"
        transform="translate(206 224)"
        style={{
          isolation: "isolate",
        }}
      >
        <Path
          id="Path_6018"
          data-name="Path 6018"
          d="M3.73,7.07c0-1.7,1.96-3.12,9.05-5.5A8.015,8.015,0,0,0,0,8.01,11.947,11.947,0,0,0,1.48,13.2c.91-.21,1.78-.44,2.58-.67V11.11h0V11.1l1.13-.93,1.13.93v.66c1.33-.54,2.14-1.11,2.12-1.69-.04-1.36-4.71-1.41-4.71-3Zm3.03,3.9-.12.14a.147.147,0,0,1-.04.02h0s-.03,0-.04-.01L5.2,9.99,3.85,11.12a.2.2,0,0,1-.05.01c-.02,0-.03,0-.04-.02l-.12-.14s-.02-.03-.01-.05,0-.03.02-.04L5.06,9.71a.411.411,0,0,1,.15-.05.269.269,0,0,1,.15.05l.48.4V9.73a.078.078,0,0,1,.02-.05.076.076,0,0,1,.04-.02h.38s.06.02.06.06h0v.8l.43.36a.147.147,0,0,1,.02.04.055.055,0,0,1-.02.05Z"
          fill="url(#linear-gradient)"
        />
        <Path
          id="Path_6019"
          data-name="Path 6019"
          d="M5.44,11.43v.67c-.16.06-.33.11-.5.17v-.83h.5Z"
          fill="url(#linear-gradient)"
        />
        <Path
          id="Path_6020"
          data-name="Path 6020"
          d="M16.02,8.01c0,6.26-8.01,13.82-8.01,13.82A27.454,27.454,0,0,1,4.82,18.3l-2.51.92c.82-.42,1.58-.82,2.29-1.21,7.38-4.03,8.99-6.38,8.7-7.84a3.721,3.721,0,0,0-2.64-2.54V5.76h-.41V7h.12v.52c-.07-.02-.13-.05-.2-.07V5.6H9.35V7.17l-.2-.06V7h.12V5.76H8.86V7.02C7.84,6.68,7.01,6.35,6.9,5.75,6.65,4.37,9.53,2.82,12.8,1.6a8.009,8.009,0,0,1,3.21,6.41Z"
          fill="url(#linear-gradient)"
        />
        <Path
          id="Path_6021"
          data-name="Path 6021"
          d="M9.86,6.96v.37c-.06-.02-.13-.04-.19-.06V6.96Z"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1518"
          data-name="Rectangle 1518"
          width={0.19}
          height={0.23}
          transform="translate(9.82 5.81)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1519"
          data-name="Rectangle 1519"
          width={0.19}
          height={0.23}
          transform="translate(9.52 5.81)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1520"
          data-name="Rectangle 1520"
          width={0.19}
          height={0.23}
          transform="translate(9.52 6.18)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1521"
          data-name="Rectangle 1521"
          width={0.19}
          height={0.23}
          transform="translate(9.82 6.18)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1522"
          data-name="Rectangle 1522"
          width={0.19}
          height={0.23}
          transform="translate(9.82 6.55)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1523"
          data-name="Rectangle 1523"
          width={0.19}
          height={0.23}
          transform="translate(9.52 6.55)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1524"
          data-name="Rectangle 1524"
          width={0.17}
          height={0.21}
          transform="translate(10.34 6.62)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1525"
          data-name="Rectangle 1525"
          width={0.17}
          height={0.21}
          transform="translate(10.34 6.28)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1526"
          data-name="Rectangle 1526"
          width={0.17}
          height={0.21}
          transform="translate(10.34 5.94)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1527"
          data-name="Rectangle 1527"
          width={0.17}
          height={0.21}
          transform="translate(9.02 6.62)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1528"
          data-name="Rectangle 1528"
          width={0.17}
          height={0.21}
          transform="translate(9.02 6.28)"
          fill="url(#linear-gradient)"
        />
        <Rect
          id="Rectangle_1529"
          data-name="Rectangle 1529"
          width={0.17}
          height={0.21}
          transform="translate(9.02 5.94)"
          fill="url(#linear-gradient)"
        />
      </G>
      <G id="Group_904" data-name="Group 904" transform="translate(206 224)">
        <Path
          id="Path_6022"
          data-name="Path 6022"
          d="M8.65,11.21a.792.792,0,0,1-.3.62C6.84,13.1,2.14,14.17.28,14.12a37.115,37.115,0,0,0,3.88-1.07h0c.26-.08.52-.17.77-.26.17-.06.34-.12.5-.18.3-.11.59-.22.88-.33a22.2,22.2,0,0,0,2.33-1.06Z"
          fill="#ccc"
        />
        <Path
          id="Path_6023"
          data-name="Path 6023"
          d="M7.05,4.41A5.823,5.823,0,0,0,4.8,6a4.174,4.174,0,0,0-.58,1.13,1.2,1.2,0,0,1,.13-.98A5.29,5.29,0,0,1,7.04,4.41Z"
          fill="#ccc"
        />
        <Path
          id="Path_6024"
          data-name="Path 6024"
          d="M12.93,10.76c-.38-1.49-1.8-1.91-2.73-2.28A12.046,12.046,0,0,1,6.71,6.77a.935.935,0,0,1-.3-.79A4.849,4.849,0,0,0,8.87,7.5c.1.04.2.07.29.1.07.02.13.05.2.07s.15.06.21.08c.04.02.07.03.11.05a1.539,1.539,0,0,0,.19.08c.09.04.18.08.26.11s.17.07.25.1l.29.12c1.51.64,2.14.96,2.26,2.28a2.253,2.253,0,0,1,0,.26Z"
          fill="#ccc"
        />
      </G>
    </G>
  </Svg>
);
export default IconSp;
