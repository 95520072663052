import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const OwnerLookup = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={33}
    viewBox="0 0 20 33"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Owner_Lookup"
      data-name="Owner Lookup"
      transform="translate(-272.737 -181.863)"
    >
      <Path
        id="Path_30"
        data-name="Path 30"
        d="M288.848,185.163h-3.333v-1.65a1.629,1.629,0,0,0-1.667-1.65h-2.222a1.629,1.629,0,0,0-1.667,1.65v1.65h-3.333a3.845,3.845,0,0,0-3.888,3.85v22a3.845,3.845,0,0,0,3.888,3.85h12.222a3.845,3.845,0,0,0,3.889-3.85v-22A3.846,3.846,0,0,0,288.848,185.163Zm-7.778-1.65a.523.523,0,0,1,.556-.55h2.222a.522.522,0,0,1,.556.55v3.85a.522.522,0,0,1-.556.55h-2.222a.523.523,0,0,1-.556-.55Zm10.556,27.5a2.737,2.737,0,0,1-2.778,2.75H276.625a2.737,2.737,0,0,1-2.777-2.75v-22a2.737,2.737,0,0,1,2.777-2.75h3.333v1.1a1.629,1.629,0,0,0,1.667,1.65h2.222a1.629,1.629,0,0,0,1.667-1.65v-1.1h3.333a2.737,2.737,0,0,1,2.778,2.75Zm-8.89-19.25a5.544,5.544,0,0,0-5.555,5.5,5.337,5.337,0,0,0,1.555,3.795,5.626,5.626,0,0,0,7.834.11l.111-.11a5.44,5.44,0,0,0-.167-7.755,5.318,5.318,0,0,0-3.778-1.54Zm-2.889,8.8A3.374,3.374,0,0,1,284.4,199.3a3.506,3.506,0,0,1,1.278,1.265A4.5,4.5,0,0,1,279.848,200.563Zm1.778-3.85a1.111,1.111,0,1,1,1.111,1.1A1.108,1.108,0,0,1,281.626,196.713Zm4.777,3.025a4.379,4.379,0,0,0-2-1.595,2.115,2.115,0,0,0-.222-3.08,2.166,2.166,0,0,0-3.111.22,2.232,2.232,0,0,0,0,2.915,4.377,4.377,0,0,0-2,1.595,4.337,4.337,0,0,1,1.166-6.1,4.427,4.427,0,0,1,6.166,1.155A4.179,4.179,0,0,1,286.4,199.738Zm.778,6.876a.522.522,0,0,1-.556.549h-7.778a.55.55,0,1,1,0-1.1h7.778A.522.522,0,0,1,287.181,206.614Zm0,2.2a.522.522,0,0,1-.556.55h-7.778a.55.55,0,1,1,0-1.1h7.778A.522.522,0,0,1,287.181,208.813Zm0,2.2a.522.522,0,0,1-.556.55h-7.778a.55.55,0,1,1,0-1.1h7.778A.522.522,0,0,1,287.181,211.013Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default OwnerLookup;
