import * as React from "react";

const WhiteLogo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    // width={60.447}
    // height={82.36}
    viewBox={"0 0 60.447 82.36"}
    {...props}
  >
    <g fill={props.fill || "#fff"}>
      <g data-name='Group 1'>
        <path
          data-name='Path 1'
          d='M14.072 26.683c0-6.41 7.4-11.758 34.137-20.758A30.012 30.012 0 0 0 30.246 0 30.223 30.223 0 0 0 .004 30.223c0 6.224 2.273 13.006 5.59 19.565 3.447-.82 6.727-1.658 9.727-2.515v-5.385l4.249-3.5 4.249 3.5v2.5c4.994-2.031 8.068-4.174 8.012-6.373-.116-5.127-17.759-5.313-17.759-11.332Zm11.422 14.739-.466.54a.229.229 0 0 1-.149.074h-.019a.21.21 0 0 1-.149-.056l-5.124-4.248-5.106 4.267a.317.317 0 0 1-.186.056.23.23 0 0 1-.149-.075l-.466-.54a.3.3 0 0 1-.056-.168.193.193 0 0 1 .074-.149l5.311-4.435a.844.844 0 0 1 .559-.186.813.813 0 0 1 .559.186l1.807 1.509v-1.433a.181.181 0 0 1 .075-.168.236.236 0 0 1 .168-.074h1.416a.182.182 0 0 1 .168.074.237.237 0 0 1 .075.168v3.019l1.621 1.342a.169.169 0 0 1 .075.149.164.164 0 0 1-.041.148Z'
        />
        <path
          data-name='Path 2'
          d='M20.515 43.118v2.516c-.615.2-1.248.428-1.9.634v-3.149Z'
        />
        <path
          data-name='Path 3'
          d='M60.45 30.223c0 23.609-30.223 52.137-30.223 52.137-.242 0-5.777-5.4-12.037-13.323l-9.466 3.484c3.075-1.584 5.963-3.093 8.627-4.565 27.839-15.2 33.894-24.074 32.814-29.59-1.006-5.068-5.273-7.733-9.951-9.6v-7.058h-1.546v4.7h.466v1.957a9.058 9.058 0 0 0-.745-.261V21.13h-3.094v5.944c-.242-.075-.484-.149-.745-.242v-.41h.466v-4.7H33.47v4.752c-3.857-1.267-6.969-2.515-7.379-4.789-.95-5.217 9.913-11.031 22.248-15.652a30.118 30.118 0 0 1 12.111 24.19Z'
        />
        <path
          data-name='Path 4'
          d='M37.193 26.273v1.416c-.242-.075-.484-.168-.708-.242v-1.174Z'
        />
        <path data-name='Rectangle 2' d='M37.062 21.913h.708v.876h-.708z' />
        <path data-name='Rectangle 3' d='M35.907 21.913h.708v.876h-.708z' />
        <path data-name='Rectangle 4' d='M35.907 23.31h.708v.876h-.708z' />
        <path data-name='Rectangle 5' d='M37.062 23.31h.708v.876h-.708z' />
        <path data-name='Rectangle 6' d='M37.062 24.708h.708v.876h-.708z' />
        <path data-name='Rectangle 7' d='M35.907 24.708h.708v.876h-.708z' />
        <path data-name='Rectangle 8' d='M39 24.987h.652v.801H39z' />
        <path data-name='Rectangle 9' d='M39 23.702h.652v.801H39z' />
        <path data-name='Rectangle 10' d='M39 22.435h.652v.801H39z' />
        <path data-name='Rectangle 11' d='M34.025 24.987h.652v.801h-.652z' />
        <path data-name='Rectangle 12' d='M34.025 23.702h.652v.801h-.652z' />
        <path data-name='Rectangle 13' d='M34.025 22.435h.652v.801h-.652z' />
      </g>
      <g data-name='Group 2'>
        <path
          data-name='Path 5'
          d='M32.609 42.298a2.919 2.919 0 0 1-1.118 2.329c-5.683 4.789-23.4 8.814-30.429 8.646a145.847 145.847 0 0 0 14.646-4.025c.969-.317 1.938-.652 2.925-.988l1.9-.67c1.118-.41 2.236-.839 3.317-1.267a85.019 85.019 0 0 0 8.759-4.025Z'
        />
        <path
          data-name='Path 6'
          d='M26.608 16.621c-2.311 1.025-6.727 3.336-8.478 6a15.551 15.551 0 0 0-2.18 4.267 4.589 4.589 0 0 1 .5-3.708c1.475-2.683 6.264-5.217 10.158-6.559Z'
        />
        <path
          data-name='Path 7'
          d='M48.782 40.584c-1.416-5.609-6.8-7.192-10.3-8.609-7.845-3.168-9.634-3.708-13.174-6.466a3.4 3.4 0 0 1-1.118-2.962c1.7 2.851 6.093 4.546 9.3 5.72.373.13.745.261 1.081.391.261.093.5.187.745.279.3.112.559.224.782.317a3.971 3.971 0 0 1 .41.186c.242.112.485.205.708.317.335.149.671.3.987.428s.634.261.932.391c.372.149.727.317 1.081.466 5.7 2.422 8.068 3.633 8.534 8.609a7.657 7.657 0 0 1 .032.933Z'
        />
      </g>
    </g>
  </svg>
);

export default WhiteLogo;
