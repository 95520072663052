import * as React from "react";
import { VStack, Stack, HStack, Text, Pressable, Link } from "native-base";

import { vw, vh } from "../../../util";
import Youtube from "../../../assets/svg/Youtube";
import Twitter from "../../../assets/svg/Twitter";
import Facebook from "../../../assets/svg/Facebook";
import FacebookOnHover from "../../../assets/svg/FacebookOnHover";
import TwitterOnHover from "../../../assets/svg/TwitterOnHover";
import YouTubeOnHover from "../../../assets/svg/YouTubeOnHover";
import Instagram from "../../../assets/Instagram";
import Linkdin from "../../../assets/Linkdin";

const BottomBar = () => {
  return (
    <>
      <VStack bg={"black"} w={"full"} h={"50vh"} width={"full"}>
        <HStack mt={vh(140)} flex={5}>
          <VStack ml={vw(70)} mr={vw(315)}>
            <Text
              color={"white"}
              fontSize={vw(17)}
              fontWeight={300}
              fontFamily={"Poppins"}
            >
              Contact the publishers
            </Text>

            <Link href="mailto:info@shadow.properties">
              <Pressable>
                {({ isHovered }) => {
                  return (
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(22)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          info@shadow.properties
                        </Text>
                      ) : (
                        <Text
                          mt={vh(22)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          info@shadow.properties
                        </Text>
                      )}
                    </Stack>
                  );
                }}
              </Pressable>
            </Link>
          </VStack>
          <VStack mr={vw(220)}>
            <Text
              fontSize={vw(17)}
              fontWeight={300}
              fontFamily={"Poppins"}
              color={"white"}
            >
              Explorate
            </Text>

            <Pressable>
              {({ isHovered }) => {
                return (
                  <Link href="https://shadow.properties/#main">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(30)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          Home
                        </Text>
                      ) : (
                        <Text
                          mt={vh(30)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          Home
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable>
            <Pressable>
              {({ isHovered }) => {
                return (
                  <Link href="https://shadow.properties/#howitworks">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          How It Works
                        </Text>
                      ) : (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          How It Works
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable>
            <Pressable>
              {({ isHovered }) => {
                return (
                  <Link href="https://shadow.properties/#features">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          Features
                        </Text>
                      ) : (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          Features
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable>
            {/* <Pressable
              
            >
              {({ isHovered, isFocused }) => {
                return (
                  <Link href="https://shadow.properties/#features">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
              fontWeight={300}
              fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          Tutorials
                        </Text>
                      ) : (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
              fontWeight={300}
              fontFamily={"Poppins"}
                          color={"white"}
                        >
                          Tutorials
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable> */}
            <Pressable>
              {({ isHovered }) => {
                return (
                  <Link href="https://shadow.properties/#pricing">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          Pricing
                        </Text>
                      ) : (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          Pricing
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable>
            <Pressable>
              {({ isHovered }) => {
                return (
                  <Link href="https://shadow.properties/#pricing">
                    <Stack>
                      {isHovered ? (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"darkBlue.600"}
                        >
                          Contact us
                        </Text>
                      ) : (
                        <Text
                          mt={vh(10)}
                          fontSize={vw(15)}
                          fontWeight={300}
                          fontFamily={"Poppins"}
                          color={"white"}
                        >
                          Contact us
                        </Text>
                      )}
                    </Stack>
                  </Link>
                );
              }}
            </Pressable>
          </VStack>
          <VStack mr={vw(210)}>
            <Text
              fontSize={vw(17)}
              fontWeight={300}
              fontFamily={"Poppins"}
              color={"white"}
            >
              Headquarter
            </Text>
            <Text
              mt={vh(30)}
              fontSize={vw(15)}
              fontWeight={300}
              fontFamily={"Poppins"}
              color={"white"}
            >
              Shadow.Properties
            </Text>
            <Text
              lineHeight={vh(40)}
              fontSize={vw(15)}
              fontWeight={300}
              fontFamily={"Poppins"}
              color={"white"}
            >
              16192 Coastal Highway, Lewes
            </Text>
            <Text
              lineHeight={vh(40)}
              fontSize={vw(15)}
              fontWeight={300}
              fontFamily={"Poppins"}
              color={"white"}
            >
              Delaware 19958
            </Text>
          </VStack>
          <VStack mr={vw(210)}>
            <Text
              fontSize={vw(17)}
              fontWeight={300}
              fontFamily={"Poppins"}
              color={"white"}
            >
              Connections
            </Text>
            <HStack mt={vh(30)} alignItems={"center"}>
              <Link
                href="https://www.facebook.com/people/Shadow-Properties/100087078565348/"
                isExternal
              >
                <Pressable mr={vw(20)}>
                  {({ isHovered }) => {
                    return (
                      <Stack>
                        {isHovered ? <FacebookOnHover /> : <Facebook />}
                      </Stack>
                    );
                  }}
                </Pressable>
              </Link>

              <Link href="https://twitter.com/Shadow_CRM" isExternal>
                <Pressable mr={vw(20)}>
                  {({ isHovered }) => {
                    return (
                      <Stack>
                        {isHovered ? <TwitterOnHover /> : <Twitter />}
                      </Stack>
                    );
                  }}
                </Pressable>
              </Link>

              <Link href="https://www.youtube.com/@shadowproperties" isExternal>
                <Pressable mr={vw(20)}>
                  {({ isHovered }) => {
                    return (
                      <Stack>
                        {isHovered ? <YouTubeOnHover /> : <Youtube />}
                      </Stack>
                    );
                  }}
                </Pressable>
              </Link>

              <Link
                href="https://www.instagram.com/shadowproperties_official/"
                isExternal
              >
                <Pressable mr={vw(20)}>
                  {({ isHovered }) => {
                    return (
                      <Stack>
                        {isHovered ? (
                          <Instagram fill={"#1e88e5"} />
                        ) : (
                          <Instagram fill={"white"} />
                        )}
                      </Stack>
                    );
                  }}
                </Pressable>
              </Link>
              <Link
                href="https://www.linkedin.com/company/37566268/admin/"
                isExternal
              >
                <Pressable mr={vw(20)}>
                  {({ isHovered }) => {
                    return (
                      <Stack>
                        {isHovered ? (
                          <Linkdin fill={"#1e88e5"} />
                        ) : (
                          <Linkdin fill={"white"} />
                        )}
                      </Stack>
                    );
                  }}
                </Pressable>
              </Link>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
};

export default React.memo(BottomBar);
