import * as React from "react";
const LeftArrowInactive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <g id="Group_1019" transform="translate(945 484) rotate(180)">
      <rect
        id="Rectangle_812"
        width={50}
        height={50}
        transform="translate(895 434)"
        fill="none"
      />
      <path
        id="Path_10310"
        d="M922.8,459.4,910,446.6l3.6-3.6L930,459.4l-16.4,16.4-3.6-3.6Z"
        fill="#bcbcbc"
      />
    </g>
  </svg>
);
export default LeftArrowInactive;
