// import React from "react";
// import { Stack, useBreakpointValue, useMediaQuery } from "native-base";

// import Medium from "./Medium";
// import Small from "./Small";
// import Large from "./Large";

// const gifs = {
//   1: [
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Driving.gif",
//     },
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Adding-single-property.gif",
//     },
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/bulk_add.gif",
//     },
//   ],
//   2: [
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Adding-Pipeline.gif",
//     },
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Adding-Contacts.gif",
//     },
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Moving-Contact.gif",
//     },
//   ],
//   3: [
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Property-Info.gif",
//     },
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Property%20Details.gif",
//     },
//     {
//       uri: "https://cdn.shadow.properties/videos_spweb/Property-Campaign.gif",
//     },
//   ],
// };

// const FoldThree = () => {
//   const sz = useBreakpointValue({
//     base: "base",
//     lg: "lg",
//     xl: "xl",
//     md: "md",
//     sm: "sm",
//   });

//   const [landscape] = useMediaQuery([
//     {
//       orientation: "landscape",
//     },
//     {
//       orientation: "portrait",
//     },
//   ]);

//   return (
//     <Stack name="fold3" className="element">
//       {/* for small devices */}
//       {(sz === "base" || sz === "sm") && (
//         <Small landscape={landscape} sz={sz} gifs={gifs} />
//       )}
//       {/* for medium devices */}
//       {sz === "md" && <Medium landscape={landscape} sz={sz} gifs={gifs} />}
//       {/* for large devices */}
//       {(sz === "lg" || sz === "xl") && (
//         <Large landscape={landscape} sz={sz} gifs={gifs} />
//       )}
//     </Stack>
//   );
// };

// export default React.memo(FoldThree);

import React, { useRef, useEffect } from "react";
import { Stack, useBreakpointValue, useMediaQuery } from "native-base";
import { json, useLocation } from "react-router-dom";
// import Iframe from "react-iframe";

import Medium from "./Medium";
import Small from "./Small";
import Large from "./Large";

const gifs = {
  1: [
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Driving.gif",
    },
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Adding-single-property.gif",
    },
    {
      uri: "https://cdn.shadow.properties/videos_spweb/bulk_add.gif",
    },
  ],
  2: [
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Adding-Pipeline.gif",
    },
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Adding-Contacts.gif",
    },
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Moving-Contact.gif",
    },
  ],
  3: [
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Property-Info.gif",
    },
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Property%20Details.gif",
    },
    {
      uri: "https://cdn.shadow.properties/videos_spweb/Property-Campaign.gif",
    },
  ],
};

const GeneratePixel = ({ ip }) => {
  // const iframeRef = useRef();
  // return (
  //   <Iframe
  //     url='https://adtrustmedia.go2cloud.org/aff_c?offer_id=1164&aff_id=1061'
  //     width='0px'
  //     height='0px'
  //     id='pixel'
  //     className=''
  //     display='hidden'
  //     position='absolute'
  //     sandbox={"allow-scripts allow-same-origin"}
  //   />
  // );
  return null;
};

const FoldThree = ({ ip, sendIp }) => {

  const location = useLocation();
  const sz = useBreakpointValue({
    base: "base",
    lg: "lg",
    xl: "xl",
    md: "md",
    sm: "sm",
  });

  const [landscape] = useMediaQuery([
    {
      orientation: "landscape",
    },
    {
      orientation: "portrait",
    },
  ]);

  return (
    <Stack name="fold3" className="element">
      <GeneratePixel ip={ip} />
      {/* for small devices */}
      {(sz === "base" || sz === "sm") && (
        <Small landscape={landscape} sz={sz} gifs={gifs} sendIp={sendIp} />
      )}
      {/* for medium devices */}
      {sz === "md" && (
        <Medium landscape={landscape} sz={sz} gifs={gifs} sendIp={sendIp} />
      )}
      {/* for large devices */}
      {(sz === "lg" || sz === "xl") && (
        <Large landscape={landscape} sz={sz} gifs={gifs} sendIp={sendIp} />
      )}
    </Stack>
  );
};

export default React.memo(FoldThree);
