export const basicFeaturesList = [
  "No. of Plans",
  "PlanPrice/Month",
  "Annual PlanPrice",
  "Users",
  "Leads/Month",
  "Mails",
  "Emails",
  "SkipTrace",
  "Corporate SkipTrace",
  "360 street view",
  "Phone Number",
  "Call/Min",
  "SMS/160characters",
];

export const additionalFeatureList = [
  "Phone Numbers",
  "SMS",
  "Calling Minutes",
  "Extra Leads",
  "Extra User",
  "List Automator",
];

export const shadowProperties = [
  {
    basicFeatures: [
      { feature: "No. of Plans", value: "3 (Team, Solo, Free)" },
      {
        feature: "PlanPrice/Month",
        value: "$299 (Team), $99 (Solo), $0/lifetime (Free)",
      },
      { feature: "Annual PlanPrice", value: "NA" },
      { feature: "Users", value: "5 (Team), 1 (Solo), 1 (Free)" },
      {
        feature: "Leads/Month",
        value: "50K (Team), 10K (Solo), 100 (Free)",
      },
      { feature: "Mails", value: "$0.75 (Team and Solo), $1.00 (Free)" },
      { feature: "Emails", value: "$0" },
      { feature: "SkipTrace", value: "$0.01 (Team and Solo), $0.02 (Free)" },
      { feature: "Corporate SkipTrace", value: "Included in Skiptrace" },
      {
        feature: "360 street view",
        value: "$0.007 (Team and Solo), $0.01 (Free)",
      },
      { feature: "Phone Number", value: "$1.00 (Team and Solo)" },
      { feature: "Call/Min", value: "$0.03 (Team), $0.04 (Solo)" },
      { feature: "SMS/160characters", value: "$0.012 (Team), $0.014 (Solo)" },
    ],
  },
  {
    additionalFeatures: [
      { feature: "Phone Numbers", value: "$0" },
      { feature: "SMS", value: "$0" },
      { feature: "Calling Minutes", value: "$0" },
      { feature: "Extra Leads", value: "$0" },
      { feature: "Extra User", value: "$0" },
      { feature: "List Automator", value: "$0" },
    ],
  },
];

export const dealMachine = [
  {
    basicFeatures: [
      { feature: "No. of Plans", value: "3 (Teams, Pro, Starter)" },
      {
        feature: "PlanPrice/Month",
        value: "$499 (Teams), $119 (Pro), $79 (Starter)",
      },
      { feature: "Annual PlanPrice", value: "NA" },
      { feature: "Users", value: "15 (Teams), 3 (Pro), 1 (Starter)" },
      { feature: "Leads", value: "500K (Teams), 50K (Pro), 10K (Starter)" },
      {
        feature: "Mails",
        value: "$1.47 (Teams), $1.75 (Pro), $1.99 (Starter)",
      },
      { feature: "Emails", value: "$0" },
      {
        feature: "SkipTrace",
        value: "$0.12 (Teams), $0.14 (Pro), $0.17 (Starter)",
      },
      {
        feature: "Corporate SkipTrace",
        value: "$0.24 (Teams), $0.28 (Pro), $0.34 (Starter)",
      },
      { feature: "360 street view", value: "NA" },
      { feature: "Phone Number", value: "NA" },
      { feature: "Call/Min", value: "NA" },
      { feature: "SMS/160characters", value: "NA" },
    ],
  },
  {
    additionalFeatures: [
      { feature: "Phone Numbers", value: "NA" },
      { feature: "SMS", value: "NA" },
      { feature: "Calling Minutes", value: "NA" },
      { feature: "Extra Leads", value: "NA" },
      { feature: "Extra User", value: "NA" },
      { feature: "List Automator", value: "NA" },
    ],
  },
];

export const resimpli = [
  {
    basicFeatures: [
      { feature: "No. of Plans", value: "3 (Enterprise, Pro, Basic)" },
      {
        feature: "PlanPrice/Month",
        value: "$749 (Enterprise), $299 (Pro), $99 (Basic)",
      },
      { feature: "Annual PlanPrice", value: "NA" },
      { feature: "Users", value: "Unlimited (Enterprise), 5 (Pro), 1 (Basic)" },
      {
        feature: "Leads",
        value: "Unlimited (Enterprise), 100K (Pro), 20K (Basic)",
      },
      {
        feature: "Mails",
        value: "$0.50 (Enterprise), $0.50 (Pro), $0.50 (Basic)",
      },
      { feature: "Emails", value: "$0" },
      {
        feature: "SkipTrace",
        value: "$0.15 (Enterprise), $0.15 (Pro), $0.15 (Basic)",
      },
      { feature: "Corporate SkipTrace", value: "Included in Skiptrace" },
      {
        feature: "360 street view",
        value: "$20/Month (Enterprise), $20/Month (Pro), $20/Month (Basic)",
      },
      { feature: "Phone Number", value: "15 (Enterprise), 5 (Pro), 2 (Basic)" },
      { feature: "Call/Min", value: "10K (Enterprise), 2K (Pro), 500 (Basic)" },
      {
        feature: "SMS/160characters",
        value: "10K (Enterprise), 2K (Pro), 500 (Basic)",
      },
    ],
  },
  {
    additionalFeatures: [
      {
        feature: "Phone Numbers",
        value: "$2/Month (Enterprise), $2/Month (Pro), $2/Month (Basic)",
      },
      {
        feature: "SMS",
        value:
          "1 Cent/Month (Enterprise), 1 Cent/Month (Pro), 1 Cent/Month (Basic)",
      },
      {
        feature: "Calling Minutes",
        value:
          "2.5 Cents/Month (Enterprise), 2.5 Cents/Month (Pro), 2.5 Cents/Month (Basic)",
      },
      { feature: "Extra Leads", value: "NA" },
      { feature: "Extra User", value: "NA" },
      { feature: "List Automator", value: "NA" },
    ],
  },
];

export const batchLeads = [
  {
    basicFeatures: [
      {
        feature: "No. of Plans",
        value: "4 (Team, Team Basic, Individual Team, Individual Basic )",
      },
      {
        feature: "PlanPrice/Month",
        value:
          "$899 (Team), $599 (Team Basic), $299 (Individual Team), $99 (Individual Basic)",
      },
      {
        feature: "Annual PlanPrice",
        value:
          "$829 (Team), $551 (Team Basic), $248 (Individual Team), $82 (Individual Basic)",
      },
      {
        feature: "Users",
        value:
          "200 (Team), 50 (Team Basic), 8 (Individual Team), 3 (Individual Basic)",
      },
      {
        feature: "Leads",
        value:
          "150K (Team), 75K (Team Basic), 25K (Individual Team), 10K (Individual Basic)",
      },
      {
        feature: "Mails",
        value:
          "$0.76 (Team), $0.78 (Team Basic), $0.79 (Individual Team), $0.81 (Individual Basic)",
      },
      { feature: "Emails", value: "$0" },
      {
        feature: "SkipTrace",
        value:
          "$0.10 (Team), $0.10 (Team Basic), $0.12 (Individual Team), $0.14 (Individual Basic)",
      },
      { feature: "Corporate SkipTrace", value: "Included in Skiptrtace" },
      { feature: "360 street view", value: "NA" },
      { feature: "Phone Number", value: "NA" },
      { feature: "Call/Min", value: "NA" },
      { feature: "SMS/160characters", value: "NA" },
    ],
  },
  {
    additionalFeatures: [
      { feature: "Phone Numbers", value: "NA" },
      { feature: "SMS", value: "NA" },
      { feature: "Calling Minutes", value: "NA" },
      {
        feature: "Extra Leads",
        value:
          "$0.01 (Team), $0.01 (Team Basic), $0.01 (Individual Team), $0.01 (Individual Basic)",
      },
      { feature: "Extra User", value: "NA" },
      { feature: "List Automator", value: "NA" },
    ],
  },
];

export const propStream = [
  {
    basicFeatures: [
      { feature: "No. of Plans", value: "1" },
      { feature: "PlanPrice/Month", value: "$99" },
      { feature: "Annual PlanPrice", value: "NA" },
      { feature: "Users", value: "1" },
      { feature: "Leads", value: "10K" },
      { feature: "Mails", value: "$0.48" },
      { feature: "Emails", value: "$0.02" },
      { feature: "SkipTrace", value: "$0.12" },
      { feature: "Corporate SkipTrace", value: "Included in Skiptrace" },
      { feature: "360 street view", value: "NA" },
      { feature: "Phone Number", value: "NA" },
      { feature: "Call/Min", value: "NA" },
      { feature: "SMS/160characters", value: "NA" },
    ],
  },
  {
    additionalFeatures: [
      { feature: "Phone Numbers", value: "NA" },
      { feature: "SMS", value: "NA" },
      { feature: "Calling Minutes", value: "NA" },
      { feature: "Extra Leads", value: "NA" },
      {
        feature: "Extra User",
        value: "$20/User/Month allowed up to 5 Users only",
      },
      { feature: "List Automator", value: "$27/Month" },
    ],
  },
];

export const options = [
  {
    index: 0,
    product: "Shadow Properties",
    trail: "14 Day Free Trial",
    features: shadowProperties,
  },
  {
    index: 1,
    product: "Deal Machine",
    trail: "7 Day Free Trial",
    features: dealMachine,
  },
  {
    index: 2,
    product: "Re Simpli",
    trail: "14 Day Free Trial",
    features: resimpli,
  },
  {
    index: 3,
    product: "Batch Leads",
    trail: "7 Day Free Trial",
    features: batchLeads,
  },
  {
    index: 4,
    product: "Propstream",
    trail: "7 Day Free Trial",
    features: propStream,
  },
];
