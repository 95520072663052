import * as React from "react";
const ListManagement = (props) => (
  <svg
    id="task-list_1"
    xmlns="http://www.w3.org/2000/svg"
    width={36.526}
    height={37.203}
    viewBox="0 0 36.526 37.203"
    {...props}
  >
    <path
      id="Path_485"
      data-name="Path 485"
      d="M259.867,64.414a.6.6,0,0,1,0,1.192h-2.449V78.836h2.456a.589.589,0,0,1,0,1.178h-3.045a.592.592,0,0,1-.589-.589h0V65.606H254.8a.6.6,0,1,1,0-1.192h1.444V50.6a.6.6,0,0,1,.6-.6h3.045a.589.589,0,1,1,0,1.178h-2.463V64.407l2.449.007Z"
      transform="translate(-235.943 -46.409)"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      id="Path_486"
      data-name="Path 486"
      d="M78.928,225.214a2.614,2.614,0,1,1-2.614-2.614A2.615,2.615,0,0,1,78.928,225.214Z"
      transform="translate(-68.407 -206.613)"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      id="Path_487"
      data-name="Path 487"
      d="M3.447,57.7l.481,1.458a.571.571,0,0,0,.553.4h6.852a.571.571,0,0,0,.553-.4L12.36,57.7Zm11.111,0h-1.9l-.5,1.544a.855.855,0,0,1-.826.6H4.474a.855.855,0,0,1-.826-.6l-.5-1.544H1.25A1.251,1.251,0,0,0,0,58.95V85.365a1.251,1.251,0,0,0,1.25,1.25H14.558a1.255,1.255,0,0,0,1.25-1.25V58.95A1.251,1.251,0,0,0,14.558,57.7ZM7.907,84.338a1.257,1.257,0,1,1,1.25-1.257A1.248,1.248,0,0,1,7.907,84.338Zm5.559-11.62-.725.28-.208.079a4.622,4.622,0,0,1-.711,1.709l.093.208.316.7-.79.79-.7-.316-.208-.093a4.673,4.673,0,0,1-1.709.711L8.74,77l-.28.718H7.347L7.067,77l-.079-.208a4.572,4.572,0,0,1-1.709-.711l-.208.093-.7.316-.79-.79.316-.7.093-.208a4.673,4.673,0,0,1-.711-1.709L3.067,73l-.718-.28V71.6l.718-.28.208-.079a4.572,4.572,0,0,1,.711-1.709l-.093-.208-.316-.7.79-.79.7.316.208.093a4.672,4.672,0,0,1,1.709-.711l.079-.208.28-.725H8.46l.28.725.079.208a4.622,4.622,0,0,1,1.709.711l.208-.093.7-.316.79.79-.316.7-.093.208a4.672,4.672,0,0,1,.711,1.709l.208.079.725.28Z"
      transform="translate(0 -53.556)"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      id="Path_488"
      data-name="Path 488"
      d="M56.906,57.7l-.467,1.458a.571.571,0,0,1-.553.4H49.027a.571.571,0,0,1-.553-.4L48,57.7Z"
      transform="translate(-44.553 -53.556)"
      fill="#d1d1d6"
      fillRule="evenodd"
    />
    <path
      id="Path_489"
      data-name="Path 489"
      d="M56.906,57.7l-.467,1.458a.571.571,0,0,1-.553.4H49.027a.571.571,0,0,1-.553-.4L48,57.7Z"
      transform="translate(-44.553 -53.556)"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      id="Path_490"
      data-name="Path 490"
      d="M369.915,8.568h-6.593A1.323,1.323,0,0,1,362,7.247V1.321A1.318,1.318,0,0,1,363.322,0h6.593a1.323,1.323,0,0,1,1.321,1.321V7.254A1.326,1.326,0,0,1,369.915,8.568ZM363.322.431a.9.9,0,0,0-.891.891V7.254a.9.9,0,0,0,.891.891h6.593a.9.9,0,0,0,.891-.891V1.321a.9.9,0,0,0-.891-.891Z"
      transform="translate(-336.001)"
      fill="#fff"
    />
    <path
      id="Path_491"
      data-name="Path 491"
      d="M409.5,25.425a.609.609,0,0,1,.862-.862l1.314,1.321,3.763-3.411a.605.605,0,1,1,.812.9l-4.166,3.778v.007a.611.611,0,0,1-.862,0L409.5,25.425Z"
      transform="translate(-379.927 -20.713)"
      fill="#fff"
      fillRule="evenodd"
    />
    <g id="Group_19" data-name="Group 19" transform="translate(25.999 14.328)">
      <path
        id="Path_492"
        data-name="Path 492"
        d="M369.915,208.061h-6.593A1.323,1.323,0,0,1,362,206.739v-5.925a1.32,1.32,0,0,1,1.106-1.3V199.5h6.809a1.323,1.323,0,0,1,1.321,1.321v5.932A1.329,1.329,0,0,1,369.915,208.061Zm-6.593-8.137a.9.9,0,0,0-.891.891v5.925a.9.9,0,0,0,.891.891h6.593a.9.9,0,0,0,.891-.891v-5.932a.9.9,0,0,0-.891-.891Z"
        transform="translate(-362 -199.5)"
        fill="#fff"
      />
      <path
        id="Path_493"
        data-name="Path 493"
        d="M409.5,224.732a.609.609,0,1,1,.862-.862l1.314,1.314,3.763-3.411a.605.605,0,1,1,.812.9l-4.166,3.778h0a.611.611,0,0,1-.862,0l-1.724-1.716Z"
        transform="translate(-405.926 -220.028)"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
    <g id="Group_20" data-name="Group 20" transform="translate(25.999 28.627)">
      <path
        id="Path_494"
        data-name="Path 494"
        d="M369.915,407.175h-6.593A1.323,1.323,0,0,1,362,405.854v-5.932a1.323,1.323,0,0,1,1.322-1.322h6.593a1.323,1.323,0,0,1,1.321,1.322v5.932A1.327,1.327,0,0,1,369.915,407.175Zm-6.593-8.137a.9.9,0,0,0-.891.891v5.932a.9.9,0,0,0,.891.891h6.593a.9.9,0,0,0,.891-.891v-5.932a.9.9,0,0,0-.891-.891Z"
        transform="translate(-362 -398.6)"
        fill="#fff"
      />
      <path
        id="Path_495"
        data-name="Path 495"
        d="M409.5,424.032a.609.609,0,0,1,.862-.862l1.314,1.314,3.763-3.411a.605.605,0,1,1,.812.9l-4.166,3.778h0a.611.611,0,0,1-.862,0l-1.724-1.716Z"
        transform="translate(-405.926 -419.313)"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
export default ListManagement;
