import * as React from "react";
const BuyerContact = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #fff;\n      }\n\n      .cls-2 {\n        clip-path: url(#clippath-1);\n      }\n\n      .cls-3 {\n        clip-path: url(#clippath);\n      }\n\n      .cls-4 {\n        fill: none;\n      }\n    "
        }
      </style>
      <clipPath id="clippath">
        <rect className="cls-4" width={24} height={24} />
      </clipPath>
      <clipPath id="clippath-1">
        <rect className="cls-4" x={7} y={7} width={10} height={10} />
      </clipPath>
    </defs>
    <g className="cls-3">
      <g id="Message">
        <path
          className="cls-1"
          d="m19.8,7.81c-.14,0-.29-.05-.41-.14l-.83-.67c-.1-.08-.12-.23-.03-.33.08-.1.23-.12.33-.04l.83.67c.07.05.16.05.23,0l.82-.66c.1-.08.25-.07.33.03.08.1.07.25-.03.33l-.82.67c-.12.1-.27.14-.41.14Z"
        />
        <path
          className="cls-1"
          d="m20.83,9.29h-2.06c-.36,0-.69-.13-.93-.37-.23-.23-.36-.55-.36-.89v-1.26c0-.34.11-.62.32-.85.24-.25.6-.4.97-.41h2.06s.06,0,.07,0c.38.02.72.19.95.45.2.23.3.52.28.82v1.25c0,.34-.13.66-.36.89-.24.24-.57.37-.93.37Zm-2.06-.47h2.06c.24,0,.45-.08.6-.23.14-.14.22-.34.22-.56v-1.26c.01-.19-.04-.36-.16-.5-.15-.17-.38-.28-.63-.3h-2.09c-.25,0-.48.1-.64.27-.13.13-.2.3-.19.47v1.32c0,.22.08.41.23.56.15.15.36.23.61.23Z"
        />
      </g>
      <g className="cls-2">
        <path
          className="cls-1"
          d="m14.75,14.64c-.02-.14-.06-.27-.12-.4-.02-.06-.05-.12-.09-.17-.15-.26-.36-.47-.62-.62-.26-.16-.57-.24-.87-.24h-2.08c-.3,0-.61.08-.88.24-.26.15-.47.36-.62.62-.03.05-.06.11-.09.17-.05.12-.1.26-.12.4-.02.1-.03.21-.03.31v.69h.7v-.69c0-.15.03-.29.09-.42.01-.04.03-.07.05-.1.09-.16.22-.29.38-.38.16-.09.34-.14.52-.14h2.08c.19,0,.37.05.53.14.16.09.29.22.38.38.02.03.03.06.05.1.06.13.1.28.09.43v.69h.7v-.69c0-.1,0-.21-.03-.31Z"
        />
        <path
          id="Path_160"
          data-name="Path 160"
          className="cls-1"
          d="m13.8,9.39c-.18-.31-.44-.57-.75-.75-.32-.19-.68-.29-1.05-.29-.37,0-.73.1-1.05.29-.31.18-.57.44-.75.75-.18.32-.28.68-.28,1.05s.1.73.28,1.05c.18.31.44.57.75.75.32.19.68.29,1.05.28.37,0,.73-.1,1.05-.28.31-.18.57-.44.75-.75.19-.32.29-.68.28-1.05,0-.37-.1-.73-.28-1.05Zm-.6,1.75c-.12.21-.29.38-.5.5-.21.12-.45.19-.7.19s-.49-.07-.7-.19c-.2-.12-.38-.29-.5-.5-.12-.21-.19-.45-.19-.7s.07-.49.19-.7c.12-.21.3-.38.5-.5.21-.13.45-.19.7-.19s.49.07.7.19c.21.12.38.29.5.5.12.21.19.45.19.7,0,.25-.06.49-.19.7Z"
        />
        <g>
          <path
            id="Path_160-2"
            data-name="Path 160"
            className="cls-1"
            d="m9.95,12.32c-.01-.18-.07-.35-.16-.5-.08-.14-.18-.25-.31-.34,0,0,0,0,0,0-.04-.03-.08-.06-.12-.08-.18-.11-.39-.17-.61-.17-.22,0-.43.05-.61.17-.18.11-.33.25-.43.43-.11.19-.17.4-.17.61s.06.43.17.61c.11.18.26.33.43.44.19.11.39.16.61.16s.42-.05.61-.16c.18-.11.33-.26.43-.44.11-.18.17-.39.17-.61,0-.04,0-.08,0-.11Zm-.51.52c-.07.12-.17.22-.29.28-.12.08-.26.11-.41.11s-.28-.04-.41-.11c-.12-.07-.22-.17-.29-.28-.07-.12-.11-.27-.11-.41s.04-.28.11-.41c.07-.12.17-.22.29-.29.13-.07.27-.11.41-.11s.28.04.41.11c.12.07.22.17.29.29.07.12.11.26.11.41s-.04.28-.11.41Zm-1.3,1.21c-.18,0-.35.04-.51.14-.15.08-.27.21-.36.36-.09.15-.14.33-.14.51v.4h.4v-.4c0-.11.03-.22.08-.31.05-.09.13-.17.22-.22.09-.05.2-.08.3-.08h.7c.02-.1.06-.22.11-.34,0-.02.02-.04.03-.07h-.84Z"
          />
          <path
            className="cls-1"
            d="m7.63,15.56h-.59v-.5c0-.19.05-.39.15-.56.1-.17.24-.31.4-.4.16-.1.35-.15.55-.15h.99l-.07.14s-.02.04-.02.05c-.05.12-.09.23-.11.33l-.02.08h-.78c-.09,0-.18.02-.25.07-.08.04-.14.1-.18.18-.05.08-.07.17-.07.26v.5Zm-.4-.19h.21v-.31c0-.13.03-.25.1-.36.06-.11.15-.2.26-.25.1-.06.22-.09.35-.09h.62c.02-.07.04-.14.07-.21h-.69c-.17,0-.33.04-.46.12-.13.07-.24.18-.33.33-.08.14-.13.31-.13.46v.31Zm1.52-1.63c-.24,0-.46-.06-.66-.17-.2-.12-.36-.28-.47-.47-.12-.2-.18-.42-.18-.66s.06-.46.18-.66c.11-.19.27-.35.47-.46.2-.12.43-.19.66-.18.23,0,.46.06.66.18.04.02.07.04.1.07.01.01.03.02.04.03,0,0,.02.01.02.02.12.1.22.22.3.34.1.16.16.35.17.53,0,.04,0,.09,0,.13,0,.23-.06.46-.18.66-.11.19-.27.35-.47.47-.2.11-.42.17-.66.17Zm-.02-2.42c-.2,0-.38.05-.55.15-.17.1-.31.24-.4.4-.1.17-.15.37-.15.56s.05.39.15.56c.1.17.24.31.4.41.34.19.79.19,1.12,0,.17-.1.31-.24.4-.4.1-.17.16-.36.16-.56,0-.03,0-.06,0-.09h0c-.01-.17-.06-.33-.15-.47-.07-.12-.16-.22-.28-.32l-.02-.02s-.06-.04-.1-.06c-.17-.11-.36-.16-.56-.16,0,0-.01,0-.02,0Zm.02,2.02c-.17,0-.32-.04-.46-.13-.12-.07-.24-.18-.32-.32-.08-.15-.12-.3-.12-.46s.04-.32.12-.45c.08-.14.19-.25.32-.33.29-.17.63-.17.92,0,.14.08.25.19.32.33.08.13.12.29.12.45s-.04.32-.12.46h0c-.08.14-.19.25-.32.32-.13.08-.29.12-.45.12Zm0-1.62c-.13,0-.25.03-.37.1-.1.06-.19.15-.25.26-.06.11-.1.23-.1.36s.03.25.1.36c.07.11.15.19.25.25.22.13.51.13.72,0,.1-.06.19-.14.26-.25.06-.11.1-.23.1-.36s-.03-.25-.1-.36c-.06-.11-.15-.2-.25-.26-.11-.07-.24-.1-.36-.1Z"
          />
        </g>
        <g>
          <path
            id="Path_160-3"
            data-name="Path 160"
            className="cls-1"
            d="m14.04,12.43c0,.22.06.43.17.61.1.18.26.33.43.44.18.11.39.16.61.16s.43-.05.61-.16c.18-.11.33-.26.43-.44.11-.18.17-.39.17-.61,0-.22-.06-.43-.17-.61-.11-.18-.26-.33-.43-.43-.19-.11-.39-.17-.61-.17-.21,0-.42.05-.61.17-.04.03-.08.05-.12.08,0,0,0,0,0,0-.12.09-.23.21-.31.34-.09.15-.15.33-.16.5,0,.04,0,.08,0,.11Zm.41,0c0-.14.04-.28.11-.41.07-.12.17-.22.29-.29.12-.07.26-.11.41-.11s.28.04.41.11c.12.07.22.17.29.29.07.12.11.26.11.41,0,.14-.04.28-.11.41-.07.12-.17.22-.29.28-.13.08-.27.11-.41.11s-.28-.04-.41-.11c-.12-.07-.22-.17-.29-.28-.07-.12-.11-.27-.11-.41Zm.58,1.62s.02.04.03.07c.05.12.09.23.11.34h.7c.11,0,.21.03.3.08.09.05.17.12.22.22.05.09.08.2.08.31v.4s.4,0,.4,0v-.4c0-.18-.05-.36-.14-.51-.09-.15-.21-.28-.36-.36-.15-.09-.33-.14-.51-.14h-.84Z"
          />
          <path
            className="cls-1"
            d="m16.37,15.06c0-.09-.02-.18-.07-.26-.04-.08-.11-.14-.18-.18-.08-.05-.16-.07-.25-.07h-.78l-.02-.08c-.02-.1-.06-.21-.11-.33,0-.02-.01-.03-.02-.05l-.07-.14h.99c.2,0,.39.05.55.15.16.09.3.22.4.4.1.17.16.37.15.56v.5h-.59v-.5Zm.4,0c0-.16-.04-.32-.13-.46-.08-.14-.19-.25-.33-.33-.13-.08-.29-.12-.46-.12h-.69c.03.07.05.14.07.21h.62c.12,0,.25.03.35.09.11.06.2.15.26.25.06.11.1.23.1.36v.31s.21,0,.21,0v-.31Zm-2.18-1.49c-.2-.12-.36-.28-.47-.47-.12-.2-.18-.43-.18-.66,0-.04,0-.09,0-.13.02-.19.08-.37.17-.53.07-.13.17-.24.3-.34,0,0,.02-.01.02-.02.01-.01.03-.02.04-.03.03-.02.07-.05.1-.07.2-.12.43-.19.66-.18.24,0,.46.06.66.18.19.12.36.28.47.46.12.2.18.43.18.66,0,.23-.06.46-.18.66-.11.19-.27.36-.47.47-.2.12-.42.17-.66.17s-.46-.06-.66-.17Zm.65-2.25c-.2,0-.39.05-.56.16-.03.02-.06.04-.1.06l-.02.02c-.12.09-.22.2-.28.32-.08.14-.13.3-.15.46h0s0,.07,0,.11c0,.2.05.39.16.56.09.16.23.3.4.4.34.19.78.19,1.12,0,.17-.1.3-.24.4-.41.1-.17.15-.36.15-.56,0-.2-.05-.39-.15-.56-.1-.16-.23-.3-.4-.4-.17-.1-.35-.15-.55-.15,0,0-.01,0-.02,0Zm-.45,1.9c-.13-.07-.24-.18-.32-.32h0c-.08-.14-.12-.3-.12-.46,0-.16.04-.32.12-.45.08-.14.19-.25.32-.33.28-.17.62-.17.92,0,.13.08.24.19.32.33.08.14.12.3.12.45,0,.16-.04.31-.12.46-.09.14-.2.25-.32.32-.14.08-.3.13-.46.13s-.32-.04-.45-.12Zm.09-1.39c-.11.06-.19.15-.25.26-.06.1-.1.23-.1.36,0,.13.03.25.1.36.06.11.15.19.26.25.21.13.5.13.72,0,.1-.06.19-.14.25-.25.06-.11.1-.24.1-.36,0-.12-.03-.25-.1-.36-.06-.11-.15-.2-.25-.26-.12-.07-.24-.1-.37-.1s-.25.03-.36.1Z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="cls-1"
            d="m7.03,6.81v-.12c0-.13-.09-.24-.22-.29v-.12c0-.17-.15-.31-.33-.31h-3.48c-.18,0-.33.14-.33.31v.12c-.13.04-.22.16-.22.29v.12c-.13.04-.22.16-.22.29v1.84c0,.17.15.31.33.31h4.35c.18,0,.33-.14.33-.31v-1.84c0-.13-.09-.24-.22-.29Zm-4.02-.63h3.48c.06,0,.11.05.11.1v.1h-3.7v-.1c0-.06.05-.1.11-.1Zm-.22.41h3.91c.06,0,.11.05.11.1v.1H2.68v-.1c0-.06.05-.1.11-.1Zm4.24,2.35c0,.06-.05.1-.11.1H2.57c-.06,0-.11-.05-.11-.1v-1.84c0-.06.05-.1.11-.1h4.35c.06,0,.11.05.11.1v1.84Z"
          />
          <path
            className="cls-1"
            d="m6.92,9.29H2.57c-.21,0-.38-.16-.38-.36v-1.84c0-.14.09-.26.22-.32v-.09c0-.14.09-.26.22-.32v-.09c0-.2.17-.36.38-.36h3.48c.21,0,.38.16.38.36v.09c.13.06.22.18.22.32v.09c.13.06.22.18.22.32v1.84c0,.2-.17.36-.38.36Zm-3.91-3.26c-.15,0-.28.12-.28.26v.16h-.03c-.11.05-.18.14-.18.25v.16h-.03c-.11.05-.18.14-.18.25v1.84c0,.14.12.26.28.26h4.35c.15,0,.28-.12.28-.26v-1.84c0-.11-.07-.2-.18-.24h-.03v-.17c0-.11-.07-.2-.18-.24h-.03v-.17c0-.14-.12-.26-.28-.26h-3.48Zm3.91,3.06H2.57c-.09,0-.16-.07-.16-.15v-1.84c0-.08.07-.15.16-.15h4.35c.09,0,.16.07.16.15v1.84c0,.08-.07.15-.16.15Zm-4.35-2.04s-.06.02-.06.05v1.84s.03.05.06.05h4.35s.06-.02.06-.05v-1.84s-.03-.05-.06-.05H2.57Zm4.29-.2H2.63v-.15c0-.08.07-.15.16-.15h3.91c.09,0,.16.07.16.15v.15Zm-4.13-.1h4.03v-.05s-.03-.05-.06-.05h-3.91s-.06.02-.06.05v.05Zm3.91-.31h-3.79v-.15c0-.08.07-.15.16-.15h3.48c.09,0,.16.07.16.15v.15Zm-3.7-.1h3.6v-.05s-.03-.05-.06-.05h-3.48s-.06.02-.06.05v.05Z"
          />
        </g>
        <path
          className="cls-1"
          d="m4.8,7.95s-.02,0-.02,0c0,0-.02,0-.03-.01-.05-.02-.08-.03-.08-.05,0,0,0-.02.03-.03.02,0,.08-.03.16.03.04.02.09.02.11-.02.03-.03.02-.08-.02-.1-.04-.03-.08-.05-.13-.06v-.06s-.04-.07-.08-.07c-.04,0-.08.03-.08.07v.06s-.03,0-.04.02c-.07.04-.12.1-.12.16,0,.13.13.17.19.19,0,0,.02,0,.02,0,0,0,.02,0,.03.01.06.02.08.03.08.05,0,0,0,.02-.03.03-.02,0-.08.03-.16-.03-.04-.02-.09-.02-.11.02-.03.03-.02.08.02.1.04.03.08.05.13.06v.06s.04.07.08.07c.04,0,.08-.03.08-.07v-.06s.03,0,.04-.01c.07-.04.12-.1.12-.16,0-.13-.12-.17-.18-.19Z"
        />
        <g>
          <path
            className="cls-1"
            d="m4.75,7.15c-.51,0-.92.39-.92.86s.41.86.92.86.92-.39.92-.86h0c0-.48-.41-.86-.92-.86Zm0,1.54c-.4,0-.72-.3-.72-.67s.32-.67.72-.67.72.3.72.67h0c0,.37-.32.67-.72.67h0Z"
          />
          <path
            className="cls-1"
            d="m4.75,8.93c-.54,0-.97-.41-.97-.91s.44-.91.97-.91h0c.53,0,.97.41.97.91,0,.5-.44.91-.97.91Zm0-1.73c-.48,0-.87.37-.87.81s.39.81.87.81.87-.37.87-.81c0-.45-.39-.81-.87-.81Zm0,1.54c-.42,0-.77-.32-.77-.72s.34-.72.77-.72.77.32.77.72c0,.4-.34.72-.77.72Zm0-1.34c-.37,0-.67.28-.67.62s.3.62.67.62.67-.28.67-.62c0-.34-.3-.62-.67-.62Z"
          />
        </g>
        <g>
          <path
            className="cls-1"
            d="m3.66,8.59h-.42c-.04-.13-.15-.24-.29-.28v-.6c.14-.04.26-.14.29-.28h.42c.06,0,.1-.04.1-.1,0-.05-.05-.09-.1-.09h-.51c-.06,0-.1.04-.1.1h0c0,.11-.09.19-.2.19-.06,0-.1.04-.1.1h0v.77c0,.05.05.1.1.1.11,0,.2.09.2.19,0,.05.05.1.1.1h.51c.06,0,.1-.04.1-.09,0-.05-.04-.1-.1-.1,0,0,0,0,0,0h0Z"
          />
          <path
            className="cls-1"
            d="m3.66,8.83h-.51c-.08,0-.15-.07-.15-.15s-.07-.14-.15-.14-.15-.07-.15-.15v-.77c0-.08.07-.15.15-.15s.15-.06.15-.14.07-.15.15-.15h.51c.08,0,.15.06.15.14,0,.08-.07.15-.15.15h-.39c-.05.12-.15.22-.28.26v.52c.13.04.23.14.28.26h.39s.08.02.11.04c.03.03.04.06.04.1,0,.08-.07.14-.15.14Zm-.51-1.53s-.05.02-.05.05c0,.13-.11.24-.25.24-.03,0-.05.02-.05.05v.77s.02.05.05.05c.14,0,.25.11.25.24,0,.03.02.05.05.05h.51s.05-.02.05-.04c0-.01,0-.02-.01-.03,0,0-.02-.02-.04-.02h-.46v-.04c-.04-.12-.14-.21-.27-.24h-.04v-.69h.04c.13-.04.22-.13.26-.25v-.04h.47s.05-.02.05-.05c0-.02-.02-.04-.05-.04h-.51Z"
          />
        </g>
        <g>
          <path
            className="cls-1"
            d="m6.64,7.54c-.11,0-.2-.09-.2-.19,0-.05-.05-.1-.1-.1h-.51c-.06,0-.1.04-.1.09,0,.05.04.1.1.1,0,0,0,0,0,0h.42c.04.13.15.24.29.28v.6c-.14.04-.26.14-.29.28h-.42c-.06,0-.1.04-.1.09,0,.05.04.1.1.1,0,0,0,0,0,0h.51c.06,0,.1-.04.1-.1,0-.11.09-.19.2-.19.06,0,.1-.04.1-.1v-.77c0-.05-.05-.1-.1-.1h0Z"
          />
          <path
            className="cls-1"
            d="m6.33,8.83h-.51s-.08-.02-.11-.05c-.03-.03-.04-.06-.04-.1,0-.04.02-.07.04-.1.03-.03.07-.05.11-.04h.38c.05-.12.15-.22.28-.26v-.52c-.13-.04-.23-.14-.28-.26h-.38s-.08-.02-.11-.05c-.03-.03-.04-.06-.04-.1,0-.04.02-.07.04-.1.03-.03.07-.04.11-.04h.51c.08,0,.15.07.15.15s.07.14.15.14.15.07.15.15v.77c0,.08-.07.15-.15.15s-.15.06-.15.14-.07.15-.15.15Zm-.51-.19s-.03,0-.04.01c0,0-.01.02-.01.03,0,.01,0,.02.01.03.01,0,.02.02.04.02h.51s.05-.02.05-.05c0-.13.11-.24.25-.24.03,0,.05-.02.05-.05v-.77s-.02-.05-.05-.05c-.14,0-.25-.11-.25-.24,0-.03-.02-.05-.05-.05h-.51s-.03,0-.04.01c0,0-.01.02-.01.03,0,.01,0,.02.01.03.01,0,.02.02.04.02h.46v.04c.04.12.14.21.27.24h.04v.69h-.04c-.12.04-.22.13-.26.25v.04h-.47Z"
          />
        </g>
      </g>
      <path
        className="cls-1"
        d="m7.07,19.7c-.07,0-.13-.02-.19-.06-2.55-1.71-4.08-4.57-4.08-7.64,0-.17,0-.34.01-.5.01-.19.17-.35.37-.33.19.01.34.17.33.37,0,.15-.01.31-.01.47,0,2.84,1.41,5.48,3.77,7.06.16.11.2.33.1.49-.07.1-.18.15-.29.15Z"
      />
      <path
        className="cls-1"
        d="m16.94,19.7c-.11,0-.22-.05-.29-.15-.11-.16-.07-.38.09-.49,2.35-1.58,3.76-4.22,3.76-7.06,0-.16,0-.31-.01-.46-.01-.19.14-.36.33-.37.23,0,.36.14.37.33,0,.17.01.33.01.5,0,3.07-1.52,5.92-4.07,7.64-.06.04-.13.06-.2.06Z"
      />
      <path
        className="cls-1"
        d="m7.3,4.85c-.12,0-.23-.06-.3-.16-.1-.16-.05-.38.11-.48,2.92-1.83,6.83-1.84,9.75-.02.16.1.21.32.11.48-.1.17-.32.21-.48.11-2.7-1.69-6.32-1.68-9.01.02-.06.04-.12.05-.19.05Z"
      />
      <g id="Page-1">
        <g id="Dribbble-Light-Preview">
          <g id="icons">
            <path
              className="cls-1"
              d="m13.16,22.32c-.7,0-1.51-.47-1.63-.54-.98-.59-1.34-1.46-1.46-1.74-.29-.69-.32-1.2-.1-1.51.07-.1.26-.3.51-.41.14-.06.24-.06.28-.07.42-.03.51.25.59.47.04.1.06.16.09.22.05.13.11.26.14.39.09.32-.08.49-.18.59-.06.06-.09.09-.09.13,0,.07.15.25.19.31l.05.06c.18.23.38.42.61.56.09.06.27.17.33.16.04-.02.08-.09.12-.16.08-.14.26-.45.66-.28.15.06.3.14.44.22l.12.07c.43.23.37.57.29.82h0s-.05.14-.05.14h0c-.06.13-.13.24-.24.33-.19.17-.43.23-.68.23Zm-2.36-3.82s-.06,0-.15.03c-.15.06-.28.2-.31.25-.06.08-.16.35.15,1.07.11.25.43,1.02,1.28,1.53.5.3,1.43.67,1.79.36.06-.05.1-.12.13-.21.09-.27.08-.28-.05-.35l-.13-.07c-.13-.07-.26-.15-.4-.2-.05,0-.08.05-.1.09-.06.1-.14.24-.3.33-.27.15-.57-.04-.75-.15-.31-.19-.55-.42-.76-.69l-.04-.05c-.13-.16-.32-.41-.29-.66.03-.19.14-.3.22-.38.08-.08.09-.09.07-.16-.03-.11-.08-.22-.12-.33-.03-.06-.05-.12-.08-.19-.08-.21-.09-.23-.15-.23Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default BuyerContact;
