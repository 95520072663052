import * as React from "react";
const AppleSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_497"
          data-name="Rectangle 497"
          width={32}
          height={32}
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="app" clipPath="url(#clip-path)">
      <path
        id="Path_10219"
        data-name="Path 10219"
        d="M23.868,10.8A5.857,5.857,0,0,1,28.6,13.4,5.287,5.287,0,0,0,26,16.667a5.791,5.791,0,0,0,.333,4.133,5.294,5.294,0,0,0,3,2.8,19.355,19.355,0,0,1-1.6,3.067Q25.335,30.4,23.4,30.4h-.067a4.924,4.924,0,0,1-1.9-.533,5.552,5.552,0,0,0-2.3-.533,5.684,5.684,0,0,0-2.367.533,4.924,4.924,0,0,1-1.9.533H14.8q-2,0-4.267-3.533A15.522,15.522,0,0,1,8.068,19.8,8.835,8.835,0,0,1,9.135,14a5.963,5.963,0,0,1,5.2-3.2,7.836,7.836,0,0,1,2.5.533,7.593,7.593,0,0,0,2.233.533,7.276,7.276,0,0,0,2.133-.533,7.945,7.945,0,0,1,2.667-.533Zm-.533-6a5.63,5.63,0,0,1-1.2,4.133,4.567,4.567,0,0,1-3.733,2h-.067a5.2,5.2,0,0,1,1.2-4A5.332,5.332,0,0,1,23.335,4.8Z"
        transform="translate(-2.668 -1.6)"
        fill="#fff"
      />
    </g>
  </svg>
);
export default AppleSVG;
