import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
const MLSProperties = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    accessibilityRole="image"
    {...props}
  >
    <G id="MLP-icon" transform="translate(-1069 -5329)">
      <Rect
        id="Rectangle_17"
        data-name="Rectangle 17"
        width={32}
        height={32}
        transform="translate(1069 5329)"
        fill="none"
      />
      <Path
        id="Path_226"
        data-name="Path 226"
        d="M28.75-31.375H17a2.753,2.753,0,0,0-2,.875,2.893,2.893,0,0,0-.875,2.125v4.625H3.25a2.893,2.893,0,0,0-2.125.875A2.893,2.893,0,0,0,.25-20.75V-9.625A2.269,2.269,0,0,0,1-8L7.75-1.25a2.2,2.2,0,0,0,1.625.625H15A2.893,2.893,0,0,0,17.125-1.5,2.753,2.753,0,0,0,18-3.5v-9l3.625,3.625a2.2,2.2,0,0,0,1.625.625h5.625a2.857,2.857,0,0,0,2-.812,2.692,2.692,0,0,0,.875-2.062V-28.5a2.753,2.753,0,0,0-.875-2A2.893,2.893,0,0,0,28.75-31.375ZM3.25-8.25H7.375a.421.421,0,0,1,.375.188.608.608,0,0,1,.125.313v4.125ZM16.125-3.5a1.1,1.1,0,0,1-.312.813A1.1,1.1,0,0,1,15-2.375H9.625V-7.75A2.259,2.259,0,0,0,9-9.312,2.094,2.094,0,0,0,7.375-10H2V-20.875a1.008,1.008,0,0,1,.375-.812A1.325,1.325,0,0,1,3.25-22h12.5a.608.608,0,0,1,.313.125.421.421,0,0,1,.188.375v18ZM17.875-15v-.875h3.25a.421.421,0,0,1,.375.188.608.608,0,0,1,.125.313v4.125ZM30-11.125a1.008,1.008,0,0,1-.375.813A1.325,1.325,0,0,1,28.75-10H23.5v-5.25a2.094,2.094,0,0,0-.687-1.625A2.259,2.259,0,0,0,21.25-17.5H18v-4a2.046,2.046,0,0,0-.625-1.5,2.585,2.585,0,0,0-1.5-.75V-28.5a1.008,1.008,0,0,1,.375-.812,1.325,1.325,0,0,1,.875-.312h11.75a1.1,1.1,0,0,1,.813.313A1.1,1.1,0,0,1,30-28.5Z"
        transform="translate(1069 5361)"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default MLSProperties;
