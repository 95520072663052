import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./CustomCarousel.css";

const CustomCarousel = ({
  children,
  onSlideChange,
  goToSlide,
  autoplay = true,
  interval = 3000,
  loop = true,
  style,
  showArrows,
}) => {
  const handleSlideChange = (currentIndex) => {
    onSlideChange && onSlideChange(currentIndex, children.length);
  };

  return (
    <div style={{ width: "100%", ...style }}>
      <Carousel
        showStatus={false}
        showIndicators={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={5}
        showArrows={showArrows}
        selectedItem={goToSlide}
        onChange={handleSlideChange}
        autoPlay={autoplay}
        interval={interval}
        infiniteLoop={loop}
        dynamicHeight
        emulateTouch
        swipeable
        className="carousel-animation-slide"
        width="100%"
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
