import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const PinProperties = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    viewBox="0 0 33 33"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Pin_Properties"
      data-name="Pin Properties"
      transform="translate(-263.416 -181.863)"
    >
      <Path
        id="Path_8"
        data-name="Path 8"
        d="M269.466,190.113v18.7a.55.55,0,0,1-.55.55h-5.5v-1.1h4.95v-17.6h-4.95v-1.1h5.5a.55.55,0,0,1,.55.55Z"
        fill="#fff"
      />
      <Path
        id="Path_9"
        data-name="Path 9"
        d="M268.366,185.163h-4.95v1.1h5.5a.55.55,0,0,0,.55-.55v-3.85h-1.1Z"
        fill="#fff"
      />
      <Path
        id="Path_10"
        data-name="Path 10"
        d="M273.866,185.163v-3.3h-1.1v3.85a.551.551,0,0,0,.55.55h23.1v-1.1Z"
        fill="#fff"
      />
      <Path
        id="Path_11"
        data-name="Path 11"
        d="M296.416,190.663v-1.1h-23.1a.55.55,0,0,0-.55.55v15.4a.551.551,0,0,0,.55.55h9.059a25.111,25.111,0,0,0,1.985,3.3H273.316a.549.549,0,0,0-.55.549v4.95h1.1v-4.4h11.286c.192.253.373.5.549.721-1.326.253-2.486.8-2.486,1.755,0,1.32,2.283,1.924,4.4,1.924s4.4-.6,4.4-1.924c0-.952-1.16-1.5-2.486-1.755.176-.225.358-.468.55-.721h6.337v-1.1h-5.544a25.249,25.249,0,0,0,1.986-3.3h3.558v-1.1h-3.091a5.046,5.046,0,0,0,.341-1.651,6.05,6.05,0,0,0-12.1,0,5.061,5.061,0,0,0,.341,1.651h-8.041v-14.3Zm-5.5,22.265c-.066.231-1.188.836-3.3.836s-3.235-.6-3.3-.814c.05-.17.754-.6,2.178-.77.391.484.66.793.71.848h0a.549.549,0,0,0,.825,0c.049-.055.319-.363.709-.847,1.425.166,2.128.6,2.178.749Zm-3.3-14.565a4.959,4.959,0,0,1,4.95,4.95c0,2.129-3.263,6.475-4.95,8.5-1.689-2.03-4.951-6.376-4.951-8.5a4.959,4.959,0,0,1,4.951-4.95Z"
        fill="#fff"
      />
      <Path
        id="Path_12"
        data-name="Path 12"
        d="M268.916,212.663h-5.5v1.1h4.95v1.1h1.1v-1.649a.551.551,0,0,0-.55-.551Z"
        fill="#fff"
      />
      <Path
        id="Path_13"
        data-name="Path 13"
        d="M290.367,203.312a2.754,2.754,0,1,0-.807,1.944A2.748,2.748,0,0,0,290.367,203.312Zm-4.4,0a1.649,1.649,0,1,1,.483,1.167A1.65,1.65,0,0,1,285.966,203.312Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default PinProperties;
