import * as React from "react";
const DroidSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_496"
          data-name="Rectangle 496"
          width={32}
          height={32}
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="andr" clipPath="url(#clip-path)">
      <path
        id="Path_10218"
        data-name="Path 10218"
        d="M8.427,13.75a1.414,1.414,0,0,1,1.033-.427,1.457,1.457,0,0,1,1.46,1.46V20.6a1.461,1.461,0,0,1-2.493,1.033A1.4,1.4,0,0,1,8,20.6v-5.82A1.4,1.4,0,0,1,8.427,13.75ZM22,8.05a5.217,5.217,0,0,1,2.153,1.787A7.051,7.051,0,0,1,25.187,11.8a8.068,8.068,0,0,1,.273,1.513H11.88a5.648,5.648,0,0,1,3.393-5.273L14.18,6.71a.173.173,0,0,1,.06-.12l.18-.06,1.153,1.393a9.006,9.006,0,0,1,3.093-.547,8.675,8.675,0,0,1,3.033.547L22.913,6.53a.173.173,0,0,1,.12.06l.12.12L22,8.043ZM11.88,18.9V14.29H25.453V24.35c0,.567-.22.847-.667.847h-1.7V28.35a1.457,1.457,0,0,1-1.46,1.46h-.06a1.458,1.458,0,0,1-.847-.3,1.372,1.372,0,0,1-.547-1.153V25.2H17.207v3.153a1.46,1.46,0,1,1-2.92,0V25.2h-1.76c-.447,0-.667-.28-.667-.847V18.9Zm3.82-7.82a.694.694,0,0,0,.787-.787.856.856,0,0,0-.24-.573.728.728,0,0,0-.547-.273.786.786,0,0,0-.607.273.856.856,0,0,0-.24.573.728.728,0,0,0,.273.547A.856.856,0,0,0,15.7,11.077Zm5.94,0a.856.856,0,0,0,.573-.24.728.728,0,0,0,.273-.547.951.951,0,0,0-.847-.847c-.527,0-.787.28-.787.847a.694.694,0,0,0,.787.787Zm5.213,2.667a1.414,1.414,0,0,1,1.033-.427,1.457,1.457,0,0,1,1.46,1.46V20.6a1.46,1.46,0,1,1-2.92,0v-5.82A1.4,1.4,0,0,1,26.853,13.743Z"
        transform="translate(-2.667 -2.177)"
        fill="#fff"
      />
    </g>
  </svg>
);
export default DroidSVG;
