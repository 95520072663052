// import React from "react";
// import {
//   VStack,
//   Center,
//   Text,
//   HStack,
//   Hidden,
//   Link as NLink,
//   Link,
// } from "native-base";

// import Googleplay from "../../assets/svg/GooglePlay";
// import Applestore from "../../assets/svg/AppleStore";

// import { vh, vw } from "../../util";

// const Medium = ({ landscape, gifs }) => {
//   return (
//     <Hidden only={["base", "sm", "lg", "xl"]}>
//       <Center margin={vh(100)}>
//         <VStack
//           style={{
//             background: "#7D2BE9 0% 0% no-repeat padding-box",
//             borderRadius: vw(40),
//             // width: "90%",
//             // height: vh(800),
//             width: vw(landscape ? 1300 : 1400), // newly changed
//             // height: vh(landscape ? 1350 : 780), // newly changed
//             height: "min-content",
//             alignSelf: "center",
//             marginTop: vh(landscape ? 100 : false), // newly changed
//             // marginTop: vh(50),
//             // justifyContent: "center",
//             // marginHorizontal: vw(20),
//           }}
//         >
//           <Text
//             // marginTop={vh(62)}
//             marginTop={landscape ? 62 : 62}
//             width={"100%"}
//             fontSize={vw(90)}
//             // lineHeight={vh(70)}
//             lineHeight={landscape ? 60 : 65}
//             fontFamily={"poppins"}
//             color="white"
//             textAlign={"center"}
//           >
//             The Only Real Estate Management App You Need…
//           </Text>
//           <Center>
//             <Text
//               width={"95%"}
//               fontSize={vw(landscape ? 35 : 53)}
//               fontFamily={"HelveticaNeueLTStd-Roman"}
//               color={"#fff"}
//               lineHeight={landscape ? 35 : 50}
//               //   lineHeight={vh(70)}
//               textAlign={"center"}
//               marginTop={vh(27)}
//               padding={vw(50)}
//             >
//               Shadow App is the future of real estate investments. Our
//               easy-to-use real estate CRM streamlines every aspect of your
//               business by bringing prominent features at your fingertips. It
//               permits you access to eminent properties, contact their owners,
//               close deals, and make money. Our App is suitable for individuals
//               and teams who believe in the power of real estate; the number of
//               deals you close, will change your life.{"\n"}
//               <Link href="https://web.shadow.properties/">
//                 <Text color={"#fff"} fontSize={vw(landscape ? 45 : 68)} fontWeight={"bold"}>
//                   Let's Get Started{"\n"} with a 14-day No Card Free Trial
//                 </Text>
//               </Link>
//             </Text>
//           </Center>

//           <HStack
//             style={{
//               justifyContent: "space-between",
//               width: "75%",
//               alignSelf: "center",
//               padding: vw(landscape ? 60 : false),
//             }}
//           >
//             <NLink href="https://apps.apple.com/us/app/shadowproperties/id1491176685/">
//               <Applestore width={vw(450)} height={vh(150)} />
//             </NLink>
//             <NLink
//               href="https://play.google.com/store/apps/details?id=and.app.properties.shadow"
//               isExternal
//             >
//               <Googleplay width={vw(450)} height={vh(150)} />
//             </NLink>
//           </HStack>
//         </VStack>
//       </Center>
//     </Hidden>
//   );
// };

// export default Medium;

import React from "react";
import {
  VStack,
  Center,
  Text,
  HStack,
  Hidden,
  Link as NLink,
  Link,
  Box,
  Pressable,
} from "native-base";

import Googleplay from "../../assets/svg/GooglePlay";
import Applestore from "../../assets/svg/AppleStore";

import { vh, vw } from "../../util";
import AppleSVG from "../../assets/svg/AppleSvg";
import WebSVG from "../../assets/svg/Web";
import DroidSVG from "../../assets/svg/Droid";

const Medium = ({ landscape, sendIp }) => {
  return (
    <Hidden only={["base", "sm", "lg", "xl"]}>
      <Center margin={vh(100)}>
        <VStack
          style={{
            background: "#7D2BE9 0% 0% no-repeat padding-box",
            borderRadius: vw(40),
            // width: "90%",
            // height: vh(800),
            width: vw(landscape ? 1300 : 1400), // newly changed
            // height: vh(landscape ? 1350 : 780), // newly changed
            height: "min-content",
            alignSelf: "center",
            marginTop: vh(landscape ? 100 : false), // newly changed
            // marginTop: vh(50),
            // justifyContent: "center",
            // marginHorizontal: vw(20),
          }}
        >
          <Text
            // marginTop={vh(62)}
            // marginTop={landscape ? 62 : 62}
            // width={"100%"}
            // fontSize={landscape ? 50 : 100}
            // // lineHeight={vh(70)}
            // lineHeight={landscape ? 60 : 65}
            // fontFamily={"Poppins"}
            // color="white"
            // textAlign={"center"}
            style={{
              width: "95%",
              alignSelf: "center",
              textAlign: "center",
              marginTop: vh(62),
              fontWeight: 500,
              fontFamily: "Poppins",
              color: "white",
              fontSize: vw(landscape ? 50 : 100),
            }}
          >
            The Only Real Estate Management App You Need…
          </Text>
          <Center>
            <Text
              width={"95%"}
              fontSize={vw(landscape ? 35 : 53)}
              fontFamily={"Poppins"}
              color={"white"}
              lineHeight={landscape ? 35 : 50}
              //   lineHeight={vh(70)}
              textAlign={"center"}
              marginTop={vh(27)}
              padding={vw(50)}
            >
              Shadow App is the future of real estate investments. Our
              easy-to-use real estate CRM streamlines every aspect of your
              business by bringing prominent features at your fingertips. It
              permits you access to eminent properties, contact their owners,
              close deals, and make money. Our App is suitable for individuals
              and teams who believe in the power of real estate; the number of
              deals you close, will change your life.{"\n"}
              <Text fontSize={vw(landscape ? 45 : 68)} fontWeight={"bold"}>
                Let's Get Started{"\n"} with a 14-day No Card Free Trial
              </Text>
            </Text>
          </Center>

          <Pressable alignSelf={"center"} onPress={sendIp}>
            <VStack
              rounded={"full"}
              style={{
                // borderRadius: vw(40),
                // height: vw(230),
                width: vw(800),
                // shadowColor: "#7D2BE959",
                // shadowOffset: { width: 0, height: 15 },
                // marginHorizontal:2,
                // shadowOpacity: 0.7,
                // shadowRadius: 40,
                background: "#F9F1FD 0% 0% no-repeat border-box",
                borderWidth: 3,
                borderColor: "#FFFFFF",
                transition: "box-shadow 0.3s ease-in-out",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: vh(62),
                alignSelf: "start",
              }}
            >
              <Text fontSize={vw(landscape ? 35 : 53)} color={"#6D549F"}>
                GET IT ON
              </Text>

              <HStack
                margin={vw(5)}
                width={"50%"}
                justifyContent={"space-around"}
              >
                <Box
                  rounded={"full"}
                  padding={vw(2)}
                  bg={"#7D2BE9"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <DroidSVG />
                </Box>
                <Box
                  rounded={"full"}
                  padding={vw(2)}
                  bg={"#7D2BE9"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box style={{ transform: "scale(0.85)" }}>
                    <AppleSVG />
                  </Box>
                </Box>
                <Box
                  rounded={"full"}
                  padding={vw(2)}
                  bg={"#7D2BE9"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <WebSVG />
                </Box>
              </HStack>
            </VStack>
          </Pressable>
        </VStack>
      </Center>
    </Hidden>
  );
};

export default Medium;
