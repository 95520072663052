import * as React from "react";
const Campaigns = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.249}
    height={25.162}
    viewBox="0 0 24.249 25.162"
    {...props}
  >
    <g
      id="NavCampaignNew_inactive_icon"
      transform="translate(-206.876 -766.838)"
    >
      <rect
        id="Rectangle_19"
        data-name="Rectangle 19"
        width={24}
        height={24}
        transform="translate(207 768)"
        fill="none"
      />
      <path
        id="Path_10143"
        data-name="Path 10143"
        d="M7.813-19.609H5.078a1.747,1.747,0,0,0-1.289.508,1.747,1.747,0,0,0-.508,1.289l-8.047.781H-7.734a1.747,1.747,0,0,0-1.289.508,1.747,1.747,0,0,0-.508,1.289v5.313a1.78,1.78,0,0,0,.273.977,1.757,1.757,0,0,0,.82.664l1.094,6.328A2.031,2.031,0,0,0-6.6-.781a2.017,2.017,0,0,0,1.289.469,1.973,1.973,0,0,0,1.68-.781A1.918,1.918,0,0,0-3.2-2.656l-.937-5.312L3.2-6.562v.078A1.683,1.683,0,0,0,3.75-5.2,1.785,1.785,0,0,0,5-4.687H7.734A1.747,1.747,0,0,0,9.023-5.2a1.747,1.747,0,0,0,.508-1.289V-17.812A1.747,1.747,0,0,0,9.023-19.1,1.653,1.653,0,0,0,7.813-19.609Zm-16.25,4.375a.687.687,0,0,1,.2-.508.687.687,0,0,1,.508-.2h2.422V-9.3H-7.734a.687.687,0,0,1-.508-.2.6.6,0,0,1-.2-.43ZM-4.453-1.875a.918.918,0,0,1-.859.391,1.039,1.039,0,0,1-.625-.234.63.63,0,0,1-.312-.469L-7.344-8.2h2.031L-4.3-2.5a.649.649,0,0,1-.153.625Zm.234-7.266v-6.8l7.5-.781v9.063ZM8.438-6.562a.828.828,0,0,1-.2.547.63.63,0,0,1-.508.234H5a.675.675,0,0,1-.469-.234A.75.75,0,0,1,4.3-6.562v-11.25a.63.63,0,0,1,.234-.508A.734.734,0,0,1,5-18.52H7.734a.687.687,0,0,1,.508.2.687.687,0,0,1,.2.508Z"
        transform="translate(221.912 788.565) rotate(-17)"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.3}
      />
    </g>
  </svg>
);
export default Campaigns;
