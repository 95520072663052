import React from "react";
import { VStack, Text, HStack, Stack, Link, Box } from "native-base";
import IconSp from "../../../assets/svg/Icon";

import { vw, vh } from "../../../util";
import WhiteLogo from "../../../assets/svg/WhiteLogo";

const PostHeader = ({ scrolled, isOpen, landscape }) => {
  return (
    <>
      <VStack
        style={{
          height: "min-content",
          width: "full",
          paddingVertical: 10,
        }}
      >
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <Stack ml={vw(35)}>
            {/* <Text bold fontSize={vw(20)} fontFamily={"Poppins_medium"}>
              ShadowProperties
            </Text> */}
            {scrolled || isOpen ? (
              <Link href="/">
                <IconSp
                  width={landscape ? vw(100) : vw(100)}
                  height={landscape ? vh(80) : vh(40)}
                />
              </Link>
            ) : (
              <Box>
                <Link href="/">
                  <WhiteLogo
                    width={landscape ? vw(100) : vw(100)}
                    height={landscape ? vh(80) : vh(40)}
                  />
                </Link>
              </Box>
            )}
          </Stack>
          <HStack space={vw(30)} mr={vw(35)}>
            <Stack>
              <Link href="/#main">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  Home
                </Text>
              </Link>
            </Stack>
            <Stack>
              <Link href="/#howitworks">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  How It Works
                </Text>
              </Link>
            </Stack>
            <Stack>
              <Link href="/#features">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  Features
                </Text>
              </Link>
            </Stack>
            {/* <Stack>
              <Link href="/tutorials">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontFamily={"Poppins_medium"}
                >
                  Tutorials
                </Text>
              </Link>
            </Stack> */}
            <Stack>
              <Link href="/#pricing">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  Pricing
                </Text>
              </Link>
            </Stack>
            <Stack>
              <Link href="/#contact">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  Contact us
                </Text>
              </Link>
            </Stack>
            <Stack>
              <Link href="/blog">
                <Text
                  color={"gray.400"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  Blog
                </Text>
              </Link>
            </Stack>
            <Stack>
              <Link href="https://calendly.com/shadow-demo/30min?month=2023-06">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  Demo
                </Text>
              </Link>
            </Stack>
            <Stack>
              <Link href="https://web.shadow.properties/">
                <Text
                  color={scrolled ? "black" : "white"}
                  fontSize={vw(15)}
                  fontWeight={300}
                  fontFamily={"Poppins"}
                >
                  Signup/Login
                </Text>
              </Link>
            </Stack>
          </HStack>
        </HStack>
      </VStack>
    </>
  );
};

export default React.memo(PostHeader);
