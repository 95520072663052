import React from "react";
import {
  Text,
  Pressable,
  Stack,
  HStack,
  Divider,
  VStack,
  Image,
  Avatar,
  Link,
} from "native-base";
import { StyleSheet } from "react-native";

import { vw, vh } from "../../../util";

const Template = (props) => {
  // useEffect(() => {
  //   getImage();
  //   getAuthor();
  // }, []);
  // console.log(item._links.author[0].href,"author_href");
  // const getImage = async () => {
  //   const url = props.item._links["wp:featuredmedia"][0].href;
  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },

  //       redirect: "follow",
  //     });

  //     if (response.ok) {
  //       const details = await response.json();
  //       // console.log(details.source_url, "url");
  //       setImage(details.source_url);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  // const getAuthor = async () => {
  //   const url = props.item._links.author[0].href;
  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },

  //       redirect: "follow",
  //     });

  //     if (response.ok) {
  //       const details = await response.json();
  //       // console.log(details, "author");
  //       setAuthor(details.name);
  //       setAvatar(details.avatar_urls[24]);
  //     }
  //   } catch (e) {
  //     console.log(e, "author error");
  //   }
  // };
  return (
    <>
      <Link href={"/blog/" + props.item?.slug} key={props.item?.id}>
        <Stack>
          <Stack
            // bg={landscape ? "amber.800" : "blue.600"}
            w={props.width}
            h={props.height}
            borderRadius="3"
            // h={window.innerHeight > 720 ? vh(200) : mh(110)}
            // w={window.innerWidth > 720 ? vw(500) : mw(335)}

            // style={styles.boxShadow}
            style={{ boxShadow: "0 15px 30px rgb(0 0 0 / 8%)" }}
          >
            <Pressable height={"min-content"} overflow={"hidden"}>
              {({ isHovered }) => (
                <Image
                  alt={props.item?._embedded["wp:featuredmedia"][0].alt_text}
                  style={
                    isHovered ? styles.imageStyleHovered : styles.imageStyle
                  }
                  // style={{
                  //   height: vh(260),
                  //   width: "full",
                  //   borderTopEndRadius: 3,
                  //   borderTopStartRadius: 3,
                  // }}
                  source={{
                    // uri: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
                    uri: props.item?._embedded["wp:featuredmedia"][0]
                      .source_url,
                  }}
                />
              )}
            </Pressable>
            <VStack ml={vw(30)} mr={vw(30)}>
              <Stack mt={vh(10)}>
                <Text
                  mt={vh(35)}
                  color={"gray.500"}
                  // fontSize={

                  //    (props.Size === "md") ? vw(800) : (props.Size === "base" || props.Size === "sm") ? vw(20) :
                  //   (props.Size === "lg" || props.Size === "xl") ? vw(5) : vw(1)
                  // }

                  // fontSize={props.Orientation ? vw(50) :  vw(14)}
                  fontSize={props.Font || vw(1)}
                  fontFamily={"Poppins"}
                >
                  {props.item?.date?.split("T")[0]}
                </Text>
                <p
                  style={{
                    marginTop: vh(15),
                    color: "rgb(63, 63, 70)",
                    fontWeight: "bold",
                    fontSize: props.HeadingFont || vw(1),
                    fontFamily: "Poppins",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: props.item?.title?.rendered,
                  }}
                ></p>
                {/* <Text
                  mt={vh(25)}
                  color={"gray.500"}
                  fontSize={props.Font || vw(1)}
                  fontFamily={"Poppins"}
                  lineHeight={vh(40)}
                >
                  {props.item?.excerpt?.rendered
                    ?.replace("<p>", "")
                    .replace("</p>", "")
                    .substr(0, 100)}
                  ...
                </Text> */}
                <p
                  style={{
                    color: "#777",
                    fontSize: props.Font || vw(1),
                    fontFamily: "Poppins",
                    lineHeight: vh(40),
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      props.item?.excerpt?.rendered.substring(0, 100) + "...",
                  }}
                ></p>
              </Stack>
            </VStack>
            <Divider mt={"auto"} />
            <VStack>
              {/* <Avatar  bg="green.500"  size={vh(60)} source={{
      uri: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
    }}>
      </Avatar> */}
            </VStack>
            <HStack m={vh(25)} ml={vw(40)}>
              <Avatar
                size={vw(42)}
                source={{
                  uri: props.item?._embedded?.author[0].avatar_urls["48"],
                }}
              />
              <VStack ml={vw(15)}>
                <Text
                  mt={vh(-5)}
                  color={"gray.700"}
                  fontSize={props.NameFont || vw(12)}
                  fontFamily={"Poppins"}
                  lineHeight={vh(30)}
                >
                  by
                </Text>
                <Text
                  mt={vh(-5)}
                  color={"gray.400"}
                  fontSize={props.NameFont || vw(12)}
                  fontFamily={"Poppins"}
                  lineHeight={vh(30)}
                >
                  {props.item?._embedded?.author[0].name}
                </Text>
              </VStack>
            </HStack>
          </Stack>
        </Stack>
      </Link>
    </>
  );
};

export default React.memo(Template);

const styles = StyleSheet.create({
  boxShadow: {
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 4,
    shadowColor: "#0000001A",
  },
  imageStyle: {
    height: vh(260),
    width: "full",
    borderTopEndRadius: 3,
    borderTopStartRadius: 3,

    transitionDuration: "1s",
  },
  imageStyleHovered: {
    height: vh(260),
    width: "full",
    borderTopEndRadius: 3,
    borderTopStartRadius: 3,
    transform: "scale(1.05)",
    transitionDuration: "1s",
  },
});
