import * as React from "react";
const WebSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_498"
          data-name="Rectangle 498"
          width={32}
          height={32}
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="web" clipPath="url(#clip-path)">
      <path
        id="Path_10220"
        data-name="Path 10220"
        d="M18.667,8a10.274,10.274,0,0,1,7.54,3.127,10.274,10.274,0,0,1,3.127,7.54,10.3,10.3,0,0,1-3.127,7.54,10.274,10.274,0,0,1-7.54,3.127h-.053a10.209,10.209,0,0,1-7.487-3.127A10.274,10.274,0,0,1,8,18.667a10.274,10.274,0,0,1,3.127-7.54A10.209,10.209,0,0,1,18.613,8h.053Zm-7.28,4.973A9.2,9.2,0,0,0,9.44,17.947h3.947a21.519,21.519,0,0,1,.513-3.9,16.565,16.565,0,0,1-2.513-1.08ZM9.44,19.38a9.253,9.253,0,0,0,1.9,4.973c.787-.373,1.64-.733,2.567-1.08a21.36,21.36,0,0,1-.513-3.9H9.447Zm2.873,6.053a9.264,9.264,0,0,0,3.28,2,12.6,12.6,0,0,1-1.333-2.82A14.179,14.179,0,0,0,12.313,25.433Zm3.28-15.54a8.315,8.315,0,0,0-3.233,2,12.747,12.747,0,0,0,1.9.82A11.644,11.644,0,0,1,15.593,9.893Zm2.36,8.053v-3.18a18.243,18.243,0,0,1-2.72-.36,20.939,20.939,0,0,0-.46,3.54h3.18Zm0,1.433h-3.18a20.939,20.939,0,0,0,.46,3.54,18.243,18.243,0,0,1,2.72-.36V19.38Zm0-9.793q-1.38.72-2.36,3.487a23.225,23.225,0,0,0,2.36.307Zm-2.36,14.667q.97,2.77,2.36,3.487V23.947C17.133,24.013,16.347,24.12,15.593,24.253Zm6.1-11.18c-.58-1.813-1.353-2.973-2.307-3.487V13.38a14.145,14.145,0,0,0,2.307-.307Zm-2.307,4.873h3.127a20.939,20.939,0,0,0-.46-3.54,18.073,18.073,0,0,1-2.667.36v3.18Zm0,1.433v3.18a18.073,18.073,0,0,1,2.667.36,20.939,20.939,0,0,0,.46-3.54H19.387Zm0,8.36q1.44-.77,2.307-3.487a13.452,13.452,0,0,0-2.307-.307Zm5.587-15.847a9.264,9.264,0,0,0-3.28-2,12.6,12.6,0,0,1,1.333,2.82A14.18,14.18,0,0,0,24.973,11.893Zm-3.28,15.54a9.172,9.172,0,0,0,3.333-2,14.179,14.179,0,0,0-1.947-.82,12.592,12.592,0,0,1-1.387,2.82ZM27.9,17.947a9.457,9.457,0,0,0-1.947-4.973,17.089,17.089,0,0,1-2.513,1.08,21.36,21.36,0,0,1,.513,3.9H27.9Zm-1.947,6.407A9.424,9.424,0,0,0,27.9,19.38H23.953a21.519,21.519,0,0,1-.513,3.9,17.089,17.089,0,0,1,2.513,1.08Z"
        transform="translate(-2.667 -2.667)"
        fill="#fff"
      />
    </g>
  </svg>
);
export default WebSVG;
