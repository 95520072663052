import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { vw, vh } from "../../util";

const TwitterOnHover = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width= { props.height  || vw(20)}
    height={ props.width || vh(20)}
    viewBox="0 0 18.12 14.72"
    {...props}
  >
    <Path
      id="Twitter_1"
      d="M12.3,23.18A10.5,10.5,0,0,0,22.88,12.6c0-.16,0-.32-.01-.48a7.342,7.342,0,0,0,1.85-1.92,7.32,7.32,0,0,1-2.13.59,3.679,3.679,0,0,0,1.63-2.06,7.177,7.177,0,0,1-2.36.9,3.721,3.721,0,0,0-6.43,2.55,3.411,3.411,0,0,0,.1.85A10.552,10.552,0,0,1,7.87,9.15a3.728,3.728,0,0,0,1.15,4.96,3.674,3.674,0,0,1-1.68-.47v.05a3.713,3.713,0,0,0,2.98,3.64,3.928,3.928,0,0,1-.98.13,3.26,3.26,0,0,1-.7-.07,3.716,3.716,0,0,0,3.47,2.58,7.448,7.448,0,0,1-4.62,1.59,8.686,8.686,0,0,1-.89-.05,10.578,10.578,0,0,0,5.7,1.67"
      transform="translate(-6.6 -8.46)"
      fill="#1e88e5"
    />
  </Svg>
);
export default TwitterOnHover;
