import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const SmartSearch = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Smart_Search"
      data-name="Skip Trace"
      transform="translate(-266.675 -185.129)"
    >
      <Path
        id="Path_32"
        data-name="Path 32"
        d="M285.109,203.494H273.058a.487.487,0,0,1-.486-.487,6.333,6.333,0,0,1,4.587-6.2.528.528,0,0,0,.364-.5v-.246a3.651,3.651,0,0,1-1.462-1.676,1.6,1.6,0,0,1-.835-.994,1.147,1.147,0,0,1,.513-1.312,3.5,3.5,0,0,1,1-2.156,3.436,3.436,0,0,1,4.759.008,3.513,3.513,0,0,1,1,2.192,1.148,1.148,0,0,1,.445,1.268,1.627,1.627,0,0,1-.762.975,3.661,3.661,0,0,1-1.47,1.695v.246a.528.528,0,0,0,.364.5,6.269,6.269,0,0,1,4.521,6.2.487.487,0,0,1-.486.487Zm-11.545-.976H284.6a5.291,5.291,0,0,0-3.816-4.777,1.491,1.491,0,0,1-1.051-1.437v-.541a.489.489,0,0,1,.282-.443,2.654,2.654,0,0,0,1.342-1.532.487.487,0,0,1,.43-.323.841.841,0,0,0,.218-.356.166.166,0,0,0-.111-.206l-.011,0a.488.488,0,0,1-.347-.459,2.426,2.426,0,1,0-4.851-.017.486.486,0,0,1-.347.457l-.076.023a.166.166,0,0,0-.111.206.779.779,0,0,0,.228.35.487.487,0,0,1,.483.324,2.657,2.657,0,0,0,1.344,1.538.488.488,0,0,1,.282.443v.541a1.491,1.491,0,0,1-1.051,1.437,5.335,5.335,0,0,0-3.88,4.777Z"
        fill="#fff"
      />
      <Path
        id="Path_33"
        data-name="Path 33"
        d="M288.05,209.938a.486.486,0,0,1-.344-.143l-2.874-2.887a.489.489,0,0,1,.082-.757,10.927,10.927,0,0,0,3.272-3.268.486.486,0,0,1,.752-.08l2.866,2.877a.489.489,0,0,1,0,.69l-3.409,3.423a.486.486,0,0,1-.344.143Zm-2.115-3.3,2.115,2.124,2.723-2.733-2.108-2.117a11.89,11.89,0,0,1-2.73,2.726Z"
        fill="#fff"
      />
      <Path
        id="Path_34"
        data-name="Path 34"
        d="M294.779,216.695a.487.487,0,0,1-.344-.143l-4.572-4.591a.49.49,0,0,1,0-.69l3.409-3.423a.485.485,0,0,1,.688,0l4.572,4.59a.489.489,0,0,1,0,.689l-3.409,3.424a.485.485,0,0,1-.344.143Zm-3.884-5.078,3.884,3.9,2.723-2.733-3.885-3.9Z"
        fill="#fff"
      />
      <Path
        id="Path_35"
        data-name="Path 35"
        d="M289.471,212.843a.488.488,0,0,1-.344-.142l-2.157-2.166a.489.489,0,0,1,0-.69l4.883-4.9a.484.484,0,0,1,.688,0l2.157,2.165a.491.491,0,0,1,0,.691l-4.883,4.9a.483.483,0,0,1-.344.142ZM288,210.189l1.47,1.475,4.2-4.212-1.47-1.477Z"
        fill="#fff"
      />
      <Path
        id="Path_36"
        data-name="Path 36"
        d="M279.083,208.834h0a11.852,11.852,0,1,1,8.348-20.233,11.877,11.877,0,0,1-2,18.373,11.727,11.727,0,0,1-6.353,1.861Zm0-22.729a10.877,10.877,0,1,0,5.829,20.047,10.9,10.9,0,0,0,1.831-16.861,10.74,10.74,0,0,0-7.66-3.186Z"
        fill="#fff"
      />
      <Path
        id="Path_37"
        data-name="Path 37"
        d="M285.905,197.61a2.579,2.579,0,1,1,2.569-2.579A2.576,2.576,0,0,1,285.905,197.61Zm0-4.182a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,285.905,193.428Z"
        fill="#fff"
      />
      <Path
        id="Path_38"
        data-name="Path 38"
        d="M272.021,197.61a2.579,2.579,0,1,1,2.569-2.579A2.577,2.577,0,0,1,272.021,197.61Zm0-4.182a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,272.021,193.428Z"
        fill="#fff"
      />
      <Path
        id="Path_39"
        data-name="Path 39"
        d="M289.377,201.792a.483.483,0,0,1-.344-.142l-1.941-1.949h-3.27a.488.488,0,0,1,0-.975h3.471a.485.485,0,0,1,.344.142l2.084,2.092a.491.491,0,0,1,0,.69.486.486,0,0,1-.344.142Z"
        fill="#fff"
      />
      <Path
        id="Path_40"
        data-name="Path 40"
        d="M268.55,201.095a.488.488,0,0,1-.271-.894l2.083-1.394a.484.484,0,0,1,.27-.082H274.1a.488.488,0,0,1,0,.976H270.78l-1.96,1.312a.481.481,0,0,1-.269.081Z"
        fill="#fff"
      />
      <Path
        id="Path_41"
        data-name="Path 41"
        d="M297.015,217.129H270.632a.488.488,0,0,1,0-.976h26.383a.488.488,0,0,1,0,.976Z"
        fill="#fff"
      />
      <Path
        id="Path_42"
        data-name="Path 42"
        d="M278.963,214.341h-11.8a.488.488,0,0,1,0-.976h11.8a.488.488,0,0,1,0,.976Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default SmartSearch;
