import React from "react";
import PropTypes from "prop-types";
import "./PaginationDots.css";

const PaginationDots = ({ total, currentIndex }) => {
  if (total < 2) {
    return null;
  }

  const visibleDots = Math.min(total, 5);
  const middleDotIndex = Math.floor(2.5);
  const isStartContinuationVisible = currentIndex > middleDotIndex;
  const isEndContinuationVisible = currentIndex + middleDotIndex < total - 1;

  const dots = [];

  for (let i = 0; i < visibleDots; i++) {
    const isFirst = i === 0;
    const isLast = i === visibleDots - 1 && isEndContinuationVisible;
    const isContinuation = (isFirst && isStartContinuationVisible) || isLast;

    let dotIndex;

    if (isStartContinuationVisible) {
      if (isEndContinuationVisible) {
        dotIndex = currentIndex - middleDotIndex + i;
      } else {
        dotIndex = total - visibleDots + i;
      }
    } else {
      dotIndex = i;
    }

    const isActive = dotIndex === currentIndex;
    const dotClassName = isActive
      ? "pagination-dot active"
      : isContinuation
      ? "pagination-dot continuation"
      : "pagination-dot inactive";

    dots.push(<span key={dotIndex} className={dotClassName}></span>);
  }

  return <div className="pagination-dots-container">{dots}</div>;
};

PaginationDots.propTypes = {
  total: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
};

export default PaginationDots;

//documentation
// The PaginationDots component is a simple, customizable, and animated pagination component for React applications.

// ### Features:

// 1. Displays pagination dots based on the total number of items and current item index.
// 2. Animates the active and continuation dots during the pagination process.
// 3. Shows a maximum of 5 dots at a time, with continuation dots to represent additional pages when necessary.

// ### Usage:

// 1. Install the `PropTypes` package if you haven't already:

// ```bash
// npm install prop-types
// ```

// 2. Copy the Pagination component file (src/Pagination.js) and the corresponding CSS file (src/PaginationDots.css) to your project.

// 3. Import the Pagination component to your desired location in your application:

// ```jsx
// import PaginationDots from "./Pagination";
// ```

// 4. Use the `<PaginationDots/>` component in your application like this:

// ```jsx
// <PaginationDots total={10} currentIndex={2} />
// ```

// ### Props:

// The following props are supported by the PaginationDots component:

// | Prop         | Type   | Required | Description                                                                    |
// | ------------ | ------ | -------- | ------------------------------------------------------------------------------ |
// | total        | number | Yes      | The total number of items or pages that need to be represented by pagination. |
// | currentIndex | number | Yes      | The current active index or page in the pagination.                            |

// ### Customization:

// You can customize the component's appearance and behavior by modifying the styling in the `PaginationDots.css` file, such as colors, dot sizes, animation duration, etc.