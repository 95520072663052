import React from "react";
import {
  Text,
  Stack,
  HStack,
  Divider,
  VStack,
  Avatar,
  Link,
} from "native-base";

import { vw, vh } from "../../../util";

const TemplateMedium = ({ Orientation, width, item,}) => {
  // console.log(item, item.title.rendered, "item");
  // console.log(item._links["wp:featuredmedia"][0].href, "link");
  const content = item.excerpt.rendered
    .replace("<p>", "")
    .replace("</p>", "")
    .substr(0, 100);
  const date = item.date.split("T")[0];
  return (
    <>
      <Link href={"/blog/" + item?.slug} key={item.id}>
        <Stack
          bg={"white"}
          ml={Orientation ? vw(50) : 0}
          mr={Orientation ? vw(50) : 0}
        >
          <Stack
            w={Orientation ? vw(650) : width}
            // h={Orientation ? vw(1300) : height}
            minH={150}
            borderRadius="3"
            // h={window.innerHeight > 720 ? vh(200) : mh(110)}
            // w={window.innerWidth > 720 ? vw(500) : mw(335)}

            // style={styles.boxShadow}
            style={{ boxShadow: "0 15px 30px rgb(0 0 0 / 8%)" }}
          >
            {/* <Image
              // style={{...styles.imageStyle, Orientation : props.Orientation }}
              height={Orientation ? vw(430) : vh(260)}
              width="full"
              source={{
                // uri: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
                uri: item?._embedded["wp:featuredmedia"][0].source_url,
              }}
            /> */}
            <img
              alt={item?._embedded["wp:featuredmedia"][0].alt_text}
              style={{
                height: "100%",
                width: "100%",
              }}
              src={item?._embedded["wp:featuredmedia"][0].source_url}
              loading="lazy"
            />
            <VStack
              ml={Orientation ? vw(70) : vh(35)}
              mr={Orientation ? vw(70) : vh(35)}
            >
              <Stack mt={vh(10)}>
                <Text
                  mt={vh(35)}
                  color={"gray.500"}
                  fontSize={Orientation ? vw(30) : vw(50)}
                  fontFamily={"Poppins"}
                >
                  {date}
                </Text>
                <VStack maxH={400} minH={400}>
                  <p
                    style={{
                      marginTop: vh(15),
                      color: "rgb(63, 63, 70)",
                      fontWeight: "bold",
                      fontSize: Orientation ? vw(43) : vh(23),
                      fontFamily: "Poppins",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item?.title?.rendered,
                    }}
                  ></p>
                  {/* <Text
                    my={"auto"}
                    color={"gray.500"}
                    // fontSize={vh(16)}
                    minH={130}
                    fontSize={Orientation ? vw(30) : vh(16)}
                    fontFamily={"Poppins"}
                    lineHeight={Orientation ? vw(60) : vh(30)}
                    overflow={"hidden"}
                    maxH={100}
                  >
                    {content}...
                  </Text> */}
                  <p
                    style={{
                      color: "#777",
                      fontSize: Orientation ? vw(30) : vh(16),
                      fontFamily: "Poppins",
                      lineHeight: Orientation ? vw(60) : vh(30),
                      maxHeight: 200,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item?.excerpt?.rendered.substring(0, 100) + "...",
                    }}
                  ></p>
                </VStack>
              </Stack>
            </VStack>
            <Divider mt={vh(20)} />
            <VStack>
              {/* <Avatar  bg="green.500"  size={vh(60)} source={{
      uri: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
    }}>
      </Avatar> */}
            </VStack>

            <HStack
              mt={Orientation ? vw(40) : vh(15)}
              ml={Orientation ? vw(80) : vh(45)}
            >
              <VStack>
                <Avatar
                  size={Orientation ? vw(75) : vh(45)}
                  source={{
                    uri: item?._embedded?.author[0].avatar_urls["48"],
                  }}
                />
              </VStack>
              <VStack
                ml={Orientation ? vw(30) : vh(20)}
                mt={Orientation ? vw(15) : vh(5)}
                m={30}
              >
                <Text
                  color={"gray.700"}
                  fontSize={Orientation ? vw(25) : vh(14)}
                  fontFamily={"Poppins"}
                  lineHeight={vh(15)}
                >
                  by
                </Text>
                <Text
                  mt={Orientation ? vw(20) : vh(5)}
                  color={"gray.400"}
                  fontSize={Orientation ? vw(25) : vh(14)}
                  fontFamily={"Poppins"}
                  lineHeight={vh(15)}
                >
                  {item?._embedded?.author[0].name}
                </Text>
              </VStack>
            </HStack>
          </Stack>
        </Stack>
      </Link>
    </>
  );
};

export default React.memo(TemplateMedium);

