import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { vw, vh } from "../../util";

const YouTubeOnHover = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width= { props.height  || vw(22)}
    height={ props.width || vh(20)}
    viewBox="0 0 18.66 13.06"
    {...props}
  >
    <Path
      id="Youtube"
      d="M24.08,10.62a2.332,2.332,0,0,0-1.65-1.65,55.786,55.786,0,0,0-7.29-.39,55.708,55.708,0,0,0-7.29.39A2.332,2.332,0,0,0,6.2,10.62a24.3,24.3,0,0,0-.39,4.49A24.236,24.236,0,0,0,6.2,19.6a2.332,2.332,0,0,0,1.65,1.65,55.786,55.786,0,0,0,7.29.39,55.708,55.708,0,0,0,7.29-.39,2.332,2.332,0,0,0,1.65-1.65,24.3,24.3,0,0,0,.39-4.49,24.236,24.236,0,0,0-.39-4.49ZM13.27,17.91v-5.6l4.85,2.8-4.85,2.8Z"
      transform="translate(-5.81 -8.58)"
      fill="#1e88e5"
    />
  </Svg>
);
export default YouTubeOnHover;
