import FontFaceObserver from "fontfaceobserver";
import React, { useState, useEffect } from "react";
import {
  VStack,
  Text,
  HStack,
  Stack,
  Link,
  Box,
  useBreakpointValue,
  Center,
  Pressable,
} from "native-base";
import IconSp from "./assets/svg/Icon";
import WhiteLogo from "./assets/svg/WhiteLogo";
import { vw, vh } from "./util";
import Divider from "./Divider";

const Navbar = ({ landscape, sendIp }) => {
  const [scrolled, setScrolled] = useState(false);
  const [scrolledUp, setScrolledUp] = useState(false);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        console.log("Scrolled up");
        setScrolledUp(true);
      } else if (prevScrollPos < currentScrollPos) {
        console.log("Scrolled down");
        setScrolledUp(false);
      }

      prevScrollPos = currentScrollPos;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
      const isScrolledUp = window.scrollY < 10;
      if (isScrolledUp !== scrolled) {
        setScrolled(!isScrolledUp);
      }
    };
    function handleWheel(event) {
      if (event.deltaY < 0) {
        console.log("Scrolled up");
        setScrolledUp(true);
      } else if (event.deltaY > 0) {
        console.log("Scrolled down");
        setScrolledUp(false);
      }
    }
    window.addEventListener("wheel", handleWheel);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleWheel);
    };
  }, [scrolled]);
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    if (isOpen || scrolledUp) {
      setOpen(false);
    }
  }, [scrolledUp]);
  const sz = useBreakpointValue({
    base: "base",
    lg: "lg",
    xl: "xl",
    md: "md",
    sm: "sm",
  });
  const styles = {
    container: {
      height: "32px",
      width: "32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      padding: "4px",
    },
    line: {
      height: "3px",
      width: "22px",
      background:
        scrolled || window.location.pathname !== "/" || isOpen
          ? "black"
          : "white",
      borderRadius: "20px",
      transition: "all 0.2s ease",
    },
    lineTop: {
      transform: isOpen ? "translateX(-2px) rotate(-45deg)" : "none",
      transformOrigin: "top right",
      marginBottom: "5px",
    },
    lineMiddle: {
      opacity: isOpen ? 0 : 1,
      transform: isOpen ? "translateX(16px)" : "none",
      width: "14px",
    },
    lineBottom: {
      transform: isOpen ? "rotate(45deg)" : "none",
      transformOrigin: "top right",
      marginTop: "5px",
    },
  };
  const menuList = [
    window.location.pathname !== "/" && { name: "Home", route: "#main" },
    { name: "How it works", route: "#howitworks" },
    { name: "Features", route: "#features" },
    { name: "Pricing", route: "#pricing" },
    { name: "Contact us", route: "#contact" },
    { name: "Blog", route: "blog" },
    {
      name: "Demo",
      route: "https://calendly.com/shadow-demo/30min?month=2023-06",
    },
  ];
  const MenuItem = (props) => {
    return (
      <VStack>
        {menuList.map((item) => {
          return (
            <Center h={landscape ? vw(60) : vh(60)}>
              <Link
                onPress={() => setOpen(!isOpen)}
                href={
                  item.name === "Demo" || item.name === "Signup/Login"
                    ? item.route
                    : "/" + item.route
                }
              >
                <Text fontFamily={"Poppins"} fontWeight={"bold"}>
                  {item.name}
                </Text>
              </Link>
            </Center>
          );
        })}
      </VStack>
    );
  };
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      const observer = new FontFaceObserver("Poppins");

      observer.load().then(() => {
        if (isLoaded !== true) setIsLoaded(true);
      });
    }, []);
  const NavItem = (props) => {

    const [hovered, setHovered] = useState(false);
    return (
      <Stack
      // style={{
      //   borderWidth: landscape ? 2 : 3,
      //   borderColor: !scrolled && hovered ? "white" : "transparent",
      //   borderRadius: vw(20),
      //   paddingVertical: vw(8),
      //   paddingHorizontal:vw(10)
      // }}
      >
        {isLoaded && (
          <Pressable
            onHoverIn={() => {
              setHovered(true);
            }}
            onHoverOut={() => {
              setHovered(false);
            }}
          >
            <Link href={props.route}>
              <Text
                color={
                  scrolled || window.location.pathname !== "/"
                    ? hovered
                      ? "rgb(125, 43, 233)"
                      : "black"
                    : hovered
                    ? "white"
                    : "white"
                }
                fontSize={vw(15)}
                fontWeight={300}
                fontFamily={"Poppins"}
                // style={{
                //   textShadowColor:
                //     !scrolled && hovered ? "rgb(125, 43, 233)" : false,
                //   textShadowRadius: !scrolled && hovered ? 4 : false,
                // }}
              >
                {props.name}
              </Text>
            </Link>
            <Divider isHovered={!scrolled && hovered} />
          </Pressable>
        )}
        {/* <Pressable
          onHoverIn={() => {
            setHovered(true);
          }}
          onHoverOut={() => {
            setHovered(false);
          }}
        >
          <Link href={props.route}>
            <Text
              color={
                scrolled || window.location.pathname !== "/"
                  ? hovered
                    ? "rgb(125, 43, 233)"
                    : "black"
                  : hovered
                  ? "white"
                  : "white"
              }
              fontSize={vw(15)}
              fontWeight={300}
              fontFamily={"Poppins"}
              // style={{
              //   textShadowColor:
              //     !scrolled && hovered ? "rgb(125, 43, 233)" : false,
              //   textShadowRadius: !scrolled && hovered ? 4 : false,
              // }}
            >
              {props.name}
            </Text>
          </Link>
          <Divider isHovered={!scrolled && hovered} />
        </Pressable> */}
        {/* {!scrolled && hovered ? (
          <Divider
            style={{
              backgroundColor: "white",
              backgroundPosition: "50% 50%",
              transition: "background-color .5s linear,background-position .5s linear",
            }}
            p={"1.1px"}
            // bg={"white"}
          />
        ) : (
          <Divider p={"1.1px"} bg={"transparent"} />
        )} */}
      </Stack>
    );
  };
  return (
    <>
      {(scrolledUp || !scrolled) && (
        <Box
          style={{
            backgroundColor:
              scrolled || window.location.pathname !== "/"
                ? "rgba(255,255,255,0.7)"
                : "transparent",
            boxShadow:
              scrolled || window.location.pathname !== "/"
                ? "1px 1px 8px #00000020"
                : "",
          }}
        >
          {(sz === "base" || sz === "sm" || sz === "md") && (
            <div>
              <div
                style={{
                  minHeight: landscape
                    ? window.innerHeight < 400
                      ? vh(200)
                      : vh(150)
                    : vh(95),
                  minWidth: "100vw",
                  position: "absolute",
                  backdropFilter:
                    scrolled || window.location.pathname !== "/"
                      ? "blur(10px)"
                      : false,
                  WebkitBackdropFilter:
                    scrolled || window.location.pathname !== "/"
                      ? "blur(10px)"
                      : false,
                  zIndex: 9999,
                }}
              ></div>
              <Box
                style={{
                  position: "fixed",
                  width: "100%",
                  zIndex: 10000,
                  boxShadow:
                    scrolled || window.location.pathname !== "/"
                      ? "1px 1px 8px #00000020"
                      : "",

                  backgroundColor:
                    scrolled || window.location.pathname !== "/"
                      ? "rgba(255,255,255,0.5)"
                      : "transparent",
                }}
              >
                <VStack
                  // bg="white"
                  w={"full"}
                  h={
                    landscape
                      ? window.innerHeight < 400
                        ? vh(200)
                        : vh(150)
                      : vh(95)
                  }
                  zIndex={100}
                >
                  <HStack
                    mt={landscape ? vh(30) : vh(20)}
                    ml={landscape ? vw(90) : vw(140)}
                    mr={landscape ? vw(90) : vw(140)}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Stack style={{ transform: "scale(1.5)" }}>
                      {scrolled ||
                      window.location.pathname !== "/" ||
                      isOpen ? (
                        <Link href="/">
                          <IconSp
                            width={landscape ? vw(100) : vw(100)}
                            height={landscape ? vh(80) : vh(40)}
                          />
                        </Link>
                      ) : (
                        <Link href="/">
                          <WhiteLogo
                            width={landscape ? vw(100) : vw(100)}
                            height={landscape ? vh(80) : vh(40)}
                          />
                        </Link>
                      )}
                    </Stack>
                    <Stack>
                      {/* <Menu
                width={landscape ? vw(100) : vw(100)}
                height={landscape ? vh(60) : vh(40)}
              /> */}
                      <div
                        style={styles.container}
                        onClick={() => {
                          setOpen(!isOpen);
                        }}
                      >
                        <div style={{ ...styles.line, ...styles.lineTop }} />
                        <div style={{ ...styles.line, ...styles.lineMiddle }} />
                        <div style={{ ...styles.line, ...styles.lineBottom }} />
                      </div>
                    </Stack>
                  </HStack>
                </VStack>
                <Box
                  style={{
                    position: "fixed",
                    width: "100%",
                    // height: "100%",
                    zIndex: 999,
                    boxShadow:
                      scrolled || window.location.pathname !== "/"
                        ? "1px 1px 8px #00000020"
                        : "",
                  }}
                >
                  <VStack
                    w={"full"}
                    h={
                      landscape
                        ? window.innerHeight < 400
                          ? vh(200)
                          : vh(150)
                        : vh(95)
                    }
                    zIndex={100}
                  >
                    <HStack
                      mt={landscape ? vh(30) : vh(20)}
                      ml={landscape ? vw(90) : vw(140)}
                      mr={landscape ? vw(90) : vw(140)}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Stack style={{ transform: "scale(1.5)" }}>
                        {scrolled ||
                        window.location.pathname !== "/" ||
                        isOpen ? (
                          <Link href="/">
                            <IconSp
                              width={landscape ? vw(100) : vw(100)}
                              height={landscape ? vh(80) : vh(40)}
                            />
                          </Link>
                        ) : (
                          <Link href="/">
                            <WhiteLogo
                              width={landscape ? vw(100) : vw(100)}
                              height={landscape ? vh(80) : vh(40)}
                            />
                          </Link>
                        )}
                      </Stack>
                      <Stack>
                        <div
                          style={styles.container}
                          onClick={() => {
                            setOpen(!isOpen);
                          }}
                        >
                          <div style={{ ...styles.line, ...styles.lineTop }} />
                          <div
                            style={{ ...styles.line, ...styles.lineMiddle }}
                          />
                          <div
                            style={{ ...styles.line, ...styles.lineBottom }}
                          />
                        </div>
                      </Stack>
                    </HStack>
                  </VStack>
                  {isOpen && (
                    <div
                      style={{
                        backgroundColor: "#000",
                        opacity: "0.5",
                        minHeight: "110vh",
                        minWidth: "100%",
                      }}
                      onClick={() => {
                        setOpen(!isOpen);
                      }}
                    >
                      {" "}
                    </div>
                  )}
                  <VStack
                    style={{
                      // display: isOpen ? "flex" : "none",
                      position: "absolute",
                      zIndex: 99,
                      width: "100%",
                      height: landscape ? "90vh" : "60vh",
                      // paddingTop: vw(300),
                      backgroundColor: "white",
                      opacity: isOpen ? 1 : 0,
                      boxShadow: isOpen ? "1px 1px 8px #00000020" : false,
                      transform: isOpen
                        ? "translateY(0%)"
                        : "translateY(-100%)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <VStack
                      justifyContent={"center"}
                      alignItems={"center"}
                      w={"100%"}
                      h={isOpen ? (landscape ? "100%" : "100%") : "100%"}
                      backgroundColor={"white"}
                      transition="all 0.3s linear"
                    >
                      <MenuItem isOpen={isOpen} />
                      <Pressable
                        // borderWidth={2}
                        height={"min-content"}
                        rounded={"full"}
                        // borderColor={
                        //   scrolled || window.location.pathname !== "/"
                        //     ? "rgb(125, 43, 233)"
                        //     : "white"
                        // }
                        // backgroundColor={
                        //   scrolled || window.location.pathname !== "/"
                        //     ? "rgb(125, 43, 233)"
                        //     : "transparent"
                        // }
                        // _hover={{
                        //   // style: {
                        //   //   background:
                        //   //     "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                        //   // },
                        //   backgroundColor: "white",
                        // }}
                        style={{
                          background:
                            scrolled || window.location.pathname !== "/"
                              ? "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box"
                              : "linear-gradient(135deg, #ffffff 0%, #dbdbdb 100%)",
                          boxShadow: "0px 3px 6px #00000029",
                        }}
                        justifyContent={"center"}
                        alignItems={"center"}
                        px={"30px"}
                        py={"10px"}
                        mt={vw(-14)}
                        onPress={() => {
                          sendIp();
                        }}
                      >
                        <Text
                          color={
                            scrolled || window.location.pathname !== "/"
                              ? "white"
                              : "#7D2BE9"
                          }
                          fontWeight={600}
                          style={{ fontFamily: "Poppins,sans-serif" }}
                        >
                          Signup / Login
                        </Text>
                      </Pressable>
                    </VStack>
                  </VStack>
                </Box>
              </Box>
            </div>
          )}
          {(sz === "lg" || sz === "xl") && (
            <VStack
              style={{
                height: "min-content",
                width: "full",
                paddingVertical: 10,
                // backgroundColor: "gray",
                backdropFilter:
                  scrolled || window.location.pathname !== "/"
                    ? "blur(10px)"
                    : false,
                WebkitBackdropFilter:
                  scrolled || window.location.pathname !== "/"
                    ? "blur(10px)"
                    : false,
              }}
            >
              <HStack justifyContent={"space-between"} alignItems={"center"}>
                <Stack ml={vw(35)}>
                  {/* <Text bold fontSize={vw(20)} fontFamily={"Poppins_medium"}>
              ShadowProperties
            </Text> */}
                  {scrolled || window.location.pathname !== "/" ? (
                    <Box style={{ transform: "scale(0.8)" }}>
                      <Link href="/">
                        <IconSp
                          width={landscape ? vw(100) : vw(100)}
                          height={landscape ? vh(80) : vh(40)}
                        />
                      </Link>
                    </Box>
                  ) : (
                    <Box>
                      <Link href="/">
                        <WhiteLogo
                          width={landscape ? vw(100) : vw(100)}
                          height={landscape ? vh(80) : vh(40)}
                        />
                      </Link>
                    </Box>
                  )}
                </Stack>
                <HStack
                  space={vw(30)}
                  mr={vw(35)}
                  // backgroundColor={"amber.200"}
                  mb={vw(-14)}
                  alignItems={"center"}
                >
                  {window.location.pathname !== "/" && (
                    // <Stack>
                    //   <Link href="/#main">
                    //     <Text
                    //       color={
                    //         scrolled || window.location.pathname !== "/"
                    //           ? "black"
                    //           : "white"
                    //       }
                    //       fontSize={vw(15)}
                    //       fontWeight="bold"
                    //       fontFamily={"Poppins_medium"}
                    //     >
                    //       Home
                    //     </Text>
                    //   </Link>
                    // </Stack>
                    <NavItem route={"/#main"} name={"Home"} />
                  )}
                  <NavItem route={"/#howitworks"} name={"How it works"} />
                  <NavItem route={"/#features"} name={"Features"} />
                  <NavItem route={"/#pricing"} name={"Pricing"} />
                  <NavItem route={"/#contact"} name={"Contact us"} />
                  <NavItem route={"/blog"} name={"Blog"} />
                  <NavItem
                    route={
                      "https://calendly.com/shadow-demo/30min?month=2023-06"
                    }
                    name={"Demo"}
                  />
                  {/* <NavItem
                    route={"https://web.shadow.properties/"}
                    name={"Signup/Login"}
                  /> */}
                  <Link>
                    <Pressable
                      // borderWidth={2}
                      height={"min-content"}
                      rounded={"full"}
                      // borderColor={
                      //   scrolled || window.location.pathname !== "/"
                      //     ? "rgb(125, 43, 233)"
                      //     : "white"
                      // }
                      // backgroundColor={
                      //   scrolled || window.location.pathname !== "/"
                      //     ? "rgb(125, 43, 233)"
                      //     : "transparent"
                      // }
                      // _hover={{
                      //   // style: {
                      //   //   background:
                      //   //     "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                      //   // },
                      //   backgroundColor: "white",
                      // }}
                      style={{
                        background:
                          scrolled || window.location.pathname !== "/"
                            ? "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box"
                            : "linear-gradient(135deg, #ffffff 0%, #dbdbdb 100%)",
                        boxShadow: "0px 3px 6px #00000029",
                      }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      px={"30px"}
                      py={"10px"}
                      mt={vw(-14)}
                      onPress={() => {
                        sendIp();
                      }}
                    >
                      <Text
                        color={
                          scrolled || window.location.pathname !== "/"
                            ? "white"
                            : "#7D2BE9"
                        }
                        fontSize={vw(15)}
                        fontWeight={600}
                        style={{ fontFamily: "Poppins,sans-serif" }}
                      >
                        Signup / Login
                      </Text>
                    </Pressable>
                  </Link>
                </HStack>
              </HStack>
            </VStack>
          )}
        </Box>
      )}
    </>
  );
};

export default React.memo(Navbar);
