import FontFaceObserver from 'fontfaceobserver';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "react-responsive-carousel/lib/styles/carousel.min.css";
const fontPoppinsRegular = new FontFaceObserver("Poppins", { weight: 400 });
const fontPoppinsMedium = new FontFaceObserver("Poppins", { weight: 500 });
const fontPoppinsSemiBold = new FontFaceObserver("Poppins", { weight: 600 });


Promise.all([
  fontPoppinsRegular.load(),
  fontPoppinsMedium.load(),
  fontPoppinsSemiBold.load(),
])
  .then(() => {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error("Failed to load Poppins fonts:", error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
