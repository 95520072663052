import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const DrivingRoutes = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={35}
    viewBox="0 0 33 35"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Driving_Routes"
      data-name="Driving Routes"
      transform="translate(-267.198 -183.299)"
    >
      <Path
        id="Path_126"
        data-name="Path 126"
        d="M284.509,200.147h0a.4.4,0,0,1-.358-.23,37.561,37.561,0,0,0-2.66-4.423c-1.572-2.376-3.056-4.622-3.056-6.088a6.116,6.116,0,0,1,12.232,0c0,1.5-1.523,3.766-3.134,6.165a37.841,37.841,0,0,0-2.662,4.349.4.4,0,0,1-.36.227Zm.038-16.056a5.325,5.325,0,0,0-5.323,5.316c0,1.227,1.487,3.476,2.925,5.65.85,1.285,1.724,2.607,2.363,3.814.641-1.184,1.513-2.48,2.358-3.741,1.474-2.195,3-4.465,3-5.723a5.326,5.326,0,0,0-5.323-5.316Z"
        fill="#fff"
      />
      <Path
        id="Path_127"
        data-name="Path 127"
        d="M284.547,192.551a3.143,3.143,0,1,1,3.146-3.143A3.148,3.148,0,0,1,284.547,192.551Zm0-5.495a2.351,2.351,0,1,0,2.353,2.351A2.356,2.356,0,0,0,284.547,187.056Z"
        fill="#fff"
      />
      <Path
        id="Path_128"
        data-name="Path 128"
        d="M272.136,211.032h0a.4.4,0,0,1-.357-.23,29.848,29.848,0,0,0-2.122-3.527c-1.263-1.911-2.457-3.716-2.457-4.91a4.97,4.97,0,0,1,9.94,0c0,1.223-1.224,3.045-2.519,4.972a30.064,30.064,0,0,0-2.123,3.468.4.4,0,0,1-.36.227Zm.031-12.84a4.18,4.18,0,0,0-4.177,4.172c0,.958,1.183,2.746,2.326,4.475.651.984,1.318,1.994,1.824,2.927.506-.915,1.172-1.9,1.82-2.87,1.171-1.744,2.384-3.549,2.384-4.531a4.179,4.179,0,0,0-4.176-4.173Z"
        fill="#fff"
      />
      <Path
        id="Path_129"
        data-name="Path 129"
        d="M272.167,204.957a2.592,2.592,0,1,1,2.6-2.591A2.6,2.6,0,0,1,272.167,204.957Zm0-4.392a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,272.167,200.564Z"
        fill="#fff"
      />
      <Path
        id="Path_130"
        data-name="Path 130"
        d="M296.907,211.085h0a.4.4,0,0,1-.359-.23,18.829,18.829,0,0,0-1.323-2.2c-.841-1.274-1.569-2.373-1.569-3.169a3.271,3.271,0,0,1,6.543,0c0,.814-.745,1.924-1.608,3.209a18.923,18.923,0,0,0-1.324,2.161.4.4,0,0,1-.358.226Zm.019-8.072a2.479,2.479,0,0,0-2.478,2.475,8.143,8.143,0,0,0,1.437,2.732c.359.542.725,1.094,1.025,1.621.3-.514.664-1.056,1.021-1.586a8.125,8.125,0,0,0,1.474-2.766,2.481,2.481,0,0,0-2.479-2.476Z"
        fill="#fff"
      />
      <Path
        id="Path_131"
        data-name="Path 131"
        d="M296.926,207.265a1.777,1.777,0,1,1,1.779-1.776A1.779,1.779,0,0,1,296.926,207.265Zm0-2.762a.985.985,0,1,0,.986.986A.988.988,0,0,0,296.926,204.5Z"
        fill="#fff"
      />
      <Path
        id="Path_132"
        data-name="Path 132"
        d="M284.521,216.837h0a.4.4,0,0,1-.358-.229,24.526,24.526,0,0,0-1.738-2.888c-1.044-1.579-2.03-3.07-2.03-4.074a4.152,4.152,0,0,1,8.3,0c0,1.027-1.01,2.532-2.079,4.125a24.747,24.747,0,0,0-1.739,2.839.4.4,0,0,1-.359.227Zm.025-10.545a3.361,3.361,0,0,0-3.36,3.356c0,.766.965,2.225,1.9,3.637.509.77,1.032,1.56,1.439,2.3.41-.723.929-1.5,1.436-2.251.956-1.425,1.946-2.9,1.946-3.683a3.361,3.361,0,0,0-3.36-3.356Z"
        fill="#fff"
      />
      <Path
        id="Path_133"
        data-name="Path 133"
        d="M284.547,211.848a2.2,2.2,0,1,1,2.2-2.2A2.2,2.2,0,0,1,284.547,211.848Zm0-3.609a1.408,1.408,0,1,0,1.409,1.408A1.411,1.411,0,0,0,284.547,208.239Z"
        fill="#fff"
      />
      <Path
        id="Path_134"
        data-name="Path 134"
        d="M284.509,202.327a2.925,2.925,0,0,1-3.174-2.575,2.622,2.622,0,0,1,1.95-2.379.4.4,0,0,1,.254.751,1.85,1.85,0,0,0-1.411,1.628c0,.983,1.068,1.785,2.381,1.785a2.179,2.179,0,0,0,2.352-1.587c.132-.786-.392-1.484-1.365-1.821a.4.4,0,1,1,.259-.748,2.47,2.47,0,0,1,1.889,2.7,2.949,2.949,0,0,1-3.135,2.246Z"
        fill="#fff"
      />
      <Path
        id="Path_135"
        data-name="Path 135"
        d="M296.9,212.789a2.433,2.433,0,0,1-2.635-2.153,2.18,2.18,0,0,1,1.554-1.965.4.4,0,1,1,.272.744,1.406,1.406,0,0,0-1.034,1.221c0,.751.827,1.361,1.843,1.361s1.842-.611,1.842-1.361a1.4,1.4,0,0,0-1.006-1.21.4.4,0,1,1,.283-.739,2.175,2.175,0,0,1,1.516,1.95,2.433,2.433,0,0,1-2.634,2.153Z"
        fill="#fff"
      />
      <Path
        id="Path_136"
        data-name="Path 136"
        d="M272.136,212.789a2.434,2.434,0,0,1-2.635-2.153,2.189,2.189,0,0,1,1.623-1.989.4.4,0,1,1,.255.75,1.416,1.416,0,0,0-1.084,1.239c0,.751.826,1.361,1.842,1.361s1.843-.611,1.843-1.361a1.413,1.413,0,0,0-1.071-1.234.4.4,0,1,1,.259-.748,2.185,2.185,0,0,1,1.6,1.982,2.435,2.435,0,0,1-2.636,2.153Z"
        fill="#fff"
      />
      <Path
        id="Path_137"
        data-name="Path 137"
        d="M284.521,218.3a2.089,2.089,0,0,1-2.258-1.858,1.883,1.883,0,0,1,1.394-1.717.4.4,0,1,1,.255.749,1.114,1.114,0,0,0-.856.968c0,.578.671,1.066,1.466,1.066s1.465-.488,1.465-1.066a1.111,1.111,0,0,0-.846-.963.4.4,0,0,1,.26-.749,1.884,1.884,0,0,1,1.378,1.712,2.088,2.088,0,0,1-2.258,1.858Z"
        fill="#fff"
      />
      <Path
        id="Path_138"
        data-name="Path 138"
        d="M282.9,216.121a.4.4,0,0,1-.168-.037l-8.869-4.151a.4.4,0,0,1,.336-.718l8.869,4.151a.4.4,0,0,1-.168.755Zm3.248,0a.4.4,0,0,1-.169-.755l8.859-4.149a.4.4,0,1,1,.336.716l-8.859,4.149a.377.377,0,0,1-.167.038Zm-12.49-6.378a.4.4,0,0,1-.264-.693l9.01-7.964a.4.4,0,1,1,.525.593l-9.009,7.965A.392.392,0,0,1,273.655,209.742Z"
        fill="#fff"
      />
      <Path
        id="Path_139"
        data-name="Path 139"
        d="M296.907,198.187h0a.4.4,0,0,1-.359-.23,18.782,18.782,0,0,0-1.323-2.2c-.841-1.273-1.569-2.372-1.569-3.167a3.271,3.271,0,0,1,6.543,0c0,.814-.745,1.924-1.608,3.208a19.034,19.034,0,0,0-1.324,2.161.4.4,0,0,1-.358.226Zm.019-8.071a2.479,2.479,0,0,0-2.478,2.475,8.13,8.13,0,0,0,1.437,2.732c.359.542.725,1.095,1.025,1.621.3-.515.664-1.057,1.02-1.585a8.112,8.112,0,0,0,1.473-2.768,2.479,2.479,0,0,0-2.478-2.475Z"
        fill="#fff"
      />
      <Path
        id="Path_140"
        data-name="Path 140"
        d="M296.926,194.368a1.777,1.777,0,1,1,1.779-1.777A1.78,1.78,0,0,1,296.926,194.368Zm0-2.762a.985.985,0,1,0,.986.985A.987.987,0,0,0,296.926,191.606Z"
        fill="#fff"
      />
      <Path
        id="Path_141"
        data-name="Path 141"
        d="M296.9,199.318a1.944,1.944,0,0,1-2.1-1.731,1.719,1.719,0,0,1,1.17-1.554.4.4,0,0,1,.3.733.951.951,0,0,0-.674.821c0,.509.6.939,1.305.939s1.3-.43,1.3-.939a.943.943,0,0,0-.651-.81.4.4,0,0,1,.311-.729,1.717,1.717,0,0,1,1.132,1.538,1.942,1.942,0,0,1-2.1,1.731Z"
        fill="#fff"
      />
      <Path
        id="Path_142"
        data-name="Path 142"
        d="M287.273,200.224a.4.4,0,0,1-.108-.777l7.921-2.241a.4.4,0,1,1,.216.762l-7.921,2.241a.4.4,0,0,1-.108.015Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default DrivingRoutes;
