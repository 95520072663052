// import React, { useEffect, useState } from "react";
// import {
//   VStack,
//   HStack,
//   Stack,
//   Center,
//   Text,
//   Box,
//   Icon,
//   Link,
//   Divider,
//   Image,
// } from "native-base";
// import { useForm } from "react-hook-form";
// import Mail from "../../assets/svg/mailIcon";
// import "./App.css";

// import { vw, vh } from "../../util";

// //fold one small
// const Small = ({ landscape }) => {
//   // //console.log("Fold 7 medium Landscape", landscape);
//   //console.log(vw(100), vh(100), "large");
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       name: "",
//       email: "",
//       message: "",
//     },
//   });

//   const onSubmit = async (data) => {
//     try {
//       // var raw = JSON.stringify({
//       //   username: data.username,
//       //   email: data.email,
//       //   password: data.password,
//       // });

//       const response = await fetch(
//         "https://api.shadow.properties/v2/get-in-touch",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(data),
//           redirect: "follow",
//         }
//       );

//       if (response.ok) {
//         const details = await response.json();
//         if (details.success) {
//           alert("Message received, will get in touch with you soon.");
//         }
//         //console.log(details, "response");
//         //console.log(data, "sending");
//       }
//       if (response.status == 403) {
//         //console.log("Forbidden");
//       }
//     } catch (error) {
//       //console.log(error);
//     }
//   };
//   const date = new Date();
//   return (
//     <VStack
//       style={{
//         width: "100%",
//         height: "min-content",
//         padding: vh(20),
//         boxShadow: "0px -8px 60px rgba(131,57,255,0.6)",
//         backgroundColor: "rgba(251,250,251,0.7)",
//         fontFamily: "HelveticaNeueLTStd-Bd",
//       }}
//     >
//       <Center>
//         <Text
//           style={{
//             textAlign: "center",
//             padding: vh(25),
//             paddingBottom: vh(5),
//             fontFamily: "HelveticaNeueLTStd-Bd",
//             color: "#7D2BE9",
//             fontSize: landscape ? vw(80) : vh(60),
//             lineHeight: landscape ? vw(80) : vh(70),
//           }}
//         >
//           Get in Touch{"\n"}with us!
//         </Text>
//       </Center>
//       <Center>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <Center>
//             <Box>
//               <input
//                 type={"text"}
//                 placeholder="Name"
//                 {...register("name", { required: true })}
//                 className="input_small"
//               />
//               {errors.name ? (
//                 <Text fontSize={"2xs"} color={"red.500"} ml={2}>
//                   *This field is required
//                 </Text>
//               ) : (
//                 <Box m={1}></Box>
//               )}
//             </Box>
//             <Box>
//               <input
//                 type={"text"}
//                 placeholder="Email"
//                 {...register("email", {
//                   required: true,
//                   pattern: {
//                     value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
//                     message: "invalid email",
//                   },
//                 })}
//                 className="input_small"
//               />
//               {errors.email ? (
//                 <Text fontSize={"2xs"} color={"red.500"} ml={2}>
//                   {errors.email.message
//                     ? "*invalid Email"
//                     : "*This field is required"}
//                 </Text>
//               ) : (
//                 <Box m={1}></Box>
//               )}
//             </Box>
//             <Box>
//               <textarea
//                 rows={4}
//                 placeholder="Message"
//                 {...register("message", { required: true })}
//                 className="input_small textarea_small"
//               />
//               {errors.message ? (
//                 <Text fontSize={"2xs"} color={"red.500"} ml={2}>
//                   *This field is required
//                 </Text>
//               ) : (
//                 <Box m={1}></Box>
//               )}
//             </Box>
//             <Box
//               width={window.innerWidth * 0.67}
//               m={2}
//               borderColor={"#7D2BE9"}
//               borderStyle={"solid"}
//               borderWidth={2}
//               borderRadius={25}
//               padding={3}
//             >
//               <Center>
//                 <input
//                   type={"submit"}
//                   value={"Send Message"}
//                   className="submit-small"
//                 />
//               </Center>
//             </Box>
//           </Center>
//         </form>
//       </Center>
//       <VStack
//         style={{
//           alignSelf: "center",
//           border: "2px solid #7D2BE9",
//         }}
//         rounded="full"
//         padding={3}
//         m={2}
//         width={"51px"}
//         height={"50px"}
//       >
//         <Icon as={<Mail />} size={5} style={{ alignSelf: "center" }} />
//       </VStack>
//       <Center>
//         <Text
//           ml={"2"}
//           color={"rgb(154,85,221)"}
//           fontSize={12}
//           lineHeight={"30pt"}
//           textAlign={"center"}
//           fontFamily={"HelveticaNeueLTStd-Bd"}
//         >
//           <Link href="mailto:info@shadow.properties" isUnderlined={false}>
//             info@shadow.properties
//           </Link>
//         </Text>
//         <Text
//           style={{
//             color: "rgb(152,138,180)",
//             textAlign: "center",
//             fontSize: 12,
//             fontWeight: "bold",
//             width: "90%",
//           }}
//         >
//           We strive to contribute the best to our real estate community, we
//           listen and answer to every question/request by everyone and add more
//           advanced features time to time.
//         </Text>
//         <Text
//           style={{
//             color: "rgb(152,138,180)",
//             textAlign: "center",
//             fontSize: 12,
//             fontWeight: "bold",
//           }}
//         >
//           <Link color={"rgb(154,85,221)"} href="/faqs">
//             FAQs.
//           </Link>
//         </Text>
//         <Divider
//           width={"95%"}
//           padding={vh(1)}
//           marginY={landscape ? vw(50) : vh(40)}
//           bg={"#DAD4E5"}
//         />
//       </Center>
//       <Center>
//         <HStack mb={5}>
//           <Link
//             href="https://www.facebook.com/people/Shadow-Properties/100087078565348/"
//             isExternal
//           >
//             <Image
//               alt="best real estate crm"
//               src={require("../../assets/png/Facebook.png")}
//               width={8}
//               height={8}
//             />
//           </Link>
//           <Link
//             href="https://www.instagram.com/shadowproperties_official/"
//             isExternal
//           >
//             <Image
//               alt="best real estate crm"
//               src={require("../../assets/png/Instagram.png")}
//               width={8}
//               height={8}
//             />
//           </Link>
//           <Link
//             href="https://www.linkedin.com/company/37566268/admin/"
//             isExternal
//           >
//             <Image
//               alt="best real estate crm"
//               src={require("../../assets/png/Linkedin.png")}
//               width={8}
//               height={8}
//             />
//           </Link>
//           <Link href="https://twitter.com/Shadow_CRM" isExternal>
//             <Image
//               alt="best real estate crm"
//               src={require("../../assets/png/Twitter.png")}
//               width={8}
//               height={8}
//             />
//           </Link>
//           <Link href="https://www.youtube.com/@shadowproperties" isExternal>
//             <Image
//               alt="best real estate crm"
//               src={require("../../assets/png/Youtube.png")}
//               width={8}
//               height={8}
//             />
//           </Link>
//         </HStack>
//         <HStack>
//           <Link
//             // onClick={() => {
//             //   window.scrollTo(0, 0);
//             //   setMain("terms");
//             //   //console.log("terms");
//             // }}
//             href="/terms"
//           >
//             <Text
//               color="#988AB4"
//               // ml={["", "", "150", "300"]}
//               fontStyle={"HelveticaNeueLTStd-Md"}
//               fontWeight={"bold"}
//               fontSize={12}
//             >
//               Terms
//             </Text>
//           </Link>

//           <Stack>
//             <Divider
//               orientation="vertical"
//               thickness={1.5}
//               fontWeight="bold"
//               bg="rgb(152,138,180)"
//               mt={1}
//               ml={["2"]}
//               height={3}
//             />
//           </Stack>
//           <Link
//             // onClick={() => {
//             //   window.scrollTo(0, 0);
//             //   setMain("privacy");
//             //   //console.log("privacy");
//             // }}
//             href="/privacy"
//           >
//             <Text
//               ml={"2"}
//               color="#988AB4"
//               fontStyle={"HelveticaNeueLTStd-Md"}
//               fontWeight={"bold"}
//               fontSize={12}
//             >
//               Privacy
//             </Text>
//           </Link>

//           <Stack>
//             <Divider
//               orientation="vertical"
//               thickness={1.5}
//               fontWeight="bold"
//               bg="rgb(152,138,180)"
//               ml={["2"]}
//               mt={1}
//               height={3}
//             />
//           </Stack>
//           <Link
//             // onClick={() => {
//             //   window.scrollTo(0, 0);
//             //   setMain("faqs");
//             //   //console.log("faqs");
//             // }}
//             href="/faqs"
//           >
//             <Text
//               ml={"2"}
//               color="#988AB4"
//               fontStyle={"HelveticaNeueLTStd-Md"}
//               fontWeight={"bold"}
//               fontSize={12}
//             >
//               FAQs
//             </Text>
//           </Link>
//         </HStack>
//       </Center>
//       <Text
//         style={{
//           color: "rgb(152,138,180)",
//           textAlign: "center",
//           fontSize: 12,
//           fontWeight: "bold",
//           padding: vw(30),
//           lineHeight: 20,
//         }}
//       >
//         Copyright © {date.getFullYear()} ShadowProperties.{"\n"}All rights
//         reserved.
//       </Text>
//     </VStack>
//     // <VStack bg={"blue.700"} style={{ height: window.innerHeight*0.5 }}>
//     //   <Center style={{ flex: 1, height: "100%" }}>Fold eight small</Center>
//     // </VStack>
//   );
// };

// export default React.memo(Small);

import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Stack,
  Center,
  Text,
  Box,
  Icon,
  Link,
  Divider,
  Image,
  Button,
} from "native-base";
import { useForm } from "react-hook-form";
import Mail from "../../assets/svg/mailIcon";
import "./App.css";
import MaskedInput from "react-text-mask";
import { vw, vh } from "../../util";

//fold one small
const Medium = ({ landscape }) => {
  //console.log("Fold 7 small Landscape", landscape,"landscapeeeeeeeeeeeeeeeeeee");
  //console.log(vw(100), vh(100), "large");
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        "https://api.shadow.properties/v2/get-in-touch",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          redirect: "follow",
        }
      );

      if (response.ok) {
        const details = await response.json();
        if (details.success) {
          reset();
          alert("Message received, will get in touch with you soon.");
        }
        //console.log(details, "response");
        //console.log(data, "sending");
      }
      if (response.status == 403) {
        //console.log("Forbidden");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const date = new Date();
  return (
    <VStack
      style={{
        width: "100%",
        height: "min-content",
        padding: vh(20),
        boxShadow: "0px -8px 60px rgba(131,57,255,0.6)",
        backgroundColor: "rgba(251,250,251,0.7)",
        fontFamily: "HelveticaNeueLTStd-Bd",
      }}
    >
      <Center my={vw(20)}>
        <Text
          style={{
            textAlign: "center",
            // padding: vh(25),
            // paddingBottom: vh(10),
            // marginY:vh(30),
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#7D2BE9",
            fontSize: landscape ? vw(65) : vh(40),
            lineHeight: landscape ? vw(80) : vh(70),
            width: "100%",
          }}
        >
          Get in Touch!
        </Text>
      </Center>
      <Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Center>
            <Box>
              <input
                type={"text"}
                placeholder="Name"
                {...register("name", { required: true })}
                className="input_small"
                onBlur={() => {
                  trigger("name");
                }}
              />
              {errors.name ? (
                <Text fontSize={"2xs"} color={"red.500"} ml={2}>
                  *This field is required
                </Text>
              ) : (
                <Box m={1}></Box>
              )}
            </Box>
            <Box>
              <input
                type={"text"}
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "invalid email",
                  },
                })}
                className="input_small"
                onBlur={() => {
                  trigger("email");
                }}
              />
              {errors.email ? (
                <Text fontSize={"2xs"} color={"red.500"} ml={2}>
                  {errors.email.message
                    ? "*invalid Email"
                    : "*This field is required"}
                </Text>
              ) : (
                <Box m={1}></Box>
              )}
            </Box>
            <Box>
              {/* <input
                type={"text"}
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "invalid email",
                  },
                })}
                className="input_small"
              /> */}
              <MaskedInput
                // type={"text"}
                mask={[
                  "(",
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                ref={register("phone", {
                  required: true,
                  pattern: {
                    value: /\d{10}/,
                    message: "invalid phone",
                  },
                })}
                onChange={(e) => {
                  setValue("phone", e.target.value.replace(/\D/g, ""));
                }}
                onBlur={() => {
                  trigger("phone");
                }}
                placeholder="Phone *"
                autoComplete="off"
                autoCapitalize="off"
                className="input_small"
              />
              {errors.email ? (
                <Text fontSize={"2xs"} color={"red.500"} ml={2}>
                  {errors.email.message
                    ? "*invalid Email"
                    : "*This field is required"}
                </Text>
              ) : (
                <Box m={1}></Box>
              )}
            </Box>
            <Box>
              <textarea
                rows={4}
                placeholder="Message"
                {...register("message", { required: true })}
                className="input_small textarea_small"
                onBlur={() => {
                  trigger("message");
                }}
              />
              {errors.Message ? (
                <Text fontSize={"2xs"} color={"red.500"} ml={2}>
                  *This field is required
                </Text>
              ) : (
                <Box m={1}></Box>
              )}
            </Box>
            <Button
              rounded={"full"}
              disabled={isValid ? false : true}
              onPress={handleSubmit(onSubmit)}
              style={
                isValid
                  ? {
                      borderWidth: landscape ? 2 : 3,
                      borderColor: "transparent",
                      width: "80%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      background:
                        "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                    }
                  : {
                      borderWidth: landscape ? 2 : 3,
                      borderColor: "#7D2BE9",
                      width: "80%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }
              }
              backgroundColor={"transparent"}
              alignSelf={"center"}
              m={vw(10)}
              mb={0}
              _text={{
                color: isValid ? "white" : "#7D2BE9",
                fontFamily: "Poppins",
                fontSize: vw(landscape ? 35 : 53),
                fontWeight: 400,
              }}
            >
              Send Message
            </Button>
          </Center>
        </form>
      </Center>
      <VStack
        style={{
          alignSelf: "center",
          border: "2px solid #7D2BE9",
        }}
        rounded="full"
        padding={3}
        m={2}
        width={"51px"}
        height={"50px"}
      >
        <Icon as={<Mail />} size={5} style={{ alignSelf: "center" }} />
      </VStack>
      <Center>
        <Text
          ml={"2"}
          color={"rgb(154,85,221)"}
          fontSize={vw(landscape ? 35 : 53)}
          lineHeight={"30pt"}
          textAlign={"center"}
          fontFamily={"Poppins"}
          fontWeight={500}
        >
          <Link href="mailto:info@shadow.properties" isUnderlined={false}>
            info@shadow.properties
          </Link>
        </Text>
        <Text
          style={{
            color: "#303030",
            textAlign: "center",
            fontSize: vw(landscape ? 35 : 53),
            // fontWeight: "bold",
            fontWeight: 300,
            fontFamily: "Poppins",
            width: "80%",
          }}
        >
          We strive to contribute the best to our real estate community, we
          listen and answer to every question/request by everyone and add more
          advanced features time to time.
        </Text>
        <Text
          style={{
            color: "rgb(152,138,180)",
            textAlign: "center",
            fontSize: vw(landscape ? 40 : 65),
            fontWeight: "500",
          }}
        >
          <Link color={"rgb(154,85,221)"} href="/faqs">
            FAQs.
          </Link>
        </Text>
        <Divider
          width={"95%"}
          padding={vh(1)}
          marginY={landscape ? vw(50) : vh(40)}
          bg={"#DAD4E5"}
        />
      </Center>
      <Center>
        <HStack mb={5}>
          <Link
            href="https://www.facebook.com/people/Shadow-Properties/100087078565348/"
            isExternal
          >
            <Image
              alt="best real estate crm"
              src={require("../../assets/png/Facebook.png")}
              width={8}
              height={8}
            />
          </Link>
          <Link
            href="https://www.instagram.com/shadowproperties_official/"
            isExternal
          >
            <Image
              alt="best real estate crm"
              src={require("../../assets/png/Instagram.png")}
              width={8}
              height={8}
            />
          </Link>
          <Link
            href="https://www.linkedin.com/company/37566268/admin/"
            isExternal
          >
            <Image
              alt="best real estate crm"
              src={require("../../assets/png/Linkedin.png")}
              width={8}
              height={8}
            />
          </Link>
          <Link href="https://twitter.com/Shadow_CRM" isExternal>
            <Image
              alt="best real estate crm"
              src={require("../../assets/png/Twitter.png")}
              width={8}
              height={8}
            />
          </Link>
          <Link href="https://www.youtube.com/@shadowproperties" isExternal>
            <Image
              alt="best real estate crm"
              src={require("../../assets/png/Youtube.png")}
              width={8}
              height={8}
            />
          </Link>
        </HStack>
        <HStack>
          <Link
            // onClick={() => {
            //   window.scrollTo(0, 0);
            //   setMain("terms");
            //   //console.log("terms");
            // }}
            href="/terms"
          >
            <Text
              color="#303030"
              // ml={["", "", "150", "300"]}
              fontFamily={"Poppins"}
              fontWeight={"300"}
              fontSize={vw(landscape ? 35 : 53)}
            >
              Terms
            </Text>
          </Link>

          <Stack>
            <Divider
              orientation="vertical"
              thickness={1.5}
              fontWeight="bold"
              bg="rgb(152,138,180)"
              mt={1}
              ml={["2"]}
              height={3}
            />
          </Stack>
          <Link
            // onClick={() => {
            //   window.scrollTo(0, 0);
            //   setMain("privacy");
            //   //console.log("privacy");
            // }}
            href="/privacy"
          >
            <Text
              ml={"2"}
              color="#303030"
              fontFamily={"Poppins"}
              fontWeight={"300"}
              fontSize={vw(landscape ? 35 : 53)}
            >
              Privacy
            </Text>
          </Link>

          <Stack>
            <Divider
              orientation="vertical"
              thickness={1.5}
              fontWeight="bold"
              bg="rgb(152,138,180)"
              ml={["2"]}
              mt={1}
              height={3}
            />
          </Stack>
          <Link
            // onClick={() => {
            //   window.scrollTo(0, 0);
            //   setMain("faqs");
            //   //console.log("faqs");
            // }}
            href="faqs"
          >
            <Text
              ml={"2"}
              color="#303030"
              fontFamily={"Poppins"}
              fontWeight={"300"}
              fontSize={vw(landscape ? 35 : 53)}
            >
              FAQs
            </Text>
          </Link>
        </HStack>
      </Center>
      <Text
        style={{
          color: "#303030",
          textAlign: "center",
          fontSize: vw(landscape ? 35 : 53),
          fontFamily: "Poppins",
          fontWeight: "300",
          padding: vw(30),
          lineHeight: landscape ? 35 : 50,
        }}
      >
        Copyright © {date.getFullYear()} ShadowProperties.{"\n"}All rights
        reserved.
      </Text>
    </VStack>
    // <VStack bg={"blue.700"} style={{ height: window.innerHeight*0.5 }}>
    //   <Center style={{ flex: 1, height: "100%" }}>Fold eight small</Center>
    // </VStack>
  );
};

export default React.memo(Medium);

