import React from "react";

const Divider = ({isHovered}) => {
  return (
    <hr
      style={{
        height: "3px",
        backgroundColor: isHovered ? "white" : false,
        border: "none",
        borderRadius: "20px",
        margin: "5px 0",
        transformOrigin: "50% 50%",
        transition: "all 0.3s cubic-bezier(0.34, 1.45, 0.7, 1)",
        transform: isHovered ? "scaleX(1.1)" : "scaleX(0.2)", // Apply transform only when isHovered is true
      }}
    />
  );
};

export default Divider;
