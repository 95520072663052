
export let DW = 1600;
export let DH = 1024;

export const vh = (value) => {
  return `${(100 * ch(value)) / window.innerHeight}vh`;
};

export const vw = (value) => {
  return `${(100 * cw(value)) / window.innerWidth}vw`;
};

const cw = (w) => {
  return (window.innerWidth / DW) * w;
};
const ch = (h) => {
  return (window.innerHeight / DH) * h;
};

export const setWH = (w, h) => {
  DW = w;
  DH = h;
};

//   function vhToPixel(value) {
//     return document.querySelector("#vhToPixel").innerHTML = `Result = ${(window.innerHeight * value) / 100}px`;
//   }
//   function vwToPixel(value) {
//     return document.querySelector("#vwToPixel").innerHTML = `Result = ${((window.innerWidth * value) / 100)}px`;
//   }
const Util = {
  vh,
  vw,
};

export default Util;
