import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const UserRoles = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={31.25}
    height={31.25}
    viewBox="0 0 31.25 31.25"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="User_Roles"
      data-name="User Roles"
      transform="translate(-264.934 -185.94)"
    >
      <Path
        id="Path_160"
        data-name="Path 160"
        d="M287.356,203.4h-13.6a.223.223,0,0,1-.235-.238v-4.531a3.513,3.513,0,0,1,3.481-3.529h.588a.215.215,0,0,1,.188.1,3.335,3.335,0,0,0,2.775,1.454,3.369,3.369,0,0,0,2.775-1.454.212.212,0,0,1,.189-.1h.588a3.513,3.513,0,0,1,3.481,3.529v4.531a.237.237,0,0,1-.235.238ZM274,202.925h13.125v-4.292a3.037,3.037,0,0,0-3.01-3.052h-.471a3.728,3.728,0,0,1-3.081,1.55,3.791,3.791,0,0,1-3.081-1.55h-.47A3.037,3.037,0,0,0,274,198.633Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_161"
        data-name="Path 161"
        d="M280.558,195.151c-2.117,0-3.951-2.79-3.951-5.079a3.952,3.952,0,1,1,7.9,0c0,2.289-1.834,5.079-3.952,5.079Zm0-8.608a3.513,3.513,0,0,0-3.481,3.529c0,2.027,1.624,4.6,3.481,4.6s3.482-2.575,3.482-4.6A3.513,3.513,0,0,0,280.558,186.543Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_162"
        data-name="Path 162"
        d="M280.558,199.9a.954.954,0,1,1,.942-.953A.949.949,0,0,1,280.558,199.9Zm0-1.431a.477.477,0,1,0,.471.477A.476.476,0,0,0,280.558,198.465Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_163"
        data-name="Path 163"
        d="M280.558,202.209a.954.954,0,1,1,.942-.953A.949.949,0,0,1,280.558,202.209Zm0-1.454a.477.477,0,1,0,.471.477A.476.476,0,0,0,280.558,200.754Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_164"
        data-name="Path 164"
        d="M276.489,203.4a.223.223,0,0,1-.235-.238v-4.34l-1.835-2.218a.232.232,0,0,1,.023-.334.225.225,0,0,1,.329.024l1.905,2.289a.177.177,0,0,1,.047.143v4.436a.236.236,0,0,1-.235.237Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_165"
        data-name="Path 165"
        d="M284.628,203.4a.223.223,0,0,1-.235-.238v-4.436a.263.263,0,0,1,.047-.143l1.905-2.289a.227.227,0,0,1,.329-.024.234.234,0,0,1,.023.334l-1.835,2.218v4.363a.218.218,0,0,1-.235.214Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_166"
        data-name="Path 166"
        d="M295.823,217.065H284.815a.224.224,0,0,1-.235-.239v-3.672a2.9,2.9,0,0,1,2.846-2.909h.494a.215.215,0,0,1,.189.1,2.676,2.676,0,0,0,4.421,0,.213.213,0,0,1,.188-.1h.495a2.881,2.881,0,0,1,2.846,2.909v3.672a.254.254,0,0,1-.235.239Zm-10.772-.477h10.538v-3.434a2.406,2.406,0,0,0-2.376-2.432h-.377a3.127,3.127,0,0,1-2.516,1.264,3.06,3.06,0,0,1-2.517-1.264h-.375a2.406,2.406,0,0,0-2.376,2.432Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_167"
        data-name="Path 167"
        d="M290.32,210.365c-1.74,0-3.246-2.29-3.246-4.173a3.247,3.247,0,1,1,6.492,0c-.024,1.884-1.506,4.173-3.246,4.173Zm0-6.964a2.8,2.8,0,0,0-2.776,2.814c0,1.621,1.294,3.7,2.776,3.7s2.775-2.051,2.775-3.7A2.816,2.816,0,0,0,290.32,203.4Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_168"
        data-name="Path 168"
        d="M290.32,215.325a.811.811,0,1,1,.8-.811.8.8,0,0,1-.8.811Zm0-1.168a.334.334,0,1,0,.329.333A.324.324,0,0,0,290.32,214.156Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_169"
        data-name="Path 169"
        d="M287,217.065a.223.223,0,0,1-.235-.239v-3.5l-1.482-1.788a.234.234,0,0,1,.024-.335.225.225,0,0,1,.329.024l1.529,1.837a.175.175,0,0,1,.047.143v3.624a.2.2,0,0,1-.211.239Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_170"
        data-name="Path 170"
        d="M293.613,217.065a.224.224,0,0,1-.236-.239v-3.6a.252.252,0,0,1,.047-.143l1.529-1.836a.226.226,0,0,1,.329-.024.236.236,0,0,1,.025.335l-1.483,1.788v3.481a.2.2,0,0,1-.211.239Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_171"
        data-name="Path 171"
        d="M276.3,217.065H265.294a.223.223,0,0,1-.235-.239v-3.672a2.9,2.9,0,0,1,2.846-2.909h.494a.214.214,0,0,1,.188.1,2.676,2.676,0,0,0,4.422,0,.213.213,0,0,1,.188-.1h.494a2.882,2.882,0,0,1,2.846,2.909v3.672a.237.237,0,0,1-.235.239Zm-10.772-.477h10.536v-3.434a2.406,2.406,0,0,0-2.375-2.432h-.376a3.126,3.126,0,0,1-2.517,1.264,3.061,3.061,0,0,1-2.517-1.264h-.376a2.406,2.406,0,0,0-2.375,2.432Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_172"
        data-name="Path 172"
        d="M270.8,210.365c-1.741,0-3.246-2.29-3.246-4.173a3.246,3.246,0,1,1,6.492,0C274.044,208.075,272.538,210.365,270.8,210.365Zm0-6.964a2.8,2.8,0,0,0-2.775,2.814c0,1.621,1.293,3.7,2.775,3.7s2.775-2.051,2.775-3.7A2.8,2.8,0,0,0,270.8,203.4Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_173"
        data-name="Path 173"
        d="M270.821,215.325a.811.811,0,1,1,.8-.811A.813.813,0,0,1,270.821,215.325Zm0-1.168a.334.334,0,1,0,.329.333A.338.338,0,0,0,270.821,214.156Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_174"
        data-name="Path 174"
        d="M267.505,217.065a.223.223,0,0,1-.235-.239v-3.5l-1.482-1.788a.234.234,0,0,1,.023-.335.225.225,0,0,1,.329.024l1.528,1.837a.175.175,0,0,1,.047.143v3.624a.2.2,0,0,1-.211.239Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
      <Path
        id="Path_175"
        data-name="Path 175"
        d="M274.114,217.065a.223.223,0,0,1-.235-.239v-3.6a.257.257,0,0,1,.047-.143l1.529-1.836a.227.227,0,0,1,.329-.024.235.235,0,0,1,.023.335l-1.482,1.788v3.481a.207.207,0,0,1-.211.239Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.25}
      />
    </G>
  </Svg>
);
export default UserRoles;
