import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const LiveDrivingMonitor = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={35}
    viewBox="0 0 25 35"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Live_Driving_Monitor"
      data-name="Live Driving Monitor"
      transform="translate(-279.364 -183.304)"
    >
      <Path
        id="Path_94"
        data-name="Path 94"
        d="M302.832,213.865a.4.4,0,0,1-.408-.4V209.3a1.262,1.262,0,0,0-1.279-1.242h-18.56a1.263,1.263,0,0,0-1.28,1.242v4.165a.408.408,0,0,1-.816,0V209.3a2.068,2.068,0,0,1,2.1-2.034h18.56a2.067,2.067,0,0,1,2.1,2.034v4.165a.4.4,0,0,1-.408.4Z"
        fill="#fff"
      />
      <Path
        id="Path_95"
        data-name="Path 95"
        d="M303.956,215.782H279.771a.4.4,0,0,1-.407-.4v-1.917a.4.4,0,0,1,.407-.4h24.185a.4.4,0,0,1,.408.4v1.917A.4.4,0,0,1,303.956,215.782Zm-23.777-.792h23.369v-1.125H280.179Z"
        fill="#fff"
      />
      <Path
        id="Path_96"
        data-name="Path 96"
        d="M300.956,218.3h-1.231a1.434,1.434,0,0,1-1.453-1.411v-1.507a.408.408,0,0,1,.816,0v1.508a.629.629,0,0,0,.637.619h1.231a.63.63,0,0,0,.638-.619v-1.508a.408.408,0,0,1,.815,0v1.508a1.434,1.434,0,0,1-1.453,1.41Z"
        fill="#fff"
      />
      <Path
        id="Path_97"
        data-name="Path 97"
        d="M284,218.3h-1.231a1.435,1.435,0,0,1-1.454-1.411v-1.507a.408.408,0,0,1,.816,0v1.508a.63.63,0,0,0,.638.619H284a.63.63,0,0,0,.637-.619v-1.508a.408.408,0,0,1,.816,0v1.508A1.435,1.435,0,0,1,284,218.3Z"
        fill="#fff"
      />
      <Path
        id="Path_98"
        data-name="Path 98"
        d="M301.144,208.062a.405.405,0,0,1-.4-.338l-.747-4.856a2.229,2.229,0,0,0-2.087-2.079H285.819a2.229,2.229,0,0,0-2.086,2.079l-.747,4.856a.407.407,0,0,1-.464.333.4.4,0,0,1-.343-.45l.747-4.855A2.989,2.989,0,0,1,285.819,200h12.088a2.989,2.989,0,0,1,2.894,2.755l.746,4.855a.4.4,0,0,1-.343.45.471.471,0,0,1-.059,0Z"
        fill="#fff"
      />
      <Path
        id="Path_99"
        data-name="Path 99"
        d="M299.44,212.274a1.707,1.707,0,1,1,1.758-1.706A1.734,1.734,0,0,1,299.44,212.274Zm0-2.622a.915.915,0,1,0,.942.915A.93.93,0,0,0,299.44,209.652Z"
        fill="#fff"
      />
      <Path
        id="Path_100"
        data-name="Path 100"
        d="M284.287,212.274a1.707,1.707,0,1,1,1.758-1.706A1.734,1.734,0,0,1,284.287,212.274Zm0-2.622a.915.915,0,1,0,.942.915A.93.93,0,0,0,284.287,209.652Z"
        fill="#fff"
      />
      <Path
        id="Path_101"
        data-name="Path 101"
        d="M303.55,208.746h-1.035a.4.4,0,1,1,0-.791h.628v-.561a.826.826,0,0,0-.837-.813h-1.389a.4.4,0,1,1,0-.792h1.389a1.63,1.63,0,0,1,1.652,1.6v.957a.4.4,0,0,1-.408.4Z"
        fill="#fff"
      />
      <Path
        id="Path_102"
        data-name="Path 102"
        d="M281.213,208.746h-1.034a.4.4,0,0,1-.409-.4v-.957a1.631,1.631,0,0,1,1.653-1.6h1.388a.4.4,0,1,1,0,.792h-1.388a.825.825,0,0,0-.836.812v.561h.627a.4.4,0,1,1,0,.791Z"
        fill="#fff"
      />
      <Path
        id="Path_103"
        data-name="Path 103"
        d="M294.686,200.789a.4.4,0,0,1-.408-.4v-.808a.265.265,0,0,0-.264-.256h-4.3a.264.264,0,0,0-.264.256v.808a.408.408,0,0,1-.816,0v-.808a1.066,1.066,0,0,1,1.08-1.048h4.3a1.066,1.066,0,0,1,1.08,1.048v.808a.4.4,0,0,1-.407.4Z"
        fill="#fff"
      />
      <Path
        id="Path_104"
        data-name="Path 104"
        d="M296.212,211.982H287.5a.4.4,0,0,1-.407-.4v-2.038a.4.4,0,0,1,.407-.4h8.714a.4.4,0,0,1,.408.4v2.038a.4.4,0,0,1-.407.4Zm-8.306-.791h7.9v-1.246h-7.9Z"
        fill="#fff"
      />
      <Path
        id="Path_105"
        data-name="Path 105"
        d="M291.863,199.329a.408.408,0,0,1-.356-.2,26.58,26.58,0,0,0-2.752-3.691c-1.688-2.013-3.281-3.913-3.281-5.928a6.392,6.392,0,0,1,12.777,0c0,2.015-1.594,3.916-3.281,5.928a26.467,26.467,0,0,0-2.752,3.691.411.411,0,0,1-.357.2Zm0-15.233a5.5,5.5,0,0,0-5.573,5.411c0,1.733,1.505,3.527,3.1,5.428a32.092,32.092,0,0,1,2.476,3.218,32.091,32.091,0,0,1,2.476-3.218c1.593-1.9,3.1-3.7,3.1-5.428a5.5,5.5,0,0,0-5.574-5.411Z"
        fill="#fff"
      />
      <Path
        id="Path_106"
        data-name="Path 106"
        d="M291.863,192.941a1.184,1.184,0,1,1,1.219-1.183A1.2,1.2,0,0,1,291.863,192.941Zm0-1.574a.392.392,0,1,0,.4.392A.4.4,0,0,0,291.863,191.367Z"
        fill="#fff"
      />
      <Path
        id="Path_107"
        data-name="Path 107"
        d="M295.719,187.916a.415.415,0,0,1-.288-.115,5.151,5.151,0,0,0-7.132,0,.417.417,0,0,1-.577,0,.389.389,0,0,1,0-.56,5.985,5.985,0,0,1,8.285,0,.388.388,0,0,1,0,.56.412.412,0,0,1-.288.115Z"
        fill="#fff"
      />
      <Path
        id="Path_108"
        data-name="Path 108"
        d="M294.514,189.086a.414.414,0,0,1-.289-.116,3.412,3.412,0,0,0-4.723,0,.415.415,0,0,1-.577,0,.388.388,0,0,1,0-.559,4.245,4.245,0,0,1,5.876,0,.388.388,0,0,1,0,.559.409.409,0,0,1-.288.116Z"
        fill="#fff"
      />
      <Path
        id="Path_109"
        data-name="Path 109"
        d="M293.31,190.256a.416.416,0,0,1-.289-.116,1.67,1.67,0,0,0-2.314,0,.416.416,0,0,1-.576,0,.387.387,0,0,1,0-.559,2.5,2.5,0,0,1,3.467,0,.387.387,0,0,1,0,.559.415.415,0,0,1-.288.116Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default LiveDrivingMonitor;
