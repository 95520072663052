import * as React from "react";
const LandingPage = (props) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <rect
          width={24}
          height={24}
          style={{
            fill: "none",
            strokeWidth: 0,
          }}
        />
      </clipPath>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g
        style={{
          clipPath: "url(#clippath)",
        }}
      >
        <g>
          <path
            d="m18.4,4.65H5.6c-1.43,0-2.6,1.17-2.6,2.6v9.5c0,1.43,1.17,2.6,2.6,2.6h12.8c1.43,0,2.6-1.17,2.6-2.6V7.25c0-1.43-1.17-2.6-2.6-2.6h0Zm-.59.53h.59c1.14,0,2.07.93,2.07,2.07v1.28h-1.63v-1.28c0-.83-.39-1.59-1.03-2.07ZM3.53,7.25c0-1.14.93-2.07,2.07-2.07h10.65c1.14,0,2.07.93,2.07,2.07v1.28H3.53v-1.28Zm0,9.5v-7.69h14.79v7.69c0,1.14-.93,2.07-2.07,2.07H5.6c-1.14,0-2.07-.93-2.07-2.07h0Zm16.95,0c0,1.14-.93,2.07-2.07,2.07h-.59c.63-.48,1.03-1.23,1.03-2.07v-7.69h1.63v7.69Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <path
            d="m6.12,5.89c-.53,0-.97.43-.97.97s.43.97.97.97.97-.43.97-.97-.43-.97-.97-.97Zm0,1.4c-.24,0-.44-.2-.44-.44s.2-.44.44-.44.44.2.44.44-.2.44-.44.44Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <path
            d="m8.6,5.89c-.53,0-.97.43-.97.97s.43.97.97.97.97-.43.97-.97-.43-.97-.97-.97Zm0,1.4c-.24,0-.44-.2-.44-.44s.2-.44.44-.44.44.2.44.44-.2.44-.44.44Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <path
            d="m11.08,5.89c-.53,0-.97.43-.97.97s.43.97.97.97.97-.43.97-.97-.43-.97-.97-.97Zm0,1.4c-.24,0-.44-.2-.44-.44s.2-.44.44-.44.44.2.44.44-.2.44-.44.44Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <path
            d="m10.92,12.37c-.87,0-1.57.7-1.57,1.57s.7,1.57,1.57,1.57,1.57-.7,1.57-1.57-.7-1.57-1.57-1.57Zm0,2.61c-.58,0-1.04-.47-1.04-1.04s.47-1.04,1.04-1.04,1.04.47,1.04,1.04-.47,1.04-1.04,1.04Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <path
            d="m14.28,14.57l-.48-.28c.03-.23.03-.46,0-.7l.48-.28c.38-.22.51-.7.29-1.08l-.34-.6c-.22-.38-.7-.51-1.08-.29l-.48.28c-.19-.14-.39-.26-.61-.35v-.55c0-.44-.36-.79-.79-.79h-.69c-.44,0-.79.36-.79.79v.55c-.22.09-.42.21-.61.35l-.48-.28c-.38-.22-.86-.09-1.08.29l-.34.6c-.22.38-.09.86.29,1.08l.48.28c-.03.23-.03.46,0,.7l-.48.28c-.38.22-.51.7-.29,1.08l.34.6c.22.38.7.51,1.08.29l.48-.28c.19.14.39.26.61.35v.55c0,.44.36.79.79.79h.69c.44,0,.79-.36.79-.79v-.55c.22-.09.42-.21.61-.35l.48.28c.38.22.86.09,1.08-.29l.34-.6c.22-.38.09-.86-.29-1.08h0Zm-.17.82l-.34.6c-.07.13-.24.17-.36.1l-.64-.37c-.1-.06-.22-.04-.3.03-.23.19-.48.34-.76.44-.11.04-.18.14-.18.25v.73c0,.15-.12.27-.27.27h-.69c-.15,0-.27-.12-.27-.27v-.73c0-.11-.07-.21-.18-.25-.28-.1-.54-.25-.76-.44-.08-.07-.21-.08-.3-.03l-.64.37c-.13.07-.29.03-.36-.1l-.34-.6c-.07-.13-.03-.29.1-.36l.64-.37c.1-.06.15-.17.13-.28-.05-.29-.05-.59,0-.88.02-.11-.03-.22-.13-.28l-.64-.37c-.13-.07-.17-.24-.1-.36l.34-.6c.07-.13.24-.17.36-.1l.64.37c.1.06.22.04.3-.03.23-.19.48-.34.76-.44.11-.04.18-.14.18-.25v-.73c0-.15.12-.27.27-.27h.69c.15,0,.27.12.27.27v.73c0,.11.07.21.18.25.28.1.54.25.76.44.08.07.21.08.3.03l.64-.37c.13-.07.29-.03.36.1l.34.6c.07.13.03.29-.1.36l-.64.37c-.1.06-.15.17-.13.28.05.29.05.59,0,.88-.02.11.03.22.13.28l.64.37c.13.07.17.24.1.36Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default LandingPage;
