// import React from "react";
// import {
//   Center,
//   Text,
//   HStack,
//   Hidden,
//   Link as NLink,
//   Circle,
//   Image,
//   Box,
//   Link,
//   VStack,
// } from "native-base";

// import Googleplay from "../../assets/svg/GooglePlay";
// import Applestore from "../../assets/svg/AppleStore";

// import { vh, vw } from "../../util";

// const Large = ({ landscape, gifs,sz }) => {
//   return (
//     <Hidden only={["base", "sm", "md"]}>
//       {sz === "lg" && (
//         <Center margin={vh(100)}>
//           <VStack
//             style={{
//               background: "#7D2BE9 0% 0% no-repeat padding-box",
//               borderRadius: vw(40),
//               // width: "90%",
//               // height: vh(800),
//               width: vw(landscape ? 1300 : 1400), // newly changed
//               // height: vh(landscape ? 1350 : 780), // newly changed
//               height: "min-content",
//               alignSelf: "center",
//               marginTop: vh(landscape ? 100 : false), // newly changed
//               // marginTop: vh(50),
//               // justifyContent: "center",
//               // marginHorizontal: vw(20),
//             }}
//           >
//             <Text
//               // marginTop={vh(62)}
//               marginTop={landscape ? 62 : 62}
//               width={"100%"}
//               fontSize={vw(85)}
//               // lineHeight={vh(70)}
//               lineHeight={landscape ? 60 : 65}
//               fontFamily={"poppins"}
//               color="white"
//               textAlign={"center"}
//             >
//               The Only Real Estate Management App You Need…
//             </Text>
//             <Center>
//               <Text
//                 width={"95%"}
//                 fontSize={vw(landscape ? 35 : 40)}
//                 fontFamily={"HelveticaNeueLTStd-Roman"}
//                 color={"#fff"}
//                 lineHeight={landscape ? 35 : 50}
//                 //   lineHeight={vh(70)}
//                 textAlign={"center"}
//                 marginTop={vh(27)}
//                 padding={vw(50)}
//               >
//                 Shadow App is the future of real estate investments. Our
//                 easy-to-use real estate CRM streamlines every aspect of your
//                 business by bringing prominent features at your fingertips. It
//                 permits you access to eminent properties, contact their owners,
//                 close deals, and make money. Our App is suitable for individuals
//                 and teams who believe in the power of real estate; the number of
//                 deals you close, will change your life.{"\n"}
//                 <Link href="https://web.shadow.properties/">
//                   <Text
//                     color={"#fff"}
//                     fontSize={vw(landscape ? 45 : 68)}
//                     fontWeight={"bold"}
//                   >
//                     Let's Get Started{"\n"} with a 14-day No Card Free Trial
//                   </Text>
//                 </Link>
//               </Text>
//             </Center>

//             <HStack
//               style={{
//                 justifyContent: "space-between",
//                 width: "75%",
//                 alignSelf: "center",
//                 padding: vw(landscape ? 60 : false),
//               }}
//             >
//               <NLink href="https://apps.apple.com/us/app/shadowproperties/id1491176685/">
//                 <Applestore width={vw(450)} height={vh(150)} />
//               </NLink>
//               <NLink
//                 href="https://play.google.com/store/apps/details?id=and.app.properties.shadow"
//                 isExternal
//               >
//                 <Googleplay width={vw(450)} height={vh(150)} />
//               </NLink>
//             </HStack>
//           </VStack>
//         </Center>
//       )}
//       {sz === "xl" && (
//         <Center margin={vh(100)}>
//           <HStack
//             style={{
//               // marginLeft: "2.25%",
//               //   height: vh(768),
//               //   height: vh(750),
//               //   width: "98%",
//               // marginTop: vh(100),
//               marginTop: vh(landscape ? 25 : 50),
//               background: "#7D2BE9 0% 0% no-repeat padding-box",
//               borderRadius: vw(40),
//               height: vh(
//                 landscape
//                   ? window.innerWidth < 1400 && window.innerHeight < 900
//                     ? 900
//                     : 1000
//                   : 600
//               ),
//               width: vw(landscape ? 1550 : 1000),
//               alignSelf: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <Center
//               style={{
//                 //   top: vh(132),
//                 top: vh(landscape ? 150 : 80),
//                 left: vw(landscape ? 180 : false),
//                 width: vw(landscape ? 600 : 1000),
//                 position: "absolute",
//                 zIndex: 99,
//                 marginLeft: vw(window.innerWidth <= 1500 ? -100 : 0),
//                 //   width: vw(600),
//                 //   left: vw(100),
//               }}
//             >
//               <Text
//                 //   fontSize={vw(48)}
//                 //   textAlign={vw("left")}
//                 //   lineHeight={vh(72)}
//                 //   textAlign={vw(landscape ? "left" : "center")}
//                 //   justifyContent={vw("center")}
//                 textAlign={landscape ? "left" : "center"}
//                 fontSize={vw(landscape ? 48 : 72)}
//                 lineHeight={vh(landscape ? 75 : 65)}
//                 fontFamily={"poppins"}
//                 color={"#FFFFFF"}
//                 opacity={1}
//               >
//                 The Only Real Estate Management App You Need…
//               </Text>
//               <Text
//                 //   fontSize={vw(18)}
//                 //   lineHeight={vh(36)}
//                 fontSize={vw(landscape ? 18 : 35)}
//                 fontFamily={"HelveticaNeueLTStd-Roman"}
//                 color={"white"}
//                 lineHeight={vh(landscape ? 36 : 40)}
//                 paddingTop={vw(20)}
//                 textAlign={landscape ? "left" : "center"}
//               >
//                 Shadow App is the future of real estate investments. Our
//                 easy-to-use real estate CRM streamlines every aspect of your
//                 business by bringing prominent features at your fingertips. It
//                 permits you access to eminent properties, contact their owners,
//                 close deals, and make money. Our App is suitable for individuals
//                 and teams who believe in the power of real estate; the number of
//                 deals you close, will change your life.{"\n"}
//                 <Link href="https://web.shadow.properties/">
//                   <Text
//                     marginTop={5}
//                     fontSize={vw(landscape ? 25 : 40)}
//                     lineHeight={vh(landscape ? 50 : 64)}
//                     fontWeight={"bold"}
//                   >
//                     Let's Get Started with a 14-day No Card Free Trial
//                   </Text>
//                 </Link>
//               </Text>

//               <HStack
//                 style={{
//                   // marginTop: vh(72)
//                   marginTop: vh(landscape ? 15 : 35),
//                   alignSelf: "flex-start",
//                 }}
//                 space={50}
//               >
//                 <NLink href="https://apps.apple.com/us/app/shadowproperties/id1491176685/">
//                   <Applestore
//                     // width={vw(200)}
//                     // height={vh(67)}
//                     width={vw(landscape ? 200 : 350)}
//                     height={vh(landscape ? 200 : 67)}
//                   />
//                 </NLink>
//                 <NLink
//                   href="https://play.google.com/store/apps/details?id=and.app.properties.shadow"
//                   isExternal
//                 >
//                   <Googleplay
//                     // width={vw(200)}
//                     // height={vh(67)}
//                     width={vw(landscape ? 215 : 350)}
//                     // height={vh(landscape ? 210 : 67)}
//                   />
//                 </NLink>
//               </HStack>
//             </Center>
//             <Box
//               style={{
//                 transform:
//                   window.innerWidth < 1400 &&
//                   (window.innerHeight < 900 || window.innerHeight < 1200)
//                     ? "scale(0.9)"
//                     : "scale(1)",
//                 marginRight:
//                   window.innerWidth < 1400 &&
//                   (window.innerHeight < 900 || window.innerHeight < 1200)
//                     ? -120
//                     : "initial",
//               }}
//             >
//               <Circle
//                 //   size={vh(880)}
//                 // size={vh(landscape ? 880 : false)} // newly changed
//                 size={vh(landscape ? 1150 : false)}
//                 style={{
//                   // marginLeft: vw(sz === "lg" ? -4 : 70),
//                   // width: vw(800),
//                   // height: vw(800),
//                   marginTop:
//                     window.innerWidth < 1400 && window.innerHeight < 900
//                       ? vh(-140)
//                       : vh(-100),
//                   marginRight: vw(20),
//                   backgroundColor: "#7D2BE9",
//                   borderColor: "#873DE8",
//                   //   borderWidth: vw(15),
//                   borderWidth: vh(landscape ? 25 : false), // newly changed
//                   animation: "fadeInOutAnimation ease-in-out 3s",
//                   animationIterationCount: "infinite",
//                 }}
//               >
//                 <Circle
//                   size={vh(landscape ? 800 : false)}
//                   style={{
//                     height: vh(landscape ? 800 : false), // newly changed
//                     weight: vw(landscape ? 800 : false), // newly changed
//                     // borderWidth: vw(20),
//                     // width: vh(600),
//                     // height: vh(600),
//                     backgroundColor: "#7D2BE9",
//                     borderColor: "#873DE8",
//                     borderWidth: vh(landscape ? 40 : false), // newly changed
//                     animation: "fadeInOutAnimation ease-in-out 3s",
//                     animationIterationCount: "infinite",
//                     animationDelay: "1s",
//                   }}
//                 />
//                 <Center
//                   style={{
//                     top: vh(30),
//                     // top: vh(landscape ? 16 : Hidden), // newly changed
//                     // weight: vw(landscape ?  1000: false), // newly changed
//                     // height: "100%",
//                     // borderWidth: vw(10),
//                     height: vh(
//                       landscape
//                         ? window.innerWidth < 1400 && window.innerHeight < 900
//                           ? 1060
//                           : 1125
//                         : false
//                     ), // newly changed
//                     width:
//                       window.innerWidth < 1400 && window.innerHeight < 900
//                         ? "45%"
//                         : "48%",
//                     borderRadius: vw(50),
//                     background:
//                       "linear-gradient(178deg, rgba(141,68,237,1) 70%, rgba(125,43,233,1) 95%, rgba(128,42,234,1) 95%)",
//                     borderColor: "#000",
//                     borderWidth: vw(landscape ? 10 : false),
//                     position: "absolute",
//                   }}
//                 >
//                   {/* <WhiteLogo
//                 //   width={vw(96)}
//                 //   height={vh(192)}
//                 height={vh(landscape ? 192 : false)}
//                 weight={vw(landscape ? 96 : false)}
//               /> */}
//                   <Image
//                     alt="driving for dollars"
//                     source={{
//                       uri: require("../../assets/webp/mapscreen.webp"),
//                     }}
//                     style={{
//                       width:
//                         window.innerWidth < 1400 && window.innerHeight < 900
//                           ? "100%"
//                           : "100%",
//                       aspectRatio: "280/612",
//                       borderRadius: vw(40),
//                     }}
//                   />
//                 </Center>
//               </Circle>
//             </Box>
//           </HStack>
//         </Center>
//       )}
//     </Hidden>
//   );
// };

// export default Large;

import React from "react";
import {
  Center,
  Text,
  HStack,
  Hidden,
  Link as NLink,
  Circle,
  Image,
  Box,
  Link,
  Icon,
  VStack,
  Divider,
  Pressable,
} from "native-base";
import apple from "../../assets/svg/apple.svg";
import android from "../../assets/svg/google play.svg";
// import Googleplay from "../../assets/svg/GooglePlay";
// import Applestore from "../../assets/svg/AppleStore";

import { vh, vw } from "../../util";
import DroidSVG from "../../assets/svg/Droid";
import WebSVG from "../../assets/svg/Web";
import AppleSVG from "../../assets/svg/AppleSvg";

const Large = ({ landscape, sendIp }) => {
  return (
    <Hidden only={["base", "sm", "md"]}>
      <Center margin={vw(117)}>
        <HStack
          style={{
            // marginLeft: "2.25%",
            //   height: vh(768),
            //   height: vh(750),
            //   width: "98%",
            // marginTop: vh(100),
            marginTop: vw(25),
            background: "#7D2BE9 0% 0% no-repeat padding-box",
            borderRadius: vw(40),
            // height: vh(
            //   landscape
            //     ? window.innerWidth < 1400 && window.innerHeight < 900
            //       ? 800
            //       : 700
            //     : 600
            // ),
            height: vw(538),
            width: vw(1550),
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <Center
            style={{
              //   top: vh(132),
              top: vw(80),
              left: vw(180),
              width: vw(600),
              position: "absolute",
              zIndex: 99,
              // marginLeft: vw(window.innerWidth <= 1500 ? -100 : 0),
              //   width: vw(600),
              //   left: vw(100),
            }}
          >
            <Text
              //   fontSize={vw(48)}
              //   textAlign={vw("left")}
              //   lineHeight={vh(72)}
              //   textAlign={vw(landscape ? "left" : "center")}
              //   justifyContent={vw("center")}
              textAlign={"left"}
              fontSize={vw(36)}
              fontWeight={500}
              lineHeight={vw(48)}
              fontFamily={"Poppins"}
              color={"#FFFFFF"}
              opacity={1}
            >
              The Only Real Estate Management App You Need…
            </Text>
            <Text
              //   fontSize={vw(18)}
              //   lineHeight={vh(36)}
              fontSize={vw(15)}
              fontFamily={"Poppins"}
              fontWeight={300}
              color={"white"}
              lineHeight={vw(26)}
              paddingTop={vw(20)}
              textAlign={"left"}
            >
              Shadow App is the future of real estate investments. Our
              easy-to-use real estate CRM streamlines every aspect of your
              business by bringing prominent features at your fingertips. It
              permits you access to eminent properties, contact their owners,
              close deals, and make money. Our App is suitable for individuals
              and teams who believe in the power of real estate; the number of
              deals you close, will change your life.{"\n"}
              <Text
                marginTop={vw(22)}
                fontSize={vw(20)}
                fontFamily={"Poppins"}
                fontWeight={500}
              >
                Let's Get Started with a 14-day No Card Free Trial
              </Text>
            </Text>
            <Pressable alignSelf={"start"} onPress={sendIp}>
              <VStack
                rounded={"full"}
                style={{
                  // borderRadius: vw(40),
                  // height: vw(230),
                  width: vw(308),
                  // shadowColor: "#7D2BE959",
                  // shadowOffset: { width: 0, height: 15 },
                  // marginHorizontal:2,
                  // shadowOpacity: 0.7,
                  // shadowRadius: 40,
                  background: "#F9F1FD 0% 0% no-repeat border-box",
                  borderWidth: 3,
                  borderColor: "#FFFFFF",
                  transition: "box-shadow 0.3s ease-in-out",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: vw(24),
                  alignSelf: "start",
                }}
              >
                <Text color={"#6D549F"}>GET IT ON</Text>

                <HStack
                  margin={vw(5)}
                  width={"50%"}
                  justifyContent={"space-around"}
                >
                  <Box
                    rounded={"full"}
                    padding={vw(2)}
                    bg={"#7D2BE9"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <DroidSVG />
                  </Box>
                  <Box
                    rounded={"full"}
                    padding={vw(2)}
                    bg={"#7D2BE9"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box style={{ transform: "scale(0.85)" }}>
                      <AppleSVG />
                    </Box>
                  </Box>
                  <Box
                    rounded={"full"}
                    padding={vw(2)}
                    bg={"#7D2BE9"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <WebSVG />
                  </Box>
                </HStack>
              </VStack>
            </Pressable>
          </Center>
          <Box
            style={{
              marginTop: vw(-80),
              transform: "scale(0.7)",
              marginRight: vw(-117),
            }}
          >
            <Circle
              //   size={vh(880)}
              // size={vh(landscape ? 880 : false)} // newly changed
              size={vw(900)}
              style={{
                // marginLeft: vw(sz === "lg" ? -4 : 70),
                // width: vw(800),
                // height: vw(800),
                marginTop: vw(-55),
                marginRight: vw(20),
                backgroundColor: "#7D2BE9",
                borderColor: "#873DE8",
                //   borderWidth: vw(15),
                borderWidth: vw(20), // newly changed
                // animation: "fadeInOutAnimation ease-in-out 3s",
                // animationIterationCount: "infinite",
              }}
            >
              <Circle
                size={vw(610)}
                style={{
                  height: vw(610), // newly changed
                  weight: vw(800), // newly changed
                  // borderWidth: vw(20),
                  // width: vh(600),
                  // height: vh(600),
                  backgroundColor: "#7D2BE9",
                  borderColor: "#873DE8",
                  borderWidth: vw(33), // newly changed
                  // animation: "fadeInOutAnimation ease-in-out 3s",
                  // animationIterationCount: "infinite",
                  // animationDelay: "1s",
                }}
              />
              <Center
                style={{
                  top: vw(25),
                  // top: vh(landscape ? 16 : Hidden), // newly changed
                  // weight: vw(landscape ?  1000: false), // newly changed
                  // height: "100%",
                  // borderWidth: vw(10),
                  height: vw(880),
                  width: "48%",
                  borderRadius: vw(50),
                  background:
                    "linear-gradient(178deg, rgba(141,68,237,1) 70%, rgba(125,43,233,1) 95%, rgba(128,42,234,1) 95%)",
                  borderColor: "#000",
                  borderWidth: vw(10),
                  position: "absolute",
                }}
              >
                <Image
                  alt="driving for dollars"
                  source={{ uri: require("../../assets/webp/mapscreen.webp") }}
                  style={{
                    width: "100%",
                    aspectRatio: "280/612",
                    borderRadius: vw(40),
                  }}
                />
              </Center>
            </Circle>
          </Box>
        </HStack>
      </Center>
    </Hidden>
  );
};

export default Large;
