import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { vw, vh } from "../../util";

const Facebook = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={vw(20)}
    height={vh(20)}
    viewBox="0 0 28 27.84"
    color={"red"}
    {...props}
  >
    <Path
      id="Facebook"
      d="M29.13,15.12A14,14,0,1,0,12.94,28.95V19.17H9.39V15.12h3.55V12.04c0-3.51,2.09-5.45,5.29-5.45a21.739,21.739,0,0,1,3.13.27v3.45H19.59a2.023,2.023,0,0,0-2.28,2.19v2.63h3.88l-.62,4.05H17.31v9.78A14,14,0,0,0,29.12,15.13Z"
      transform="translate(-1.13 -1.12)"
      fill={props.color || "#fff"}
    /> 
  </Svg>
);
export default Facebook;
