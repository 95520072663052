import React from "react";
import {
  VStack,
  HStack,
  Stack,
  Center,
  Text,
  Box,
  Icon,
  Link,
  Divider,
  Image,
  Button,
} from "native-base";
import { useForm } from "react-hook-form";
import Mail from "../../assets/svg/mailIcon";
import "./App.css";
import InputMask from "react-input-mask";
import MaskedInput from "react-text-mask";
import { vw, vh } from "../../util";
import { useState } from "react";

//fold one small
const Large = ({ landscape, sz }) => {
  //console.log("Fold 8 large Landscape", landscape, sz);
  //console.log(vw(100), vh(100), "large");
  const [messageFailed, setMessageFailed] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);
  const [isFilled, setFilled] = useState(false);
  const buttonStyleHovered = {
    boxShadow: "5px 10px 15px 0px rgba(125,43,233,0.2)",
  };
  const buttonStyle = {
    boxShadow: "5px 10px 15px 0px transparent",
  };
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  });

  const onSubmit = async (data) => {
    console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaa");
    // https://api.shadow.properties/v2/get-in-touch
    try {
      const response = await fetch(
        "https://api.shadow.properties/v2/get-in-touch",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          redirect: "follow",
        }
      );
      console.log(response, "response");
      if (response.ok) {
        const details = await response.json();
        if (details.success) {
          setMessageFailed(false);
          reset();
          alert("Message received, will get in touch with you soon.");
        }
      }
      if (!response.ok) {
        setMessageFailed(true);
      }
    } catch (error) {
      console.log(error, "catch");
    }
  };
  const date = new Date();
  return (
    <VStack
      style={{
        width: "100%",
        height: "min-content",
        padding: vw(30),
        boxShadow: "0px -2px 50px 10px rgba(131,57,255,0.5)",
        backgroundColor: "rgba(251,250,251,0.7)",
        fontFamily: "HelveticaNeueLTStd-Bd",
        overflow: "hidden",
      }}
    >
      <Center>
        <Stack
          style={{
            width: window.innerWidth * 0.75,
            paddingBottom: vw(60),
            paddingTop: vw(10),
          }}
        >
          <HStack>
            <VStack padding={vw(10)}>
              <Text
                style={{
                  paddingTop: vw(30),
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: vw(58),
                  color: "#7D2BE9",
                  lineHeight: vw(75),
                }}
              >
                Get in Touch!
              </Text>
              <HStack paddingTop={vw(15)}>
                <Icon
                  as={<Mail />}
                  size={vw(21)}
                  style={{ alignSelf: "center" }}
                />
                <Text
                  ml={vw(10)}
                  color={"rgb(154,85,221)"}
                  // fontSize={18}
                  lineHeight={vw(55)}
                  textAlign={"center"}
                  fontSize={vw(15)}
                  fontWeight={500}
                  fontFamily={"Poppins"}
                >
                  <Link
                    href="mailto:info@shadow.properties"
                    isUnderlined={false}
                  >
                    info@shadow.properties
                  </Link>
                </Text>
              </HStack>
              <Text
                fontSize={vw(15)}
                fontWeight={300}
                fontFamily={"Poppins"}
                width={vw(400)}
              >
                We strive to contribute the best to our real estate community,
                we listen and answer to every question/request by everyone and
                add more advanced features time to time.{" "}
                <Link color={"rgb(154,85,221)"} fontWeight={500} href="/faqs">
                  {" "}
                  FAQs.
                </Link>
              </Text>
            </VStack>
            <Center ml={"auto"}>
              <Box padding={vw(22)} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <HStack>
                    <VStack>
                      <Box m={vw(10)} mb={0}>
                        <input
                          type={"text"}
                          placeholder="Name *"
                          autoComplete="off"
                          {...register("name", { required: true })}
                          onBlur={() => {
                            trigger("name");
                          }}
                          className={errors.name ? "input-error" : "input"}
                        />
                        {errors.name ? (
                          <Text fontSize={"2xs"} color={"red.500"}>
                            *This field is required
                          </Text>
                        ) : (
                          <Box m={vw(10)} mb={0}></Box>
                        )}
                      </Box>
                      <Box m={vw(10)} mb={0}>
                        <input
                          type={"text"}
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                              message: "invalid email",
                            },
                          })}
                          onBlur={() => {
                            trigger("email");
                          }}
                          placeholder="Email *"
                          autoComplete="off"
                          className={errors.email ? "input-error" : "input"}
                        />
                        {errors.email ? (
                          <Text fontSize={"2xs"} color={"red.500"}>
                            {errors.email.message
                              ? "*invalid Email"
                              : "*This field is required"}
                          </Text>
                        ) : (
                          <Box m={vw(10)} mb={0}></Box>
                        )}
                      </Box>
                      <Box m={vw(10)} mb={0}>
                        <MaskedInput
                          // type={"text"}
                          mask={[
                            "(",
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          guide={false}
                          ref={register("phone", {
                            required: true,
                            pattern: {
                              value: /\d{10}/,
                              message: "invalid phone",
                            },
                          })}
                          onChange={(e) => {
                            setValue(
                              "phone",
                              e.target.value.replace(/\D/g, "")
                            );
                          }}
                          onBlur={() => {
                            trigger("phone");
                          }}
                          placeholder="Phone *"
                          autoComplete="off"
                          className={errors.phone ? "input-error" : "input"}
                        />
                        {errors.phone ? (
                          <Text fontSize={"2xs"} color={"red.500"}>
                            {errors.phone.message
                              ? "*invalid phone number"
                              : "*This field is required"}
                          </Text>
                        ) : (
                          <Box m={vw(10)} mb={0}></Box>
                        )}
                      </Box>
                    </VStack>
                    <Box m={vw(10)} mb={0}>
                      <textarea
                        rows={4}
                        {...register("message", { required: true })}
                        onBlur={() => {
                          trigger("message");
                        }}
                        placeholder="Message *"
                        autoComplete="off"
                        className={
                          errors.message
                            ? "input-error textarea"
                            : "input textarea"
                        }
                      />
                      {errors.message ? (
                        <Text fontSize={"2xs"} color={"red.500"}>
                          *This field is required
                        </Text>
                      ) : (
                        <Box m={vw(10)} mb={0}></Box>
                      )}
                    </Box>
                    <Box m={vw(10)} mb={0}>
                      <Text
                        fontSize={"2xs"}
                        color={messageFailed ? "red.500" : "transparent"}
                        m={vw(10)}
                        style={{ userSelect: "none" }}
                      >
                        Message Not Sent! Try again later or Use Chat Bot.
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box>
                  <Center>
                    <Button
                      rounded={"full"}
                      disabled={isValid ? false : true}
                      onPress={handleSubmit(onSubmit)}
                      style={
                        isValid
                          ? {
                              borderWidth: landscape ? 2 : 3,
                              borderColor: "transparent",
                              width: vw(180),
                              height: vw(50),
                              alignItems: "center",
                              justifyContent: "center",
                              background:
                                "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                            }
                          : {
                              borderWidth: landscape ? 2 : 3,
                              borderColor: "#7D2BE9",
                              width: vw(180),
                              height: vw(50),
                              alignItems: "center",
                              justifyContent: "center",
                            }
                      }
                      backgroundColor={"transparent"}
                      alignSelf={"flex-start"}
                      m={vw(10)}
                      mb={0}
                      _text={{
                        color: isValid ? "white" : "#7D2BE9",
                        fontFamily: "Poppins",
                        fontSize: vw(15),
                        fontWeight: 400,
                      }}
                    >
                      Send Message
                    </Button>
                  </Center>
                </Box>
              </form>
            </Center>
          </HStack>
        </Stack>
        <Divider
          width={"95%"}
          padding={vw(1)}
          marginY={vw(35)}
          bg={"#DAD4E5"}
        />
        <HStack width={window.innerWidth * 0.75}>
          <Box>
            <Text
              style={{
                color: "rgb(152,138,180)",
                textAlign: "center",
                fontSize: vw(15),
                lineHeight: vw(32),
                marginRight: "auto",
                fontFamily: "Poppins",
                fontWeight: 400,
              }}
            >
              Copyright © {date.getFullYear()} ShadowProperties. All rights
              reserved.
            </Text>
          </Box>
          <HStack ml={"auto"}>
            <Link
              href="https://www.facebook.com/people/Shadow-Properties/100087078565348/"
              isExternal
            >
              <Image
                alt="driving for dollars"
                src={require("../../assets/png/Facebook.png")}
                width={8}
                height={8}
              />
            </Link>
            <Link
              href="https://www.instagram.com/shadowproperties_official/"
              isExternal
            >
              <Image
                alt="best real estate crm"
                src={require("../../assets/png/Instagram.png")}
                width={8}
                height={8}
              />
            </Link>
            <Link
              href="https://www.linkedin.com/company/37566268/admin/"
              isExternal
            >
              <Image
                alt="driving for dollars"
                src={require("../../assets/png/Linkedin.png")}
                width={8}
                height={8}
              />
            </Link>
            <Link href="https://twitter.com/Shadow_CRM" isExternal>
              <Image
                alt="driving for dollars"
                src={require("../../assets/png/Twitter.png")}
                width={8}
                height={8}
              />
            </Link>
            <Link href="https://www.youtube.com/@shadowproperties" isExternal>
              <Image
                alt="driving for dollars"
                src={require("../../assets/png/Youtube.png")}
                width={8}
                height={8}
              />
            </Link>
          </HStack>
          <Box justifyContent={"center"} ml={"auto"}>
            <HStack>
              <Link
                // onClick={() => {
                //   window.scrollTo(0, 0);
                //   setMain("terms");
                //   //console.log("terms");
                // }}
                href="/terms"
              >
                <Text
                  color="#988AB4"
                  // ml={["", "", "150", "300"]}
                  fontWeight={400}
                  fontSize={vw(15)}
                  fontFamily={"Poppins"}
                >
                  Terms
                </Text>
              </Link>

              <Stack>
                <Divider
                  orientation="vertical"
                  thickness={1}
                  fontWeight="bold"
                  bg="rgb(152,138,180)"
                  ml={vw(10)}
                  height={vw(25)}
                />
              </Stack>
              <Link
                // onClick={() => {
                //   window.scrollTo(0, 0);
                //   setMain("privacy");
                //   //console.log("privacy");
                // }}
                href="/privacy"
              >
                <Text
                  ml={vw(10)}
                  color="#988AB4"
                  fontWeight={400}
                  fontSize={vw(15)}
                  fontFamily={"Poppins"}
                >
                  Privacy
                </Text>
              </Link>

              <Stack>
                <Divider
                  orientation="vertical"
                  thickness={1}
                  fontWeight="bold"
                  bg="rgb(152,138,180)"
                  ml={vw(10)}
                  height={vw(25)}
                />
              </Stack>
              <Link
                // onClick={() => {
                //   window.scrollTo(0, 0);
                //   setMain("faqs");
                //   //console.log("faqs");
                // }}
                href="/faqs"
              >
                <Text
                  ml={vw(10)}
                  color="#988AB4"
                  fontWeight={400}
                  fontSize={vw(15)}
                  fontFamily={"Poppins"}
                >
                  FAQs
                </Text>
              </Link>
            </HStack>
          </Box>
        </HStack>
      </Center>
    </VStack>
  );
};

export default React.memo(Large);
