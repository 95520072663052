import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const AddProperties = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={32}
    viewBox="0 0 35 32"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Bulk_Add_Properties"
      data-name="Bulk Add Properties"
      transform="translate(-265.273 -183.194)"
    >
      <Path
        id="Path_20"
        data-name="Path 20"
        d="M293.253,183.194a4.4,4.4,0,0,0-3.1,1.276,4.344,4.344,0,0,0-1.285,3.078v.709l-3-2.626a4.715,4.715,0,0,0-6.184,0l-14.13,12.339a.826.826,0,0,0-.074,1.171.838.838,0,0,0,1.178.074l1.249-1.092v13.221a3.843,3.843,0,0,0,1.135,2.72,3.887,3.887,0,0,0,2.737,1.129h10.991a.829.829,0,1,0,0-1.657H271.779a2.215,2.215,0,0,1-1.559-.642,2.191,2.191,0,0,1-.647-1.55V196.666l11.206-9.795a3.037,3.037,0,0,1,3.985,0l11.444,10,.011.009,2.667,2.334a.838.838,0,0,0,1.177-.075.826.826,0,0,0-.076-1.17l-2.356-2.059v-8.364a4.348,4.348,0,0,0-1.284-3.076,4.4,4.4,0,0,0-3.095-1.278Zm2.714,11.26-5.429-4.75v-2.156a2.7,2.7,0,0,1,1.373-2.291,2.731,2.731,0,0,1,2.683,0,2.7,2.7,0,0,1,1.373,2.291Z"
        fill="#fff"
      />
      <Path
        id="Path_21"
        data-name="Path 21"
        d="M278.75,195.622a3.99,3.99,0,0,0,1.179,2.828,4.041,4.041,0,0,0,5.689,0,3.98,3.98,0,0,0,0-5.654,4.035,4.035,0,0,0-5.688,0,3.992,3.992,0,0,0-1.179,2.827Zm6.378,0h0a2.338,2.338,0,0,1-.691,1.656,2.367,2.367,0,0,1-3.331,0,2.332,2.332,0,0,1,0-3.312,2.365,2.365,0,0,1,3.331,0,2.337,2.337,0,0,1,.691,1.656Z"
        fill="#fff"
      />
      <Path
        id="Path_22"
        data-name="Path 22"
        d="M292.971,200.677a7.323,7.323,0,0,0-5.164,2.126,7.227,7.227,0,0,0,0,10.265,7.333,7.333,0,0,0,10.327,0,7.222,7.222,0,0,0,0-10.263,7.331,7.331,0,0,0-5.161-2.129Zm0,12.859h0a5.655,5.655,0,0,1-3.985-1.64,5.579,5.579,0,0,1,0-7.922,5.66,5.66,0,0,1,7.97,0,5.575,5.575,0,0,1,0,7.92,5.656,5.656,0,0,1-3.983,1.642Z"
        fill="#fff"
      />
      <Path
        id="Path_23"
        data-name="Path 23"
        d="M295.691,207.107H293.8v-1.878a.833.833,0,0,0-1.667,0v1.878h-1.889a.829.829,0,1,0,0,1.657h1.889v1.878a.833.833,0,0,0,1.667,0v-1.878h1.889a.829.829,0,1,0,0-1.657Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default AddProperties;
