import React, { useState, useEffect } from "react";
import {
  NativeBaseProvider,
  VStack,
  extendTheme,
  Box,
  useMediaQuery,
} from "native-base";
import "pure-react-carousel/dist/react-carousel.es.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { throttle } from "lodash";
import FoldOne from "./folds/one";
import FoldTwo from "./folds/two";
import FoldThree from "./folds/three";
import FoldFour from "./folds/four";
import FoldFive from "./folds/five";
import FoldSix from "./folds/six";
import FoldSeven from "./folds/seven";
import TermsFold from "./folds/terms";
import PrivacyFold from "./folds/privacy";
import FaqsFold from "./folds/faqs";
import BlogFold from "./folds/blog";
import PostPage from "./folds/blog/templates/PostPage";
import Navbar from "./navbar";
import FontFaceObserver from "fontfaceobserver";
import RedirectPage from "./direct";
function App() {
  const [landscape] = useMediaQuery([
    {
      orientation: "landscape",
    },
    {
      orientation: "portrait",
    },
  ]);
  const themeConfig = {
    useSystemColorMode: false,
    initialColorMode: "light",
  };
  // useEffect(() => {
  //   window.addEventListener("beforeunload", function (event) {
  //     console.log("beforeunload", window.location.href);
  //     const href = window.location.href;
  //     // // console.log("href", href);
  //     if (href.includes("play.google.com") || href.includes("apps.apple.com")) {
  //       console.log("href playstrore", href);
  //     }
  //     // event.preventDefault();
  //     // setTimeout(
  //     //   (function (href) {
  //     //     console.log("href timeout", window.location.href);
  //     //   })(href),
  //     //   1000
  //     // );
  //   });
  //   window.addEventListener("unload", function (event) {
  //     console.log("unload", window.location.href);
  //   });
  // }, []);

  // useEffect(()=>{
  //   console.log("window.location.search",window.location.search)
  // },[])
  // console.log("window.location.search", window.location.search);
  // alert(window.localStorage.getItem("SPUID"));
  // window.postMessage(window.localStorage.getItem("SPUID"));
  useEffect(() => {
    if (window.location.hash) {
      console.log("hash", window.location.hash);
      const hash = window.location.hash.replace("#", "");
      const element = document.getElementById(hash);
      console.log("element", element);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  });
  console.log(window.location.pathname, "pathname");

  // extend the theme
  const customTheme = extendTheme({ config: themeConfig });

  // blog functionalities
  let platform = "web";
  if (navigator.userAgent.includes("iPhone")) {
    platform = "ios";
  } else if (navigator.userAgent.includes("Android")) {
    platform = "android";
  }

  // const [isRendering,setIsRendering] = useState(true)
  // const handleLoading = () => {
  //   setIsRendering(!isRendering);
  // };

  // useEffect(() => {
  //   window.addEventListener("load", handleLoading);
  //   return () => window.removeEventListener("load", handleLoading);
  // }, []);

  // const scrollPosition = useRef(0);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   scrollPosition.current = position;
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: false });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(
    Number(sessionStorage.getItem("currentPage")) || 1
  );
  const [perPage, setCount] = useState(platform === "web" ? 8 : 3);
  const [isLoading, setLoading] = useState(false);
  const [totalPosts, setTotalposts] = useState(null);
  const [ip, setIP] = useState(null);
  useEffect(() => {
    sessionStorage.setItem("currentPage", page);
  }, [page]);
  useEffect(() => {
    sub();
  }, [page]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const observer = new FontFaceObserver("Poppins");

    observer.load().then(() => {
      if (isLoaded !== true) setIsLoaded(true);
    });
  }, []);
  useEffect(() => {
    const getData = async () => {
      const res = await fetch("https://api.ipify.org/?format=json");
      const data = await res.json();
      setIP(data.ip);
    };
    //passing getData method to the lifecycle method
    getData();
  }, []);
  const sub = async () => {
    try {
      await setLoading(true);
      const url = `https://apiblog.shadow.properties/wp-json/wp/v2/posts?_embed&page=${page}&per_page=${perPage}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },

        redirect: "follow",
      });

      if (response.ok) {
        const details = await response.json();

        // console.log(totalPosts,"total pasts");
        details
          .sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          })
          .reverse();
        setData(details);
        setTotalposts(response.headers.get("X-WP-Total"));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setPage(1);
    }
  };
  const sendIp = async () => {
    window.location.href =
      "https://adtrustmedia.go2cloud.org/aff_c?offer_id=1198&aff_id=1061";
  };
  return (
    <NativeBaseProvider theme={customTheme}>
      {/* updated with seperate folds for terms, faqs,privacy */}
      {isLoaded && (
        <>
          {" "}
          {!(
            window.location.href.includes("/blog") &&
            window.location.pathname !== "/blog"
          ) && (
            <Box
              style={{
                position: "fixed",
                width: "100%",
                zIndex: 9999,
              }}
            >
              <Navbar landscape={landscape} sendIp={sendIp} />
            </Box>
          )}
          {/* <Box
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 9999,
        }}
      >
        <Navbar landscape={landscape} />
      </Box> */}
          <BrowserRouter>
            <VStack
              style={{
                flex: 1,
                padding: 0,
                margin: 0,
                transform: "scale(1)",
                // display: fold < 9 ? "flex" : "none",
              }}
            >
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <>
                      <section id="main">
                        <FoldOne sendIp={sendIp} />
                      </section>
                      <section id="howitworks">
                        <FoldTwo />
                      </section>
                      <section id="features">
                        <FoldFour />
                      </section>
                      <section id="pricing">
                        <FoldFive sendIp={sendIp} />
                      </section>
                      {/* <FoldSix /> */}
                      <FoldThree ip={ip} sendIp={sendIp} />
                      <section id="contact">
                        <FoldSeven />
                      </section>
                    </>
                  }
                />
                <Route path="/terms" element={<TermsFold />} />
                <Route path="/privacy" element={<PrivacyFold />} />
                <Route path="/faqs" element={<FaqsFold />} />
                <Route
                  path="/direct"
                  element={<RedirectPage sendIp={sendIp} />}
                />
                <Route
                  path="/blog"
                  element={
                    <BlogFold
                      perPage={perPage}
                      totalPosts={totalPosts}
                      page={page}
                      setPage={setPage}
                      setCount={setCount}
                      data={data}
                      isLoading={isLoading}
                    />
                  }
                />
                <Route path={`/blog/:post`} element={<PostPage />} />
                {/* routes for features */}
              </Routes>

              {/* <Blog /> */}
            </VStack>
          </BrowserRouter>
        </>
      )}
    </NativeBaseProvider>
  );
}

export default React.memo(App);
