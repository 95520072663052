import * as React from "react";
const LeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <g
      id="Group_1019"
      data-name="Group 1019"
      transform="translate(945 484) rotate(180)"
    >
      <rect
        id="Rectangle_812"
        data-name="Rectangle 812"
        width={50}
        height={50}
        transform="translate(895 434)"
        fill="none"
      />
      <path
        id="Path_10310"
        data-name="Path 10310"
        d="M9.6,3.058-3.15-9.689.492-13.3,16.85,3.058.492,19.416-3.15,15.774Z"
        transform="translate(913.15 456.3)"
        fill="#737373"
      />
    </g>
  </svg>
);
export default LeftArrow;
