import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const AutomatedMails = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={33}
    viewBox="0 0 31 33"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Automated_Mails"
      data-name="Automated Mails"
      transform="translate(-266.235 -181.858)"
    >
      <Path
        id="Path_74"
        data-name="Path 74"
        d="M290.592,184.058H284.5v1.1h6.09a1.667,1.667,0,0,1,1.174.483,1.648,1.648,0,0,1,.486,1.167v.55h-2.768v1.1h2.768V203.08l-1.432-1.423-.591-.588-.553-.512a2.513,2.513,0,0,0-3.156,0s-.384.383-.476.512a5.551,5.551,0,0,0-.3.588,2.485,2.485,0,0,0-.124,1.1s.073.41.118.541c.051.147.27.559.27.559a2.55,2.55,0,0,0,.32.389l.716.711,1.107,1.1,1.107,1.1-.182.465c-.063.159-.253.635-.253.635a5.742,5.742,0,0,0-.357,1.1H276.753v-4.95h-1.107v7.7a2.746,2.746,0,0,0,.812,1.944,2.782,2.782,0,0,0,1.956.806h12.178v-1.1a.544.544,0,0,0-.122-.343,4.586,4.586,0,0,1-.407-5.192l.455-.792h0a.547.547,0,0,0-.089-.66l-3.322-3.3a1.392,1.392,0,0,1,.638-2.317,1.412,1.412,0,0,1,1.348.344l4.429,4.4.782-.777-.944-.941v-5.4l1.535,1.839h0a4.933,4.933,0,0,1,1.232,3.26v10.976h1.107V203.882a6.015,6.015,0,0,0-1.495-3.973l-2.38-2.851V186.808a2.746,2.746,0,0,0-.812-1.944,2.782,2.782,0,0,0-1.956-.806Zm-12.179,29.7a1.664,1.664,0,0,1-1.174-.483,1.644,1.644,0,0,1-.486-1.167v-1.65h11.6a5.658,5.658,0,0,0,1.013,3.3Z"
        fill="#fff"
      />
      <Path
        id="Path_75"
        data-name="Path 75"
        d="M283.949,211.558h1.107v1.1h-1.107Z"
        fill="#fff"
      />
      <Path
        id="Path_76"
        data-name="Path 76"
        d="M277.86,205.205v2.5a.548.548,0,0,0,.162.389.555.555,0,0,0,.391.161h4.428a.556.556,0,0,0,.392-.161.548.548,0,0,0,.162-.389v-3.054h-1.107v2.5h-3.322v-1.953Z"
        fill="#fff"
      />
      <Path
        id="Path_77"
        data-name="Path 77"
        d="M289.485,196.158h1.661v1.1h-1.661Z"
        fill="#fff"
      />
      <Path
        id="Path_78"
        data-name="Path 78"
        d="M289.485,191.758h1.661v1.1h-1.661Z"
        fill="#fff"
      />
      <Path
        id="Path_79"
        data-name="Path 79"
        d="M266.378,193.227l1.861,2.045-.583,2.695a.55.55,0,0,0,.372.639l2.641.847.851,2.625h0a.553.553,0,0,0,.643.368l2.712-.579,2.058,1.849h0a.555.555,0,0,0,.742,0l2.058-1.849,2.712.579a.555.555,0,0,0,.644-.368l.852-2.625,2.641-.846h0a.549.549,0,0,0,.371-.639l-.583-2.695,1.862-2.045h0a.548.548,0,0,0,0-.738l-1.862-2.044.583-2.695a.55.55,0,0,0-.371-.64l-2.641-.847-.852-2.624a.553.553,0,0,0-.643-.368l-2.712.579L277.678,182a.556.556,0,0,0-.742,0l-2.058,1.849-2.712-.579a.554.554,0,0,0-.644.368l-.852,2.624-2.641.846a.55.55,0,0,0-.372.638l.583,2.7-1.861,2.046a.547.547,0,0,0,0,.738Zm2.873-2.255a.548.548,0,0,0,.13-.484L268.844,188l2.436-.779h0a.552.552,0,0,0,.357-.354l.785-2.42,2.5.534h0a.562.562,0,0,0,.488-.129l1.9-1.7,1.9,1.7a.558.558,0,0,0,.488.13l2.5-.535.784,2.42h0a.553.553,0,0,0,.357.355l2.436.78-.538,2.485h0a.551.551,0,0,0,.131.485l1.715,1.883-1.716,1.885a.548.548,0,0,0-.13.485l.538,2.485-2.436.779h0a.553.553,0,0,0-.357.354l-.784,2.42-2.5-.534h0a.556.556,0,0,0-.488.13l-1.9,1.7-1.9-1.7a.557.557,0,0,0-.488-.13l-2.5.534-.785-2.42h0a.553.553,0,0,0-.357-.354l-2.436-.78.538-2.485h0a.549.549,0,0,0-.131-.484l-1.714-1.884Z"
        fill="#fff"
      />
      <Path
        id="Path_80"
        data-name="Path 80"
        d="M272.355,195.468a2.282,2.282,0,0,0,.753.305l.432,2.145a.552.552,0,0,0,.543.44H276.3a.557.557,0,0,0,.433-.207.546.546,0,0,0,.107-.465l-.493-2.169,5.425.636h0a.556.556,0,0,0,.494-.2.547.547,0,0,0,.111-.46l-1.661-7.7a.551.551,0,0,0-.338-.4.557.557,0,0,0-.519.059l-5.176,3.57-1.573.337h0a2.272,2.272,0,0,0-1.43.984,2.248,2.248,0,0,0,.677,3.121Zm7.45-6.637,1.324,6.141-5.038-.591-.564-2.6Zm-4.2,8.427h-1.068l-.3-1.515.98-.21Zm-3-4.315a1.158,1.158,0,0,1,.734-.5l1.139-.245.306,1.41.185.855-1.139.243h0a1.172,1.172,0,0,1-.878-.159,1.154,1.154,0,0,1-.347-1.6Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default AutomatedMails;

