import React, { useState, useEffect } from "react";
import {
  VStack,
  HStack,
  Center,
  Stack,
  Spinner,
  Pressable,
  Box,
  Text,
} from "native-base";
import Header from "./templates/Header";
import Template from "./templates/Template";
import BottomBar from "./templates/BottomBar";
import RightArrow from "../../assets/svg/RightArrow";
import LeftArrow from "../../assets/svg/LeftArrow";
import LeftArrowInactive from "../../assets/svg/LeftArrowInactive";
import RightArrowInactive from "../../assets/svg/RightArrowInactive";
import { vw, vh } from "../../util";

//fold one large
const Large = ({
  perPage,
  totalPosts,
  page,
  setPage,
  isLoading,
  data,
  scrolled,
}) => {
  // const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState(Math.ceil(totalPosts / perPage));
  // console.log(pageCount, totalPosts, "pageCount");
  useEffect(() => {
    setPageCount(Math.ceil(totalPosts / perPage));
  }, [totalPosts]);
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   getData();
  //   console.log("ggggggggg");
  // }, []);

  // const getData = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://directproads.com/wp-json/wp/v2/media"
  //     );
  //     const json = await response.json();
  //     setData(json);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handlePress = (e) => {
    setShow(!show);
  };

  return (
    <VStack>
      {/* <div
        style={{
          position: "fixed",
          top:0,
          left:0,
          width: "100%",
          zIndex: 9999,
          backgroundColor: "#fff",
          boxShadow: scrolled ? "1px 1px 8px #00000020" : "1px 1px 2px #eee",
        }}
      >
        <Header />
      </div> */}
      <Center
        justifyContent={"flex-start"}
        w={"100%"}
        height={"min-content"}
        style={{ zIndex: 999, bottom: "50vh" }}
      >
        <VStack
          backgroundColor="white"
          mt={"60vh"}
          w={"100%"}
          // style={{ boxShadow: "1px 1px 20px white" }}
        >
          {/* filters */}

          {/* {isLoading ? (
            <></>
          ) : (
            <HStack alignItems={"center"} backgroundColor={"gray.200"}>
              <Box>
                <ShowFilter />
              </Box>
              <Text color={"grey"} fontSize={vw(15)} fontFamily={"Poppins"}>
                Show filters
              </Text>
            </HStack>
          )} */}
          {/* filters end */}
          {isLoading ? (
            <Center height={"100vh"}>
              <Spinner size={"lg"} color={"black"} />
            </Center>
          ) : (
            <Center>
              {/* <FlatList
                numColumns={4}
                data={data}
                keyExtractor={({ id }, index) => id}
                renderItem={({ item }) => (
                  <Stack
                    mb={vh(20)}
                    paddingBottom={10}
                    mt={vh(30)}
                    mr={vw(30)}
                    ml={vw(15)}
                  >
                    <Template
                      HeadingFont={vw(21)}
                      Font={vw(14)}
                      NameFont={vw(12)}
                      width={vw(350)}
                      height={vh(810)}
                      item={item}
                      // image={item.guid.rendered}
                    />
                  </Stack>
                )}
              /> */}
              <HStack flexWrap={"wrap"} backgroundColor="white">
                {data.map((item, idx) => (
                  <Stack key={idx} mb={vh(20)} paddingBottom={10} m={vw(20)}>
                    <Template
                      HeadingFont={vw(21)}
                      Font={vw(14)}
                      NameFont={vw(12)}
                      width={vw(350)}
                      height={vh(890)}
                      item={item}
                      // image={item.guid.rendered}
                    />
                  </Stack>
                ))}
              </HStack>
              <HStack
                width={"min-content"}
                justifyContent={"space-around"}
                alignItems={"center"}
                my={"10"}
              >
                {page !== 1 && (
                  <Pressable
                    onPress={() => {
                      window.scrollTo({ top: 0 });
                      setPage(page - 1);
                    }}
                  >
                    <Box>
                      <LeftArrow />
                    </Box>
                  </Pressable>
                )}
                {page === 1 && (
                  <Box>
                    <LeftArrowInactive />
                  </Box>
                )}
                {/* <Text fontFamily={"poppins"} fontSize={"110%"}>
                  Page: {page}
                </Text> */}
                {[...Array(pageCount).keys()].map((idx) => {
                  return (
                    <Pressable
                      onPress={() => {
                        setPage(idx + 1);
                      }}
                    >
                      <Text
                        fontFamily={"poppins"}
                        color={page === idx + 1 ? "#bcbcbc" : "black"}
                        fontSize={"110%"}
                        margin={vw(20)}
                      >
                        {idx + 1}
                      </Text>
                    </Pressable>
                  );
                })}
                {page !== pageCount && (
                  <Pressable
                    onPress={() => {
                      window.scrollTo({ top: 0 });
                      setPage(page + 1);
                    }}
                  >
                    <Box>
                      <RightArrow />
                    </Box>
                  </Pressable>
                )}
                {page === pageCount && (
                  <Box>
                    <RightArrowInactive />
                  </Box>
                )}
              </HStack>
            </Center>
          )}
        </VStack>
      </Center>
      <VStack mt={"auto"} style={{ position: "fixed", bottom: 0, zIndex: 998 }}>
        <VStack>
          <BottomBar />
        </VStack>
      </VStack>
    </VStack>
  );
};

export default React.memo(Large);
