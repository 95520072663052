// import React, { useState } from "react";
// import {
//   VStack,
//   Center,
//   Text,
//   HStack,
//   Image,
//   Pressable,
//   Divider,
//   Box,
// } from "native-base";
// import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel";
// import "pure-react-carousel/dist/react-carousel.es.css";
// import EyeIcon from "../../assets/svg/EyeIcon";
// import SearchIcon from "../../assets/svg/SearchIcon";
// import MoreIcon from "../../assets/svg/MoreIcon";
// import DropDown from "../../assets/svg/DropDown";
// import { vh, vw } from "../../util";

// let platform = "web";
// if (navigator.userAgent.includes("iPhone")) {
//   platform = "ios";
// } else if (navigator.userAgent.includes("Android")) {
//   platform = "android";
// }
// const isAppleOrMac = /(Macintosh|iPad|iPhone|iPod)/i.test(navigator.userAgent);
// const flow = [
//   {
//     step: 0,
//     title: "Find & Add Properties",
//     description:
//       " Quickly find attractive properties based on the area code or address and drop a pin on the map to save them to your property list",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Adding_properties.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Adding_properties.webm",
//   },
//   {
//     step: 1,
//     title: "360° Property View",
//     description:
//       "This feature renders a 360° view of a property, enabling a virtually immersive and realistic experience—assess the property layout before scheduling a visit.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/MLS.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/MLS.webm",
//   },
//   {
//     step: 2,
//     title: "Skip Trace",
//     description:
//       "Access contact information like email address and phone number of property owner using the Skip Trace feature and add additional contacts as needed.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Smart_search.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Smart_search.webm",
//   },
//   {
//     step: 3,
//     title: "Choose the default address or email",
//     description:
//       "Set the provided address or add a new address as default to run campaigns successfully.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Setting_as_default_1.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Setting_as_default_1.webm",
//   },
//   {
//     step: 4,
//     title: "Signatures and Templates",
//     description:
//       "Establish unique signatures and templates for each active campaign to build authority in the marketplace",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Temp_Sign_.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Temp_Sign_.webm",
//   },
//   {
//     step: 5,
//     title: "Create a campaign",
//     description:
//       "Create automated campaigns and send scheduled emails and postal mails to property owners directly from the app.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Campaign1.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Campaign1.webm",
//   },
//   {
//     step: 6,
//     title: "List management",
//     description:
//       "Build custom property lists and attach a campaign that is automatically activated for each property within the list.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/list_management.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/list_management.webm",
//   },
//   {
//     step: 7,
//     title: "Pipeline",
//     description:
//       "Organize your properties based on their deal status and swiftly move them from one pipeline to another.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Pipeline_1.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Pipeline_1.webm",
//   },
//   {
//     step: 8,
//     title: "Live Team Driving — An Unrivaled Feature",
//     description:
//       "Easily monitor all the driving routes of your team members at once. You can also tap on individual members to follow their route.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Team_drive.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Team_drive.webm",
//   },
//   {
//     step: 9,
//     title: "Buyer contacts (Coming Soon)",
//     description:
//       "Share shortlisted properties with interested buyers based on their requirements and close deals.",
//     uri: isAppleOrMac
//       ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Buyers-Contact.mp4"
//       : "https://cdn.shadow.properties/shadow.properties/how-it-works/Buyers-Contact.webm",
//   },
// ];
// const Medium = ({ landscape, gifs }) => {
//   //console.log("Fold 2 Medium Landscape", landscape);
//   let i = 0;
//   const HowItWorksFold = () => {
//     let i = 0;
//     const [isOpen, setOpen] = useState(false);
//     const [step, setStep] = useState(0);
//     const ListItem = ({ item, step }) => {
//       console.log(item);
//       return (
//         <Pressable
//           // display={step === item.step ? "none" : "flex"}
//           onPress={() => {
//             setStep(item.step);
//             setOpen(!isOpen);
//           }}
//         >
//           <Text
//             style={{
//               textAlign: "left",
//               fontFamily: "Poppins",
//               fontWeight: "600",
//               color: step === item.step ? "#7D2BE9" : "#988AB4",
//             }}
//             fontSize={vw(landscape ? 40 : 65)}
//           >
//             {item.title}
//           </Text>
//           {item.step !== 9 ? <Divider mt={1} /> : <></>}
//         </Pressable>
//       );
//     };
//     return (
//       // <Center w={"100%"} mt={10}>
//       //   <Center w={"100%"} zIndex={99} position={"absolute"} top={0}>
//       //     <Pressable
//       //       width={"100%"}
//       //       onPress={() => {
//       //         setOpen(!isOpen);
//       //       }}
//       //     >
//       //       <Center
//       //         style={{
//       //           background: "#FFFFFF 0% 0% no-repeat padding-box",
//       //           border: "2px solid #7D2BE9",
//       //           borderRadius: "25px",
//       //           opacity: 1,
//       //           width: "90%",
//       //           padding: 5,
//       //           paddingHorizontal: 0,
//       //           margin: 20,
//       //         }}
//       //       >
//       //         <HStack w={"90%"} justifyContent={"space-between"} padding={1.2}>
//       //           <Box>
//       //             <Text
//       //               style={{ color: "#7D2BE9", fontFamily: "Poppins_medium" }}
//       //               fontSize={window.innerWidth <= 360 ? 12 : 14}
//       //             >
//       //               {flow[step].title}
//       //             </Text>
//       //           </Box>
//       //           <Pressable
//       //             onPress={() => {
//       //               setOpen(!isOpen);
//       //             }}
//       //             style={{ justifyContent: "center", alignItems: "center" }}
//       //           >
//       //             <Box
//       //               style={{
//       //                 justifyContent: "center",
//       //                 alignItems: "center",
//       //                 transform: isOpen ? "rotate(180deg)" : "",
//       //                 transition: "transform 150ms ease",
//       //               }}
//       //             >
//       //               <DropDown fill={"#7D2BE9"} />
//       //             </Box>
//       //           </Pressable>
//       //         </HStack>
//       //         {isOpen ? (
//       //           <VStack w={"90%"}>
//       //             <Divider mt={2} />
//       //             {[...Array(10).keys()].map(() => {
//       //               let item = flow[i++];
//       //               return (
//       //                 <VStack m={1}>
//       //                   <ListItem item={item} step={step} />
//       //                 </VStack>
//       //               );
//       //             })}
//       //           </VStack>
//       //         ) : (
//       //           <></>
//       //         )}
//       //       </Center>
//       //     </Pressable>
//       //   </Center>
//       //   <Text
//       //     style={{
//       //       marginTop: landscape ? vh(350) : vw(400),
//       //       width: "90%",
//       //       textAlign: "center",
//       //       margin: vw(80),
//       //       color: "#988AB4",
//       //       fontFamily: "HelveticaNeueLTStd-Bd",
//       //       fontSize: vw(landscape ? 40 : 65),
//       //     }}
//       //   >
//       //     {flow[step].description}
//       //   </Text>
//       //   {/* <Center
//       //       alignSelf={"center"}
//       //       style={{
//       //         background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//       //         // boxShadow: "1px 2.5px 10px #00000033",
//       //         border: "3px solid #FFFFFF",
//       //         borderRadius: 40,
//       //         // width: vw(
//       //         //   landscape
//       //         //     ? platform === "android"
//       //         //       ? 750
//       //         //       : 900
//       //         //     : platform === "android"
//       //         //     ? 1100
//       //         //     : 1100
//       //         // ),
//       //         // height: vw(
//       //         //   landscape
//       //         //     ? platform === "android"
//       //         //       ? 750
//       //         //       : 1950
//       //         //     : platform === "android"
//       //         //     ? 2300
//       //         //     : 2300
//       //         // ),
//       //         width:"min-content",
//       //         height:"min-content",
//       //         marginHorizontal: vw(landscape ? 350 : 260),
//       //         boxShadow: "2px 2px 20px #00000033",
//       //       }}
//       //     >
//       //       <Image
//       //         style={{
//       //           // width: "100%",
//       //           // height: "100%",
//       //           width: vw(
//       //             landscape
//       //               ? platform === "android"
//       //                 ? 750
//       //                 : 850
//       //               : platform === "android"
//       //               ? 1100
//       //               : 1100
//       //           ),
//       //           // height: vw(
//       //           //   landscape
//       //           //     ? platform === "android"
//       //           //       ? 750
//       //           //       : 2000
//       //           //     : platform === "android"
//       //           //     ? 2200
//       //           //     : 2300
//       //           // ),
//       //           aspectRatio: 280/605,
//       //           backgroundColor: "transparent",
//       //           borderRadius: 40,
//       //         }}
//       //         margin={vh(10)}
//       //         resizeMode="cover"
//       //         source={{
//       //           uri: flow[step].uri,
//       //         }}
//       //       />
//       //     </Center> */}
//       //   <Center
//       //     // key={"gif-" + idx}
//       //     style={{
//       //       background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//       //       boxShadow: "15px 26px 70px #00000026",
//       //       border: "3px solid #FFFFFF",
//       //       borderRadius: 45,
//       //       paddingVertical: window.innerWidth <= 900 ? vw(10) : vw(5),
//       //       // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
//       //       height: "min-content",
//       //       // marginTop: vh(100),
//       //     }}
//       //   >
//       //     {/* <Image
//       //         // alt={gifs.alt}
//       //         style={{
//       //           width: vw(275),
//       //           // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
//       //           backgroundColor: "transparent",
//       //           borderRadius: vw(10),
//       //           aspectRatio: 280 / 605,
//       //           margin: vw(6),
//       //         }}
//       //         source={urls[selected-1]}
//       //       /> */}
//       //     <video
//       //       style={{
//       //         width: vw(
//       //           landscape
//       //             ? platform === "android"
//       //               ? 750
//       //               : 850
//       //             : platform === "android"
//       //             ? 1100
//       //             : 1100
//       //         ),
//       //         // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
//       //         backgroundColor: "transparent",
//       //         borderRadius: 40,
//       //         aspectRatio: 280 / 605,
//       //         margin: vh(6),
//       //       }}
//       //       src={flow[step].uri}
//       //       loop
//       //       autoPlay
//       //       playsInline
//       //       muted
//       //       preload="auto"
//       //     >
//       //       <source src={flow[step].uri} />
//       //     </video>
//       //   </Center>
//       // </Center>
//       <Center w={"100%"} mt={landscape ? false : vh(30)}>
//         <Center w={"100%"} zIndex={99} position={"absolute"} top={0}>
//           <Pressable
//             width={"100%"}
//             onPress={() => {
//               setOpen(!isOpen);
//             }}
//             alignItems={"center"}
//           >
//             <Center
//               style={{
//                 background: "#FFFFFF 0% 0% no-repeat padding-box",
//                 border: "2px solid #7D2BE9",
//                 borderRadius: "25px",
//                 opacity: 1,
//                 width: "90%",
//                 padding: 5,
//                 paddingHorizontal: 0,
//                 margin: 20,
//               }}
//             >
//               <HStack w={"90%"} justifyContent={"space-between"} padding={1.5}>
//                 <Box>
//                   <Text
//                     style={{
//                       color: "#7D2BE9",
//                       fontFamily: "Poppins",
//                       fontWeight: "600",
//                       fontSize: vw(landscape ? 40 : 65),
//                     }}
//                   >
//                     {flow[step].title}
//                   </Text>
//                 </Box>
//                 <Pressable
//                   onPress={() => {
//                     setOpen(!isOpen);
//                   }}
//                   style={{ justifyContent: "center", alignItems: "center" }}
//                 >
//                   <Box
//                     style={{
//                       justifyContent: "center",
//                       alignItems: "center",
//                       transform: isOpen ? "rotate(180deg)" : "",
//                       transition: "transform 150ms ease",
//                     }}
//                   >
//                     <DropDown fill={"#7D2BE9"} />
//                   </Box>
//                 </Pressable>
//               </HStack>
//               {isOpen ? (
//                 <VStack w={"90%"}>
//                   <Divider mt={2} />
//                   {[...Array(10).keys()].map(() => {
//                     let item = flow[i++];
//                     return (
//                       <VStack m={1}>
//                         <ListItem item={item} step={step} />
//                       </VStack>
//                     );
//                   })}
//                 </VStack>
//               ) : (
//                 <></>
//               )}
//             </Center>
//           </Pressable>
//         </Center>
//         <Text
//           style={{
//             marginTop: landscape ? vh(300) : vw(250),
//             width: "90%",
//             textAlign: "center",
//             margin: vw(80),
//             color: "#303030",
//             fontFamily: "Poppins",
//             fontWeight: "300",
//             fontSize: vw(landscape ? 40 : 45),
//           }}
//         >
//           {flow[step].description}
//         </Text>
//         <Center
//           // key={"gif-" + idx}
//           style={{
//             background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//             boxShadow: "15px 26px 70px #00000026",
//             border: "3px solid #FFFFFF",
//             borderRadius: 45,
//             paddingVertical: window.innerWidth <= 900 ? vw(10) : vw(5),
//             // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
//             height: "min-content",
//             // marginTop: vh(100),
//           }}
//         >
//           <video
//             style={{
//               width: vw(
//                 landscape
//                   ? platform === "android"
//                     ? 450
//                     : 450
//                   : platform === "android"
//                   ? 1100
//                   : 750
//               ),
//               // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
//               backgroundColor: "transparent",
//               borderRadius: 40,
//               aspectRatio: 280 / 605,
//               margin: 6,
//             }}
//             src={flow[step].uri}
//             loop
//             autoPlay
//             playsInline
//             muted
//             preload="auto"
//           >
//             <source src={flow[step].uri} />
//           </video>
//         </Center>
//       </Center>
//     );
//   };
//   const [isOpen, setOpen] = useState(false);
//   const [step, setStep] = useState(0);
//   const ListItem = ({ item, step }) => {
//     return (
//       <Pressable
//         onPress={() => {
//           setStep(item.step);
//           setOpen(!isOpen);
//         }}
//       >
//         <Text
//           style={{
//             textAlign: "left",
//             fontFamily: "Poppins",
//             fontWeight: "600",
//             color: step === item.step ? "#7D2BE9" : "#988AB4",
//             fontSize: landscape
//               ? vw(30)
//               : vw(window.innerWidth < 900 ? 50 : 60),
//           }}
//         >
//           {item.title}
//         </Text>
//         {item.step !== 9 ? <Divider mt={1} /> : <></>}
//       </Pressable>
//     );
//   };
//   return (
//     <VStack
//       style={{
//         background: `linear-gradient(90deg, rgba(234,215,249,1) 0%, rgba(250,246,254,1) 25%, rgba(255,255,255,1) 50%, rgba(255,247,227,1) 75%, rgba(255,244,217,1) 100%)`,
//         justifyContent: "center",
//         // zIndex: -1,
//       }}
//     >
//       {/* <Center style={{ marginTop: vh(50) }}>
//         <Text
//           style={{
//             fontFamily: "poppins",
//             fontSize: vw(landscape ? 50 : 80),
//             color: "#7D2BE9",
//             textAlign: "center",
//           }}
//         >
//           How Shadow CRM Works?
//         </Text>
//         <Text
//           width={"80%"}
//           style={{
//             color: "#6D549F",
//             fontFamily: "HelveticaNeueLTStd-Bd",
//             fontSize: vw(landscape ? 40 : 65),
//             marginTop: vh(30),
//           }}
//           justifyContent={"center"}
//           alignItems={"center"}
//           textAlign={"center"}
//         >
//           A smart app designed for Lead Generation, Performance Tracking, Market
//           Automation, and Deal Management
//         </Text>
//         <Center w={"100%"}>
//           <Center w={"100%"} zIndex={99} position={"absolute"} top={0}>
//             <Pressable
//               width={"100%"}
//               onPress={() => {
//                 setOpen(!isOpen);
//               }}
//               alignItems={"center"}
//             >
//               <Center
//                 style={{
//                   background: "#FFFFFF 0% 0% no-repeat padding-box",
//                   border: "2px solid #7D2BE9",
//                   borderRadius: "25px",
//                   opacity: 1,
//                   width: "90%",
//                   padding: 5,
//                   paddingHorizontal: 0,
//                   margin: 20,
//                 }}
//               >
//                 <HStack
//                   w={"90%"}
//                   justifyContent={"space-between"}
//                   padding={1.5}
//                 >
//                   <Box>
//                     <Text
//                       style={{
//                         color: "#7D2BE9",
//                         fontFamily: "Poppins_medium",
//                         fontSize: landscape
//                           ? vw(30)
//                           : vw(window.innerWidth < 900 ? 50 : 60),
//                       }}
//                     >
//                       {flow[step].title}
//                     </Text>
//                   </Box>
//                   <Pressable
//                     onPress={() => {
//                       setOpen(!isOpen);
//                     }}
//                     style={{ justifyContent: "center", alignItems: "center" }}
//                   >
//                     <Box
//                       style={{
//                         justifyContent: "center",
//                         alignItems: "center",
//                         transform: isOpen ? "rotate(180deg)" : "",
//                         transition: "transform 150ms ease",
//                       }}
//                     >
//                       <DropDown fill={"#7D2BE9"} />
//                     </Box>
//                   </Pressable>
//                 </HStack>
//                 {isOpen ? (
//                   <VStack w={"90%"}>
//                     <Divider mt={2} />
//                     {[...Array(10).keys()].map(() => {
//                       let item = flow[i++];
//                       return (
//                         <VStack m={1}>
//                           <ListItem item={item} step={step} />
//                         </VStack>
//                       );
//                     })}
//                   </VStack>
//                 ) : (
//                   <></>
//                 )}
//               </Center>
//             </Pressable>
//           </Center>
//           <Text
//             style={{
//               marginTop: landscape ? vh(300) : vw(310),
//               width: "90%",
//               textAlign: "center",
//               margin: vw(80),
//               color: "#988AB4",
//               fontFamily: "Poppins_medium",
//               fontSize: landscape ? vw(30) : vw(40),
//             }}
//           >
//             {flow[step].description}
//           </Text>
//           <Center
//             // key={"gif-" + idx}
//             style={{
//               background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//               boxShadow: "15px 26px 70px #00000026",
//               border: "3px solid #FFFFFF",
//               borderRadius: 45,
//               paddingVertical: window.innerWidth <= 900 ? vw(10) : vw(5),
//               // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
//               height: "min-content",
//               // marginTop: vh(100),
//             }}
//           >
//             <video
//               style={{
//                 width: vw(
//                   landscape
//                     ? platform === "android"
//                       ? 450
//                       : 450
//                     : platform === "android"
//                     ? 1100
//                     : 750
//                 ),
//                 // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
//                 backgroundColor: "transparent",
//                 borderRadius: 40,
//                 aspectRatio: 280 / 605,
//                 margin: 6,
//               }}
//               src={flow[step].uri}
//               loop
//               autoPlay
//               playsInline
//               muted
//               preload="auto"
//             >
//               <source src={flow[step].uri} />
//             </video>
//           </Center>
//         </Center>
//         <Center style={{ marginTop: vh(50) }}>
//           <Text
//             style={{
//               fontFamily: "poppins",
//               fontSize: vw(landscape ? 50 : 80),
//               color: "#7D2BE9",
//               textAlign: "center",
//             }}
//           >
//             Fully Featured Mobile App
//           </Text>
//           <Text
//             width={"80%"}
//             style={{
//               color: "#988AB4",
//               fontFamily: "HelveticaNeueLTStd-Bd",
//               fontSize: vw(landscape ? 40 : 65),
//               marginTop: vh(30),
//             }}
//             justifyContent={"center"}
//             alignItems={"center"}
//             textAlign={"center"}
//           >
//             Don’t wait till you reach your office; close million-dollar deals
//             from your couch using a full-fledged mobile app and stay lightyears
//             ahead of your competition.
//           </Text>
//         </Center>
//         <Center style={{ marginTop: vh(50) }}>
//           <Text
//             style={{
//               fontFamily: "poppins",
//               fontSize: vw(landscape ? 50 : 80),
//               color: "#7D2BE9",
//               textAlign: "center",
//             }}
//           >
//             Driving for Dollars
//           </Text>
//           <Text
//             width={"80%"}
//             style={{
//               color: "#988AB4",
//               fontFamily: "HelveticaNeueLTStd-Bd",
//               fontSize: vw(landscape ? 40 : 65),
//               marginTop: vh(30),
//             }}
//             justifyContent={"center"}
//             alignItems={"center"}
//             textAlign={"center"}
//           >
//             Let your team drive to the best properties nearby with the most
//             advanced and accurate driving for dollars feature using our
//             formidable mobile app. Monitor them all from one screen and provide
//             instructions. Driving for dollars allows you to track every member
//             from one screen and magnify them to follow individual directions.
//           </Text>

//         </Center>
//         <Center style={{ marginTop: vh(50) }}>
//           <Text
//             style={{
//               fontFamily: "poppins",
//               fontSize: vw(landscape ? 50 : 80),
//               color: "#7D2BE9",
//               textAlign: "center",
//             }}
//           >
//             MLS Properties
//           </Text>
//           <Text
//             width={"80%"}
//             style={{
//               color: "#988AB4",
//               fontFamily: "HelveticaNeueLTStd-Bd",
//               fontSize: vw(landscape ? 40 : 65),
//               marginTop: vh(30),
//             }}
//             justifyContent={"center"}
//             alignItems={"center"}
//             textAlign={"center"}
//           >
//             Experience the world's top-of-the-line property management CRM and
//             gain access to premium MLS properties with in-depth info to maximize
//             your profits.
//           </Text>

//         </Center>
//         <Center style={{ marginTop: vh(50) }}>
//           <Text
//             style={{
//               fontFamily: "poppins",
//               fontSize: vw(landscape ? 50 : 80),
//               color: "#7D2BE9",
//               textAlign: "center",
//             }}
//           >
//             Postcard and Email Automation
//           </Text>
//           <Text
//             width={"80%"}
//             style={{
//               color: "#988AB4",
//               fontFamily: "HelveticaNeueLTStd-Bd",
//               fontSize: vw(landscape ? 40 : 65),
//               marginTop: vh(30),
//             }}
//             justifyContent={"center"}
//             alignItems={"center"}
//             textAlign={"center"}
//           >
//             Effortlessly communicate with property owners with campaign
//             automation feature & send periodic postcards and emails — close
//             50,000 property deals a month with reusable templates.
//           </Text>

//         </Center>
//       </Center> */}
//       <Center style={{ marginTop: vh(50) }}>
//         {/* <Center
//           backgroundColor={"rgba(125, 43, 233, 1)"}
//           style={{ width: "100%" }}
//           mt={landscape ? false : vh(150)}
//           borderRadius={25}
//           padding={5}
//         >
//           <Image
//             alt="driving for dollars"
//             style={{
//               width: landscape ? "60%" : "80%",
//               aspectRatio: 1200 / 800,
//             }}
//             source={require("../../assets/png/ShadowMobiles.png")}
//             m="auto"
//             mt={landscape ? false : vh(-150)}
//           />
//           <Text
//             style={{
//               fontFamily: "poppins",
//               fontSize: vw(landscape ? 50 : 80),
//               color: "white",
//               textAlign: "center",
//             }}
//           >
//             Features Spotlight
//           </Text>
//           <Text
//             width={"80%"}
//             style={{
//               color: "white",
//               fontFamily: "HelveticaNeueLTStd-Bd",
//               fontSize: vw(landscape ? 40 : 65),
//               marginTop: vh(30),
//             }}
//             justifyContent={"center"}
//             alignItems={"center"}
//             textAlign={"center"}
//           >
//             A smart real estate mobile and web app designed for nationwide
//             Property Finding, Investment Tracking, Marketing Automation, and
//             Buyer Management with unparalleled features and performance.
//           </Text>
//         </Center> */}
//         <Center
//           style={{
//             width: "90%",
//             height: "min-content",
//             background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//             boxShadow: "15px 26px 70px #00000040",
//             borderWidth: 3,
//             borderColor: "#FFFFFF",
//             borderRadius: 40,
//             // padding: vh(20),
//             alignSelf: "center",
//           }}
//           mt={landscape ? vw(350) : vh(200)}
//         >
//           <Center
//             style={{
//               background:
//                 "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//               borderRadius: 40,
//               width: "100%",
//               height: "100%",
//               alignItems: "center",
//               paddingVertical: vh(30),
//             }}
//           >
//             <Image
//               alt="driving for dollars"
//               style={{
//                 width: landscape ? "60%" : "100%",
//                 aspectRatio: 1200 / 800,
//               }}
//               source={require("../../assets/png/ShadowMobiles.png")}
//               m="auto"
//               mt={landscape ? vw(-350) : vh(-200)}
//               // backgroundColor={"gray.200"}
//             />
//             <Text
//               style={{
//                 fontFamily: "Poppins",
//                 fontWeight: "500",
//                 fontSize: vw(landscape ? 50 : 80),
//                 color: "white",
//                 textAlign: "center",
//                 // fontWeight: "bold",
//               }}
//               mt={landscape ? vw(-40) : vh(-30)}
//             >
//               Features Spotlight
//             </Text>
//             <Text
//               width={"80%"}
//               style={{
//                 color: "white",
//                 fontFamily: "Poppins",
//                 fontWeight: "300",
//                 fontSize: vw(landscape ? 40 : 45),
//                 marginTop: landscape ? vw(15) : vh(5),
//               }}
//               justifyContent={"center"}
//               alignItems={"center"}
//               textAlign={"center"}
//             >
//               A smart real estate mobile and web app designed for nationwide
//               Property Finding, Investment Tracking, Marketing Automation, and
//               Buyer Management with unparalleled features and performance.
//             </Text>
//           </Center>
//         </Center>
//         <HowItWorksFold />
//         <Center style={{ marginTop: vh(50), width: "100%" }}>
//           {/* <Image
//           alt="driving for dollars"
//           style={{
//             width: "60%",
//             aspectRatio: 1200 / 800,
//           }}
//           source={require("../../assets/png/ShadowMobiles.png")}
//           ml="auto"
//         />
//         <Text
//           style={{
//             fontFamily: "poppins",
//             fontSize: vw(landscape ? 50 : 80),
//             color: "#7D2BE9",
//             textAlign: "center",
//           }}
//         >
//           Fully Featured Mobile App
//         </Text>
//         <Text
//           width={"80%"}
//           style={{
//             color: "#988AB4",
//             fontFamily: "HelveticaNeueLTStd-Bd",
//             fontSize: vw(landscape ? 40 : 65),
//             marginTop: vh(30),
//           }}
//           justifyContent={"center"}
//           alignItems={"center"}
//           textAlign={"center"}
//         >
//           Don’t wait till you reach your office; close million-dollar deals from
//           your couch using a full-fledged mobile app and stay lightyears ahead
//           of your competition.
//         </Text> */}
//           <Center
//             // style={{
//             //   width: "90%",
//             //   height: "min-content",
//             //   background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//             //   boxShadow: "15px 26px 70px #00000040",
//             //   borderWidth: 3,
//             //   borderColor: "#FFFFFF",
//             //   borderRadius: 40,
//             //   // padding: vh(20),
//             //   alignSelf: "center",
//             // }}
//             mt={landscape ? vw(350) : vh(200)}
//           >
//             <Center
//             // style={{
//             //   background:
//             //     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//             //   borderRadius: 40,
//             //   width: "100%",
//             //   height: "100%",
//             //   alignItems: "center",
//             //   paddingVertical: vh(30),
//             // }}
//             >
//               <Image
//                 alt="driving for dollars"
//                 style={{
//                   width: landscape ? "60%" : "100%",
//                   aspectRatio: 1600 / 1200,
//                 }}
//                 source={require("../../assets/png/fully_featured.png")}
//                 m="auto"
//                 mt={landscape ? vw(-350) : vh(-200)}
//                 // backgroundColor={"gray.200"}
//               />
//               <Text
//                 style={{
//                   fontFamily: "Poppins",
//                   fontWeight: "500",
//                   fontSize: vw(landscape ? 50 : 80),
//                   color: "#7d2be9",
//                   textAlign: "center",
//                   // fontWeight: "bold",
//                 }}
//                 // mt={landscape ? vw(-40) : vh(-30)}
//               >
//                 Fully Featured Mobile App
//               </Text>
//               <Text
//                 width={"80%"}
//                 style={{
//                   color: "#303030",
//                   fontFamily: "Poppins",
//                   fontWeight: "300",
//                   fontSize: vw(landscape ? 40 : 45),
//                   marginTop: landscape ? vw(15) : vh(5),
//                 }}
//                 justifyContent={"center"}
//                 alignItems={"center"}
//                 textAlign={"center"}
//               >
//                 Don’t wait till you reach your office; close million-dollar
//                 deals from your couch using a full-fledged mobile app and stay
//                 lightyears ahead of your competition.
//               </Text>
//             </Center>
//           </Center>
//         </Center>
//         <Center style={{ marginTop: vh(50), width: "100%" }}>
//           <Center
//             // style={{
//             //   width: "90%",
//             //   height: "min-content",
//             //   background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//             //   boxShadow: "15px 26px 70px #00000040",
//             //   borderWidth: 3,
//             //   borderColor: "#FFFFFF",
//             //   borderRadius: 40,
//             //   // padding: vh(20),
//             //   alignSelf: "center",
//             // }}
//             mt={landscape ? vw(350) : vh(200)}
//           >
//             <Center
//             // style={{
//             //   background:
//             //     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//             //   borderRadius: 40,
//             //   width: "100%",
//             //   height: "100%",
//             //   alignItems: "center",
//             //   paddingVertical: vh(30),
//             // }}
//             >
//               <Image
//                 alt="driving for dollars"
//                 style={{
//                   width: landscape ? "60%" : "100%",
//                   aspectRatio: 1600 / 1200,
//                 }}
//                 source={require("../../assets/png/Driving-for-dollars.png")}
//                 m="auto"
//                 mt={landscape ? vw(-350) : vh(-200)}
//                 // backgroundColor={"gray.200"}
//               />
//               <Text
//                 style={{
//                   fontFamily: "Poppins",
//                   fontWeight: "500",
//                   fontSize: vw(landscape ? 50 : 80),
//                   color: "#7d2be9",
//                   textAlign: "center",
//                   // fontWeight: "bold",
//                 }}
//                 // mt={landscape ? vw(-40) : vh(-30)}
//               >
//                 Driving for Dollars
//               </Text>
//               <Text
//                 width={"80%"}
//                 style={{
//                   color: "#303030",
//                   fontFamily: "Poppins",
//                   fontWeight: "300",
//                   fontSize: vw(landscape ? 40 : 45),
//                   // marginTop: landscape ? vw(15) : vh(5),
//                 }}
//                 justifyContent={"center"}
//                 alignItems={"center"}
//                 textAlign={"center"}
//               >
//                 Let your team drive to the best properties nearby with the most
//                 advanced and accurate driving for dollars feature using our
//                 formidable mobile app. Monitor them all from one screen and
//                 provide instructions. Driving for dollars allows you to track
//                 every member from one screen and magnify them to follow
//                 individual directions.
//               </Text>
//             </Center>
//           </Center>
//         </Center>
//         <Center style={{ marginTop: vh(50), width: "100%" }}>
//           <Center
//             // style={{
//             //   width: "90%",
//             //   height: "min-content",
//             //   background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//             //   boxShadow: "15px 26px 70px #00000040",
//             //   borderWidth: 3,
//             //   borderColor: "#FFFFFF",
//             //   borderRadius: 40,
//             //   // padding: vh(20),
//             //   alignSelf: "center",
//             // }}
//             mt={landscape ? vw(350) : vh(200)}
//           >
//             <Center
//             // style={{
//             //   background:
//             //     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//             //   borderRadius: 40,
//             //   width: "100%",
//             //   height: "100%",
//             //   alignItems: "center",
//             //   paddingVertical: vh(30),
//             // }}
//             >
//               <Image
//                 alt="driving for dollars"
//                 style={{
//                   width: landscape ? "60%" : "100%",
//                   aspectRatio: 1600 / 1200,
//                 }}
//                 source={require("../../assets/png/MLS.png")}
//                 m="auto"
//                 mt={landscape ? vw(-350) : vh(-200)}
//                 // backgroundColor={"gray.200"}
//               />
//               <Text
//                 style={{
//                   fontFamily: "Poppins",
//                   fontWeight: "500",
//                   fontSize: vw(landscape ? 50 : 80),
//                   color: "#7d2be9",
//                   textAlign: "center",
//                   // fontWeight: "bold",
//                 }}
//                 // mt={landscape ? vw(-40) : vh(-30)}
//               >
//                 MLS Properties
//               </Text>
//               <Text
//                 width={"80%"}
//                 style={{
//                   color: "#303030",
//                   fontFamily: "Poppins",
//                   fontWeight: "300",
//                   fontSize: vw(landscape ? 40 : 45),
//                   // marginTop: landscape ? vw(15) : vh(5),
//                 }}
//                 justifyContent={"center"}
//                 alignItems={"center"}
//                 textAlign={"center"}
//               >
//                 Experience the world's top-of-the-line property management CRM
//                 and gain access to premium MLS properties with in-depth info to
//                 maximize your profits.
//               </Text>
//             </Center>
//           </Center>
//         </Center>
//         <Center
//           style={{ marginTop: vh(50), width: "100%", marginBottom: vh(80) }}
//         >
//           <Center
//             // style={{
//             //   width: "90%",
//             //   height: "min-content",
//             //   background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//             //   boxShadow: "15px 26px 70px #00000040",
//             //   borderWidth: 3,
//             //   borderColor: "#FFFFFF",
//             //   borderRadius: 40,
//             //   // padding: vh(20),
//             //   alignSelf: "center",
//             // }}
//             mt={landscape ? vw(350) : vh(200)}
//           >
//             <Center
//             // style={{
//             //   background:
//             //     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//             //   borderRadius: 40,
//             //   width: "100%",
//             //   height: "100%",
//             //   alignItems: "center",
//             //   paddingVertical: landscape ? vw(30) : vh(30),
//             // }}
//             >
//               <Image
//                 alt="driving for dollars"
//                 style={{
//                   width: landscape ? "60%" : "100%",
//                   aspectRatio: 1200 / 800,
//                 }}
//                 source={require("../../assets/png/market_auto.png")}
//                 m="auto"
//                 mt={landscape ? vw(-350) : vh(-200)}
//                 // backgroundColor={"gray.200"}
//               />
//               <Text
//                 style={{
//                   fontFamily: "Poppins",
//                   fontWeight: "500",
//                   fontSize: vw(landscape ? 50 : 70),
//                   color: "#7d2be9",
//                   textAlign: "center",
//                   // fontWeight: "bold",
//                 }}
//                 // mt={landscape ? vw(-40) : vh(-30)}
//               >
//                 Postcard and Email Automation
//               </Text>
//               <Text
//                 width={"80%"}
//                 style={{
//                   color: "#303030",
//                   fontFamily: "Poppins",
//                   fontWeight: "300",
//                   fontSize: vw(landscape ? 40 : 45),
//                   marginTop: landscape ? vw(15) : vh(5),
//                 }}
//                 justifyContent={"center"}
//                 alignItems={"center"}
//                 textAlign={"center"}
//               >
//                 Effortlessly communicate with property owners with campaign
//                 automation feature & send periodic postcards and emails — close
//                 50,000 property deals a month with reusable templates.
//               </Text>
//             </Center>
//           </Center>
//         </Center>
//         {/* <Text
//           style={{
//             fontFamily: "poppins",
//             fontSize: vw(landscape ? 50 : 80),
//             color: "#7D2BE9",
//             textAlign: "center",
//             marginTop: 35,
//           }}
//         >
//           Spotlight Features
//         </Text>
//         <Text
//           textAlign={"center"}
//           style={{
//             width: "90%",
//             color: "#6D549F",
//             fontFamily: "HelveticaNeueLTStd-Bd",
//             fontSize: vw(landscape ? 40 : 65),
//             alignSelf: "center",
//             // fontSize: vw(25),
//             // marginLeft: vw(40),
//             marginTop: vh(30),
//             // justifyContent: "center",
//           }}
//         >
//           Be greeted with astounding features that make your property management
//           process; a breeze.
//         </Text> */}
//       </Center>
//       {/* <CarouselProvider
//         naturalSlideWidth={5}
//         naturalSlideHeight={7}
//         totalSlides={3}
//         visibleSlides={3}
//         currentSlide={1}
//         touchEnabled={false}
//         dragEnabled={false}
//         interval={2000}
//         infinite
//       >
//         <Slider
//           style={{
//             alignItems: "center",
//             paddingLeft: landscape ? "37.5%" : "35%",
//             paddingRight: "10%",
//             marginTop: "5%",
//           }}
//         >
//           <Slide
//             style={{
//               justifyContent: "center",
//             }}
//             index={0}
//           >
//             <Center
//               style={{
//                 marginVertical: "12%",
//                 marginHorizontal: "-80%",
//                 // justifyContent: "space-evenly",
//                 // marginHorizontal: vh(landscape ? vh("10%") : vh("10%")),
//                 // marginHorizontal: "10%",
//                 // height: "90%",
//                 // width: "90%",
//                 height: vh(landscape ? 400 : 130), // newly changed
//                 width: vw(landscape ? 400 : 480), // newly changed
//                 background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//                 border: landscape ? "5px solid #FFFFFF" : "5px solid #FFFFFF",
//                 borderRadius: vw(20),
//                 boxShadow: "2px 2px 20px #00000033",
//               }}
//             >
//               <VStack
//                 style={{
//                   justifyContent: "center",
//                   alignItems: "center",
//                   width: "100%",
//                   height: "100%",
//                   borderRadius: vw(20),
//                   background:
//                     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//                 }}
//               >
//                 <Center
//                   style={{
//                     // height: vh(100),
//                     // width: vh(100),
//                     height: vh(landscape ? 90 : 40), // newly changed
//                     width: vw(landscape ? 70 : 100), // newly changed
//                     border: "3px solid #FFFFFF80",
//                     borderRadius: vh(10),
//                     background: "#8339FF 0% 0% no-repeat padding-box",
//                   }}
//                 >
//                   <EyeIcon width={vw(92)} height={vh(92)} fill="#FFE095" />
//                 </Center>
//                 <Text
//                   style={{
//                     marginTop: vh(10),
//                     color: "#FFFFFF",
//                     fontFamily: "HelveticaNeueLTStd-Md",
//                     fontSize: vw(landscape ? 25 : 30), // newly changed
//                     // fontSize: vw(72),
//                     textAlign: "center",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Accurate Driving Routes{"\n"}
//                   <Text fontSize={vw(landscape ? 18 : 30)} fontWeight="normal">
//                     Keep track of every driving route along with the miles
//                     driven, time taken, and properties added.
//                   </Text>
//                 </Text>
//               </VStack>
//             </Center>
//           </Slide>
//           <Slide
//             style={{
//               justifyContent: "center",
//             }}
//             index={1}
//           >
//             <Center
//               style={{
//                 marginVertical: "12%",
//                 height: vh(landscape ? 400 : 130), // newly changed
//                 width: vw(landscape ? 400 : 480), // newly changed
//                 background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//                 border: landscape ? "5px solid #FFFFFF" : "5px solid #FFFFFF",
//                 borderRadius: vw(20),
//                 boxShadow: "2px 2px 20px #00000033",
//               }}
//             >
//               <VStack
//                 style={{
//                   justifyContent: "center",
//                   alignItems: "center",
//                   width: "100%",
//                   height: "100%",
//                   borderRadius: vw(20),
//                   background:
//                     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//                 }}
//               >
//                 <Center
//                   style={{
//                     // height: vh(100),
//                     // width: vh(100),
//                     height: vh(landscape ? 90 : 40), // newly changed
//                     width: vw(landscape ? 70 : 100), // newly changed
//                     border: "3px solid #FFFFFF80",
//                     borderRadius: vh(10),
//                     background: "#8339FF 0% 0% no-repeat padding-box",
//                   }}
//                 >
//                   <SearchIcon width={92} height={92} fill="#FFE095" />
//                 </Center>
//                 <Text
//                   style={{
//                     marginTop: vh(10),
//                     color: "#FFFFFF",
//                     fontFamily: "HelveticaNeueLTStd-Md",
//                     fontSize: vw(landscape ? 25 : 30), // newly changed
//                     // fontSize: vw(72),
//                     textAlign: "center",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Campaign Automation{"\n"}
//                   <Text fontSize={vw(landscape ? 18 : 30)} fontWeight="normal">
//                     Create and Launch Mail/ E-mail campaigns and increase your
//                     odds of getting owner’s response
//                   </Text>
//                 </Text>
//               </VStack>
//             </Center>
//           </Slide>
//           <Slide
//             style={{
//               justifyContent: "center",
//             }}
//             index={1}
//           >
//             <Center
//               style={{
//                 marginVertical: "12%",
//                 // marginHorizontal: vw(landscape ? vw("10%") : vw("-80%")),
//                 marginHorizontal: "80%",
//                 // height: "90%",
//                 // width: "90%",
//                 height: vh(landscape ? 400 : 130), // newly changed
//                 width: vw(landscape ? 400 : 480), // newly changed
//                 background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//                 border: landscape ? "5px solid #FFFFFF" : "5px solid #FFFFFF",
//                 borderRadius: vw(20),
//                 boxShadow: "2px 2px 20px #00000033",
//               }}
//             >
//               <VStack
//                 style={{
//                   justifyContent: "center",
//                   alignItems: "center",
//                   width: "100%",
//                   height: "100%",
//                   borderRadius: vw(20),
//                   background:
//                     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
//                 }}
//               >
//                 <Center
//                   style={{
//                     // height: vh(100),
//                     // width: vh(100),
//                     height: vh(landscape ? 90 : 40), // newly changed
//                     width: vw(landscape ? 70 : 100), // newly changed

//                     border: "3px solid #FFFFFF80",
//                     borderRadius: vh(10),
//                     background: "#8339FF 0% 0% no-repeat padding-box",
//                   }}
//                 >
//                   <MoreIcon width={vw(92)} height={vh(92)} fill="#FFE095" />
//                 </Center>
//                 <Text
//                   style={{
//                     marginTop: vh(10),
//                     color: "#FFFFFF",
//                     fontFamily: "HelveticaNeueLTStd-Md",
//                     fontSize: vw(landscape ? 25 : 30), // newly changed
//                     // fontSize: vw(72),
//                     textAlign: "center",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Build Property List{"\n"}
//                   <Text fontSize={vw(landscape ? 18 : 30)} fontWeight="normal">
//                     Add and maintain 10000-50000 properties per month in your
//                     list
//                   </Text>
//                 </Text>
//               </VStack>
//             </Center>
//           </Slide>
//         </Slider>
//       </CarouselProvider>
//       <Box top={-30}>
//         <CarouselProvider
//           naturalSlideWidth={50} //{100}
//           naturalSlideHeight={100} // {160}
//           totalSlides={9}
//           visibleSlides={3}
//           currentSlide={0}
//           isIntrinsicHeight={false}
//           step={2}
//           isPlaying
//           interval={2000}
//           infinite
//         >
//           <Slider
//             style={{
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//             className={"carousel__slider1"}
//           >
//             {Object.keys(gifs).map((s) => {
//               return gifs[s].map((g, idx) => (
//                 <Slide
//                   key={"slide" + i}
//                   style={{
//                     justifyContent: "center",
//                     // margin: "auto",
//                   }}
//                   index={i++}
//                 >
//                   <Center
//                     alignSelf={"center"}
//                     style={{
//                       marginTop: landscape ? vh(85) : vh(25),

//                       background: "#FFFFFF4D 0% 0% no-repeat padding-box",
//                       boxShadow: "15px 26px 50px #00000026",
//                       border: "3px solid #FFFFFF",
//                       borderRadius: 45,
//                       // paddingVertical:window.innerWidth <= 900 ? vw(10) : vw(5),
//                       // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
//                       height: "min-content",
//                       width: "78%",
//                       padding: "2%",
//                       marginHorizontal: "12%",
//                     }}
//                   >
//                     <video
//                       style={{
//                         // width: vw(
//                         //   landscape
//                         //     ? platform === "android"
//                         //       ? 750
//                         //       : 850
//                         //     : platform === "android"
//                         //     ? 1090
//                         //     : 1090
//                         // ),
//                         // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
//                         width: "100%",
//                         height: "100%",
//                         backgroundColor: "transparent",
//                         borderRadius: 40,
//                         aspectRatio: 628 / 1367,
//                         // margin: vh(6),
//                       }}
//                       src={g.uri}
//                       loop
//                       autoPlay
//                       playsInline
//                       muted
//                       preload="auto"
//                     >
//                       <source src={g.uri} />
//                     </video>
//                   </Center>
//                 </Slide>
//               ));
//             })}
//           </Slider>
//           <Center style={{ marginTop: vh(landscape ? -150 : -50) }}>
//             <HStack
//               space={2}
//               style={{
//                 height: vh(60),
//                 // width: vw(100),
//                 alignItems: "center",
//                 alignSelf: "center",
//                 marginVertical: vh(5),
//               }}
//             >
//               {[...Array(9 / 3).keys()].map((d) => {
//                 return (
//                   <Dot
//                     key={"dot-" + d}
//                     className="dots"
//                     slide={d * 3}
//                     style={{
//                       // fontSize: 5,
//                       // width: vw(
//                       //   landscape
//                       //     ? platform === "android"
//                       //       ? false
//                       //       : false
//                       //     : platform === "android"
//                       //     ? 2
//                       //     : 10
//                       // ),
//                       height: vh(
//                         landscape
//                           ? platform === "android"
//                             ? 50
//                             : 50
//                           : platform === "android"
//                           ? 18
//                           : 15
//                       ),
//                       borderWidth: vw(
//                         landscape
//                           ? platform === "android"
//                             ? 5
//                             : 5
//                           : platform === "android"
//                           ? 6
//                           : 6
//                       ),
//                     }}
//                   />
//                 );
//                 // <DotGroup renderDots={"2"} className="dots" slide={d} />
//               })}
//             </HStack>
//           </Center>
//         </CarouselProvider>
//       </Box> */}
//     </VStack>
//   );
// };

// export default React.memo(Medium);

import React, { useState, useRef } from "react";
import {
  VStack,
  Center,
  Text,
  HStack,
  Stack,
  Box,
  Pressable,
  Divider,
  Image,
} from "native-base";
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import EyeIcon from "../../assets/svg/EyeIcon";
import SearchIcon from "../../assets/svg/SearchIcon";
import MoreIcon from "../../assets/svg/MoreIcon";

import DropDown from "../../assets/svg/DropDown";
import { vh, vw } from "../../util";
import CustomCarousel from "../../SPcarousel/CustomCarousel";
import PaginationDots from "../../pagination";
// this is for carosel to get the exact alignment for both ios and android
let platform = "web";
if (navigator.userAgent.includes("iPhone")) {
  platform = "ios";
} else if (navigator.userAgent.includes("Android")) {
  platform = "android";
}

const flow = [
  {
    step: 0,
    title: "Find & Add Properties",
    description:
      "Quickly find attractive properties based on the area code or address and drop a pin on the map to save them to your property list",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Adding_properties.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Adding_properties.webm",
  },
  {
    step: 1,
    title: "360° Property View",
    description:
      "This feature renders a 360° view of a property, enabling a virtually immersive and realistic experience—assess the property layout before scheduling a visit.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/MLS.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/MLS.webm",
  },
  {
    step: 2,
    title: "Skip Trace",
    description:
      "Access contact information like email address and phone number of property owner using the Skip Trace feature and add additional contacts as needed.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Smart_search.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Smart_search.webm",
  },
  {
    step: 3,
    title: "Choose the default address or email",
    description:
      "Set the provided address or add a new address as default to run campaigns successfully.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Setting_as_default_1.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Setting_as_default_1.webm",
  },
  {
    step: 4,
    title: "Signatures and Templates",
    description:
      "Establish unique signatures and templates for each active campaign to build authority in the marketplace",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Temp_Sign_.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Temp_Sign_.webm",
  },
  {
    step: 5,
    title: "Create a campaign",
    description:
      "Create automated campaigns and send scheduled emails and postal mails to property owners directly from the app.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Campaign1.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Campaign1.webm",
  },
  {
    step: 6,
    title: "List management",
    description:
      "Build custom property lists and attach a campaign that is automatically activated for each property within the list.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/list_management.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/list_management.webm",
  },
  {
    step: 7,
    title: "Pipeline",
    description:
      "Organize your properties based on their deal status and swiftly move them from one pipeline to another.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Pipeline_1.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Pipeline_1.webm",
  },
  {
    step: 8,
    title: "Live Team Drive - An Unrivaled Feature",
    description:
      "Easily monitor all the driving routes of your team members at once. You can also tap on individual members to follow their route.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Team_drive.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Team_drive.webm",
  },
  {
    step: 9,
    title: "Buyer contacts (Coming Soon)",
    description:
      "Automate sharing shortlisted properties with interested buyers based on their requirements and close deals.",
    uri:
      platform === "ios"
        ? "https://cdn.shadow.properties/shadow.properties/how-it-works/Buyers-Contact.mp4"
        : "https://cdn.shadow.properties/shadow.properties/how-it-works/Buyers-Contact.webm",
  },
];

const Medium = ({ landscape, gifs }) => {
  //console.log("Fold 2 Small Landscape", landscape);
  const [scrolledFold, setScrolledFold] = useState(1);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollValue = window.scrollY;
  //     if (isScrolled !== scrolled) {
  //       setScrolled(isScrolled);
  //     }
  //     const isScrolledUp = window.scrollY < 10;
  //     if (isScrolledUp !== scrolled) {
  //       setScrolled(!isScrolledUp);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [scrolled]);
  let i = 0;
  const HowItWorksFold = () => {
    let i = 0;
    const [isOpen, setOpen] = useState(false);
    const [step, setStep] = useState(0);
    // const ListItem = ({ item, step }) => {
    //   console.log(item);
    //   return (
    //     <Pressable
    //       // display={step === item.step ? "none" : "flex"}
    //       onPress={() => {
    //         setStep(item.step);
    //         setOpen(!isOpen);
    //       }}
    //     >
    //       <Text
    //         style={{
    //           textAlign: "left",
    //           fontFamily: "Poppins",
    //           fontWeight: "600",
    //           color: step === item.step ? "#7D2BE9" : "#988AB4",
    //         }}
    //         fontSize={vw(40)}
    //       >
    //         {item.title}
    //       </Text>
    //       {item.step !== 9 ? <Divider mt={1} /> : <></>}
    //     </Pressable>
    //   );
    // };
    const [totalSlides, setTotalSlides] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);
    const handleSlideChange = (currentIndex, totalSlides) => {
      console.log("Current slide index:", currentIndex);
      setCurrentSlide(currentIndex);
      console.log("Total slides:", totalSlides);
      setTotalSlides(totalSlides);
    };
    return (
      // <Center w={"100%"} mt={10}>
      //   <Center w={"100%"} zIndex={99} position={"absolute"} top={0}>
      //     <Pressable
      //       width={"100%"}
      //       justifyContent={"center"}
      //       alignItems={"center"}
      //       onPress={() => {
      //         setOpen(!isOpen);
      //       }}
      //     >
      //       <Center
      //         style={{
      //           background: "#FFFFFF 0% 0% no-repeat padding-box",
      //           border: "2px solid #7D2BE9",
      //           borderRadius: "50px",
      //           opacity: 1,
      //           width: "90%",
      //           padding: 5,
      //           paddingHorizontal: 0,
      //           margin: 20,
      //         }}
      //       >
      //         <HStack
      //           w={"90%"}
      //           justifyContent={"space-between"}
      //           py={2}
      //           padding={1.2}
      //         >
      //           <Box>
      //             <Text
      //               style={{
      //                 color: "#7D2BE9",
      //                 fontFamily: "Poppins",
      //                 fontWeight: "600",
      //               }}
      //               fontSize={vw(landscape ? 40 : 65)}
      //             >
      //               {flow[step].title}
      //             </Text>
      //           </Box>
      //           <Pressable
      //             onPress={() => {
      //               setOpen(!isOpen);
      //             }}
      //             style={{ justifyContent: "center", alignItems: "center" }}
      //           >
      //             <Box
      //               style={{
      //                 justifyContent: "center",
      //                 alignItems: "center",
      //                 transform: isOpen ? "rotate(180deg)" : "",
      //                 transition: "transform 150ms ease",
      //               }}
      //             >
      //               <DropDown fill={"#7D2BE9"} />
      //             </Box>
      //           </Pressable>
      //         </HStack>
      //         {isOpen ? (
      //           <VStack w={"90%"}>
      //             <Divider mt={2} />
      //             {[...Array(10).keys()].map(() => {
      //               let item = flow[i++];
      //               return (
      //                 <VStack m={1}>
      //                   <ListItem item={item} step={step} />
      //                 </VStack>
      //               );
      //             })}
      //           </VStack>
      //         ) : (
      //           <></>
      //         )}
      //       </Center>
      //     </Pressable>
      //   </Center>
      //   <Text
      //     style={{
      //       marginTop: landscape ? vh(350) : vw(280),
      //       width: "90%",
      //       textAlign: "center",
      //       margin: vw(80),
      //       color: "#303030",
      //       fontFamily: "Poppins",
      //       fontWeight: "300",
      //       fontSize: vw(landscape ? 40 : 65),
      //     }}
      //   >
      //     {flow[step].description}
      //   </Text>
      //   {/* <Center
      //       alignSelf={"center"}
      //       style={{
      //         background: "#FFFFFF4D 0% 0% no-repeat padding-box",
      //         // boxShadow: "1px 2.5px 10px #00000033",
      //         border: "3px solid #FFFFFF",
      //         borderRadius: 40,
      //         // width: vw(
      //         //   landscape
      //         //     ? platform === "android"
      //         //       ? 750
      //         //       : 900
      //         //     : platform === "android"
      //         //     ? 1100
      //         //     : 1100
      //         // ),
      //         // height: vw(
      //         //   landscape
      //         //     ? platform === "android"
      //         //       ? 750
      //         //       : 1950
      //         //     : platform === "android"
      //         //     ? 2300
      //         //     : 2300
      //         // ),
      //         width:"min-content",
      //         height:"min-content",
      //         marginHorizontal: vw(landscape ? 350 : 260),
      //         boxShadow: "2px 2px 20px #00000033",
      //       }}
      //     >
      //       <Image
      //         style={{
      //           // width: "100%",
      //           // height: "100%",
      //           width: vw(
      //             landscape
      //               ? platform === "android"
      //                 ? 750
      //                 : 850
      //               : platform === "android"
      //               ? 1100
      //               : 1100
      //           ),
      //           // height: vw(
      //           //   landscape
      //           //     ? platform === "android"
      //           //       ? 750
      //           //       : 2000
      //           //     : platform === "android"
      //           //     ? 2200
      //           //     : 2300
      //           // ),
      //           aspectRatio: 280/605,
      //           backgroundColor: "transparent",
      //           borderRadius: 40,
      //         }}
      //         margin={vh(10)}
      //         resizeMode="cover"
      //         source={{
      //           uri: flow[step].uri,
      //         }}
      //       />
      //     </Center> */}
      //   <Center
      //     // key={"gif-" + idx}
      //     style={{
      //       background: "#FFFFFF4D 0% 0% no-repeat padding-box",
      //       boxShadow: "15px 26px 70px #00000026",
      //       border: "3px solid #FFFFFF",
      //       borderRadius: 45,
      //       paddingVertical: window.innerWidth <= 900 ? vw(10) : vw(5),
      //       // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
      //       height: "min-content",
      //       width: "50%",
      //       // marginTop: vh(100),
      //     }}
      //   >
      //     {/* <Image
      //         // alt={gifs.alt}
      //         style={{
      //           width: vw(275),
      //           // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
      //           backgroundColor: "transparent",
      //           borderRadius: vw(10),
      //           aspectRatio: 280 / 605,
      //           margin: vw(6),
      //         }}
      //         source={urls[selected-1]}
      //       /> */}
      //     <video
      //       style={{
      //         // width: vw(
      //         //   landscape
      //         //     ? platform === "android"
      //         //       ? 750
      //         //       : 850
      //         //     : platform === "android"
      //         //     ? 1100
      //         //     : 1100
      //         // ),
      //         width: "95%",
      //         // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
      //         backgroundColor: "transparent",
      //         borderRadius: 40,
      //         aspectRatio: 280 / 605,
      //         margin: vh(6),
      //       }}
      //       src={flow[step].uri}
      //       loop
      //       autoPlay
      //       playsInline
      //       muted
      //       preload="auto"
      //     >
      //       <source src={flow[step].uri} />
      //     </video>
      //   </Center>
      // </Center>
      <Center w={"100vw"} mt={10} marginY={"20%"}>
        <CustomCarousel
          animation="sliding"
          onSlideChange={handleSlideChange}
          autoplay={true}
          interval={8000}
          loop={true}
          style={{
            width: "100%",
            height: "min-content",
          }}
          showArrows={false}
        >
          {flow.map((item, idx) => {
            return (
              <Center
                width={"100%"}
                height={"min-content"}
                // backgroundColor={`amber.${idx}00`}
              >
                <Center
                  style={{
                    width: "85%",
                    height: landscape ? "50vh" : "35vh",
                    // background: "#FFFFFF4D 0% 0% no-repeat padding-box",
                    backgroundColor: "white",
                    borderWidth: 3,
                    borderColor: "#FFFFFF",
                    borderRadius: 40,
                    alignSelf: "center",
                  }}
                >
                  <Center
                    style={{
                      background:
                        "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
                      borderRadius: 35,
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      width={"80%"}
                      style={{
                        fontFamily: "Poppins",
                        // fontWeight: "500",
                        fontSize: vw(landscape ? 50 : 100),
                        color: "white",
                        textAlign: "center",
                        // fontWeight: "bold",
                      }}
                    >
                      {item.title}
                    </Text>
                    <Text
                      width={"80%"}
                      style={{
                        color: "#ffffff",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        fontSize: vw(landscape ? 40 : 65),
                        lineHeight: "auto",
                      }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      textAlign={"center"}
                    >
                      {item.description}
                    </Text>
                  </Center>
                </Center>
                <Center
                  // key={"gif-" + idx}
                  mt={"10%"}
                  marginY={"25%"}
                  style={{
                    background: "#FFFFFF4D 0% 0% no-repeat padding-box",
                    boxShadow: "15px 26px 70px #00000026",
                    border: "3px solid #FFFFFF",
                    borderRadius: 45,
                    // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
                    height: "min-content",
                    // marginTop: vh(100),
                  }}
                >
                  <video
                    style={{
                      width: vw(
                        landscape
                          ? platform === "android"
                            ? 750
                            : 850
                          : platform === "android"
                          ? 1100
                          : 1100
                      ),
                      // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
                      backgroundColor: "transparent",
                      borderRadius: 40,
                      aspectRatio: 280 / 605,
                      margin: vh(6),
                    }}
                    src={item.uri}
                    loop
                    autoPlay
                    playsInline
                    muted
                    preload="auto"
                  >
                    <source src={item.uri} />
                  </video>
                </Center>
              </Center>
            );
          })}
        </CustomCarousel>

        <Box
          mt={vw(landscape ? -300 : -300)}
          style={{ transform: "scale(200%)" }}
        >
          <PaginationDots total={10} currentIndex={currentSlide} />
        </Box>
      </Center>
    );
  };

  return (
    <VStack
      style={{
        background: `linear-gradient(90deg, rgba(234,215,249,1) 0%, rgba(250,246,254,1) 25%, rgba(255,255,255,1) 50%, rgba(255,247,227,1) 75%, rgba(255,244,217,1) 100%)`,
        justifyContent: "center",
      }}
    >
      <Center style={{ marginTop: vh(50) }}>
        {/* <Center
          backgroundColor={"rgba(125, 43, 233, 1)"}
          style={{ width: "100%" }}
          mt={landscape ? false : vh(150)}
          borderRadius={25}
          padding={5}
        >
          <Image
            alt="driving for dollars"
            style={{
              width: landscape ? "60%" : "80%",
              aspectRatio: 1200 / 800,
            }}
            source={require("../../assets/png/ShadowMobiles.png")}
            m="auto"
            mt={landscape ? false : vh(-150)}
          />
          <Text
            style={{
              fontFamily: "poppins",
              fontSize: vw(landscape ? 50 : 80),
              color: "white",
              textAlign: "center",
            }}
          >
            Features Spotlight
          </Text>
          <Text
            width={"80%"}
            style={{
              color: "white",
              fontFamily: "HelveticaNeueLTStd-Bd",
              fontSize: vw(landscape ? 40 : 65),
              marginTop: vh(30),
            }}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            A smart real estate mobile and web app designed for nationwide
            Property Finding, Investment Tracking, Marketing Automation, and
            Buyer Management with unparalleled features and performance.
          </Text>
        </Center> */}
        <Center
          style={{
            width: "95%",
            height: "min-content",
            background: "#FFFFFF4D 0% 0% no-repeat padding-box",
            boxShadow: "15px 26px 70px #00000040",
            borderWidth: 3,
            borderColor: "#FFFFFF",
            borderRadius: 40,
            // padding: vh(20),
            alignSelf: "center",
          }}
          mt={landscape ? vw(350) : vh(200)}
        >
          <Center
            style={{
              background:
                "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
              borderRadius: 40,
              width: "100%",
              height: "100%",
              alignItems: "center",
              paddingVertical: vh(30),
            }}
          >
            <Image
              alt="driving for dollars"
              style={{
                width: landscape ? "60%" : "100%",
                aspectRatio: 1200 / 800,
              }}
              source={require("../../assets/png/ShadowMobiles.png")}
              m="auto"
              mt={landscape ? vw(-350) : vh(-200)}
              // backgroundColor={"gray.200"}
            />
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: vw(landscape ? 50 : 100),
                color: "white",
                textAlign: "center",
                // fontWeight: "bold",
              }}
              mt={landscape ? vw(-40) : vh(-30)}
            >
              Features Spotlight
            </Text>
            <Text
              width={"80%"}
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: vw(landscape ? 40 : 65),
                marginTop: vh(10),
                lineHeight: "auto",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              // mt={landscape ? vw(-40) : vh(-30)}
            >
              A smart real estate mobile and web app designed for nationwide
              Property Finding, Investment Tracking, Marketing Automation, and
              Buyer Management with unparalleled features and performance.
            </Text>
          </Center>
        </Center>
        <HowItWorksFold />
        {/* <Text
          style={{
            fontFamily: "poppins",
            fontSize: vw(landscape ? 50 : 80),
            color: "#7D2BE9",
            textAlign: "center",
            marginTop: 35,
          }}
        >
          Spotlight Features
        </Text>
        <Text
          textAlign={"center"}
          style={{
            width: "90%",
            color: "#6D549F",
            fontFamily: "HelveticaNeueLTStd-Bd",
            fontSize: vw(landscape ? 40 : 65),
            alignSelf: "center",
            // fontSize: vw(25),
            // marginLeft: vw(40),
            marginTop: vh(30),
            // justifyContent: "center",
          }}
        >
          Be greeted with astounding features that make your property management
          process; a breeze.
        </Text> */}
      </Center>
      <Center style={{ marginTop: vh(-50), width: "100%" }}>
        {/* <Image
          alt="driving for dollars"
          style={{
            width: "60%",
            aspectRatio: 1200 / 800,
          }}
          source={require("../../assets/png/ShadowMobiles.png")}
          ml="auto"
        />
        <Text
          style={{
            fontFamily: "poppins",
            fontSize: vw(landscape ? 50 : 80),
            color: "#7D2BE9",
            textAlign: "center",
          }}
        >
          Fully Featured Mobile App
        </Text>
        <Text
          width={"80%"}
          style={{
            color: "#988AB4",
            fontFamily: "HelveticaNeueLTStd-Bd",
            fontSize: vw(landscape ? 40 : 65),
            marginTop: vh(30),
          }}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          Don’t wait till you reach your office; close million-dollar deals from
          your couch using a full-fledged mobile app and stay lightyears ahead
          of your competition.
        </Text> */}
        <Center
          // style={{
          //   width: "95%",
          //   height: "min-content",
          //   background: "#FFFFFF4D 0% 0% no-repeat padding-box",
          //   boxShadow: "15px 26px 70px #00000040",
          //   borderWidth: 3,
          //   borderColor: "#FFFFFF",
          //   borderRadius: 40,
          //   // padding: vh(20),
          //   alignSelf: "center",
          // }}
          mt={landscape ? vw(350) : vh(200)}
        >
          <Center
          // style={{
          //   background:
          //     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
          //   borderRadius: 40,
          //   width: "100%",
          //   height: "100%",
          //   alignItems: "center",
          //   paddingVertical: vh(30),
          // }}
          >
            <Image
              alt="driving for dollars"
              style={{
                width: landscape ? "60%" : "100%",
                aspectRatio: 1600 / 1200,
              }}
              source={require("../../assets/png/fully_featured.png")}
              m="auto"
              mt={landscape ? vw(-350) : vh(-200)}
              // backgroundColor={"gray.200"}
            />
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: vw(landscape ? 50 : 100),
                color: "#7d2be9",
                textAlign: "center",
                // fontWeight: "bold",
              }}
              // mt={landscape ? vw(-40) : vh(-30)}
            >
              Fully Featured Mobile App
            </Text>
            <Text
              width={"80%"}
              style={{
                color: "#303030",
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: vw(landscape ? 40 : 65),
                marginTop: vh(10),
                lineHeight: "auto",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              Don’t wait till you reach your office; close million-dollar deals
              from your couch using a full-fledged mobile app and stay
              lightyears ahead of your competition.
            </Text>
          </Center>
        </Center>
      </Center>
      <Center style={{ marginTop: vh(50), width: "100%" }}>
        <Center
          // style={{
          //   width: "95%",
          //   height: "min-content",
          //   // background: "#FFFFFF4D 0% 0% no-repeat padding-box",
          //   boxShadow: "15px 26px 70px #00000040",
          //   // borderWidth: 3,
          //   // borderColor: "#FFFFFF",
          //   borderRadius: 40,
          //   // padding: vh(20),
          //   alignSelf: "center",
          // }}
          mt={landscape ? vw(350) : vh(200)}
        >
          <Center
          // style={{
          //   // background:
          //   //   "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
          //   backgroundColor:"white",
          //   borderRadius: 40,
          //   width: "100%",
          //   height: "100%",
          //   alignItems: "center",
          //   paddingVertical: vh(30),
          // }}
          >
            <Image
              alt="driving for dollars"
              style={{
                width: landscape ? "60%" : "100%",
                aspectRatio: 1600 / 1200,
              }}
              source={require("../../assets/png/Driving-for-dollars.png")}
              m="auto"
              mt={landscape ? vw(-350) : vh(-200)}
              // backgroundColor={"gray.200"}
            />
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: vw(landscape ? 50 : 100),
                color: "#7d2be9",
                textAlign: "center",
                // fontWeight: "bold",
              }}
              // mt={landscape ? vw(-40) : vh(-30)}
            >
              Driving for Dollars
            </Text>
            <Text
              width={"80%"}
              style={{
                color: "#303030",
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: vw(landscape ? 40 : 65),
                marginTop: vh(10),
                lineHeight: "auto",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              Let your team drive to the best properties nearby with the most
              advanced and accurate driving for dollars feature using our
              formidable mobile app. Monitor them all from one screen and
              provide instructions. Driving for dollars allows you to track
              every member from one screen and magnify them to follow individual
              directions.
            </Text>
          </Center>
        </Center>
      </Center>
      <Center style={{ marginTop: vh(50), width: "100%" }}>
        <Center
          // style={{
          //   width: "95%",
          //   height: "min-content",
          //   background: "#FFFFFF4D 0% 0% no-repeat padding-box",
          //   boxShadow: "15px 26px 70px #00000040",
          //   borderWidth: 3,
          //   borderColor: "#FFFFFF",
          //   borderRadius: 40,
          //   // padding: vh(20),
          //   alignSelf: "center",
          // }}
          mt={landscape ? vw(350) : vh(200)}
        >
          <Center
          // style={{
          //   background:
          //     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
          //   borderRadius: 40,
          //   width: "100%",
          //   height: "100%",
          //   alignItems: "center",
          //   paddingVertical: vh(30),
          // }}
          >
            <Image
              alt="driving for dollars"
              style={{
                width: landscape ? "60%" : "100%",
                aspectRatio: 1600 / 1200,
              }}
              source={require("../../assets/png/MLS.png")}
              m="auto"
              mt={landscape ? vw(-350) : vh(-200)}
              // backgroundColor={"gray.200"}
            />
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: vw(landscape ? 50 : 100),
                color: "#7d2be9",
                textAlign: "center",
                // fontWeight: "bold",
              }}
              mt={landscape ? vw(-40) : vh(-30)}
            >
              MLS Properties
            </Text>
            <Text
              width={"80%"}
              style={{
                color: "#303030",
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: vw(landscape ? 40 : 65),
                marginTop: vh(10),
                lineHeight: "auto",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              Experience the world's top-of-the-line property management CRM and
              gain access to premium MLS properties with in-depth info to
              maximize your profits.
            </Text>
          </Center>
        </Center>
      </Center>
      <Center
        style={{ marginTop: vh(50), width: "100%", marginBottom: vh(80) }}
      >
        <Center
          // style={{
          //   width: "95%",
          //   height: "min-content",
          //   background: "#FFFFFF4D 0% 0% no-repeat padding-box",
          //   boxShadow: "15px 26px 70px #00000040",
          //   borderWidth: 3,
          //   borderColor: "#FFFFFF",
          //   borderRadius: 40,
          //   // padding: vh(20),
          //   alignSelf: "center",
          // }}
          mt={landscape ? vw(350) : vh(200)}
        >
          <Center
          // style={{
          //   background:
          //     "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
          //   borderRadius: 40,
          //   width: "100%",
          //   height: "100%",
          //   alignItems: "center",
          //   paddingVertical: vh(30),
          // }}
          >
            <Image
              alt="driving for dollars"
              style={{
                width: landscape ? "60%" : "100%",
                aspectRatio: 1600 / 1200,
              }}
              source={require("../../assets/png/market_auto.png")}
              m="auto"
              mt={landscape ? vw(-350) : vh(-200)}
              // backgroundColor={"gray.200"}
            />
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: vw(landscape ? 50 : 100),
                color: "#7d2be9",
                textAlign: "center",
                // fontWeight: "bold",
                width: "80%",
              }}
              mt={landscape ? vw(-40) : vh(-30)}
            >
              Postcard and Email Automation
            </Text>
            <Text
              width={"80%"}
              style={{
                color: "#303030",
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: vw(landscape ? 40 : 65),
                marginTop: vh(10),
                lineHeight: "auto",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              Effortlessly communicate with property owners with campaign
              automation feature & send periodic Postcards and Emails - close
              50,000 property deals a month with reusable templates.
            </Text>
          </Center>
        </Center>
      </Center>
      {/* <Stack zIndex={999} marginTop={5}>
        <CarouselProvider
          naturalSlideWidth={120}
          naturalSlideHeight={landscape ? 70 : 100}
          totalSlides={3}
          visibleSlides={1}
          currentSlide={1}
          interval={2000}
          infinite
        >
          <Slider
            style={{
              paddingLeft: landscape ? "14.5%" : "9.5%",
              paddingRight: landscape ? "11%" : "6%",
              zIndex: 99,
            }}
          >
            <Slide
              style={{
                justifyContent: "center",
              }}
              index={0}
            >
              <Center
                style={{
                  marginTop: vh(landscape ? 70 : 30), // newly changed
                  marginVertical: "4%",
                  marginHorizontal: vw(landscape ? 100 : 30),
                  width: "min-content",
                  background: "#FFFFFF4D 0% 0% no-repeat padding-box",
                  borderWidth: 3,
                  borderColor: "#FFFFFF",
                  borderRadius: 20,
                  boxShadow: "2px 2px 20px #00000033",
                  zIndex: 99,
                }}
              >
                <VStack
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: vw(landscape ? 900 : 1170),
                    padding: 15,
                    borderRadius: 14,
                    background:
                      "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
                    margin: 5,
                    height: landscape
                      ? vw(500)
                      : window.innerHeight < 650
                      ? vh(250)
                      : vh(220),
                  }}
                >
                  <Center
                    style={{
                      width: 45,
                      height: 45,
                      border: "2px solid #FFFFFF80",
                      borderRadius: 10,
                      background: "#8339FF 0% 0% no-repeat padding-box",
                      marginBottom: "auto",
                    }}
                  >
                    <EyeIcon width={vw(100)} height={vh(100)} fill="#FFE095" />
                  </Center>
                  <Text
                    style={{
                      marginTop: vh(12), // new change
                      color: "#FFFFFF",
                      fontFamily: "HelveticaNeueLTStd-Md",
                      fontSize: vw(landscape ? 55 : 62),
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Accurate Driving Routes{"\n"}
                    <Text
                      style={{
                        fontSize: vw(landscape ? 35 : 42),
                        fontWeight: "none",
                      }}
                    >
                      Keep track of every driving route along with the miles
                      driven, time taken, and properties added.
                    </Text>
                  </Text>
                </VStack>
              </Center>
            </Slide>
            <Slide
              style={{
                justifyContent: "center",
              }}
              index={1}
            >
              <Center
                style={{
                  marginTop: vh(landscape ? 70 : 30), // newly changed
                  marginVertical: "4%",
                  marginHorizontal: vw(landscape ? 100 : 30),
                  width: "min-content",
                  background: "#FFFFFF4D 0% 0% no-repeat padding-box",
                  borderWidth: 3,
                  borderColor: "#FFFFFF",
                  borderRadius: 20,
                  boxShadow: "2px 2px 20px #00000033",
                  zIndex: 99,
                }}
              >
                <VStack
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: vw(landscape ? 900 : 1170),
                    padding: 15,
                    borderRadius: 14,
                    background:
                      "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
                    margin: 5,
                    height: landscape
                      ? vw(500)
                      : window.innerHeight < 650
                      ? vh(250)
                      : vh(220),
                  }}
                >
                  <Center
                    style={{
                      width: 45,
                      height: 45,
                      border: "2px solid #FFFFFF80",
                      borderRadius: 10,
                      background: "#8339FF 0% 0% no-repeat padding-box",
                      marginBottom: "auto",
                    }}
                  >
                    <SearchIcon width={92} height={92} fill="#FFE095" />
                  </Center>
                  <Text
                    style={{
                      marginTop: vh(12), // new change
                      color: "#FFFFFF",
                      fontFamily: "HelveticaNeueLTStd-Md",
                      fontSize: vw(landscape ? 55 : 62),
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Campaign Automation{"\n"}
                    <Text
                      style={{
                        fontSize: vw(landscape ? 35 : 42),
                        fontWeight: "none",
                      }}
                    >
                      Create and Launch Mail/ E-mail campaigns and increase your
                      odds of getting owner’s response
                    </Text>
                  </Text>
                </VStack>
              </Center>
            </Slide>
            <Slide
              style={{
                justifyContent: "center",
              }}
              index={1}
            >
              <Center
                style={{
                  marginTop: vh(landscape ? 70 : 30), // newly changed
                  marginVertical: "4%",
                  marginHorizontal: vw(landscape ? 100 : 30),
                  width: "min-content",
                  background: "#FFFFFF4D 0% 0% no-repeat padding-box",
                  borderWidth: 3,
                  borderColor: "#FFFFFF",
                  borderRadius: 20,
                  boxShadow: "2px 2px 20px #00000033",
                  zIndex: 99,
                }}
              >
                <VStack
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: vw(landscape ? 900 : 1170),
                    padding: 15,
                    borderRadius: 14,
                    background:
                      "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
                    margin: 5,
                    height: landscape
                      ? vw(500)
                      : window.innerHeight < 650
                      ? vh(250)
                      : vh(220),
                  }}
                >
                  <Center
                    style={{
                      width: 45,
                      height: 45,
                      border: "2px solid #FFFFFF80",
                      borderRadius: 10,
                      background: "#8339FF 0% 0% no-repeat padding-box",
                      marginBottom: "auto",
                    }}
                  >
                    <MoreIcon width={vw(92)} height={vh(92)} fill="#FFE095" />
                  </Center>
                  <Text
                    style={{
                      marginTop: vh(12), // new change
                      color: "#FFFFFF",
                      fontFamily: "HelveticaNeueLTStd-Md",
                      fontSize: vw(landscape ? 55 : 62),
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Build Property List{"\n"}
                    <Text
                      style={{
                        fontSize: vw(landscape ? 35 : 42),
                        fontWeight: "none",
                      }}
                    >
                      Add and maintain 10000-50000 properties per month in your
                      list{"\n"}
                    </Text>
                  </Text>
                </VStack>
              </Center>
            </Slide>
          </Slider>
        </CarouselProvider>
      </Stack>
      <Stack top={landscape ? "" : vh(-80)}>
        <CarouselProvider
          naturalSlideWidth={10} //{100}
          naturalSlideHeight={18} // {160}
          totalSlides={9}
          visibleSlides={1}
          currentSlide={0}
          isIntrinsicHeight={false}
          isPlaying
          interval={2000}
          infinite
        >
          <Slider className={"carousel__slider1"}>
            {Object.keys(gifs).map((s) => {
              //console.log(typeof s, "typeee");
              return gifs[s].map((g, idx) => (
                <Slide
                  key={"slide" + i}
                  style={{
                    justifyContent: "center",
                    // margin: "auto",
                    marginTop: landscape ? vw(40) : vh(25),
                  }}
                  index={i++}
                >
                  <Center>
                    <Center
                      // key={"gif-" + idx}
                      style={{
                        background: "#FFFFFF4D 0% 0% no-repeat padding-box",
                        boxShadow: "15px 26px 70px #00000026",
                        border: "3px solid #FFFFFF",
                        borderRadius: 45,
                        // paddingVertical:window.innerWidth <= 900 ? vw(10) : vw(5),
                        // paddingHorizontal: window.innerWidth <= 1440 ? vh(10) : vh,
                        height: "min-content",
                        width: "min-content",
                        marginTop: vh(30),
                      }}
                    >
                      <video
                        style={{
                          width: vw(
                            landscape
                              ? platform === "android"
                                ? 600
                                : 850
                              : platform === "android"
                              ? 1090
                              : 1090
                          ),
                          // height: window.innerWidth <= 1440 ? vh(650) : vh(790),
                          backgroundColor: "transparent",
                          borderRadius: 40,
                          aspectRatio: 280 / 605,
                          margin: vh(6),
                        }}
                        src={g.uri}
                        loop
                        autoPlay
                        playsInline
                        muted
                        preload="auto"
                      >
                        <source src={g.uri} />
                      </video>
                    </Center>
                  </Center>
                </Slide>
              ));
            })}
          </Slider>
          <Center
            style={{
              marginTop: vw(
                landscape
                  ? platform === "android"
                    ? -1400
                    : -950
                  : platform === "android"
                  ? -400
                  : -400
              ), // newly changed
            }}
          >
            <HStack
              space={2}
              style={{
                height: vh(100),
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              {[...Array(i).keys()].map((d) => (
                <Dot
                  key={"dot-" + d}
                  className="dots"
                  slide={d}
                  style={{
                    fontSize: 5,
                    width: vw(
                      landscape
                        ? platform === "android"
                          ? false
                          : false
                        : platform === "android"
                        ? 2
                        : 10
                    ),
                    height: vw(
                      landscape
                        ? platform === "android"
                          ? 35
                          : 35
                        : platform === "android"
                        ? 60
                        : 55
                    ),
                    borderWidth: vw(
                      landscape
                        ? platform === "android"
                          ? 5
                          : 5
                        : platform === "android"
                        ? 6
                        : 6
                    ),
                  }}
                />
              ))}
            </HStack>
          </Center>
        </CarouselProvider>
      </Stack> */}
    </VStack>
  );
};

export default React.memo(Medium);
