import * as React from "react";
const CallsTexts = (props) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <rect
          width={24}
          height={24}
          style={{
            fill: "none",
            strokeWidth: 0,
          }}
        />
      </clipPath>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g
        style={{
          clipPath: "url(#clippath)",
        }}
      >
        <g id="Layer_23" data-name="Layer 23">
          <path
            d="m12.49,20.61c-.89,0-1.76-.23-2.51-.69-1.27-.8-2.43-1.74-3.47-2.82-1.06-1.04-1.99-2.21-2.78-3.47-1.17-1.87-.9-4.3.67-5.86l.66-.65c.73-.69,1.88-.69,2.62,0l1.49,1.49c.72.71.74,1.86.03,2.59,0,0-.02.02-.03.03l-.71.71c-.24.25-.24.65,0,.9l2.33,2.33c.25.24.65.24.9,0l.73-.73c.73-.72,1.91-.72,2.65,0l1.49,1.49c.72.71.74,1.86.03,2.59,0,0-.02.02-.03.03l-.66.66c-.89.91-2.11,1.42-3.38,1.41ZM6.36,7.48c-.24,0-.48.08-.66.25l-.66.69c-1.27,1.26-1.49,3.22-.54,4.73.75,1.21,1.64,2.32,2.65,3.31,1,1.01,2.11,1.91,3.32,2.66,1.51.95,3.47.72,4.73-.54l.66-.66c.18-.17.28-.41.27-.66,0-.25-.1-.49-.27-.66l-1.49-1.49c-.37-.35-.95-.35-1.32,0l-.71.71c-.6.6-1.58.6-2.19,0l-2.34-2.34c-.6-.6-.6-1.58,0-2.19l.71-.71c.18-.18.27-.42.27-.66,0-.25-.1-.48-.27-.66l-1.49-1.52c-.18-.17-.42-.25-.66-.25Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <path
            d="m16.06,11.99c-.79,0-1.57-.16-2.29-.49-.91.42-1.93.57-2.93.43-.19-.03-.35-.17-.39-.36-.04-.19.05-.39.22-.49.5-.29.91-.71,1.18-1.22-.45-.65-.7-1.42-.7-2.21,0-2.37,2.21-4.27,4.93-4.27s4.93,1.93,4.93,4.27-2.23,4.34-4.94,4.34h0Zm-2.08-1.41c.64.33,1.36.5,2.08.49,2.21,0,4.01-1.52,4.01-3.38s-1.8-3.4-4.01-3.4-4.01,1.51-4.01,3.38c0,.69.25,1.35.68,1.88.1.14.13.32.06.48-.16.35-.36.69-.61.98.42-.06.83-.17,1.22-.35l.2-.1c.05-.03.11-.05.17-.05,0,0,.15.04.21.08Z"
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <circle
            cx={13.93}
            cy={7.67}
            r={0.5}
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <circle
            cx={16.06}
            cy={7.67}
            r={0.5}
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
          <circle
            cx={18.18}
            cy={7.67}
            r={0.5}
            style={{
              fill: "#fff",
              strokeWidth: 0,
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default CallsTexts;
