// import React from "react";
// import {
//   VStack,
//   Center,
//   Text,
//   HStack,
//   Hidden,
//   Link as NLink,
//   Link,
// } from "native-base";

// import Googleplay from "../../assets/svg/GooglePlay";
// import Applestore from "../../assets/svg/AppleStore";

// import { vh, vw } from "../../util";

// const Small = ({ landscape, gifs }) => {
//   //console.log("Fold 3 Small Landscape", landscape);
//   return (
//     <Hidden from={"md"}>
//       <Center margin={vh(40)}>
//         <VStack
//           style={{
//             background: "#7D2BE9 0% 0% no-repeat padding-box",
//             borderRadius: vw(50),
//             height: landscape ? vw(950) : "min-content",
//             width: "89vw", // newly changed
//             alignSelf: "center",
//           }}
//         >
//           <Center>
//             <Text
//               marginTop={vw(landscape ? 100 : 150)} // newly changed
//               // width={vw(landscape ? 780 : 900)} // newly changed
//               fontSize={vw(landscape ? 60 : 105)} // newly changed
//               lineHeight={vw(landscape ? 70 : 150)} // newly changed
//               fontFamily={"poppins"}
//               color="white"
//               textAlign={"center"}
//             >
//               The Only Real Estate Management App You Need…
//             </Text>
//             <Text
//               width="85%"
//               fontSize={vw(landscape ? 34 : 60)} // newly changed
//               lineHeight={vw(landscape ? 65 : 120)} // newly changed
//               fontFamily={"HelveticaNeueLTStd-Roman"}
//               color={"white"}
//               textAlign={"center"}
//               marginTop={vh(26)}
//               backgroundColor="red.100"
//               padding={vw(20)}
//             >
//               Shadow App is the future of real estate investments. Our
//               easy-to-use real estate CRM streamlines every aspect of your
//               business by bringing prominent features at your fingertips. It
//               permits you access to eminent properties, contact their owners,
//               close deals, and make money. Our App is suitable for individuals
//               and teams who believe in the power of real estate; the number of
//               deals you close, will change your life.{"\n"}
//               <Link href="https://web.shadow.properties/">
//                 <Text fontSize={vw(landscape ? 49 : 75)} fontWeight={"bold"}>
//                   Let's Get Started{"\n"} with a 14-day No Card Free Trial
//                 </Text>
//               </Link>
//             </Text>
//           </Center>

//           <HStack
//             space={vh(40)}
//             style={{
//               // justifyContent: "space-evenly",
//               //   width: vw(350),
//               alignSelf: "center",
//               marginTop: landscape ? 0 : -20,
//             }}
//           >
//             <NLink href="https://apps.apple.com/us/app/shadowproperties/id1491176685/">
//               <Applestore
//                 //   width={vw(450)}
//                 //   height={vh(54)}
//                 width={vw(landscape ? 350 : 460)}
//                 height={vh(landscape ? 105 : 200)}
//               />
//             </NLink>
//             <NLink
//               href="https://play.google.com/store/apps/details?id=and.app.properties.shadow"
//               isExternal
//             >
//               <Googleplay
//                 // width={vw(450)}
//                 // height={vh(54)}
//                 width={vw(landscape ? 300 : 480)}
//                 // height={vh(landscape ? 105 : 200)}
//               />
//             </NLink>
//           </HStack>
//         </VStack>
//       </Center>
//     </Hidden>
//   );
// };

// export default Small;

import React from "react";
import {
  VStack,
  Center,
  Text,
  HStack,
  Hidden,
  Link as NLink,
  Link,
  Image,
  Pressable,
  Box,
} from "native-base";

import Googleplay from "../../assets/svg/GooglePlay";
import Applestore from "../../assets/svg/AppleStore";

import apple from "../../assets/svg/apple.svg";
import android from "../../assets/svg/google play.svg";

import { vh, vw } from "../../util";
import DroidSVG from "../../assets/svg/Droid";
import AppleSVG from "../../assets/svg/AppleSvg";
import WebSVG from "../../assets/svg/Web";

const Small = ({ landscape, sendIp }) => {
  //console.log("Fold 3 Small Landscape", landscape);
  return (
    <Hidden from={"md"}>
      <Center margin={vh(40)}>
        <VStack
          style={{
            background: "#7D2BE9 0% 0% no-repeat padding-box",
            borderRadius: vw(50),
            height: "min-content",
            width: "89vw", // newly changed
            alignSelf: "center",
          }}
        >
          <Center>
            <Text
              marginTop={vw(landscape ? 100 : 150)} // newly changed
              // width={vw(landscape ? 780 : 900)} // newly changed
              fontSize={vw(landscape ? 50 : 100)} // newly changed
              lineHeight={vw(landscape ? 70 : 150)} // newly changed
              fontFamily={"Poppins"}
              fontWeight={500}
              color="white"
              textAlign={"center"}
            >
              The Only Real Estate Management App You Need…
            </Text>
            <Text
              width="85%"
              fontSize={vw(landscape ? 40 : 65)} // newly changed
              lineHeight={vw(landscape ? 65 : 120)} // newly changed
              fontFamily={"Poppins"}
              fontWeight={300}
              color={"white"}
              textAlign={"center"}
              marginTop={vh(26)}
              backgroundColor="red.100"
              padding={vw(20)}
            >
              Shadow App is the future of real estate investments. Our
              easy-to-use real estate CRM streamlines every aspect of your
              business by bringing prominent features at your fingertips. It
              permits you access to eminent properties, contact their owners,
              close deals, and make money. Our App is suitable for individuals
              and teams who believe in the power of real estate; the number of
              deals you close, will change your life.{"\n"}
              <Text
                fontSize={vw(landscape ? 49 : 75)}
                mt={5}
                fontWeight={"bold"}
              >
                Let's Get Started{"\n"} with a 14-day No Card Free Trial
              </Text>
            </Text>
          </Center>

          {/* <HStack
            space={vh(40)}
            style={{
              // justifyContent: "space-evenly",
              //   width: vw(350),
              alignSelf: "center",
              marginTop: landscape ? 0 : -20,
            }}
          >
            <NLink
              onPress={() => {
                sendIp(
                  "https://apps.apple.com/us/app/shadowproperties/id1491176685/",
                  "I"
                );
              }}
              // href='https://apps.apple.com/us/app/shadowproperties/id1491176685/'
            >
              <Applestore
                //   width={vw(450)}
                //   height={vh(54)}
                width={vw(landscape ? 350 : 460)}
                height={vh(landscape ? 105 : 200)}
              />
            </NLink>
            <NLink
              onPress={() => {
                sendIp(
                  "https://play.google.com/store/apps/details?id=and.app.properties.shadow"
                );
              }}
              // href='https://play.google.com/store/apps/details?id=and.app.properties.shadow'
              // isExternal
            >
              <Googleplay
                // width={vw(450)}
                // height={vh(54)}
                width={vw(landscape ? 300 : 480)}
                // height={vh(landscape ? 105 : 200)}
              />
            </NLink>
          </HStack> */}
          {/* <HStack
            style={{ justifyContent: "space-evenly", marginVertical: vh(20) }}
          >
            <Image src={ios} style={{ width: "130px", height: "42px" }} />
            <Image src={android} style={{ width: "145px", height: "42.5px" }} />
          </HStack> */}
          <Pressable alignSelf={"center"} onPress={sendIp}>
            <VStack
            rounded={"full"}
              style={{
                // borderRadius: vw(40),
                // height: vw(230),
                width: vw(1000),
                // shadowColor: "#7D2BE959",
                // shadowOffset: { width: 0, height: 15 },
                // marginHorizontal:2,
                // shadowOpacity: 0.7,
                // shadowRadius: 40,
                background: "#F9F1FD 0% 0% no-repeat border-box",
                borderWidth: 3,
                borderColor: "#FFFFFF",
                transition: "box-shadow 0.3s ease-in-out",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: vh(62),
                alignSelf: "start",
              }}
            >
              <Text fontSize={vw(landscape ? 35 : 53)} color={"#6D549F"}>
                GET IT ON
              </Text>

              <HStack
                margin={vw(5)}
                width={"50%"}
                justifyContent={"space-around"}
              >
                <Box
                  rounded={"full"}
                  padding={vw(5)}
                  bg={"#7D2BE9"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <DroidSVG />
                </Box>
                <Box
                  rounded={"full"}
                  padding={vw(2)}
                  bg={"#7D2BE9"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box style={{ transform: "scale(0.85)" }}>
                    <AppleSVG />
                  </Box>
                </Box>
                <Box
                  rounded={"full"}
                  padding={vw(2)}
                  bg={"#7D2BE9"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <WebSVG />
                </Box>
              </HStack>
            </VStack>
          </Pressable>
        </VStack>
      </Center>
    </Hidden>
  );
};

export default Small;
