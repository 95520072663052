import React, { useState } from "react";
import { Box, Divider, Stack, VStack, Text } from "native-base";
import { StyleSheet } from "react-native";
import Faq from "react-faq-component";
import { vh, vw } from "../../util";
const data = {
  rows: [
    {
      title: `What are Credits?`,
      content: `Credits are our in-app currency equivalent to US dollars, used for Skip Trace, Postcards, Street View Property Picture, and 360° street view. When you signup, you get a 14-day free trial and 3 free credits. Your remaining credits will be carried forward if you upgrade to a Solo or a Team plan. Later, users have to manually purchase additional credits according to their needs!`,
    },
    {
      title: `What are the available plans?`,
      content: `We have three plans available as of now, a Team plan for $299, a Solo plan for $99, and a Free plan for $0.`,
    },
    {
      title: `What are the limitations of a Free plan?`,
      content: `Our Free Plan gives you only 100 properties for a lifetime. Moreover, the free credits won't roll over from the trial period if you opt for a free plan. Also, the credit usage for Skip Trace and 360-degree property view is slightly higher than for paid accounts.`,
    },
    {
      title: `What payment methods are accepted?`,
      content: `We currently accept VISA and Mastercard credit cards. However, as we proceed, more payment methods will be availed. Stay Tuned!`,
    },
    {
      title: `What platform is Shadow available on?`,
      content: `Shadow App is available on every major platform, including Android, iOS, and the web.`,
    },
    {
      title: `What does support include?`,
      content: `We are always available for our customers. You can contact us 24x5 via email or have a live chat with us regarding any technical issues or questions related to your account or the software.`,
    },
    {
      title: `Does Shadow integrate with third-party systems?`,
      content: `Currently, Shadow is a stand-alone system that works seamlessly on its own. However, our team is working hard to bring you the flexibility of integrating the app with other third-party software.`,
    },
    {
      title: `Are there any hidden charges or setup fees`,
      content: `There are no hidden charges or setup fees. We are building a reliable and robust system that offers complete transparency to its users. You only pay for the monthly subscription and credits.`,
    },
    {
      title: ` Can I pay quarterly or annually?`,
      content: `Currently, we only have monthly plans, $99 for individuals and $299 for team accounts, respectively. We might bring forth a quarterly or annual plan in the future.`,
    },
    {
      title: `Is my data safe?`,
      content: `Absolutely! At Shadow, we take matters of data security very seriously. We are hosted on the highly reliable cloud partners Amazon AWS, DigitalOcean, and Microsoft Azure servers, which promise optimal uptime and data security for all our customer’s data. 
     Our hosting partner’s servers are hosted in world-class data centers that are protected by biometric locks and 24-hour surveillance. Furthermore, we ensure that our application is constantly updated with the latest security patches. Finally, we use SSL encryption to keep your data safe at all times.`,
    },
    {
      title: `Can I cancel my account at any time?`,
      content: `Yes, you can cancel your subscription at any time, but please note that your subscription will remain active until the next billing date. We offer no refunds of any kind once the payments are processed.`,
    },
    {
      title: `How to Invite more than 5 members to my team account?`,
      content: `Our available team account supports up to 5 members. We also have an enterprise plan for those with larger team sizes. To avail of the enterprise plan, contact our team via email from the “contact us” page.`,
    },
  ],
};

const Small = ({ landscape }) => {
  const [rows, setRowsOption] = useState(null);
  return (
    <>
      <VStack bg={"#ffffff"} safeAreaTop>
        <Box mt={20}>
          <Text style={styless.title}>FAQs</Text>
          <Divider style={styless.titleDivider}></Divider>
        </Box>
        <Stack style={styless.faqStyleBody}>
          <Box style={styless.faqStyleWrapper}>
            <Faq
              data={data}
              styles={styles}
              config={config}
              getRowOptions={setRowsOption}
            />
          </Box>
        </Stack>
        <Box padding={vh(10)} />
      </VStack>
    </>
  );
};
export default React.memo(Small);

const styless = StyleSheet.create({
  title: {
    fontSize: 30,
    marginTop: vh(4),
    color: "#5011a2",
    alignSelf: "center",
    fontFamily: "poppins",
  },
  titleDivider: {
    marginTop: vh(1),
    width: vw(280),
    alignSelf: "center",
    backgroundColor: "#e62e00",
  },
  faqStyleBody: {
    marginTop: vh(6),
    alignItems: "center",
  },
  faqStyleWrapper: {
    width: vw(1200),
    fontFamily: "poppins",
  },
});

const styles = {
  rowTitleColor: "#7D2BE9",
  rowContentColor: "#808080",
  arrowColor: "#7D2BE9",
  rowTitleTextSize: "0.938rem",
  rowContentTextSize: "0.75rem",
  rowContentPaddingBottom: "0.313rem",
  rowContentPaddingRight: "1rem",
  timingFunc: "ease",
  // transitionDuration: "1s",
};

const config = {
  animate: true,
  tabFocus: true,
  // openOnload: 0,
  // expandIcon: "+",
  // collapseIcon: "-",
};
