import React, { useEffect, useRef, useState } from "react";
import {
  VStack,
  HStack,
  Stack,
  Center,
  Text,
  Pressable,
  Box,
  Heading,
  ScrollView,
  Link,
} from "native-base";

import { StyleSheet } from "react-native";

import { vw, vh } from "../../util";
import Okok from "../../assets/svg/Okok";
import PaginationDots from "../../pagination";
import { options } from "./pricingData";
import { FeaturesColumn } from "./tableComponents";
import { additionalFeatureList, basicFeaturesList } from "./pricingData";
const Small = ({ landscape, sendIp }) => {
  var [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth, landscape]);
  // var width = window.innerWidth;
  // useEffect(() => {
  //   width = window.innerWidth;
  // }, [window.innerWidth]);
  const scrollref = useRef();
      const [selectionHeight, setSelectionHeight] = useState(null);
      const [shownProducts, setShownProducts] = useState({
        0: 0, // Set initial indices for each column
        1: 1,
        2: 4,
      });
  const [index, setIndex] = useState(1);
  // console.log("Fold 5 small Landscape", landscape);
  //console.log(vw(100), vh(100));
  const TickText = (props) => {
    return (
      <HStack paddingTop={width < 300 ? 1 : width * 0.02}>
        {/* <Icon as={<Okok />} size={5} backgroundColor={"pink.200"} /> */}
        <Box boxSize={"20px"}>
          <Okok />
        </Box>
        <Text
          ml={2}
          color={"#303030"}
          fontFamily={"Poppins"}
          fontSize={vw(landscape ? 40 : 65)}
          fontWeight={300}
        >
          {props.children}
        </Text>
      </HStack>
    );
  };

  const handleScroll = (e) => {
    console.log(e);
    if (e < window.innerWidth / 3) {
      setIndex(1);
    }
    if (e > window.innerWidth / 3 && e < 2 * window.innerWidth) {
      setIndex(2);
    }
    if (e >= 2 * window.innerWidth) {
      setIndex(3);
    }
  };

  return (
    <VStack
      style={{
        height: "min-content",
        width: "100%",
        background: "rgb(135, 207, 255)",
        background: `linear-gradient(0deg, rgba(135,207,255,1) 0%, rgba(255,244,254,1) 80%)`,
        backgroundSize: "cover",
      }}
    >
      <Center>
        <Heading
          fontSize={vw(landscape ? 65 : 130)}
          color="rgb(125,43,233)"
          fontFamily={"Poppins"}
          fontWeight={600}
          padding={vh(20)}
          paddingTop={vh(55)}
        >
          Choose a plan
        </Heading>
        <Text
          style={{
            color: "#303030",
            fontFamily: "Poppins",
            fontSize: vw(landscape ? 40 : 65),

            width: "80%",
            textAlign: "center",
          }}
          paddingBottom={vh(20)}
          my={vw(20)}
        >
          The only intuitive, affordable, and fully-featured app for both mobile
          and web with zero hidden fees. Try to differ after trying.
        </Text>
        <Box
          borderRadius={"30"}
          alignSelf={"center"}
          borderStyle={"solid"}
          backgroundColor={"white"}
          // borderWidth={2}
          // borderColor={"rgb(125,43,233)"}
        >
          <HStack justifyContent={"center"} alignItems={"center"}>
            <Pressable
              onPress={() => {
                scrollref.current.scrollTo({ x: 0 });
              }}
            >
              <Box style={index == 1 ? Styles.blueBg : Styles.whiteBg}>
                <Center>
                  <Text
                    color={index == 1 ? "white" : "rgb(125,43,233)"}
                    fontWeight={index == 1 ? 500 : 400}
                    mt={index == 1 ? -0.5 : false}
                  >
                    Free
                  </Text>
                </Center>
              </Box>
            </Pressable>
            <Pressable
              onPress={() => {
                scrollref.current.scrollTo({ x: window.innerWidth });
              }}
            >
              <Box style={index == 2 ? Styles.blueBg : Styles.whiteBg}>
                <Center>
                  <Text
                    color={index == 2 ? "white" : "rgb(125,43,233)"}
                    fontWeight={index == 2 ? 500 : 400}
                    mt={index == 2 ? -0.5 : false}
                  >
                    Solo
                  </Text>
                </Center>
              </Box>
            </Pressable>
            <Pressable
              onPress={() => {
                scrollref.current.scrollTo({
                  x: window.innerWidth * 2,
                  behaviour: "instant",
                });
              }}
            >
              <Box style={index == 3 ? Styles.blueBg : Styles.whiteBg}>
                <Center>
                  <Text
                    color={index == 3 ? "white" : "rgb(125,43,233)"}
                    fontFamily={"Poppins"}
                    fontWeight={index == 3 ? 500 : 400}
                    mt={index == 3 ? -0.5 : false}
                  >
                    Team
                  </Text>
                </Center>
              </Box>
            </Pressable>
          </HStack>
        </Box>
        <Box width={width} maxH={"100%"} overflow={"auto"}>
          <ScrollView
            maxH={"100%"}
            horizontal
            onScroll={(e) => {
              handleScroll(e.nativeEvent.contentOffset.x);
            }}
            scrollEventThrottle={40}
            ref={scrollref}
            showsHorizontalScrollIndicator={false}
            snapToAlignment="center"
            decelerationRate={"fast"}
            // disableIntervalMomentum
            height={"min-content "}

            // pagingEnabled
          >
            <HStack
              // marginX={width > 600 ? width * 0.06 : 0}
              width={"300vw"}
              mb={"1.5vh"}
              justifyContent={"space-evenly"}
              style={{ scrollSnapType: "x mandatory" }}
            >
              <Box
                style={[Styles.styledbox]}
                m={8}
                // height={height * 0.7}
                minH={"90%"}
                height={"min-content"}
                width={landscape ? width * 0.5 : width * 0.75}
                overflow={"auto"}
              >
                <Box margin={8} ml={10}>
                  <HStack>
                    <Text
                      fontSize={30}
                      color={"rgb(125,43,233)"}
                      fontFamily={"Poppins"}
                      fontWeight={500}
                    >
                      $0
                    </Text>
                  </HStack>
                  <Text
                    m={3}
                    color={"rgb(152,138,180)"}
                    fontFamily={"Poppins"}
                    fontWeight={300}
                  >
                    (Try for free till you ❤️ it)
                  </Text>
                  <TickText>1 User</TickText>
                  <TickText>100 Properties</TickText>
                  <TickText>+100 properties for every referral</TickText>
                  <TickText>Driving Routes</TickText>
                  <TickText>4*6 Mail = $1.00</TickText>
                  <TickText>360° Street View = $0.01/View</TickText>
                  <TickText>
                    Street View Property Picture = $0.01/Picture
                  </TickText>
                  <TickText>Skip Trace = $0.2/Match</TickText>
                  <TickText>Email Support</TickText>
                </Box>
                <Center mt={-2} mb={5}>
                  <Box>
                    <Center
                      style={{
                        background:
                          "linear-gradient(320deg, rgb(125,43,233) 40% , rgb(172,116,248) 80%)", //rgb(125,43,233)
                        backgroundSize: "cover",
                        padding: vh(15),
                        borderRadius: vh(50),
                        boxShadow: "0px 10px 10px 0px rgba(125,43,233,0.2)",
                      }}
                    >
                      <Link onPress={sendIp}>
                        <Text
                          color={"white"}
                          textAlign={"center"}
                          mx={6}
                          pt={0.5}
                          fontFamily={"Poppins"}
                          fontWeight={300}
                        >
                          Get it for Free!
                        </Text>
                      </Link>
                    </Center>
                  </Box>
                </Center>
              </Box>
              <Box
                style={[Styles.styledbox]}
                m={8}
                // height={height * 0.7}
                minH={"90%"}
                height={"min-content"}
                width={landscape ? width * 0.5 : width * 0.75}
                overflow={"auto"}
              >
                <Box margin={8} ml={10}>
                  <HStack>
                    <Text
                      fontSize={30}
                      color={"rgb(125,43,233)"}
                      fontFamily={"Poppins"}
                      fontWeight={500}
                    >
                      $99
                    </Text>
                    <Text
                      m={3}
                      color={"rgb(152,138,180)"}
                      fontFamily={"Poppins"}
                      fontWeight={300}
                    >
                      (Monthly)
                    </Text>
                  </HStack>
                  <TickText>1 User</TickText>
                  <TickText>10000 Properties/month</TickText>
                  <TickText>Driving Routes</TickText>
                  <TickText>1 Credit = $1.00</TickText>
                  <TickText>4*6 Mail = $0.75</TickText>
                  <TickText>360° Street View = $0.007/View</TickText>
                  <TickText>
                    Street View Property Picture = $0.007/Picture
                  </TickText>
                  <TickText>Skip Trace = $0.1/Match</TickText>
                  <TickText>Feature Requests</TickText>
                  <TickText>Email Support</TickText>
                  <TickText>Live Chat Support</TickText>
                </Box>
                <Center mt={"auto"} mb={5}>
                  <Box>
                    <Center
                      style={{
                        background:
                          "linear-gradient(320deg, rgb(125,43,233) 40% , rgb(172,116,248) 80%)", //rgb(125,43,233)
                        backgroundSize: "cover",
                        padding: vh(15),
                        borderRadius: vh(50),
                        boxShadow: "0px 10px 10px 0px rgba(125,43,233,0.2)",
                      }}
                    >
                      <Link onPress={sendIp}>
                        <Text
                          color={"white"}
                          textAlign={"center"}
                          mx={6}
                          pt={0.5}
                          fontFamily={"Poppins"}
                          fontWeight={300}
                        >
                          Subscribe for Solo
                        </Text>
                      </Link>
                    </Center>
                  </Box>
                </Center>
              </Box>
              <Box
                style={Styles.styledbox}
                // m={10}
                // ml={20}
                m={8}
                // height={height * 0.7}
                minH={"90%"}
                height={"min-content"}
                width={landscape ? width * 0.5 : width * 0.75}
                // height={height * 0.7}
                overflow={"auto"}
                // width={width * 0.7}
              >
                <Box margin={8} ml={10}>
                  <HStack>
                    <Text
                      fontSize={30}
                      color={"rgb(125,43,233)"}
                      fontFamily={"Poppins"}
                      fontWeight={500}
                    >
                      $299
                    </Text>
                    <Text
                      m={3}
                      color={"rgb(152,138,180)"}
                      fontFamily={"HelveticaNeueLTStd-Md"}
                      fontWeight={300}
                    >
                      (Monthly)
                    </Text>
                  </HStack>
                  <TickText>5 Users</TickText>
                  <TickText>50000 Properties/month</TickText>
                  <TickText>Live Driving Monitor</TickText>
                  <TickText>1 Credit = $1.00</TickText>
                  <TickText>4*6 Mail = $0.75</TickText>
                  <TickText>360° Street View = $0.007/View</TickText>
                  <TickText>
                    Street View Property Picture = $0.007/Picture
                  </TickText>
                  <TickText>Skip Trace = $0.1/Match</TickText>
                  <TickText>Feature Requests</TickText>
                  <TickText>Email Support</TickText>
                  <TickText>Live Chat Support</TickText>
                </Box>
                <Center mt={"auto"} mb={5}>
                  <Box>
                    <Center
                      style={{
                        background:
                          "linear-gradient(320deg, rgb(125,43,233) 40% , rgb(172,116,248) 80%)", //rgb(125,43,233)
                        backgroundSize: "cover",
                        padding: vh(15),
                        borderRadius: vh(50),
                        boxShadow: "0px 10px 10px 0px rgba(125,43,233,0.2)",
                      }}
                    >
                      <Link onPress={sendIp}>
                        <Text
                          color={"white"}
                          textAlign={"center"}
                          mx={5}
                          pt={0.5}
                          fontFamily={"Poppins"}
                          fontWeight={300}
                        >
                          Subscribe for Team
                        </Text>
                      </Link>
                    </Center>
                  </Box>
                </Center>
              </Box>
            </HStack>
          </ScrollView>
        </Box>
        {/* <HStack
          alignSelf="center"
          space="2"
          padding={vh(20)}
          mb={vh(20)}
          height={"3vh"}
        >
          <Stack style={index == 1 ? Styles.mystyle : Styles.mystyle1}>
            <Text> </Text>
          </Stack>
          <Stack style={index == 2 ? Styles.mystyle : Styles.mystyle1}>
            <Text> </Text>
          </Stack>
          <Stack style={index == 3 ? Styles.mystyle : Styles.mystyle1}>
            <Text> </Text>
          </Stack>
        </HStack> */}
        <Box>
          <PaginationDots total={3} currentIndex={index - 1} />
        </Box>
        <ScrollView horizontal width={"100%"}>
          <Center
            style={{
              width: "1366px",
              minHeight: "100px",
            }}
          >
            <HStack
              style={{
                width: "94.44%",
                borderRadius: "20px",
                background: "#F8F3FF",
                padding: "8px",
              }}
            >
              <Center
                style={{
                  width: "17.75%",
                  // backgroundColor: "pink",
                }}
              >
                <VStack
                  style={{
                    width: "75.98%",
                    height: "100%",
                    // backgroundColor: "orange",
                    paddingVertical: "30px",
                    gap: "5px",
                  }}
                >
                  <Center
                    style={{
                      borderRadius: "12px",
                      // padding: "17px",
                      background:
                        "linear-gradient(302deg, #7D2BE5 21.24%, #BB85FF 84.48%)",
                      height: `${selectionHeight}px`,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "24px",
                      }}
                    >
                      Basic Features
                    </Text>
                  </Center>
                  {basicFeaturesList.map((item, index) => (
                    <Center
                      style={{
                        borderRadius: "10px",
                        border: "1px solid transparent",
                        background: "transparent",
                        minHeight: "46px",
                        paddingHorizontal: "8px",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          color: "#382058",
                          textAlign: "right",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                        }}
                      >
                        {item}
                      </Text>
                    </Center>
                  ))}
                  <Center style={{ height: "136px" }}>
                    <Center
                      style={{
                        borderRadius: "12px",
                        padding: "17px",
                        background:
                          "linear-gradient(302deg, #7D2BE5 21.24%, #BB85FF 84.48%)",
                        height: `${selectionHeight}px`,
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          color: "#FFF",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "24px",
                        }}
                      >
                        Additional Features
                      </Text>
                    </Center>
                  </Center>
                  {additionalFeatureList.map((item, index) => (
                    <Center
                      style={{
                        borderRadius: "10px",
                        border: "1px solid transparent",
                        background: "transparent",
                        minHeight: "46px",
                        paddingHorizontal: "8px",
                      }}
                    >
                      <Text
                        style={{
                          width: "100%",
                          color: "#382058",
                          textAlign: "right",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                        }}
                      >
                        {item}
                      </Text>
                    </Center>
                  ))}
                </VStack>
              </Center>
              <HStack
                style={{
                  width: "82.25%",
                  borderRadius: "20px",
                  background: "#FFF",
                  padding: "25px",
                  gap: "1.89%",
                }}
              >
                <FeaturesColumn
                  cardIndex={0}
                  shownProducts={shownProducts}
                  setShownProducts={setShownProducts}
                  options={options}
                  setSelectionHeight={setSelectionHeight}
                  defaultOnly={true}
                />
                <FeaturesColumn
                  cardIndex={1}
                  shownProducts={shownProducts}
                  setShownProducts={setShownProducts}
                  options={options}
                  // setSelectionHeight={setSelectionHeight}
                  // options={options.filter(val=>val!==index)}
                />
                <FeaturesColumn
                  cardIndex={2}
                  shownProducts={shownProducts}
                  setShownProducts={setShownProducts}
                  options={options}
                  // setSelectionHeight={setSelectionHeight}
                />
              </HStack>
            </HStack>
          </Center>
        </ScrollView>
        {/* <Box
          backgroundColor={"rgb(125, 43, 233)"}
          // py={"5px"}
          // px={"10px"}
          rounded={"lg"}
          mb={"10px"}
          width={"90%"}
        >
          <Text
            m={5}
            color={"white"}
            fontFamily={"Poppins"}
            fontWeight={300}
            // fontSize={18}
            textAlign={"center"}
          >
            Expand Your Team size by subscribing to the Custom Enterprise plan.{" "}
            <Link href="/#contact">Contact Us!</Link>
          </Text>
        </Box> */}
        <Text
          m={5}
          color={"black"}
          fontFamily={"Poppins"}
          fontWeight={300}
          fontSize={vw(landscape ? 40 : 65)}
          textAlign={"center"}
        >
          Expand Your Team size by subscribing to the Custom Enterprise plan.{" "}
          <Link href="/#contact">Contact Us!</Link>
        </Text>
      </Center>
    </VStack>
  );
};

export default React.memo(Small);

const Styles = StyleSheet.create({
  // styledbox: {
  //   backgroundColor: "white",
  //   shadowColor: "#7D2BE959",
  //   shadowRadius: 30,
  //   padding: 10,
  //   height: height * 0.8,
  //   width: width * 0.8,
  //   borderRadius: 40,
  //   opacity: 1,
  // },
  whiteBg: {
    backgroundColor: "transparent",
    variant: "unstyled",
    borderRadius: 30,
    padding: 10,
    width: 110,
    height: 40,
    // transition: "all 0.2s ease-in-out",
  },
  blueBg: {
    backgroundColor: "rgb(125,43,233)",
    variant: "unstyled",
    borderRadius: 30,
    padding: 10,
    width: 105,
    height: 35,
    alignItems: "center",
    marginHorizontal: 5,
    // transition: "all 0.2s ease-in-out",
  },
  mystyle: {
    borderRadius: 50,
    borderWidth: 2,
    width: 12,
    height: 12,
    shadow: 2,
    alignSelf: "center",
    borderColor: "#7D2BE9",
    opacity: "100%",
    transition: "all 0.2s ease-in-out",
  },
  mystyle1: {
    borderRadius: 50,
    borderWidth: 4.5,
    width: 5,
    height: 4,
    backgroundColor: "#7D2BE9",
    alignSelf: "center",
    borderColor: "#7D2BE9",
    opacity: "30%",
    transition: "all 0.2s ease-in-out",
  },
  styledbox: {
    backgroundColor: "white",
    shadowColor: "#7D2BE959",
    shadowRadius: 20,
    shadowOffset: { width: 0, height: 15 },
    borderRadius: 40,
    opacity: 1,
    scrollSnapAlign: "center",
  },
  Button: {
    padding: 10,
    borderRadius: 30,
  },
});
