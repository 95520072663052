import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const ParcelLookup = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={35}
    viewBox="0 0 33 35"
    accessibilityRole="image"
    {...props}
  >
    <G
      id="Parcel_Lookup"
      data-name="Parcel Lookup"
      transform="translate(-267.407 -183.979)"
    >
      <Path
        id="Path_28"
        data-name="Path 28"
        d="M271.971,195.232a.865.865,0,0,1,.864-.866H288.75a.865.865,0,0,1,0,1.731H272.836a.865.865,0,0,1-.865-.865Zm.864,5.325H288.75a.866.866,0,0,0,0-1.731H272.836a.866.866,0,0,0,0,1.731Zm0,4.459H288.75a.866.866,0,0,0,0-1.732H272.836a.866.866,0,0,0,0,1.732Zm26.143,11.389a6.66,6.66,0,0,1-4.472,2.524,6.71,6.71,0,0,1-6.927-3.906H268.272a.865.865,0,0,1-.865-.865V184.845a.865.865,0,0,1,.865-.866H286.4a.862.862,0,0,1,.62.263l6.911,7.109a.867.867,0,0,1,.246.6v13.607a6.718,6.718,0,0,1,4.8,10.843Zm-11.711-25.456h3.86l-3.86-3.972Zm-.2,22.341c-.011-.073-.022-.146-.03-.22h0a6.69,6.69,0,0,1,.552-3.6H272.836a.865.865,0,0,1,0-1.731h15.9a6.683,6.683,0,0,1,3.714-2.078V192.681H286.4a.865.865,0,0,1-.864-.866V185.71h-16.4V213.29Zm9.7-4.954a4.991,4.991,0,1,0,1.9,3.328,4.973,4.973,0,0,0-1.9-3.328Zm-.474,1.565-2.98,2.983-1.665-1.667a.866.866,0,1,0-1.224,1.225l2.277,2.28a.863.863,0,0,0,1.223,0l3.592-3.6h0a.866.866,0,0,0-1.223-1.225Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default ParcelLookup;
