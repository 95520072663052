import React from "react";
import { Stack, useBreakpointValue, useMediaQuery } from "native-base";

import Medium from "./Medium";
import Small from "./Small";
import Large from "./Large";

const isAppleOrMac = /(Macintosh|iPad|iPhone|iPod)/i.test(navigator.userAgent);
const gifs = {
  1: [
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Driving.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Driving.webm",
      alt: "skip tracing for real estate in usa",
    },
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Adding-single-property.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Adding-single-property.webm",
      alt: "wholesaling houses in usa",
    },
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Bulk-add.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Bulk-add.webm",
      alt: "wholesaling in real estate in usa",
    },
  ],
  2: [
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Creating-Pipeline.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Creating-Pipeline.webm",
      alt: "crm real estate software in usa",
    },
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Adding-contacts.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Adding-contacts.webm",
      alt: "investment property managementin usa",
    },
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Moving-Contact.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Moving-Contact.webm",
      alt: "investing in properties in usa",
    },
  ],
  3: [
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Property-Info.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Property-Info.webm",
      alt: "lead generation in real estate",
    },
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Property-Details.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Property-Details.webm",
      alt: "real estate softwares",
    },
    {
      uri:
        isAppleOrMac
          ? "https://cdn.shadow.properties/shadow.properties/spotlight-features/Campaign.mp4"
          : "https://cdn.shadow.properties/shadow.properties/spotlight-features/Campaign.webm",
      alt: "investing in homes",
    },
  ],
};

const FoldTwo = () => {
  const sz = useBreakpointValue({
    base: "base",
    lg: "lg",
    xl: "xl",
    md: "md",
    sm: "sm",
  });

  const [landscape] = useMediaQuery([
    {
      orientation: "landscape",
    },
    {
      orientation: "portrait",
    },
  ]);

  return (
    <Stack name="fold2" className="element">
      {/* for small devices */}
      {(sz === "base" || sz === "sm") && (
        <Small landscape={landscape} sz={sz} gifs={gifs} />
      )}
      {/* for medium devices */}
      {sz === "md" && (
        <Medium
          landscape={landscape}
          isAppleOrMac={isAppleOrMac}
          sz={sz}
          gifs={gifs}
        />
      )}
      {/* for large devices */}
      {(sz === "lg" || sz === "xl") && (
        <Large landscape={landscape} sz={sz} gifs={gifs} />
      )}
    </Stack>
  );
};

export default React.memo( FoldTwo);
