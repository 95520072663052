import React, { useEffect, useRef, useState } from "react";
import {
  VStack,
  HStack,
  Stack,
  Center,
  Text,
  Pressable,
  Box,
  Heading,
  Icon,
  ScrollView,
  Link,
} from "native-base";
import { options } from "./pricingData";

import { StyleSheet } from "react-native";

import { vw, vh } from "../../util";

import Okok from "../../assets/svg/Okok";
import { FeaturesColumn } from "./tableComponents";
import { additionalFeatureList, basicFeaturesList } from "./pricingData";
//fold one small
const Large = ({ landscape, sendIp }) => {
  var width = window.innerWidth;
  console.log(vw(40));
  useEffect(() => {
    width = window.innerWidth;
  }, [window.innerWidth]);
  const selectionRef = useRef();
  const [selectionHeight, setSelectionHeight] = useState(null);
  const [shownProducts, setShownProducts] = useState({
    0: 0, // Set initial indices for each column
    1: 1,
    2: 4,
  });
  useEffect(() => {
    console.log(shownProducts, "shownProducts");
  }, [shownProducts]);
  const [index, setIndex] = useState(1);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  //console.log("Fold 5 Large Landscape", landscape);
  //console.log(vw(100), vh(100));

  const TickText = (props) => {
    return (
      <HStack
        paddingTop={window.innerWidth < 300 ? 1 : window.innerHeight * 0.02}
      >
        <Icon as={<Okok />} size={vw(21)} />
        <Text
          ml={vw(8)}
          fontSize={vw(15)}
          fontWeight={300}
          fontFamily={"Poppins"}
          color={"black"}
        >
          {props.children}
        </Text>
      </HStack>
    );
  };

  const handleScroll = (e) => {
    //console.log(e);
    if (e < 180) {
      setIndex(1);
    }
    if (e >= 180) {
      setIndex(2);
    }
  };
  return landscape ? (
    <VStack
      style={{
        height: "min-content",
        width: "100%",
        background: "rgb(135, 207, 255)",
        background: `linear-gradient(0deg, rgba(135,207,255,1) 0%, rgba(255,244,254,1) 80%)`,
        backgroundSize: "cover",
        paddingVertical: vw(22),
      }}
    >
      <Center>
        <Text
          // marginTop={vh(50)}
          fontFamily={"Poppins"}
          fontWeight={"600"}
          fontSize={vw(58)}
          color={"#7D2BE9"}
        >
          Choose a Plan
        </Text>
        <Text
          // style={{
          //   color: "#6D549F",
          //   fontFamily: "HelveticaNeueLTStd-Bd",
          //   fontSize: vw(18),
          // }}
          // marginLeft={5}
          fontSize={vw(15)}
          fontFamily="Poppins"
          fontWeight={300}
          color={"black"}
          lineHeight={vw(24)}
        >
          The only intuitive, affordable, and fully-featured app for both mobile
          and web with zero hidden fees. Try to differ after trying.
        </Text>
        <Box m={vw(42)} width={width * 0.8}>
          <HStack justifyContent={"space-evenly"}>
            <Pressable
              onHoverIn={() => {
                setActive1(true);
              }}
              onHoverOut={() => {
                setActive1(false);
              }}
            >
              <Box
                // mr={"auto"}
                style={active1 ? Styles.styledbox : Styles.unstyledbox}
                width={width * 0.24}
                minH={"100%"}
              >
                <Box m={vw(42)} pl={vw(25)}>
                  <HStack alignItems={"flex-end"} alignContent={"flex-end"}>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                      // mb={20.78}
                    >
                      Free {"\n"}
                    </Text>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                    >
                      $0{" "}
                    </Text>
                  </HStack>
                  <Text
                    // width={vw(500)}
                    fontSize={vw(15)}
                    fontFamily="Poppins"
                    fontWeight={300}
                    color={"black"}
                    lineHeight={vw(26)}
                    marginBottom={vw(4)}
                  >
                    (Try for free till you ❤️ it)
                  </Text>
                  <TickText>1 User</TickText>
                  <TickText>100 Properties</TickText>
                  <TickText>+100 properties for every referral</TickText>
                  <TickText>Driving Routes</TickText>
                  <TickText>4*6 Mail = $1.00</TickText>
                  <TickText>360° Street View = $0.01/View</TickText>
                  <TickText>
                    Street View Property Picture = $0.01/Picture
                  </TickText>
                  <TickText>Skip Trace = $0.2/Match</TickText>
                  <TickText>Email Support</TickText>
                </Box>
                <Center mt={"auto"} mb={vw(42)}>
                  <Box>
                    {active1 ? (
                      <Center
                        style={{
                          background:
                            "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                          padding: vw(17),
                          borderRadius: vw(50),
                          // borderWidth: "2px",
                          borderColor: "transparent",
                          boxShadow: "5px 10px 15px 0px rgba(125,43,233,0.2)",
                          transition: "box-shadow 0.2s ease-in-out",
                        }}
                      >
                        <Link onPress={sendIp}>
                          <Text
                            color={"white"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(!landscape ? 28 : 15)}
                            fontWeight={400}
                            // color={"black"}
                          >
                            Get it for Free!
                          </Text>
                        </Link>
                      </Center>
                    ) : (
                      <Center
                        style={{
                          borderColor: "#988AB4",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          padding: vw(15.5),
                          borderRadius: vw(50),
                        }}
                      >
                        <Link onPress={sendIp}>
                          <Text
                            // color={"#6D549F"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(!landscape ? 28 : 15)}
                            fontWeight={400}
                            color={"black"}
                          >
                            Get it for Free!
                          </Text>
                        </Link>
                      </Center>
                    )}
                  </Box>
                </Center>
              </Box>
            </Pressable>
            <Pressable
              onHoverIn={() => {
                setActive2(true);
              }}
              onHoverOut={() => {
                setActive2(false);
              }}
            >
              <Box
                // mr={"auto"}
                style={active2 ? Styles.styledbox : Styles.unstyledbox}
                width={width * 0.24}
                minH={"100%"}
              >
                <Box m={vw(42)} pl={vw(25)}>
                  <HStack alignItems={"flex-end"} alignContent={"flex-end"}>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                      // mb={20.78}
                    >
                      Solo {"\n"}
                    </Text>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                    >
                      $99{" "}
                    </Text>
                    <Text
                      // width={vw(500)}
                      // width={vw(500)}
                      fontSize={vw(15)}
                      fontFamily="Poppins"
                      fontWeight={300}
                      color={"black"}
                      lineHeight={vw(26)}
                      marginBottom={vw(4)}
                    >
                      (Monthly)
                    </Text>
                  </HStack>
                  <TickText>1 User</TickText>
                  <TickText>10000 Properties/month</TickText>
                  <TickText>Driving Routes</TickText>
                  <TickText>1 Credit = $1.00</TickText>
                  <TickText>4*6 Mail = $0.75</TickText>
                  <TickText>360° Street View = $0.007/View</TickText>
                  <TickText>
                    Street View Property Picture = $0.007/Picture
                  </TickText>
                  <TickText>Skip Trace = $0.1/Match</TickText>
                  <TickText>Feature Requests</TickText>
                  <TickText>Email Support</TickText>
                  <TickText>Live Chat Support</TickText>
                </Box>
                <Center mt={"auto"} mb={vw(42)}>
                  <Box>
                    {active2 ? (
                      <Center
                        style={{
                          background:
                            "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                          padding: vw(17),
                          borderRadius: vw(50),
                          // borderWidth: "2px",
                          borderColor: "transparent",
                          boxShadow: "5px 10px 15px 0px rgba(125,43,233,0.2)",
                          transition: "box-shadow 0.2s ease-in-out",
                        }}
                      >
                        <Link onPress={sendIp}>
                          <Text
                            color={"white"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(!landscape ? 28 : 15)}
                            fontWeight={400}
                            // color={"black"}
                          >
                            Subscribe for Solo
                          </Text>
                        </Link>
                      </Center>
                    ) : (
                      <Center
                        style={{
                          borderColor: "#988AB4",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          padding: vw(15.5),
                          borderRadius: vw(50),
                        }}
                      >
                        <Link onPress={sendIp}>
                          <Text
                            // color={"#6D549F"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(!landscape ? 28 : 15)}
                            fontWeight={400}
                            color={"black"}
                          >
                            Subscribe for Solo
                          </Text>
                        </Link>
                      </Center>
                    )}
                  </Box>
                </Center>
              </Box>
            </Pressable>
            <Pressable
              onHoverIn={() => {
                setActive3(true);
              }}
              onHoverOut={() => {
                setActive3(false);
              }}
              // ml="auto"
            >
              <Box
                // mr={"auto"}
                style={active3 ? Styles.styledbox : Styles.unstyledbox}
                width={width * 0.24}
                minH={"100%"}
              >
                <Box m={vw(42)} pl={vw(25)}>
                  <HStack alignItems={"flex-end"} alignContent={"flex-end"}>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                      // mb={20.78}
                    >
                      Team{" "}
                    </Text>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                    >
                      $299{" "}
                    </Text>
                    <Text
                      // width={vw(500)}
                      fontSize={vw(15)}
                      fontFamily="Poppins"
                      fontWeight={300}
                      color={"black"}
                      lineHeight={vw(26)}
                      marginBottom={vw(4)}
                    >
                      (Monthly)
                    </Text>
                  </HStack>
                  <TickText>5 Users</TickText>
                  <TickText>50000 Properties/month</TickText>
                  <TickText>Live Driving Monitor</TickText>
                  <TickText>1 Credit = $1.00</TickText>
                  <TickText>4*6 Mail = $0.75</TickText>
                  <TickText>360° Street View = $0.007/View</TickText>
                  <TickText>
                    Street View Property Picture = $0.007/Picture
                  </TickText>
                  <TickText>Skip Trace = $0.1/Match</TickText>
                  <TickText>Feature Requests</TickText>
                  <TickText>Email Support</TickText>
                  <TickText>Live Chat Support</TickText>
                </Box>
                <Center mt={"auto"} mb={vw(42)}>
                  <Box>
                    {active3 ? (
                      <Center
                        style={{
                          background:
                            "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                          padding: vw(17),
                          borderRadius: vw(50),
                          // borderWidth: "2px",
                          borderColor: "transparent",
                          boxShadow: "5px 10px 15px 0px rgba(125,43,233,0.2)",
                          transition: "box-shadow 0.2s ease-in-out",
                        }}
                      >
                        <Link onPress={sendIp}>
                          <Text
                            color={"white"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(!landscape ? 28 : 15)}
                            fontWeight={400}
                            // color={"black"}
                          >
                            Subscribe for Team
                          </Text>
                        </Link>
                      </Center>
                    ) : (
                      <Center
                        style={{
                          borderColor: "#988AB4",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          padding: vw(15.5),
                          borderRadius: vw(50),
                        }}
                      >
                        <Link onPress={sendIp}>
                          <Text
                            // color={"#6D549F"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(!landscape ? 28 : 15)}
                            fontWeight={400}
                            color={"black"}
                          >
                            Subscribe for Team
                          </Text>
                        </Link>
                      </Center>
                    )}
                  </Box>
                </Center>
              </Box>
            </Pressable>
          </HStack>
        </Box>
        {/* <Box
          backgroundColor={"rgb(125, 43, 233)"}
          py={vw(10)}
          px={vw(20)}
          rounded={"full"}
          mb={"10px"}
        >
          <Text
            // ml={2}
            color={"white"}
            fontFamily="Poppins"
            fontWeight={300}
            // color={"black"}
            fontSize={vw(15)}
          >
            Expand Your Team size by subscribing to the Custom Enterprise plan.{" "}
            <Link fontWeight={500} href="/#contact">
              Contact Us!
            </Link>
          </Text>
        </Box> */}
        <Center
          style={{
            width: "100%",
            minHeight: "100px",
          }}
          my={"2%"}
        >
          <HStack
            style={{
              width: "94.44%",
              borderRadius: "20px",
              background: "#F8F3FF",
              padding: "8px",
            }}
          >
            <Center
              style={{
                width: "17.75%",
                // backgroundColor: "pink",
              }}
            >
              <VStack
                style={{
                  width: "75.98%",
                  height: "100%",
                  // backgroundColor: "orange",
                  paddingVertical: "30px",
                  gap: "5px",
                }}
              >
                <Center
                  style={{
                    borderRadius: "12px",
                    // padding: "17px",
                    background:
                      "linear-gradient(302deg, #7D2BE5 21.24%, #BB85FF 84.48%)",
                    height: `${selectionHeight}px`,
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: vw(20),
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "24px",
                    }}
                  >
                    Plan Features
                  </Text>
                </Center>
                {basicFeaturesList.map((item, index) => (
                  <Center
                    style={{
                      borderRadius: "10px",
                      border: "1px solid transparent",
                      background: "transparent",
                      minHeight: "46px",
                      paddingHorizontal: "8px",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        color: "#382058",
                        textAlign: "right",
                        fontFamily: "Poppins",
                        fontSize: vw(12),
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                      }}
                    >
                      {item}
                    </Text>
                  </Center>
                ))}
                <Center style={{ height: "136px" }}>
                  <Center
                    style={{
                      borderRadius: "12px",
                      padding: "17px",
                      background:
                        "linear-gradient(302deg, #7D2BE5 21.24%, #BB85FF 84.48%)",
                      height: `${selectionHeight}px`,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: vw(20),
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "24px",
                      }}
                    >
                      Additional Costs
                    </Text>
                  </Center>
                </Center>
                {additionalFeatureList.map((item, index) => (
                  <Center
                    style={{
                      borderRadius: "10px",
                      border: "1px solid transparent",
                      background: "transparent",
                      minHeight: "46px",
                      paddingHorizontal: "8px",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        color: "#382058",
                        textAlign: "right",
                        fontFamily: "Poppins",
                        fontSize: vw(12),
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                      }}
                    >
                      {item}
                    </Text>
                  </Center>
                ))}
              </VStack>
            </Center>
            <HStack
              style={{
                width: "82.25%",
                borderRadius: "20px",
                background: "#FFF",
                padding: "25px",
                gap: "1.89%",
              }}
            >
              <FeaturesColumn
                cardIndex={0}
                shownProducts={shownProducts}
                setShownProducts={setShownProducts}
                options={options}
                setSelectionHeight={setSelectionHeight}
                defaultOnly={true}
              />
              <FeaturesColumn
                cardIndex={1}
                shownProducts={shownProducts}
                setShownProducts={setShownProducts}
                options={options}
                // setSelectionHeight={setSelectionHeight}
                // options={options.filter(val=>val!==index)}
              />
              <FeaturesColumn
                cardIndex={2}
                shownProducts={shownProducts}
                setShownProducts={setShownProducts}
                options={options}
                // setSelectionHeight={setSelectionHeight}
              />
            </HStack>
          </HStack>
        </Center>
        <Text
          // ml={2}
          color={"black"}
          fontFamily="Poppins"
          fontWeight={300}
          // color={"black"}
          fontSize={vw(15)}
        >
          Expand Your Team size by subscribing to the Custom Enterprise plan.{" "}
          <Link fontWeight={500} href="/#contact">
            Contact Us!
          </Link>
        </Text>
      </Center>
    </VStack>
  ) : (
    // <VStack
    //   style={{
    //     height: "min-content",
    //     width: "100%",
    //     background: "rgb(135, 207, 255)",
    //     background: `linear-gradient(0deg, rgba(135,207,255,1) 0%, rgba(255,244,254,1) 80%)`,
    //     backgroundSize: "cover",
    //   }}
    // >
    //   <Center>
    //     <Heading
    //       fontSize={vh(40)}
    //       color="rgb(125,43,233)"
    //       fontFamily={"poppins"}
    //       padding={vh(20)}
    //     >
    //       Choose a Plan
    //     </Heading>
    //     <Box
    //       borderRadius={"30"}
    //       alignSelf={"center"}
    //       borderStyle={"solid"}
    //       borderWidth={3}
    //       borderColor={"rgb(125,43,233)"}
    //     >
    //       <HStack>
    //         <Pressable
    //           onPress={() => {
    //             scrollref.current.scrollTo({ x: 0 });
    //           }}
    //         >
    //           <Box style={index == 1 ? Styles.blueBg : Styles.whiteBg}>
    //             <Center>
    //               <Text
    //                 color={index == 1 ? "white" : "rgb(125,43,233)"}
    //                 fontFamily={"HelveticaNeueLTStd-Md"}
    //               >
    //                 Solo
    //               </Text>
    //             </Center>
    //           </Box>
    //         </Pressable>
    //         <Pressable
    //           onPress={() => {
    //             scrollref.current.scrollTo({ x: window.innerWidth });
    //           }}
    //         >
    //           <Box style={index == 2 ? Styles.blueBg : Styles.whiteBg}>
    //             <Center>
    //               <Text
    //                 color={index == 2 ? "white" : "rgb(125,43,233)"}
    //                 fontFamily={"HelveticaNeueLTStd-Md"}
    //               >
    //                 Team
    //               </Text>
    //             </Center>
    //           </Box>
    //         </Pressable>
    //       </HStack>
    //     </Box>
    //     <Box width={width} maxH={"100%"} overflow={"auto"}>
    //       <ScrollView
    //         maxH={"100%"}
    //         horizontal
    //         contentContainerStyle={{ marginBottom: "5%" }}
    //         onScroll={(e) => {
    //           handleScroll(e.nativeEvent.contentOffset.x);
    //         }}
    //         scrollEventThrottle={20}
    //         ref={scrollref}
    //         showsHorizontalScrollIndicator={false}
    //       >
    //         <HStack
    //           // marginX={width > 600 ? width * 0.06 : 0}
    //           width={"200vw"}
    //           justifyContent={"space-evenly"}
    //         >
    //           <Box
    //             style={Styles.styledbox}
    //             m={10}
    //             // height={height * 0.7}
    //             width={width * 0.7}
    //             overflow={"auto"}
    //           >
    //             <Box margin={8} ml={10}>
    //               <HStack>
    //                 <Text
    //                   fontSize={30}
    //                   color={"rgb(125,43,233)"}
    //                   fontFamily={"HelveticaNeueLTStd-Bd"}
    //                 >
    //                   $99
    //                 </Text>
    //                 <Text
    //                   m={3}
    //                   color={"rgb(152,138,180)"}
    //                   fontFamily={"HelveticaNeueLTStd-Md"}
    //                 >
    //                   (Monthly)
    //                 </Text>
    //               </HStack>
    //               <TickText>1 User</TickText>
    //               <TickText>10000 Properties/day</TickText>
    //               <TickText>Driving Routes</TickText>
    //               <TickText>1 Credit = $1.00</TickText>
    //               <TickText>4*6 Mail = $0.75</TickText>
    //               <TickText>360° Street View = $0.007/View</TickText>
    //               <TickText>Skip Trace = $0.1/Match</TickText>
    //               <TickText>Feature Requests</TickText>
    //               <TickText>Email Support</TickText>
    //               <TickText>Live Chat Support</TickText>
    //             </Box>
    //             <Center mt={"auto"} mb={5}>
    //               <Box>
    //                 <Center
    //                   style={{
    //                     background:
    //                       "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
    //                     padding: vh(20),
    //                     borderRadius: vh(50),
    //                   }}
    //                 >
    //                   <Link href="https://web.shadow.properties/" isExternal>
    //                     <Text
    //                       color={"white"}
    //                       textAlign={"center"}
    //                       mx={5}
    //                       fontFamily={"HelveticaNeueLTStd-Md"}
    //                     >
    //                       Subscribe for Solo
    //                     </Text>
    //                   </Link>
    //                 </Center>
    //               </Box>
    //             </Center>
    //           </Box>
    //           <Box
    //             style={Styles.styledbox}
    //             m={10}
    //             ml={20}
    //             // height={height * 0.7}
    //             overflow={"auto"}
    //             width={width * 0.7}
    //           >
    //             <Box margin={8}>
    //               <HStack>
    //                 <Text
    //                   fontSize={30}
    //                   color={"rgb(125,43,233)"}
    //                   fontFamily={"HelveticaNeueLTStd-Bd"}
    //                 >
    //                   $299
    //                 </Text>
    //                 <Text
    //                   m={3}
    //                   color={"rgb(152,138,180)"}
    //                   fontFamily={"HelveticaNeueLTStd-Md"}
    //                 >
    //                   (Monthly)
    //                 </Text>
    //               </HStack>
    //               <TickText>5 Users</TickText>
    //               <TickText>50000 Properties/day</TickText>
    //               <TickText>Live Driving Monitor</TickText>
    //               <TickText>1 Credit = $1.00</TickText>
    //               <TickText>4*6 Mail = $0.75</TickText>
    //               <TickText>360° Street View = $0.007/View</TickText>
    //               <TickText>Skip Trace = $0.1/Match</TickText>
    //               <TickText>Feature Requests</TickText>
    //               <TickText>Email Support</TickText>
    //               <TickText>Live Chat Support</TickText>
    //             </Box>
    //             <Center mt={"auto"} mb={5}>
    //               <Box>
    //                 <Center
    //                   style={{
    //                     background:
    //                       "transparent linear-gradient(135deg, #AD75F8 0%, #7D2BE9 100%) 0% 0% no-repeat padding-box",
    //                     padding: vh(20),
    //                     borderRadius: vh(50),
    //                   }}
    //                 >
    //                   <Link href="https://web.shadow.properties/" isExternal>
    //                     <Text
    //                       color={"white"}
    //                       textAlign={"center"}
    //                       mx={5}
    //                       fontFamily={"HelveticaNeueLTStd-Md"}
    //                     >
    //                       Subscribe for Team
    //                     </Text>
    //                   </Link>
    //                 </Center>
    //               </Box>
    //             </Center>
    //           </Box>
    //         </HStack>
    //       </ScrollView>
    //     </Box>
    //     <HStack alignSelf="center" space="2" padding={vh(20)}>
    //       <Stack style={index == 2 ? Styles.mystyle1 : Styles.mystyle}>
    //         <Text> </Text>
    //       </Stack>
    //       <Stack style={index == 1 ? Styles.mystyle1 : Styles.mystyle}>
    //         <Text> </Text>
    //       </Stack>
    //     </HStack>
    //   </Center>
    // </VStack>
    <VStack
      style={{
        height: "min-content",
        width: "100%",
        background: "rgb(135, 207, 255)",
        background: `linear-gradient(0deg, rgba(135,207,255,1) 0%, rgba(255,244,254,1) 80%)`,
        backgroundSize: "cover",
        paddingVertical: vw(22),
      }}
    >
      <Center>
        <Text
          // marginTop={vh(50)}
          fontFamily={"Poppins"}
          fontWeight={"600"}
          fontSize={vw(58)}
          color={"#7D2BE9"}
        >
          Choose a Plan
        </Text>
        <Text
          // style={{
          //   color: "#6D549F",
          //   fontFamily: "HelveticaNeueLTStd-Bd",
          //   fontSize: vw(18),
          // }}
          // marginLeft={5}
          fontSize={vw(15)}
          fontFamily="Poppins"
          fontWeight={300}
          color={"black"}
          lineHeight={vw(24)}
        >
          The only intuitive, affordable, and fully-featured app for both mobile
          and web with zero hidden fees. Try to differ after trying.
        </Text>
        <Box m={vw(42)} width={width * 0.8}>
          <HStack justifyContent={"space-evenly"}>
            <Pressable
              onHoverIn={() => {
                setActive1(true);
              }}
              onHoverOut={() => {
                setActive1(false);
              }}
            >
              <Box
                // mr={"auto"}
                style={active1 ? Styles.styledbox : Styles.unstyledbox}
                width={width * 0.24}
                minH={"100%"}
              >
                <Box m={vw(42)} pl={vw(25)}>
                  <HStack alignItems={"flex-end"} alignContent={"flex-end"}>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                      // mb={20.78}
                    >
                      Free {"\n"}
                    </Text>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                    >
                      $0{" "}
                    </Text>
                  </HStack>
                  <Text
                    // width={vw(500)}
                    fontSize={vw(15)}
                    fontFamily="Poppins"
                    fontWeight={300}
                    color={"black"}
                    lineHeight={vw(26)}
                    marginBottom={vw(4)}
                  >
                    (Try for free till you ❤️ it)
                  </Text>
                  <TickText>1 User</TickText>
                  <TickText>100 Properties</TickText>
                  <TickText>+100 properties for every referral</TickText>
                  <TickText>Driving Routes</TickText>
                  <TickText>4*6 Mail = $1.00</TickText>
                  <TickText>360° Street View = $0.01/View</TickText>
                  <TickText>Skip Trace = $0.2/Match</TickText>
                  <TickText>Email Support</TickText>
                </Box>
                <Center mt={"auto"} mb={vw(42)}>
                  <Box>
                    {active1 ? (
                      <Center
                        style={{
                          background:
                            "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                          padding: vw(17),
                          borderRadius: vw(50),
                          // borderWidth: "2px",
                          borderColor: "transparent",
                          boxShadow: "5px 10px 15px 0px rgba(125,43,233,0.2)",
                          transition: "box-shadow 0.2s ease-in-out",
                        }}
                      >
                        <Link href="https://web.shadow.properties/" isExternal>
                          <Text
                            color={"white"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(15)}
                            fontWeight={400}
                            // color={"black"}
                          >
                            Get it for Free!
                          </Text>
                        </Link>
                      </Center>
                    ) : (
                      <Center
                        style={{
                          borderColor: "#988AB4",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          padding: vw(15.5),
                          borderRadius: vw(50),
                        }}
                      >
                        <Link href="https://web.shadow.properties/" isExternal>
                          <Text
                            // color={"#6D549F"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(15)}
                            fontWeight={400}
                            color={"black"}
                          >
                            Get it for Free!
                          </Text>
                        </Link>
                      </Center>
                    )}
                  </Box>
                </Center>
              </Box>
            </Pressable>
            <Pressable
              onHoverIn={() => {
                setActive2(true);
              }}
              onHoverOut={() => {
                setActive2(false);
              }}
            >
              <Box
                // mr={"auto"}
                style={active2 ? Styles.styledbox : Styles.unstyledbox}
                width={width * 0.24}
                minH={"100%"}
              >
                <Box m={vw(42)} pl={vw(25)}>
                  <HStack alignItems={"flex-end"} alignContent={"flex-end"}>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                      // mb={20.78}
                    >
                      Solo {"\n"}
                    </Text>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                    >
                      $99{" "}
                    </Text>
                    <Text
                      // width={vw(500)}
                      // width={vw(500)}
                      fontSize={vw(15)}
                      fontFamily="Poppins"
                      fontWeight={300}
                      color={"black"}
                      lineHeight={vw(26)}
                      marginBottom={vw(4)}
                    >
                      (Monthly)
                    </Text>
                  </HStack>
                  <TickText>1 User</TickText>
                  <TickText>10000 Properties/month</TickText>
                  <TickText>Driving Routes</TickText>
                  <TickText>1 Credit = $1.00</TickText>
                  <TickText>4*6 Mail = $0.75</TickText>
                  <TickText>360° Street View = $0.007/View</TickText>
                  <TickText>Skip Trace = $0.1/Match</TickText>
                  <TickText>Feature Requests</TickText>
                  <TickText>Email Support</TickText>
                  <TickText>Live Chat Support</TickText>
                </Box>
                <Center mt={"auto"} mb={vw(42)}>
                  <Box>
                    {active2 ? (
                      <Center
                        style={{
                          background:
                            "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                          padding: vw(17),
                          borderRadius: vw(50),
                          // borderWidth: "2px",
                          borderColor: "transparent",
                          boxShadow: "5px 10px 15px 0px rgba(125,43,233,0.2)",
                          transition: "box-shadow 0.2s ease-in-out",
                        }}
                      >
                        <Link href="https://web.shadow.properties/" isExternal>
                          <Text
                            color={"white"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(15)}
                            fontWeight={400}
                            // color={"black"}
                          >
                            Subscribe for Solo
                          </Text>
                        </Link>
                      </Center>
                    ) : (
                      <Center
                        style={{
                          borderColor: "#988AB4",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          padding: vw(15.5),
                          borderRadius: vw(50),
                        }}
                      >
                        <Link href="https://web.shadow.properties/" isExternal>
                          <Text
                            // color={"#6D549F"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(15)}
                            fontWeight={400}
                            color={"black"}
                          >
                            Subscribe for Solo
                          </Text>
                        </Link>
                      </Center>
                    )}
                  </Box>
                </Center>
              </Box>
            </Pressable>
            <Pressable
              onHoverIn={() => {
                setActive3(true);
              }}
              onHoverOut={() => {
                setActive3(false);
              }}
              // ml="auto"
            >
              <Box
                // mr={"auto"}
                style={active3 ? Styles.styledbox : Styles.unstyledbox}
                width={width * 0.24}
                minH={"100%"}
              >
                <Box m={vw(42)} pl={vw(25)}>
                  <HStack alignItems={"flex-end"} alignContent={"flex-end"}>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                      // mb={20.78}
                    >
                      Team{" "}
                    </Text>
                    <Text
                      color={"rgb(125,43,233)"}
                      fontSize={vw(36)}
                      fontWeight={500}
                      fontFamily="Poppins"
                      lineHeight={vw(48)}
                    >
                      $299{" "}
                    </Text>
                    <Text
                      // width={vw(500)}
                      fontSize={vw(15)}
                      fontFamily="Poppins"
                      fontWeight={300}
                      color={"black"}
                      lineHeight={vw(26)}
                      marginBottom={vw(4)}
                    >
                      (Monthly)
                    </Text>
                  </HStack>
                  <TickText>5 Users</TickText>
                  <TickText>50000 Properties/month</TickText>
                  <TickText>Live Driving Monitor</TickText>
                  <TickText>1 Credit = $1.00</TickText>
                  <TickText>4*6 Mail = $0.75</TickText>
                  <TickText>360° Street View = $0.007/View</TickText>
                  <TickText>Skip Trace = $0.1/Match</TickText>
                  <TickText>Feature Requests</TickText>
                  <TickText>Email Support</TickText>
                  <TickText>Live Chat Support</TickText>
                </Box>
                <Center mt={"auto"} mb={vw(42)}>
                  <Box>
                    {active3 ? (
                      <Center
                        style={{
                          background:
                            "transparent linear-gradient(135deg, #AD75F8 20%, #7D2BE9 60%) 0% 0% no-repeat padding-box",
                          padding: vw(17),
                          borderRadius: vw(50),
                          // borderWidth: "2px",
                          borderColor: "transparent",
                          boxShadow: "5px 10px 15px 0px rgba(125,43,233,0.2)",
                          transition: "box-shadow 0.2s ease-in-out",
                        }}
                      >
                        <Link href="https://web.shadow.properties/" isExternal>
                          <Text
                            color={"white"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(15)}
                            fontWeight={400}
                            // color={"black"}
                          >
                            Subscribe for Team
                          </Text>
                        </Link>
                      </Center>
                    ) : (
                      <Center
                        style={{
                          borderColor: "#988AB4",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          padding: vw(15.5),
                          borderRadius: vw(50),
                        }}
                      >
                        <Link href="https://web.shadow.properties/" isExternal>
                          <Text
                            // color={"#6D549F"}
                            textAlign={"center"}
                            mx={vw(57)}
                            px={vw(5)}
                            fontFamily="Poppins"
                            fontSize={vw(15)}
                            fontWeight={400}
                            color={"black"}
                          >
                            Subscribe for Team
                          </Text>
                        </Link>
                      </Center>
                    )}
                  </Box>
                </Center>
              </Box>
            </Pressable>
          </HStack>
        </Box>
        {/* <Box
          backgroundColor={"rgb(125, 43, 233)"}
          py={vw(10)}
          px={vw(20)}
          rounded={"full"}
          mb={"10px"}
        >
          <Text
            // ml={2}
            color={"white"}
            fontFamily="Poppins"
            fontWeight={300}
            // color={"black"}
            fontSize={vw(15)}
          >
            Expand Your Team size by subscribing to the Custom Enterprise plan.{" "}
            <Link fontWeight={500} href="/#contact">
              Contact Us!
            </Link>
          </Text>
        </Box> */}
        <Center
          style={{
            width: "100%",
            minHeight: "100px",
          }}
        >
          <HStack
            style={{
              width: "94.44%",
              borderRadius: "20px",
              background: "#F8F3FF",
              padding: "8px",
            }}
          >
            <Center
              style={{
                width: "17.75%",
                // backgroundColor: "pink",
              }}
            >
              <VStack
                style={{
                  width: "75.98%",
                  height: "100%",
                  // backgroundColor: "orange",
                  paddingVertical: "30px",
                  gap: "5px",
                }}
              >
                <Center
                  style={{
                    borderRadius: "12px",
                    // padding: "17px",
                    background:
                      "linear-gradient(302deg, #7D2BE5 21.24%, #BB85FF 84.48%)",
                    height: `${selectionHeight}px`,
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: vw(20),
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "24px",
                    }}
                  >
                    Plan Features
                  </Text>
                </Center>
                {basicFeaturesList.map((item, index) => (
                  <Center
                    style={{
                      borderRadius: "10px",
                      border: "1px solid transparent",
                      background: "transparent",
                      minHeight: "46px",
                      paddingHorizontal: "8px",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        color: "#382058",
                        textAlign: "right",
                        fontFamily: "Poppins",
                        fontSize: vw(12),
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                      }}
                    >
                      {item}
                    </Text>
                  </Center>
                ))}
                <Center style={{ height: "136px" }}>
                  <Center
                    style={{
                      borderRadius: "12px",
                      padding: "17px",
                      background:
                        "linear-gradient(302deg, #7D2BE5 21.24%, #BB85FF 84.48%)",
                      height: `${selectionHeight}px`,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: vw(20),
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "24px",
                      }}
                    >
                      Additional Costs
                    </Text>
                  </Center>
                </Center>
                {additionalFeatureList.map((item, index) => (
                  <Center
                    style={{
                      borderRadius: "10px",
                      border: "1px solid transparent",
                      background: "transparent",
                      minHeight: "46px",
                      paddingHorizontal: "8px",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        color: "#382058",
                        textAlign: "right",
                        fontFamily: "Poppins",
                        fontSize: vw(12),
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15px",
                      }}
                    >
                      {item}
                    </Text>
                  </Center>
                ))}
              </VStack>
            </Center>
            <HStack
              style={{
                width: "82.25%",
                borderRadius: "20px",
                background: "#FFF",
                padding: "25px",
                gap: "1.89%",
              }}
            >
              <FeaturesColumn
                cardIndex={0}
                shownProducts={shownProducts}
                setShownProducts={setShownProducts}
                options={options}
                setSelectionHeight={setSelectionHeight}
                defaultOnly={true}
              />
              <FeaturesColumn
                cardIndex={1}
                shownProducts={shownProducts}
                setShownProducts={setShownProducts}
                options={options}
                // setSelectionHeight={setSelectionHeight}
                // options={options.filter(val=>val!==index)}
              />
              <FeaturesColumn
                cardIndex={2}
                shownProducts={shownProducts}
                setShownProducts={setShownProducts}
                options={options}
                // setSelectionHeight={setSelectionHeight}
              />
            </HStack>
          </HStack>
        </Center>
        <Text
          // ml={2}
          color={"black"}
          fontFamily="Poppins"
          fontWeight={300}
          // color={"black"}
          fontSize={vw(15)}
        >
          Expand Your Team size by subscribing to the Custom Enterprise plan.{" "}
          <Link fontWeight={500} href="/#contact">
            Contact Us!
          </Link>
        </Text>
      </Center>
    </VStack>
  );
};

export default React.memo(Large);

const Styles = StyleSheet.create({
  // styledbox: {
  //   backgroundColor: "white",
  //   shadowColor: "#7D2BE959",
  //   shadowRadius: 30,
  //   padding: 10,
  //   height: height * 0.8,
  //   width: width * 0.8,
  //   borderRadius: 40,
  //   opacity: 1,
  // },
  whiteBg: {
    backgroundColor: "transparent",
    variant: "unstyled",
    borderRadius: 30,
    padding: 10,
    width: 110,
    height: 40,
    transition: "all 0.2s ease-in-out",
  },
  blueBg: {
    backgroundColor: "rgb(125,43,233)",
    variant: "unstyled",
    borderRadius: 30,
    padding: 10,
    width: 110,
    height: 40,
    transition: "all 0.2s ease-in-out",
  },
  mystyle: {
    borderRadius: 50,
    borderWidth: 2,
    width: 20,
    height: 20,
    shadow: 2,
    alignSelf: "center",
    borderColor: "#7D2BE9",
    opacity: "100%",
    transition: "all 0.2s ease-in-out",
  },
  mystyle1: {
    borderRadius: 50,
    borderWidth: 3,
    width: "45%",
    aspectRatio: 1,
    backgroundColor: "blue",
    alignSelf: "center",
    borderColor: "#7D2BE9",
    opacity: "30%",
    transition: "all 0.2s ease-in-out",
  },
  styledbox: {
    backgroundColor: "white",
    shadowColor: "#7D2BE959",
    shadowRadius: 30,
    shadowOffset: { width: 0, height: 30 },
    borderRadius: 40,
    opacity: 1,

    transition: "box-shadow 0.2s ease-in-out",
  },
  unstyledbox: {
    backgroundColor: "white",
    borderRadius: 40,
    opacity: 1,

    transition: "box-shadow 0.2s ease-in-out",
  },
  Button: {
    padding: 10,
    borderRadius: 30,
  },
});
